import { decryptData } from "./crypto";

export function hasPermission(permission) {
    const userPermissions = localStorage.getItem("user_pemissions");
  
    if (userPermissions) {
      const permissions = decryptData(userPermissions); // Decrypt the permissions
      return permissions?.includes(permission);
    }
  
    return false;
  }
  
  