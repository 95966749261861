<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">AGING REPORT</h6>
                </div>
                <div class="col-6 text-end">
                  <b-button @click="generateExcel" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']" />
                  </b-button>
                  <report-filter :form="form" @filterAction="filterAction" :filter="filter"></report-filter>
                  <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon
                      :icon="['fas', 'book']" /></b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon
                      :icon="['fas', 'download']" /></b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <!-- <div class="row">
                  <div class="col-md-12">
                   
                  </div>
                </div> -->
              <div class="row" ref="pdfContent">
                <div class="col-12 col-md-12">
                  <statement-institution :institution="institution" :current_date="false"
                    :title="'AGING REPORT'"></statement-institution>
                </div>
                <hr />
                <div class="col-12" style="text-align: right;font-weight: bolder">
                  <span v-if="filter.start_date">
                    From {{ filter.start_date }}
                  </span>
                  <span v-if="filter.end_date">
                    To {{ filter.end_date }}
                  </span>
                </div>

                <div class="col-12 col-md-12 trans_details">
                  <b-table striped bordered responsive id="member-table" :busy.sync="isBusy" :items="rows"
                    :fields="fields" :tbody-tr-class="rowClass" show-empty>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template #cell(fullname)="data">
                      {{ data.item.customer?.first_name + data.item.customer?.last_name }}
                    </template>
                    <template #cell(principal_amount)="data">
                      {{ displayNumber(data.item.principal_amount) }}
                    </template>
                    <template #cell(interest_paid)="data">
                      {{ displayNumber(data.item.interest_paid) }}
                    </template>
                    <template #cell(principal_paid)="data">
                      {{ displayNumber(data.item.principal_paid) }}
                    </template>
                    <template #cell(loan_balance)="data">
                      {{ displayNumber(data.item.interest_balance + data.item.principal_balance) }}
                    </template>
                    <template #cell(savings_total)="data">
                      {{ displayNumber(data.item.savings_total) }}
                    </template>
                    <template #cell(net_balance)="data">
                      {{ displayNumber((data.item.interest_balance + data.item.principal_balance) - data.item.savings_total) }}
                    </template>
                  </b-table>
                </div>

              </div>
              <div class="row">
                <div class="col-md-12 text-end">
                  <div class="d-flex justify-content-between">
                    <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage" :total-rows="totalRecords"
                      :per-page="perPage" aria-controls="member-table"></b-pagination>
                    <!-- <b-form-select @change="selectPerPage" v-model="per_page_selected" :options="per_page_options"
                        size="sm" class="mt-3"></b-form-select> -->
                    <div class="text-xs col text-end mt-1 akkurate-dark">
                      <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                      <b-form-group label="Per Page" label-size="sm" class="mb-0">
                        <b-form-select @change="selectPerPage" id="per-page-select" v-model="perPage"
                          :options="per_page_options" size="sm"></b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import html2pdf from "html2pdf.js";
import ReportFilter from "../form/ReportFilter.vue";
import ExcelJS from 'exceljs';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter,
  },
  data() {
    return {
      form: {
        start_date: new Date().toISOString().split("T")[0],
        end_date: new Date().toISOString().split("T")[0],
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        purpose: '',
        terms_options:[],
        branch_options:[],
        coa_options:[],
      },
      filter: {
        condition: true,
        purpose: true,
        start_date:true,
        end_date:true,
      },
      totalRecords: 0,
      search: "Search",
      isBusy: false,
      isSearching: false,
      isLoadingInstitution: false,
      institution: "",
      currentPage: 1,
      perPage: 10,
      per_page_options: ['10', '20', '50', '100','500'],
      per_page_selected: "10",
      fields: [
        {
          key: "index",
          label: "S/N",
        },
        {
          key: "loan_number",
          label: "Loan Number",
        },
        {
          key: "fullname",
          label: "Customer",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "start_date",
          label: "Start Date",
        },
        {
          key: "maturity_date",
          label: "Maturity Date",
        },
        {
          key: "current_duration",
          label: "Duration(Days)",
        },
        {
          key: "customer.mobile",
          label: "Mobile",
        },
        {
          key: "principal_amount",
          label: "Principal",
        },
        {
          key: "interest_paid",
          label: "Interest Pd",
        },
        {
          key: "principal_paid",
          label: "Principal Pd",
        },
        {
          key: "loan_balance",
          label: "Loan Bal.",
        },
        {
          key: "savings_total",
          label: "Saving Bal.",
        },
        {
          key: "net_balance",
          label: "Net Bal.",
        },
      ],
      rows: [],
    };
  },
  computed: {
    // rows() {
    //   return this.items?.length ?? 0;
    // },
    // sumLoanBalance(){
    //   let total = 0;
    //   for(let i = 0; i < this.items.length; i++){
    //     total = this.items[i].loan_balance;
    //   }
    //   return total;
    // },
    // sumSavingBalance(){
    //   let total = 0;
    //   for(let i = 0; i < this.items.length; i++){
    //     total = this.items[i].saving_balance;
    //   }

    //   return total;
    // },
  },

  created() {

    // var date = new Date();
    // var lastMonth = new Date(date.setMonth(date.getMonth() - 3));
    // this.filter.start_date = lastMonth.toISOString().split("T")[0];
    // this.filter.end_date = new Date().toISOString().split("T")[0];

    this.getInstitution();
    this.getAging();
  },
  methods: {
    rowClass() {
      return 'custom-row-height'
    },
    navigatePage(index) {
      this.isBusy = true;
      this.currentPage = index;
      this.getAging()
    },
    async getAging() {
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.post("/reports/loans/aging/delinquency", {
        "page": this.currentPage,
        "per_page": this.perPage,
        "start_date": this.form.start_date,
        "end_date": this.form.end_date,
      })
        .then((response) => {
          // this.isBusy = false;
          // this.search = "Search"
          // this.isSearching = false;
          // var res = response.data?.data;
          // // console.log(response);
          // this.items = res;
          // this.totalRecords = response.data.meta.total;
          // Handle response and update table data
          this.isBusy = false;  // Set isBusy to false when the data is loaded
          this.$Progress.finish();  // Finish the progress bar
          this.rows = response.data.data;
          this.totalRecords = response.data.meta.total;
        })
        .catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        });
    },
    filterAction() {
      this.getAging()
      // this.changePerPageNumber(this.perPage);
    },
    selectPerPage(index) {
      this.perPage = index
      this.getAging()
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
    async generateExcel() {
      const fileUrl = '/REPORT_TEMPLATE_NEW.xlsx'; // Path to the file in the public folder
      const imageUrl = '/default_logo.png';  // Path to your image file


      // Institution details
      const institutionName = this.institution.name;
      const institutionMotto = this.institution.motto;
      const institutionAddress = this.institution.address;
      const institutionPhone = this.institution.telephone;
      const institutionEmail = this.institution.email;
      const date = 'FROM: ' + this.form.start_date + ' TO: ' + this.form.end_date
      const terms = 'TERMS:  ' + this.form.terms

      try {
        // Fetch the Excel file from the public URL
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        // Load the file into ExcelJS
        const wb = new ExcelJS.Workbook();
        await wb.xlsx.load(arrayBuffer);

        // Access the first sheet
        const ws = wb.getWorksheet(1); // Get the first worksheet

        // Add Image to the top of the sheet
        const imageResponse = await fetch(imageUrl);
        const imageBuffer = await imageResponse.arrayBuffer();
        const imageId = wb.addImage({
          buffer: imageBuffer,
          extension: 'png',  // Adjust this if you're using a different image type (e.g., jpg)
        });

        // Insert the image at the top-left corner (row 1, column 1)
        ws.addImage(imageId, {
          tl: { col: 0, row: 0 },  // Top-left corner (adjust this as needed)
          ext: { width: 300, height: 200 },  // Set the image size (adjust width/height as needed)
        });

        // Insert institution details next to the image (starting at row 1, col 5)
        const institutionDetailsStartCol = 5; // Column where text starts after image
        const institutionDetailsRow = 1;  // Row for institution details

        // Add the institution name, address, and phone number
        ws.getCell(`Q${institutionDetailsRow}`).value = institutionName;
        ws.getCell(`Q${institutionDetailsRow}`).font = { bold: true, size: 14 }; // Bold and bigger font for name

        ws.getCell(`Q${institutionDetailsRow + 1}`).value = institutionAddress;
        ws.getCell(`Q${institutionDetailsRow + 1}`).font = { size: 12 };  // Regular font size for address

        ws.getCell(`Q${institutionDetailsRow + 2}`).value = institutionMotto;
        ws.getCell(`Q${institutionDetailsRow + 2}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`Q${institutionDetailsRow + 3}`).value = institutionPhone;
        ws.getCell(`Q${institutionDetailsRow + 3}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`Q${institutionDetailsRow + 4}`).value = institutionEmail;
        ws.getCell(`Q${institutionDetailsRow + 4}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`Q${institutionDetailsRow + 6}`).value = date;
        ws.getCell(`Q${institutionDetailsRow + 6}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`Q${institutionDetailsRow + 7}`).value = terms;
        ws.getCell(`Q${institutionDetailsRow + 7}`).font = { size: 12 };  // Regular font size for phone

        // Optionally adjust the column width if the text is long
        ws.getColumn('R').width = 50;  // Adjust the width of column D (if necessary)

        // Define header row
        const header = [
          "Loan Number",
          "Customer",
          "Status",
          "Start Date",
          "Maturity Date",
          "Duration(Days)",
          "Mobile",
          "Principal",
          "Interest Pd",
          "Principal Pd",
          "Loan Bal.",
          "Saving Bal.",
          "Net Bal.",
          "Principal Balance",
        ];



        // Add header row starting from row 13 (or the next row after the image and details)
        const startingRow = institutionDetailsRow + 10;  // Row 4 after institution details
        let headerRow = ws.getRow(startingRow);
        headerRow.values = header;
        headerRow.font = { bold: true };
        headerRow.commit(); // Commit the row to the worksheet

        // Add individual data rows
        let currentRow = startingRow + 1;


        // Process each item
        this.rows.forEach(item => {
          const row = ws.getRow(currentRow);
          // Map item values to the corresponding header columns
          row.values = [
            item.loan_number,
            item.customer.first_name + ' ' + item.customer.last_name,
            item.status,
            item.start_date,
            item.maturity_date,
            item.current_duration,
            item.customer?.mobile,
            item.principal_amount,
            item.interest_paid,
            item.principal_paid,
            item.interest_balance + item.principal_balance,
            item.savings_total,
            (item.interest_balance + item.principal_balance) - item.savings_total,
            item.principal_balance,
          ];
          // Apply text wrapping and center align for COA Name and COA Total columns
          row.getCell(4).alignment = { wrapText: true, vertical: 'top', horizontal: 'top' }; // COA Name
          row.getCell(5).alignment = { wrapText: true, vertical: 'top', horizontal: 'top' }; // COA Total

          row.commit();
          currentRow++;
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link and simulate a click to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Modified_REPORT_TEMPLATE.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      }
    }
  },
};
</script>