<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">
          <div class="card card-shape home-box">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">NOTE TO THE BALANCE SHEET</h6>
                </div>
                <div class="col-6 text-end">
                  <b-button @click="generateExcel" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']" />
                  </b-button>
                  <report-filter :form="form" @filterAction="filterAction" :filter="filter"></report-filter>
                  <b-button @click="printPDF" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'book']" />
                  </b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'download']" />
                  </b-button>
                </div>
              </div>

            </div>
            <div class="card-body" ref="pdfContent">
              <div class="row">
                <div class="col-12 col-md-12">
                  <statement-institution :institution="institution" :current_date="false"
                    :title="'NOTE TO THE BALANCE SHEET'"></statement-institution>
                </div>
                <hr />
              </div>

              <div class="row">
                <div class="col-md-12 text-end">
                  <div>Terms: <span class="fw-bold">{{ form.terms }}</span></div>
                  <span class="mt-2">From: <strong>{{ form.start_date }}</strong> To: <strong>{{
                    form.end_date }}</strong> </span>
                </div>
              </div>
              <!-- <div class="" style="text-align: center;">
                <div class="fw-bold akkurate-green">NOTE TO THE BALANCE SHEET</div>
              </div> -->
              <div v-if="isBusy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
              <div v-else id="balance-sheet" class="row">
                <div class="col-12 my-2">
                </div>
                <div class="col-6 col-md-6 px-0 trans_details">
                  <b-table-simple outlined bordered class="mb-0">
                    <b-thead>
                      <b-tr>
                        <b-th class="text-center fs-6 akkurate-green" colspan="3"><span>ASSETS</span></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(value, item) in assets_items" :key="item" class="list-item">
                        <b-td width="30%" style="font-weight: bold;">{{ value.display_name }}</b-td>

                        <b-td width="20%" style="font-weight: bold;" v-if="value.transactions.length > 0">
                          {{ institution.currency_code }} {{ Number(value.transactions[0].balance ?
                            value.transactions[0].balance.toFixed(2) :
                          0).toLocaleString() }}
                        </b-td>
                        <b-td style="font-weight: bold;" v-else>
                          {{ institution.currency_code }} 0
                        </b-td>
                        <b-td class="list-item">
                          <div v-for="(val, idx) in value.subheads" :key="idx" class="row">
                            <div class="col-6">
                              {{ val.name }}
                            </div>
                            <div class="col-6">
                              {{ institution.currency_code }} {{ Number(val.total ? val.total.toFixed(2) :
                              0).toLocaleString() }}
                            </div>
                            <hr v-if="idx < value.subheads.length - 1" style="border-color: #808080;"
                              class="m-0 ms-1" />
                          </div>
                        </b-td>
                      </b-tr>
                      <b-tr v-for="(provision, item) in provisions" :key="item" class="list-item">
                        <b-td style="font-weight: bold;">{{ provision.display_name }}</b-td>

                        <b-td style="font-weight: bold;" v-if="provision.transactions.length > 0">
                          {{ institution.currency_code }} {{ Number(provision.transactions[0].balance ?
                            provision.transactions[0].balance.toFixed(2)
                            :
                            0).toLocaleString() }}
                        </b-td>
                        <b-td style="font-weight: bold;" v-else>
                          {{ institution.currency_code }} 0
                        </b-td>
                        <b-td class="list-item">
                          <div v-for="(val, idx) in provision.subheads" :key="idx" class="row">
                            <div class="col-6">
                              {{ val.name }}
                            </div>
                            <div class="col-6">
                              {{ institution.currency_code }} {{ Number(val.total ? val.total.toFixed(2) :
                              0).toLocaleString() }}
                            </div>
                            <hr v-if="idx < provision.subheads.length - 1" style="border-color: #808080;"
                              class="m-0 ms-1" />
                          </div>
                        </b-td>
                      </b-tr>

                    </b-tbody>
                  </b-table-simple>
                </div>
                <div class="col-6 col-md-6 px-0 trans_details">
                  <b-table-simple outlined bordered class="mb-0">
                    <b-thead>
                      <b-tr>
                        <b-th class="text-center fs-6 akkurate-green" colspan="3"><span>LIABILITIES +
                            CAPITAL</span></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(value, item) in liability_items" :key="item" class="list-item">
                        <b-td style="font-weight: bold;">{{ value.display_name }}</b-td>
                        <b-td style="font-weight: bold;" v-if="value.transactions.length > 0">
                          {{ institution.currency_code }} {{ Number(value.transactions[0].balance ?
                            value.transactions[0].balance.toFixed(2) :
                          0).toLocaleString() }}
                        </b-td>
                        <b-td style="font-weight: bold;" v-else>
                          {{ institution.currency_code }} 0
                        </b-td>
                        <b-td class="list-item">
                          <div v-for="(val, idx) in value.subheads" :key="idx" class="row">
                            <div class="col-6">
                              {{ val.name }}
                            </div>
                            <div class="col-6">
                              {{ institution.currency_code }} {{ val.total }}
                            </div>
                            <hr v-if="idx < value.subheads.length - 1" style="border-color: #808080;"
                              class="m-0 ms-1" />
                          </div>
                        </b-td>
                      </b-tr>

                      <!-- surpluses -->
                      <b-tr v-for="(value, item) in surpluses" :key="item" class="list-item">
                        <b-td style="font-weight: bold;">{{ value.display_name }}</b-td>
                        <b-td>
                          <span style="font-weight: bold;" v-if="value.transactions.length > 0">
                            {{ institution.currency_code }} {{ displayNumber(value.transactions[0].balance) }}
                          </span>
                          <span style="font-weight: bold;" v-else>
                            {{ institution.currency_code }} 0
                          </span>
                        </b-td>
                        <br />
                        <b-tr v-for="(val, idx) in value.subheads" :key="idx" class="list-item">
                          <b-td>{{ val.name }}</b-td>
                          <b-td>
                            {{ institution.currency_code }} {{ displayNumber(val.total) }}
                          </b-td>
                        </b-tr>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-6 p-0 trans_details">
                  <b-table-simple outlined bordered>
                    <b-tfoot>
                      <b-tr>
                        <b-th width="30%" class="py-0 px-2">Total Assest</b-th>
                        <b-th width="20%" class="py-0 px-2"></b-th>
                        <b-th class="py-0 px-2">{{ institution.currency_code }} {{ displayNumber(grant_total_assets)
                          }}</b-th>
                      </b-tr>
                      <b-tr>
                        <b-th class="py-0 px-2">PROVISION FOR LOAN LOSSES</b-th>
                        <b-th class="py-0 px-2">{{ institution.currency_code }} {{ displayNumber(loan_losses) }}</b-th>
                        <b-th class="py-0 px-2"></b-th>
                      </b-tr>
                      <b-tr>
                        <b-th class="py-0 px-2">ACCUMULATED DEPRECIATION</b-th>
                        <b-th class="py-0 px-2">{{ institution.currency_code }} {{ displayNumber(accumulated_dep)
                          }}</b-th>
                        <b-th class="py-0 px-2">{{ institution.currency_code }} {{ displayNumber(accumulated_dep +
                          loan_losses) }}</b-th>
                      </b-tr>
                      <!-- <b-tr>
                        <b-th class="py-0 px-2" width="50%">Total Provision</b-th>
                        <b-th class="py-0 px-2">{{ displayNumber(accumulated_dep + loan_losses) }}</b-th>
                      </b-tr> -->
                      <b-tr>
                        <b-th class="py-0 px-2">Net Asset</b-th>
                        <b-th class="py-0 px-2"></b-th>
                        <b-th class="py-0 px-2">{{ institution.currency_code }} {{ displayNumber(Number(grant_total_assets
                          -
                          (accumulated_dep + loan_losses)).toFixed(2)) }}</b-th>
                      </b-tr>
                    </b-tfoot>

                  </b-table-simple>
                </div>
                <div class="col-6 col-md-6 p-0 trans_details">
                  <b-table-simple outlined>
                    <b-tfoot>
                      <b-tr>
                        <b-th class="py-0 px-2" width="50%">Total Liability</b-th>
                        <b-th class="py-0 px-2">{{ institution.currency_code }} {{
                          displayNumber(this.grant_total_liabilities) }}</b-th>
                      </b-tr>
                      <b-tr>
                        <b-th class="py-0 px-2" width="50%">Total Surplus</b-th>
                        <b-th class="py-0 px-2">{{ institution.currency_code }} {{ displayNumber(grant_total_surpluses)
                          }}</b-th>
                      </b-tr>
                      <b-tr>
                        <b-th class="py-0 px-2" width="50%">Total Liabilities + Surplus</b-th>
                        <b-th class="py-0 px-2">{{ institution.currency_code }} {{ displayNumber(liabilities_plus_surplus)
                          }}</b-th>
                      </b-tr>

                    </b-tfoot>
                  </b-table-simple>
                </div>
                <div class="col-12 p-0 text-center akkurate-green mb-2 fw-bold">
                  Information on Ratio
                </div>
                <div class="col-6 p-0">
                  <b-table-simple outlined>
                    <b-tfoot>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2">Liquidity Reserve</b-th>
                        <b-th class="py-0 px-2">{{ liquidity_res }} %</b-th>
                      </b-tr>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2" width="50%">Return on Asset</b-th>
                        <b-th class="py-0 px-2">{{ return_on_assets }} %</b-th>
                      </b-tr>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2" width="50%">Capital Adequacy</b-th>
                        <b-th class="py-0 px-2">{{ capital_adequacy }} %</b-th>
                      </b-tr>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2" width="50%">Prepared By</b-th>
                        <b-th class="py-0 px-2"></b-th>
                      </b-tr>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2" width="50%">Accountant</b-th>
                        <b-th class="py-0 px-2"></b-th>
                      </b-tr>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2" width="50%">Signature</b-th>
                        <b-th class="py-0 px-2"></b-th>
                      </b-tr>

                    </b-tfoot>
                  </b-table-simple>
                </div>
                <div class="col-6 p-0">
                  <b-table-simple responsive outlined>
                    <b-tfoot>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2" colspan="2">(Total liquid Funds / total assets * 100)</b-th>
                      </b-tr>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2" colspan="2">(Surplus difference / total assets * 100)</b-th>
                      </b-tr>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2" colspan="2">([Member Shares + total capital res] / total assets *
                          100)</b-th>
                      </b-tr>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2">Certified By</b-th>
                        <b-th class="py-0 px-2"></b-th>
                      </b-tr>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2">Manager/Board Treasurer</b-th>
                        <b-th class="py-0 px-2"></b-th>
                      </b-tr>
                      <b-tr class="trans_details">
                        <b-th class="py-0 px-2">Signature</b-th>
                        <b-th class="py-0 px-2"></b-th>
                      </b-tr>
                    </b-tfoot>
                  </b-table-simple>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import html2pdf from "html2pdf.js";
import ReportFilter from "../form/ReportFilter.vue";
import ExcelJS from 'exceljs';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter
  },
  data() {
    return {
      form: {
        start_date: this.getBeginningOfYear(),
        end_date: new Date().toISOString().split('T')[0],
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "YTD",
        terms_options: ["YTD", "Periodic"],
        categories: [],
      },
      filter: {
        branches: true,
        products: false,
        users: false,
        status: false,
        condition: false,
        terms: true,
        coa: false,
        voucher_status: false,
        start_date: true,
        end_date: true,
      },
      search: "Search",
      grant_total_liabilities: 0,
      liabilities_plus_surplus: 0,
      grant_total_surpluses: 0,
      grant_total_assets: 0,
      isBusy: false,
      isSearching: false,
      selected_num: "10",
      numbers_options: [10, 50, 100, 500],
      isLoadingInstitution: false,
      institution: "",
      start_date: "",
      end_date: "",
      liability_items: "",
      assets_items: "",
      provisions: [],
      loan_to_member: "",
      total_loans: 0,
      surpluses: "",
      loan_losses: 0,
      accumulated_dep: 0,
      liquid_funds: 0,
      reserves: 0,
      fields: [
        {
          key: "account_number",
          label: "Account Number",
        },
        {
          key: "customer_name",
          label: "Customer Name",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "min_balance",
          label: "Minimum Balance",
        },
        {
          key: "total",
          label: "Total",
        },

      ],
      perPage: 20,
      currentPage: 1,
      items: [],
      total_assets: 0,
      member_shares: 0,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    sumLoanBalance() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = this.items[i].loan_balance;
      }
      return total;
    },
    sumSavingBalance() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = this.items[i].saving_balance;
      }

      return total;
    },
    liquidity_res() {
      return Number((this.liquid_funds / this.grant_total_assets) * 100).toFixed(2) ?? 0;
    },
    return_on_assets() {
      return Number((this.grant_total_surpluses / this.grant_total_assets) * 100).toFixed(2) ?? 0;
    },
    capital_adequacy() {
      return Number(((this.member_shares + this.reserves) / this.grant_total_assets) * 100).toFixed(2) ?? 0;
    },
    totalProvisionYear() {
      return this.provisions.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },

    totalAssetsMinusProvision() {
      return this.total_loans - this.totalProvisionYear ?? 0;
    }
  },

  created() {
    this.getInstitution();
    this.getBalanceSheets();
  },
  methods: {
    async getProductDropdown() {
      this.isBusy = true;
      this.search = "Processing...";
      await ApiService.query("/reports/controls/products/dropdown")
        .then((response) => {
          // console.log(response.data);
          this.isBusy = false;
          this.search = "Search";
          this.form.branch_options = response.data.branches;
        })
        .catch((error) => {
          // console.log(error);
          this.isBusy = false;
          this.search = "Search";
        });
    },
    async getBalanceSheets() {
      this.items = [];
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.post("/reports/financials/balance-sheet", {
        startDate: this.form.start_date,
        endDate: this.form.end_date,
        terms: this.form.terms,
        branch: this.form.branches,
      }
      ).then((response) => {
        this.isBusy = false;
        this.search = "Search"
        this.isSearching = false;
        // var res = response.data?.data;
        var res = response.data;
        this.assets_items = res.assets;
        this.liability_items = res.liabilities;
        this.surpluses = res.surpluses;
        this.grant_total_assets = res.grant_total_assets;
        this.grant_total_liabilities = res.grant_total_liabilities;
        this.grant_total_surpluses = res.total_surpluses;
        this.liabilities_plus_surplus = (this.grant_total_liabilities + this.grant_total_surpluses);
        this.start_date = this.form.start_date;
        this.end_date = this.form.end_date;
        this.provisions = res.provisions;
        this.loan_to_member = res.loan_to_member
        this.loan_losses = res.loan_losses
        this.accumulated_dep = res.accumulated_dep
        this.liquid_funds = res.liquid_funds
        this.reserves = res.reserves
        this.member_shares = res.member_shares
        // this.total_loans = res.total_loans;
        // console.log(this.total_loans);
        // this.total_assets = res.assets[0].total;
        // this.total_member_shares = res.liabilities[3].total;
        // this.grant_total_capitals = res.grant_total_capitals
      })
        .catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        });
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "slice" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    filterTable() {
      this.getBalanceSheets();
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
    filterAction() {
      this.getBalanceSheets();
    },
    async generateExcel() {
      const fileUrl = '/REPORT_TEMPLATE_NEW.xlsx'; // Path to the file in the public folder
      const imageUrl = '/default_logo.png';  // Path to your image file


      // Institution details
      const institutionName = this.institution.name;
      const institutionMotto = this.institution.motto;
      const institutionAddress = this.institution.address;
      const institutionPhone = this.institution.telephone;
      const institutionEmail = this.institution.email;
      const date = 'FROM: ' + this.form.start_date + ' TO: ' + this.form.end_date
      const terms = 'TERMS:  ' + this.form.terms

      try {
        // Fetch the Excel file from the public URL
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        // Load the file into ExcelJS
        const wb = new ExcelJS.Workbook();
        await wb.xlsx.load(arrayBuffer);

        // Access the first sheet
        const ws = wb.getWorksheet(1); // Get the first worksheet

        // Add Image to the top of the sheet
        const imageResponse = await fetch(imageUrl);
        const imageBuffer = await imageResponse.arrayBuffer();
        const imageId = wb.addImage({
          buffer: imageBuffer,
          extension: 'png',  // Adjust this if you're using a different image type (e.g., jpg)
        });

        // Insert the image at the top-left corner (row 1, column 1)
        ws.addImage(imageId, {
          tl: { col: 0, row: 0 },  // Top-left corner (adjust this as needed)
          ext: { width: 300, height: 200 },  // Set the image size (adjust width/height as needed)
        });

        // Insert institution details next to the image (starting at row 1, col 5)
        const institutionDetailsStartCol = 5; // Column where text starts after image
        const institutionDetailsRow = 1;  // Row for institution details

        // Add the institution name, address, and phone number
        ws.getCell(`L${institutionDetailsRow}`).value = institutionName;
        ws.getCell(`L${institutionDetailsRow}`).font = { bold: true, size: 14 }; // Bold and bigger font for name

        ws.getCell(`L${institutionDetailsRow + 1}`).value = institutionAddress;
        ws.getCell(`L${institutionDetailsRow + 1}`).font = { size: 12 };  // Regular font size for address

        ws.getCell(`L${institutionDetailsRow + 2}`).value = institutionMotto;
        ws.getCell(`L${institutionDetailsRow + 2}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 3}`).value = institutionPhone;
        ws.getCell(`L${institutionDetailsRow + 3}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 4}`).value = institutionEmail;
        ws.getCell(`L${institutionDetailsRow + 4}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 6}`).value = date;
        ws.getCell(`L${institutionDetailsRow + 6}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 7}`).value = terms;
        ws.getCell(`L${institutionDetailsRow + 7}`).font = { size: 12 };  // Regular font size for phone

        // Optionally adjust the column width if the text is long
        ws.getColumn('K').width = 50;  // Adjust the width of column D (if necessary)

        // Define header row
        const header = [
          "Code",
          "Name",
          "Total",
          "Coa Name",
          "Coa Total"
        ];



        // Add header row starting from row 13 (or the next row after the image and details)
        const startingRow = institutionDetailsRow + 10;  // Row 4 after institution details
        let headerRow = ws.getRow(startingRow);
        headerRow.values = header;
        headerRow.font = { bold: true };
        headerRow.commit(); // Commit the row to the worksheet

        // Add individual data rows
        let currentRow = startingRow + 1;


        // Process each item
        this.assets_items.forEach(item => {
          const row = ws.getRow(currentRow);
          // Map item values to the corresponding header columns
          row.values = [
            item.code,
            item.display_name,
            item.total, // Remove extra spaces
            item.subheads.map(subhead => subhead.name).join('\n'), // Newline-separated subheads
            item.subheads.map(subhead => subhead.total).join('\n') // Newline-separated subheads
          ];
          // Apply text wrapping and center align for COA Name and COA Total columns
          row.getCell(4).alignment = { wrapText: true, vertical: 'top', horizontal: 'top' }; // COA Name
          row.getCell(5).alignment = { wrapText: true, vertical: 'top', horizontal: 'top' }; // COA Total

          row.commit();
          currentRow++;
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link and simulate a click to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Modified_REPORT_TEMPLATE.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      }
    }
  },
};
</script>