<template>
    <div>
        <a class="dropdown-item" v-b-modal="data.account_number + transaction">Mobile Money</a>
        <b-modal :id="data.account_number + transaction" size="lg" @hidden="resetModal" @ok="handleOk">
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <div class="w-100 d-flex align-items-center justify-content-between">
                    <h5 :class="getClassForTransaction(transaction)" class="fw-bold mb-0">{{ transaction }} <span
                            class="ms-5"><i class="far fa-money-bill-alt"></i></span></h5>
                    <b-button variant="white" @click="close()">
                        &times;
                    </b-button>
                </div>

            </template>
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <!-- PassPort Pic -->
                    <div class="col-md-4 text-center">
                        <b-form-group label-for="passport" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="member">Passport picture</label>
                            </template>
                            <img :src="data.customer.passport_pic_path" alt="Image" style="height:150px"
                                class="img-fluid text-center" />
                        </b-form-group>
                    </div>
                    <!-- Signature -->
                    <div class="col-md-4 text-center">
                        <b-form-group label-for="member" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="member">Signature</label>
                            </template>
                            <img :src="data.customer.signature_image_path" alt="Image" style="height:150px"
                                class="img-fluid text-center" />
                        </b-form-group>
                    </div>
                    <!-- Ghana card -->
                    <div class="col-md-4 text-center">
                        <b-form-group label-for="member" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="member">Ghana Card</label>
                            </template>
                            <img :src="data.customer.gh_card_front_path" alt="Image" style="height:150px"
                                class="img-fluid text-center" />
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group label-for="member" class="mb-3">

                            <template #label>
                                <label class="akkurate-dark small" for="member">Member</label>
                            </template>
                            <b-form-input id="member" v-model="getFullNameAndID" v-validate="{ required: true }"
                                :state="validateState('member')" name="member" class="mt-2 field-container fields"
                                type="text" disabled></b-form-input>
                            <b-form-invalid-feedback>
                                The Balance field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label-for="account_number" class="mb-3">

                            <template #label>
                                <label class="akkurate-dark small" for="account_number">Account Number</label>
                            </template>
                            <b-form-input id="account_number" v-model="data.account_number"
                                v-validate="{ required: false }" :state="validateState('account_number')"
                                name="account_number" class="mt-2 field-container fields" type="text"
                                disabled></b-form-input>
                            <b-form-invalid-feedback>
                                The Balance field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.account_balance">
                                   {{ form.errors.account_balance[0] }}
                               </errors> -->
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label-for="teller" class="mb-3">

                            <template #label>
                                <label class="akkurate-dark small" for="teller">Teller</label>
                            </template>
                            <b-form-input id="teller" name="teller" class="mt-2 field-container fields"
                                v-validate="{ required: true }" :state="validateState('teller')" v-model="getTeller"
                                type="text" disabled></b-form-input>
                            <b-form-invalid-feedback>
                                The Balance field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.account_balance">
                                   {{ form.errors.account_balance[0] }}
                               </errors> -->
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="akkurate-dark small" for="trans_date">Transaction Date <star></star></label>
                        <b-form-group id="trans_date" label-for="trans_date">
                            <b-form-input class="mt-2 field-container fields" v-validate="{ required: true }"
                                :state="validateState('trans_date')" name="trans_date" v-model="form.trans_date"
                                type="date"></b-form-input>
                            <b-form-invalid-feedback>
                                The Trasaction Date field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.date_of_birth">
                            {{ form.errors.date_of_birth[0] }}
                        </errors> -->
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label-for="amount" class="mb-3">

                            <template #label>
                                <label class="akkurate-dark small" for="amount">Amount <star></star></label>
                            </template>
                            <b-form-input id="amount" v-validate="{ required: true }" :state="validateState('amount')"
                                name="amount" class="mt-2 field-container fields" type="text"
                                v-model="form.trans_amount" placeholder="Enter Amount"></b-form-input>
                            <b-form-invalid-feedback>
                                The Amount field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.account_balance">
                                   {{ form.errors.account_balance[0] }}
                               </errors> -->
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group label-for="textarea-lazy" class="mb-2">

                            <template #label>
                                <label class="akkurate-dark small" for="network">Network <star></star></label>
                            </template>
                            <multiselect class="mt-2 field-container" v-model="form.network" :options="network_options"
                                placeholder="Select Network" selectLabel="" deselectLabel="" name="network">
                            </multiselect>
                        </b-form-group>
                        <b-form-invalid-feedback>
                            The Network field is required
                        </b-form-invalid-feedback>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group label-for="textarea-lazy" class="mb-2">

                            <template #label>
                                <label class="akkurate-dark small" for="momo_reference">Momo Reference Number <star>
                                    </star></label>
                            </template>
                            <b-form-input id="momo_reference" v-validate="{ required: true }"
                                :state="validateState('momo_reference')" name="momo_reference"
                                class="mt-2 field-container fields" type="text" v-model="form.momo_reference"
                                placeholder="Enter Momo Reference Number"></b-form-input>
                            <b-form-invalid-feedback>
                                The Momo Reference Number field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-6">
                        <b-form-group label-for="textarea-lazy" class="mb-2">

                            <template #label>
                                <label class="akkurate-dark small" for="external_reference">External Reference <star>
                                    </star></label>
                            </template>
                            <b-form-input id="external_reference" v-validate="{ required: true }"
                                :state="validateState('external_reference')" name="external_reference"
                                class="mt-2 field-container fields" type="text" v-model="form.external_reference"
                                placeholder="Enter External Reference"></b-form-input>
                            <b-form-invalid-feedback>
                                The External Reference field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-6">
                        <b-form-group label-for="mobile" class="mb-2">

                            <template #label>
                                <label class="akkurate-dark small" for="mobile">Mobile Number <star></star></label>
                            </template>
                            <b-form-input id="mobile" v-validate="{ required: true }" :state="validateState('mobile')"
                                name="mobile" class="mt-2 field-container fields" type="text" v-model="form.mobile"
                                placeholder="Enter Mobile Number"></b-form-input>
                            <b-form-invalid-feedback>
                                The Mobile Number field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>


                    <div class="col-12 col-md-6">
                        <b-form-group label-for="narration" class="mb-2">

                            <template #label>
                                <label class="akkurate-dark small" for="narration">Narration <star></star></label>
                            </template>
                            <b-form-textarea class="mt-2 fields" id="narration" v-validate="{ required: true }"
                                :state="validateState('narration')" name="narration" v-model="form.narration"
                                placeholder="Enter Narration"></b-form-textarea>
                            <b-form-invalid-feedback>
                                The Narration field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>



                <hr />
                <div class="row">
                    <div class="col-12 col-md-12 text-center">
                        <label class="label btn akkurate-gray-btn">
                            <input type="file" @change="handleFileUpload" />
                            <span class="text-white akkurate-small">Form of Identification</span>
                        </label>
                        <div v-if="selectedFile">
                            <label class="akkurate-dark small">Preview</label>
                            <img class="imagePreviewWrapper" :src="previewUrl" alt="File Preview" />
                            <b-button type="button" @click="uploadFile" :disabled="upload_processing"
                                class="akkurate-green-btn">
                                <b-spinner v-if="spin_visible" small></b-spinner>
                                <i v-if="success_visible" class="fas fa-check loading"></i>
                                <i v-if="text_visible" class="fas fa-upload loading"></i> {{ upload }}
                            </b-button>

                        </div>
                    </div>
                </div>
            </form>

            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="$bvModal.hide(data.account_number + transaction)" type="button"
                        class="akkurate-grey-btn border-0 btn-size ms-2 float-right">
                        <span class="small"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Cancel</span>
                    </b-button>
                    <b-button @click="ok()" type="submit"
                        :class="transaction == 'Mobile Money Deposit' ? 'akkurate-green-btn' : 'akkurate-danger-btn'"
                        class="akkurate-green-btn btn-size float-right border-0">
                        <span class="small"><font-awesome-icon class="me-2" :icon="['fas', 'save']" /> Save</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <momo-deposit ref="showMomoDeposit" :report_data="report_data"></momo-deposit>
        <momo-withdrawal ref="showMomoWithdrawal" :report_data="report_data"></momo-withdrawal>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import MomoDeposit from '../../reports/momo/MomoDeposit.vue'
import MomoWithdrawal from '../../reports/momo/MomoWithdrawal.vue'
export default {
    name: "MobileMoney",
    components: {
        Multiselect,
        MomoDeposit,
        MomoWithdrawal,
    },
    props: ["data", "transaction", 'institution', 'user'],
    data() {
        return {
            selectedFile: null,
            previewUrl: null,
            status_options: ['Active', 'Inactive'],
            isSaving: false,
            add_or_edit: "add",
            is_processing: false,
            spin_visible: false,
            text_visible: true,
            success_visible: false,
            upload_processing: false,
            upload: "Upload",
            report_data: "",
            network_options: ['MTN', 'Vodafone', 'AirtelTigo'],
            // bank_chart_options: [],
            form: {
                account_number: this.data.account_number,
                trans_amount: "",
                source: "Web",
                network: "MTN", //'MTN', 'Vodafone', 'AirtelTigo'
                mobile: "",
                narration: null,
                transaction_mode: "Momo", //"Cash", "Cheque", "Momo", "Direct Debit"
                external_reference: "",
                trans_date: new Date().toISOString().substr(0, 10),
                momo_reference: "",
                currency: "GHS"
            },
        }
    },
    computed: {
        getFullNameAndID() {
            let first_name = this.data?.customer?.first_name != null ? this.data?.customer?.first_name : "";
            let middlename = this.data?.customer?.middlename != null ? this.data?.customer?.middlename : "";
            let last_name = this.data?.customer?.last_name != null ? this.data?.customer?.last_name : "";
            let code = this.data?.customer?.code != null ? this.data?.customer?.code : "";
            return first_name + ' ' + middlename + ' ' + last_name + ' - ' + code;
        },
        getTeller() {
            let first_name = this.user?.first_name != null ? this.user?.first_name : '';
            let middle_name = this.user?.middle_name != null ? this.user?.middle_name : '';
            let surname = this.user?.surname != null ? this.user?.surname : '';
            return first_name + ' ' + middle_name + ' ' + surname;
        }
    },
    methods: {
        getClassForTransaction(transaction) {
            return transaction == 'Mobile Money Deposit' ? 'akkurate-green' : 'akkurate-danger';
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
                this.previewFile(file);
            }
        },
        previewFile(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.previewUrl = reader.result;
            };
        },
        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async uploadFile() {
            this.upload = "Processing..."
            this.spin_visible = true;
            this.text_visible = false;
            await this.timeout(3000);
            this.spin_visible = false;
            this.text_visible = false;
            this.success_visible = true
            this.upload_processing = true
            this.upload = "Successfully Uploaded"
            // console.log(this.data.account_number);
        },
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        resetModal() {
            this.form.trans_amount = "";
            this.form.network = "MTN";
            this.form.reference_no = "";
            this.form.external_reference = "";
            this.form.mobile = "";
            this.form.narration = "";
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(async (result) => {
                if (!result) {
                    console.log(result);
                    return;
                }
                this.isSaving = true;
                let transaction = this.transaction.split(" ");
                this.$Progress.start();
                console.log(transaction[2])
                switch (transaction[2]) {
                    case "Deposit":
                        await ApiService.post("/transactions/deposits/store", this.form)
                            .then((response) => {
                                this.$emit('reloadAccounts')
                                this.$Progress.finish();
                                this.isSaving = false;
                                swal.fire({
                                    title: response.data.transaction.trans_status,
                                    // title: "Successfully Saved",
                                    text: 'CODE : ' + response.data.transaction.code,
                                    // text: 'CODE : 12558896555',
                                    confirmButtonColor: "#018673",
                                    icon: 'success',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    showCancelButton: true, // Show the "Cancel" button
                                    confirmButtonText: 'OK', // Customize the text for the "OK" button
                                    cancelButtonText: 'Print', // Text for the custom button
                                    buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                    customClass: {
                                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                        cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                                    }
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$bvModal.hide(this.data.account_number + this.transaction);
                                    } else if (result.isDismissed) {
                                        this.$bvModal.hide(this.data.account_number + this.transaction);
                                        this.$refs.showMomoDeposit.showModal();
                                        let data = response.data?.transaction
                                        this.report_data = {
                                            'trans_date': data.trans_date,
                                            'account_number': this.data?.account_number,
                                            'fullname': this.getFullNameAndID,
                                            'transaction_mode': data.transaction_mode,
                                            'network': data.network,
                                            'mobile': data.mobile,
                                            'external_reference': data.external_reference,
                                            'momo_reference': data.momo_reference,
                                            'transaction_type': data.transaction_type,
                                            'amount': data.trans_amount,
                                            'branch_name': this.data?.branch?.name,
                                            'narration': data.narration,
                                            'institution': this.institution,
                                            'receipt_no': data.id,
                                            'teller': this.getTeller,
                                        };
                                    }
                                })
                            }).catch((error) => {
                                this.$Progress.finish();
                                this.isSaving = false;
                                if (error.response.status) {
                                    swal.fire({
                                        icon: "error",
                                        title: "Unable to process request",
                                        text: error.response?.data?.message,
                                        showCancelButton: false, // Show the "Cancel" button
                                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                        customClass: {
                                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                        }
                                        // footer: '<a href="#">Why do I have this issue?</a>'
                                    });
                                }
                                if (error.response.data.errors) {
                                    this.form.errors = error.response.data.errors;
                                    // console.log(this.form.errors);
                                }
                                this.$Progress.fail();
                                this.isSaving = false;

                            });
                        break;
                    case "Withdrawal":
                        await ApiService.post("/transactions/withdrawals/store", this.form)
                            .then((response) => {
                                this.$emit('reloadAccounts')
                                this.$Progress.finish();
                                this.isSaving = false;
                                swal.fire({
                                    title: response.data.transaction.trans_status,
                                    // title: "Successfully Saved",
                                    text: 'CODE : ' + response.data.transaction.code,
                                    // text: 'CODE : 12558896555',
                                    confirmButtonColor: "#018673",
                                    icon: 'success',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    showCancelButton: true, // Show the "Cancel" button
                                    confirmButtonText: 'OK', // Customize the text for the "OK" button
                                    cancelButtonText: 'Print', // Text for the custom button
                                    buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                    customClass: {
                                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                        cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                                    }
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.$bvModal.hide(this.data.account_number + this.transaction);
                                    } else if (result.isDismissed) {
                                        this.$bvModal.hide(this.data.account_number + this.transaction);
                                        this.$refs.showMomoWithdrawal.showModal();
                                        let data = response.data?.transaction
                                        this.report_data = {
                                            'trans_date': data.trans_date,
                                            'account_number': this.data?.account_number,
                                            'fullname': this.getFullNameAndID,
                                            'transaction_mode': data.transaction_mode,
                                            'network': data.network,
                                            'mobile': data.mobile,
                                            'external_reference': data.external_reference,
                                            'momo_reference': data.momo_reference,
                                            'transaction_type': data.transaction_type,
                                            'amount': data.trans_amount,
                                            'branch_name': this.data?.branch?.name,
                                            'narration': data.narration,
                                            'institution': this.institution,
                                            'receipt_no': data.id,
                                            'teller': this.getTeller,
                                        };
                                    }
                                })
                            }).catch((error) => {
                                this.$Progress.fail();
                                this.isSaving = false;
                                if (error.response.status) {
                                    swal.fire({
                                        icon: "error",
                                        title: "Unable to process request",
                                        text: error.response?.data?.message,
                                        showCancelButton: false, // Show the "Cancel" button
                                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                        customClass: {
                                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                        }
                                        // footer: '<a href="#">Why do I have this issue?</a>'
                                    });
                                }

                            });
                        break;
                }

            });
            // Hide the modal manually

        }
    }
}
</script>