<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">Voucher Reports</h6>
                </div>
                <div class="col-6 text-end">
                  <b-button @click="generateExcel" class="btn-sm ms-5 me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']" />
                  </b-button>
                  <report-filter :form="form" @filterAction="filterAction" :filter="filter"></report-filter>
                  <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon
                      :icon="['fas', 'book']" /></b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon
                      :icon="['fas', 'download']" /></b-button>
                </div>
              </div>
            </div>
            <div class="card-body" ref="pdfContent">
              <div class="row">
                <div class="col-12 col-md-12">
                  <statement-institution :institution="institution" :current_date="false"
                    :title="'VOUCHER REPORT'"></statement-institution>
                </div>
                <hr />
                <div class="row mb-2">
                  <div class="col-12 small">
                    Branch(es) : {{ branch_list }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-4 text-end">
                  <span style="font-weight: bold" v-if="form.start_date && form.end_date">From {{ form.start_date }} to {{ form.end_date
                  }}</span>
                </div>
              </div>
              <div v-if="isBusy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
              <div class="row">
                <div class="col-12 col-md-12 trans_details">
                  <b-table striped bordered responsive="lg" id="voucher-table" :busy.sync="isBusy" :items="rows"
                  :fields="fields" :tbody-tr-class="rowClass" show-empty>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <!-- <template #custom-foot>
                      <b-tr>
                        <b-th colspan="2"></b-th>
                        <b-th>TOTAL</b-th>
                        <b-th>{{ getDrTotal }}</b-th>
                        <b-th>{{ getCrTotal }}</b-th>
                        <b-th></b-th>
                        <b-th>{{ getTotal }}</b-th>
                      </b-tr>
                    </template> -->
                  </b-table>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage" :total-rows="totalRecords"
                  :per-page="perPage" aria-controls="voucher-table"></b-pagination>
                <!-- <b-form-select @change="selectPerPage" v-model="per_page_selected" :options="per_page_options"
                        size="sm" class="mt-3"></b-form-select> -->
                <div class="col text-end mt-1 akkurate-dark">
                  <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                  <b-form-group label="Per Page" label-size="sm" class="mb-0">
                    <b-form-select @change="selectPerPage" id="per-page-select" v-model="perPage"
                      :options="per_page_options" size="sm"></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import html2pdf from "html2pdf.js";
import ReportFilter from "@/views/main/pages/reports/form/ReportFilter.vue";
import ExcelJS from 'exceljs';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter
  },
  data() {
    return {
      totalRecords: 0,
      filter: {
        branches: true,
        products: false,
        users: false,
        status: false,
        condition: false,
        coa: false,
        terms: true,
        start_date: true,
        end_date: true
      },
      form: {
        start_date: this.getOneMonthEailer(),
        end_date: new Date().toISOString().split('T')[0],
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "YTD",
        terms_options: ["YTD", "Periodic"],
        categories: [],
      },
      branch_list: "",
      branch_arr: [],
      status_arr: [],
      search: "Search",
      isBusy: false,
      currentPage: 1,
      isBusy: false,
      isSearching: false,
      // selected_num: "10",
      // numbers_options: [10, 50, 100, 500],
      isLoadingInstitution: false,
      per_page_options: ['10', '20', '50', '100'],
      per_page_selected: "10",
      institution: "",
      fields: [
        {
          key: "code",
          label: "Code",
        },
        {
          key: "beneficiary_name",
          label: "Beneficiary",
        },
        {
          key: "beneficiary_phone",
          label: "Phone",
        },
        {
          key: "cheque_number",
          label: "Cheque",
        },
        {
          key: "narration",
          label: "Narration",
        },
        {
          key: "amount_requested",
          label: "Req amount",
        },
        {
          key: "recorded_date",
          label: "Trans date",
        },

        {
          key: "amount_approved",
          label: "Approved amount",
        },
        {
          key: "branch.name",
          label: "Branch",
        },
        {
          key: "status",
          label: "Status",
        },

      ],
      perPage: 20,
      currentPage: 1,
      rows: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: [
          {
            field: '', // example: 'name'
            type: '' // 'asc' or 'desc'
          }
        ],
        page: 1, // what page I want to show
        perPage: 20 // how many items I'm showing per page
      },
    };
  },
  computed: {
    sumLoanBalance() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = this.items[i].loan_balance;
      }
      return total;
    },
    sumSavingBalance() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = this.items[i].saving_balance;
      }

      return total;
    },
  },

  created() {
    this.getInstitution();
    this.loadItems();
    this.getProductDropdown();
  },
  methods: {
    rowClass() {
      return 'custom-row-height'
    },
    filterTable() {
      this.loadItems();
    },

    navigatePage(index) {
      this.isBusy = true;
      this.currentPage = index;
      this.loadItems()
    },
    selectPerPage(index) {
      this.perPage = index
      this.loadItems()
    },

    // updateParams(newProps) {
    //   this.serverParams = Object.assign({}, this.serverParams, newProps);
    // },

    // onPageChange(params) {
    //   this.updateParams({ page: params.currentPage });
    //   this.loadItems();
    // },

    // onPerPageChange(params) {
    //   this.updateParams({ perPage: params.currentPerPage });
    //   this.loadItems();
    // },

    // onSortChange(params) {
    //   this.updateParams({
    //     sort: [{
    //       type: params.sortType,
    //       field: this.columns[params.columnIndex].field,
    //     }],
    //   });
    //   this.loadItems();
    // },

    // onColumnFilter(params) {
    //   this.updateParams(params);
    //   this.loadItems();
    // },
    // onSearch(event) {
    //   // console.log(event)
    //   this.loadItems(event.searchTerm)
    // },
    // filterTable() {
    //   this.loadItems();
    // },
    async getProductDropdown() {
      this.isBusy = true;
      this.search = "Processing...";
      await ApiService.query("/reports/controls/products/dropdown")
        .then((response) => {
          // console.log(response.data);
          this.isBusy = false;
          this.search = "Search";
          // console.log(response.data.branches)
          this.form.branch_options = response.data.branches;
          // this.form.product_options = response.data.products;
          // this.form.coa_options = response.data.coa_subheads;
        })
        .catch((error) => {
          // console.log(error);
          this.isBusy = false;
          this.search = "Search";
        });
    },
    async loadItems() {
      // this.items = [];
      let branches = [];
       //Reduce Branch Array Object For Filters
       branches = this.form.branches?.reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.id);
        return accumulator;
      }, []);
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.post("/vouchers/paginate", {
        "page": this.currentPage,
        "per_page": this.perPage,
        "filter": this.form.search,
        "branch_ids": branches, //Eg. 1, 2, etc
        "statuses": this.form.status,
        "start_date": this.form.start_date,
        "end_date": this.form.end_date,
        // "users_ids": users,
      })
        .then((response) => {
          console.log(response);
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          // var res = response.data?.data;
          // this.branch_list = this.form.branches.map(branch => branch.name).join(", ");
          // res.map((index) => {
          //   const data = {
          //     code: index.code,
          //     beneficiary_name: index.beneficiary_name,
          //     beneficiary_phone: index.beneficiary_phone,
          //     cheque_number: index.cheque_number,
          //     amount_requested: index.amount_requested,
          //     amount_approved: index.amount_approved,
          //     status: index.status,
          //     branch: index.branch.name,

          //   }
          //   this.items.push(data);
          this.totalRecords = response.data.meta.total;
          this.rows = response.data.data;  // Return data to populate the table

        })
        .catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        });
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },

    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
    async generateExcel() {
      const fileUrl = '/REPORT_TEMPLATE_NEW.xlsx'; // Path to the file in the public folder
      const imageUrl = '/default_logo.png';  // Path to your image file

      // Institution details
      const institutionName = this.institution.name;
      const institutionMotto = this.institution.motto;
      const institutionAddress = this.institution.address;
      const institutionPhone = this.institution.telephone;
      const institutionEmail = this.institution.email;
      const date = 'FROM: ' + this.form.start_date + ' TO: ' + this.form.end_date
      const terms = 'TERMS:  ' + this.form.terms


      try {
        // Fetch the Excel file from the public URL
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        // Load the file into ExcelJS
        const wb = new ExcelJS.Workbook();
        await wb.xlsx.load(arrayBuffer);

        // Access the first sheet
        const ws = wb.getWorksheet(1); // Get the first worksheet

        // Add Image to the top of the sheet
        const imageResponse = await fetch(imageUrl);
        const imageBuffer = await imageResponse.arrayBuffer();
        const imageId = wb.addImage({
          buffer: imageBuffer,
          extension: 'png',  // Adjust this if you're using a different image type (e.g., jpg)
        });

        // Insert the image at the top-left corner (row 1, column 1)
        ws.addImage(imageId, {
          tl: { col: 0, row: 0 },  // Top-left corner (adjust this as needed)
          ext: { width: 300, height: 200 },  // Set the image size (adjust width/height as needed)
        });

        // Insert institution details next to the image (starting at row 1, col 5)
        const institutionDetailsStartCol = 5; // Column where text starts after image
        const institutionDetailsRow = 1;  // Row for institution details

        // Add the institution name, address, and phone number
        ws.getCell(`L${institutionDetailsRow}`).value = institutionName;
        ws.getCell(`L${institutionDetailsRow}`).font = { bold: true, size: 14 }; // Bold and bigger font for name

        ws.getCell(`L${institutionDetailsRow + 1}`).value = institutionAddress;
        ws.getCell(`L${institutionDetailsRow + 1}`).font = { size: 12 };  // Regular font size for address

        ws.getCell(`L${institutionDetailsRow + 2}`).value = institutionMotto;
        ws.getCell(`L${institutionDetailsRow + 2}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 3}`).value = institutionPhone;
        ws.getCell(`L${institutionDetailsRow + 3}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 4}`).value = institutionEmail;
        ws.getCell(`L${institutionDetailsRow + 4}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 6}`).value = date;
        ws.getCell(`L${institutionDetailsRow + 6}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 7}`).value = terms;
        ws.getCell(`L${institutionDetailsRow + 7}`).font = { size: 12 };  // Regular font size for phone

        // Optionally adjust the column width if the text is long
        ws.getColumn('K').width = 50;  // Adjust the width of column D (if necessary)

        // Define header row
        const header = [
          "GL Code",
          "Head Name",
          "Type",
          "DR (GHS)",
          "CR (GHS)",
        ];

        // Add header row starting from row 13 (or the next row after the image and details)
        const startingRow = institutionDetailsRow + 10;  // Row 4 after institution details
        let headerRow = ws.getRow(startingRow);
        headerRow.values = header;
        headerRow.font = { bold: true };
        headerRow.commit(); // Commit the row to the worksheet

        // Add individual data rows
        let currentRow = startingRow + 1;

        // Process each item
        this.items.forEach(item => {
          const row = ws.getRow(currentRow);
          // Map item values to the corresponding header columns
          row.values = [
            item.code,
            item.beneficiary_name,
            item.beneficiary_phone,
            item.cheque_number,
            item.amount_requested,
            item.amount_approved,
            item.status,
            item.branch,
          ];
          row.commit();
          currentRow++;
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link and simulate a click to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Modified_REPORT_TEMPLATE.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      }
    },
  },
};
</script>