UsersIndex

<template>
  <div id="wrapper">
    <SidebarView :voucher="true" :voucher-index="true" :voucher-visible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="mx-3">

          <!-- begin row  -->
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-shape home-box">
                <div class="card-header d-flex flex-row align-items-center justify-content-between">
                  <h6 class="m-0 fw-bold text-green">All Vouchers</h6>
                  <b-button class="btn btn-sm akkurate-green-btn" href="/voucher/create" variant="primary">
                    <span class="akkurate-small"> <i class="fas fa-plus"></i> Add Voucher</span>
                  </b-button>
                </div>
                <div class="card-body py-3 default_bg" style="border-bottom: 1px solid rgb(1, 134, 115);">
                  <div class="home-box border border-success p-3 bg-white">
                    <form @submit.prevent="filterTable">
                      <journal-voucher-filter :form="form" :users_options="users_options"
                        :branch_options="branch_options"></journal-voucher-filter>
                    </form>
                  </div>
                </div>
                <div class="card-body pt-3">
                  <div class="table_text_size">
                    <b-table striped bordered responsive="lg" id="voucher-table" :busy.sync="isBusy" :items="rows"
                      :fields="fields" :tbody-tr-class="rowClass" show-empty>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template #cell(created_by)="data">
                        <div>{{ data.item.created_by.first_name }} {{ data.item.created_by.surname }}</div>
                      </template>
                      <template #cell(status)="data">
                        <span :class="statusBadge(data.item.status)">{{ data.item.status }}</span>
                      </template>
                      <template #empty="scope">
                        <h4 class="text-center small mt-3">{{ scope.emptyText }}</h4>
                      </template>
                      <template #cell(actions)="data">
                        <span class="d-flex">
                          <voucher-view :data="data.item"></voucher-view>
                          <a v-if="data.item.status != 'REJECTED' && data.item.status != 'APPROVED' && data.item.status != 'DISBURSED' && data.item.status != 'REVERSED'"
                            title="Approve" class="btn btn-sm akkurate-green-btn text-white broder-0 custom-pointer"
                            :href="`/voucher/approve/${data.item.id}`">
                            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'check']" /></span>
                          </a>
                          <reject :data="data.item" @rejected="rejected"></reject>
                          <a v-if="data.item.status == 'APPROVED'" :disabled="isDisbursing" title="Disburse"
                            @click="disburseVoucher(data.item.id)"
                            class="btn btn-sm akkurate-gray-btn text-white custom-pointer">
                            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'exchange-alt']" /></span>
                          </a>
                          <a v-if="data.item.status == 'DISBURSED'" @click="printVoucher(data.item)"
                            class="btn btn-sm akkurate-green-btn text-white custom-pointer">
                            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'book']" /></span>
                          </a>
                          <a title="download" target="_blank" v-if="data.item?.receipt_location != undefined"
                            :href="configBase + '/' + data.item.receipt_location"
                            class="btn btn-sm text-white akkurate-gray-btn custom-pointer">
                            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'download']" /></span>
                          </a>
                          <a v-if="data.item.status == 'DISBURSED'" :disabled="isReversing" title="Reverse"
                            @click="reverseVoucher(data.item.id)"
                            class="btn btn-sm akkurate-blue text-white custom-pointer">
                            <span class="akkurate-small"><font-awesome-icon
                                :icon="['fas', 'arrow-rotate-left']" /></span>
                          </a>
                        </span>
                      </template>
                    </b-table>
                    <div class="d-flex justify-content-between">
                      <b-pagination class="mt-1" @change="navigatePage" v-model="currentPage" :total-rows="totalRecords"
                        :per-page="perPage" aria-controls="voucher-table"></b-pagination>
                      <!-- <b-form-select @change="selectPerPage" v-model="per_page_selected" :options="per_page_options"
                        size="sm" class="mt-3"></b-form-select> -->
                      <div class="col text-end mt-1 akkurate-dark">
                        <div>{{ currentPage }} - {{ perPage }} of {{ totalRecords }}</div>
                        <b-form-group label="Per Page" label-size="sm" class="mb-0">
                          <b-form-select @change="selectPerPage" id="per-page-select" v-model="perPage"
                            :options="per_page_options" size="sm"></b-form-select>
                        </b-form-group>
                      </div>
                    </div>
                  </div>

                  <b-modal size="xl" id="bv-modal-example">
                    <template #modal-title>
                      <h6 class="akkurate-green fw-bold">Voucher Payment</h6>
                    </template>
                    <print-voucher :data="voucherData" :institution="institution" ref="printVoucher"></print-voucher>
                    <template #modal-footer>
                      <div class="w-100">
                        <!-- Emulate built in modal footer ok and cancel button actions -->
                        <b-button @click="handleGeneratePDF" type="button"
                          class="akkurate-green-btn download-btn ms-2 float-right"><span
                            class="small"><font-awesome-icon :icon="['fas', 'book']" /> Print</span></b-button>
                        <b-button @click="handleExportToPDF" class="akkurate-green-btn download-btn float-right"><span
                            class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>
                      </div>
                    </template>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
          <!-- end row  -->
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>

</template>

<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";

import ApiService from "@/core/services/api.service";

import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import Multiselect from 'vue-multiselect'
import Reject from '../forms/modal/Reject.vue'
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import PrintVoucher from "../report/PrintVoucher.vue";
import VoucherView from "../view/VoucherView.vue";
import JournalVoucherFilter from "../../journal/JournalVoucherFilter.vue";

// import $ from 'jquery';
// import 'datatables.net';
// import JwtService from "@/core/services/jwt.service";
import config from '@/config.js'

export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    Multiselect,
    Reject,
    VoucherView,
    PrintVoucher,
    JournalVoucherFilter
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
    this.getVoucherDropdown();
    this.configBase = config?.base_url;
    this.loadItems()

  },

  data() {
    return {
      totalRecords: 0,
      searchFilter: "",
      configBase: "",
      isLoading: false,
      form: {
        status: '',
        branch: [],
        start_date: '',
        end_date: '',
        users: [],
      },
      isDisbursing: false,
      // branch_arr: [],
      // status_arr: [],
      users_options: [],
      status_options: ['PENDING', 'APPROVED', 'REJECTED', 'DISBURSED'],
      branch_options: [],
      isReversing: false,
      // users_arr: [],
      more_details: false,
      voucherData: {},
      institution: {},
      currentPage: 1,
      perPage: 10,
      per_page_options: ['10', '20', '50', '100'],
      per_page_selected: "10",
      isBusy: false,
      fields: [
        {
          label: 'Code',
          key: 'code',
        },
        {
          label: 'Date',
          key: 'recorded_date',
        },
        {
          label: 'Beneficiary_Name',
          key: 'beneficiary_name',
        },

        {
          label: 'Branch',
          key: 'branch.name',
        },
        {
          label: 'Amt_Requested',
          key: 'amount_requested',
        },
        {
          label: 'Narration',
          key: 'narration',
        },
        {
          label: 'Status',
          key: 'status',
        },
        {
          label: 'Created_By',
          key: 'created_by',
        },
        {
          label: 'Actions',
          key: 'actions',
          //   formatFn: this.renderActions,
        },
      ],
      rows: [],
    };
  },
  methods: {
    statusBadge(item) {
      if (item === 'APPROVED') {
        return 'badge akkurate-blue'
      } else if (item === 'REJECTED') {
        return 'badge akkurate-gray-bg'
      } else if (item === 'DISBURSED') {
        return 'badge akkurate-green-bg'
      } else if (item === 'PENDING') {
        return 'badge akkurate-warning-bg'
      } else if (item === 'REVERSED') {
        return 'badge akkurate-danger-bg'
      } else {
        return 'badge akkurate-warning-bg'
      }
    },
    rowClass() {
      return 'custom-row-height'
    },
    filterTable() {
      this.loadItems();
    },

    navigatePage(index) {
      this.isBusy = true;
      this.currentPage = index;
      this.loadItems()
    },
    selectPerPage(index) {
      this.perPage = index
      this.loadItems()
    },

    async getVoucherDropdown() {
      this.$Progress.start();
      await ApiService.get('/vouchers/dropdown')
        .then((response) => {
          this.$Progress.finish();
          this.branch_options = response.data.branches;
          this.users_options = response.data.users;
          this.institution = response.data.institution
        }).catch((error) => {
          this.$Progress.fail();
          console.log(error);
        })
    },
    // load items is what brings back the rows from server
    async loadItems() {
      this.isBusy = true;
      this.isLoading = true;
      let users = []
      let branches = [];
      //Reduce Users Array Object For Filters
      users = this.form.users?.reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.id);
        return accumulator;
      }, []);
      //Reduce Branch Array Object For Filters
      branches = this.form.branch?.reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.id);
        return accumulator;
      }, []);

      await ApiService.post("/vouchers/paginate",
        {
          "page": this.currentPage,
          "per_page": this.perPage,
          "filter": this.form.search,
          "branch_ids": branches, //Eg. 1, 2, etc
          "statuses": this.form.status,
          "start_date": this.form.start_date,
          "end_date": this.form.end_date,
          "users_ids": users,
        })
        .then(response => {
          // console.log(response.data.data);
          this.isBusy = false;  // Set isBusy to false when the data is loaded
          this.$Progress.finish();  // Finish the progress bar

          this.totalRecords = response.data.meta.total;
          this.rows = response.data.data;  // Return data to populate the table

        }).catch(() => {
          // console.log(error);
          this.isLoading = false
        })
        .finally(() => {
          // this.isLoading = false
          this.isLoading = false
        });
    },
    rejected() {
      // this.loadItems();
    },

    // onBranchChange() {
    //   this.branch_arr = [];
    //   if (this.form.branch.length > 0) {
    //     this.form.branch.map((index) => {
    //       this.branch_arr.push(index.id);
    //     });
    //   }

    // },

    // onStatusChange() {
    //   this.status_arr = [];
    //   if (this.form.status.length > 0) {
    //     this.form.status.map((index) => {
    //       this.status_arr.push(index);
    //     });
    //   }
    //   // console.log(this.status_arr);

    // },
    // onBranchChange() {
    //   this.branch_arr = [];
    //   if (this.form.branch.length > 0) {
    //     this.form.branch.map((index) => {
    //       this.branch_arr.push(index.id);
    //     });
    //   }

    // },

    // onUserChange() {
    //   console.log(this.form.users);
    //   this.users_arr = [];
    //   if (this.form.users.length > 0) {
    //     this.form.users.map((index) => {
    //       this.users_arr.push(index.id);
    //     });
    //   }
    // },

    // onStatusChange() {
    //   this.status_arr = [];
    //   if (this.form.status.length > 0) {
    //     this.form.status.map((index) => {
    //       this.status_arr.push(index);
    //     });
    //   }
    //   // console.log(this.status_arr);

    // },
    printVoucher(row) {
      this.voucherData = row
      this.$nextTick(() => {
        this.$bvModal.show('bv-modal-example');
      });
    },
    async disburseVoucher(index) {
      swal.fire({
        title: "Disburse Voucher",
        text: 'Are you sure you want to disburse voucher ' + index + ' ?',
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "YES",
        cancelButtonText: "NO",
        allowOutsideClick: true,
        customClass: {
          cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
          confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
        }

      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          this.isDisbursing = true;
          ApiService.post(`/vouchers/disburse/${index}`)
            .then((response) => {
              this.$Progress.finish();
              swal.fire({
                // title: response.statusText,
                text: response.data.message,
                icon: "success",
                allowOutsideClick: true,
                allowEscapeKey: true,
                showCancelButton: false, // Show the "Cancel" button
                confirmButtonText: 'okay', // Customize the text for the "OK" button
                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                customClass: {
                  confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                }
              });
              this.loadItems();
            }).catch((error) => {
              this.$Progress.fail();
              swal.fire({
                // title: response.statusText,
                text: error.response?.data?.message,
                icon: "error",
                allowOutsideClick: true,
                allowEscapeKey: true,
                showCancelButton: false, // Show the "Cancel" button
                confirmButtonText: 'okay', // Customize the text for the "OK" button
                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                customClass: {
                  confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                }
              });
            })
        }
      });



      // this.$Progress.start();
      // this.isDisbursing = true;
      // // console.log(this.form);
      // await ApiService.post(`/vouchers/disburse/${index}`)
      //   .then((response) => {
      //     this.isDisbursing = false;
      //     this.$Progress.finish();
      //     toast.fire({
      //       icon: "success",
      //       title: response.data.message,
      //     });
      //     this.loadItems();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     this.isDisbursing = false;
      //     this.$Progress.fail();
      //     // if (error.response.data.errors) {
      //     //     this.form.errors = error.response.data.errors;
      //     // }
      //   });
    },
    exportToExcel() {
      const workbook = XLSX.utils.book_new();

      // Get the table data
      const tableData = this.$refs.userTable.filteredRows[0].children;

      // Convert the table data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate the Excel file buffer
      const excelBuffer = XLSX.write(workbook, {
        type: 'array',
        bookType: 'xlsx'
      });

      // Save the Excel file
      FileSaver.saveAs(
        new Blob([excelBuffer], { type: 'application/octet-stream' }),
        'table_data.xlsx'
      );
    },
    show_more() {
      this.more_details = !this.more_details
    },
    handleGeneratePDF() {
      // Call the generatePDF method inside the print-voucher component
      this.$refs.printVoucher.generatePDF();
    },
    handleExportToPDF() {
      // Call the exportToPDF method inside the print-voucher component
      this.$refs.printVoucher.exportToPDF();
    },
    reverseVoucher(index) {
      swal.fire({
        // title: response.data.transaction.trans_status,
        title: "Reverse Voucher",
        // text: 'CODE : ' + response.data.transaction.code,
        text: 'Are you sure?',
        confirmButtonColor: "#018673",
        icon: 'question',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: true, // Show the "Cancel" button
        confirmButtonText: 'No', // Customize the text for the "OK" button
        cancelButtonText: 'Yes', // Text for the custom button
        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
        customClass: {
          confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
          cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isDisbursing = false;
          // this.$bvModal.hide(String(this.loan_no + this.statement));
        } else if (result.isDismissed) {
          this.isLoading = true;
          this.isDisbursing = false;
          this.$Progress.start();
          // this.$bvModal.hide(String(this.loan_no + this.statement));
          await ApiService.post(`/vouchers/reverse/${index}`)
            .then((response) => {
              console.log(response);
              this.isLoading = false;
              this.$Progress.finish();
              toast.fire({
                icon: "success",
                title: response.data.message,
              });
              this.loadItems();
            })
            .catch((error) => {
              this.isDisbursing = false;
              this.isLoading = false;
              this.$Progress.fail();
              toast.fire({
                icon: "error",
                title: error.response.data.message,
              });
              // if (error.response.data.errors) {
              //     this.form.errors = error.response.data.errors;
              // }
            });
        }
      })
    },
  }
};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
