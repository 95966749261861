<template>
    <div class="">
        <div v-if="isLoading" class="col-12">
            <!-- begin loader -->
            <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
            <!-- end loader -->
        </div>
        <div v-else class="row">
            <div class="col-md-12">
                <b-tabs justified>
                    <b-tab title="Personal Details" active class="text-xs">
                        <div class="col-md-12">
                            <b-card-group deck>
                                <b-card>
                                    <!-- <template #header>
                                        <div>
                                            <h6 class="m-0 fw-bold text-green">Personal Details</h6>
                                        </div>
                                    </template> -->
                                    <b-card-body>
                                        <div class="row">
                                            <div class="col text-center">
                                                <img v-bind:src="form.passport_pic_path" alt="Image"
                                                    style="height:200px" class="img-fluid text-center" />
                                                <p class="mt-2 akkurate-green fw-bold">Passport Pic</p>
                                            </div>
                                            <div class="col text-center">
                                                <img v-if="imageLoaded" v-bind:src="frontImgUrl" alt="Image"
                                                    @load="handleImageLoad" style="height:200px"
                                                    class="img-fluid text-center" />
                                                <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image"
                                                    style="height:200px" class="img-fluid text-center" />
                                                <p class="mt-2 akkurate-green fw-bold">GH Card - Front</p>
                                            </div>
                                            <div class="col text-center">
                                                <img v-if="imageLoaded" v-bind:src="backImgUrl" alt="Image"
                                                    @load="handleImageLoad" style="height:200px"
                                                    class="img-fluid text-center" />
                                                <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image"
                                                    style="height:200px" class="img-fluid text-center" />
                                                <p class="mt-2 akkurate-green fw-bold">GH Card - Back</p>
                                            </div>

                                            <div class="col text-center">
                                                <img v-bind:src="form.signature_image_path" alt="Image"
                                                    style="height:200px" class="img-fluid text-center" />
                                                <p class="mt-2 akkurate-green fw-bold">Signature</p>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-1">
                                                <label class="akkurate-dark" for="title">Title</label>
                                                <b-form-group id="title" label-for="title">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.title" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-3">
                                                <label class="akkurate-dark" for="search_term">First Name</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.first_name" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Middle Name</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.middlename" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Surname</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.last_name" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Gender</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.gender" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Cust. Code</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.code" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Ext. Cust. Code</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.external_code" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="dob">Date of Birth</label>
                                                <b-form-group id="dob" label-for="dob">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.date_of_birth" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="marital_status">Marital Status</label>
                                                <b-form-group id="marital_status" label-for="marital_status">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.marital_status" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="email">Email</label>
                                                <b-form-group id="email" label-for="email">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.email" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="mobile">Mobile</label>
                                                <b-form-group id="mobile" label-for="mobile">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.mobile" disabled></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="ghana_card">Ghana Card Number</label>
                                                <b-form-group id="ghana_card" label-for="ghana_card">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.ghana_card_number" disabled></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="ghana_card">Status</label>
                                                <b-form-group id="status" label-for="status">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.status" disabled></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="ghana_card">Source</label>
                                                <b-form-group id="source" label-for="source">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.source" disabled></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="ghana_card">Pin Set</label>
                                                <b-form-group id="status" label-for="status">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        :value="form.is_pin_changed == 1 ? 'Yes' : 'No'"
                                                        disabled></b-form-input>
                                                </b-form-group>
                                            </div>


                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="registered_date">Registered
                                                    Date</label>
                                                <b-form-group id="status" label-for="status">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        :value="form.registered_date" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-card-group>
                        </div>
                    </b-tab>
                    <b-tab title="Employee Details">
                        <div class="col-12 col-md-12">
                            <div class="card card-shape home-box">
                                <!-- <div class="card-header d-flex flex-row align-items-center">
                                    <h6 class="m-0 fw-bold text-green">Employer Details</h6>
                                </div> -->
                                <div class="card-body">
                                    <employer-details :form="form" :add_or_edit="add_or_edit"></employer-details>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Financial Info">
                        <div class="col-12 col-md-12">
                            <div class="card card-shape home-box">
                                <!-- <div class="card-header d-flex flex-row align-items-center">
                                    <h6 class="m-0 fw-bold text-green">Financial Information</h6>
                                </div> -->
                                <div class="card-body">
                                    <financial-information :form="form" :add_or_edit="add_or_edit">
                                    </financial-information>
                                </div>
                            </div>
                        </div>

                    </b-tab>
                    <b-tab title="Address">
                        <div class="col-md-12">
                            <b-card-group deck>
                                <b-card>
                                    <!-- <template #header>
                                        <div>
                                            <h6 class="m-0 fw-bold text-green">Address</h6>
                                        </div>
                                    </template> -->
                                    <b-card-body>
                                        <div class="row">
                                            <div class="col-12 col-md-4">
                                                <b-form-group label-for="address" class="mb-2">
                                                    <template #label>
                                                        <label class="akkurate-dark" for="address">Address <star></star>
                                                        </label>
                                                    </template>
                                                    <b-form-textarea class="mt-2 fields" id="address"
                                                        v-model="form.address" disabled></b-form-textarea>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="digital_address">Digital Address
                                                    <star></star>
                                                </label>
                                                <b-form-group id="digital_address" label-for="digital_address">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.digital_address" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Region</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="region.name" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Home Town</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="form.home_town" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <label class="akkurate-dark" for="search_term">Branch</label>
                                                <b-form-group id="search_term" label-for="search_term">
                                                    <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                                        v-model="branch.name" disabled></b-form-input>
                                                </b-form-group>
                                            </div>
                                        </div>
                                    </b-card-body>


                                </b-card>
                            </b-card-group>
                        </div>
                    </b-tab>
                    <b-tab title="Account">
                        <div class="card">
                            <div class="card-body">
                                <vue-good-table :columns="account_columns" :rows="form.accounts">
                                    <!-- Add your custom delete button column -->
                                    <template slot="table-row" slot-scope="props">
                                        <span class="d-flex" v-if="props.column.field == 'account_balance'">
                                            <span v-if="props.row.account_type.base_type == 'Loan'">
                                                {{ displayNumber(props.row.loan.interest_balance +
                                                    props.row.loan.principal_balance) }}
                                            </span>
                                            <span v-else>
                                                {{ displayNumber(props.row.account_balance) }}
                                            </span>
                                        </span>

                                        <span class="d-flex" v-else-if="props.column.field == 'account_type.name'">
                                            <span v-if="props.row.account_type.base_type == 'Loan'">
                                                {{ props.row.loan.loan_product.name }}
                                            </span>
                                            <span v-else>
                                                {{ props.row.account_type.name }}
                                            </span>
                                        </span>

                                        <span class="d-flex" v-else-if="props.column.field == 'account_number'">
                                            <span v-if="props.row.account_type.base_type == 'Loan'">
                                                {{ props.row.loan.loan_number }}
                                            </span>
                                            <span v-else>
                                                {{ props.row.account_number }}
                                            </span>
                                        </span>

                                        <span class="d-flex" v-else-if="props.column.field == 'action'">
                                            <a :title="props.row.account_type.base_type + ' Statement'"
                                                v-if="props.row.account_type.base_type == 'Savings'"
                                                class="btn btn-sm akkurate-gray-btn text-white"
                                                v-b-modal="String(props.row?.id + 'account_statement')">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'book']" /></span>
                                            </a>
                                            <account-statement :data="props.row"
                                                :institution="institution"></account-statement>
                                            <a :title="props.row.account_type.base_type + ' Statement'"
                                                v-if="props.row.account_type.base_type == 'Loan'"
                                                class="btn btn-sm akkurate-gray-btn text-white"
                                                v-b-modal="String(props.row?.id + 'statement')">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'book']" /></span>
                                            </a>
                                            <loan-statement :data="props.row" :statement="'statement'"
                                                :institution="institution" :branch="props.row?.account?.customer?.branch"></loan-statement>

                                        </span>

                                        <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                        </span>
                                    </template>
                                </vue-good-table>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Beneficiaries" :active="active_beneficiary">
                        <div class="card">
                            <div class="card-header pe-1">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h6 class="m-0 fw-bold text-green">Beneficiary</h6>
                                    </div>
                                    <div>
                                        <b-button class="btn btn-sm akkurate-green-btn"
                                            @click="showBeneficiaryAddModal()" variant="primary">
                                            <span class="akkurate-small"> <i class="fas fa-plus"></i> Add
                                                Beneficiary</span>
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body table_text_size">
                                <b-table striped bordered responsive id="beneficiary-table" :busy.sync="isBusy"
                                    :items="form.beneficiaries" :fields="beneficiary_fields" :tbody-tr-class="rowClass"
                                    show-empty>
                                    <template #cell(actions)="data">
                                        <a title="Edit"
                                            class="btn btn-sm akkurate-blue text-white broder-0 custom-pointer"
                                            @click="showBeneficiaryEditModal(data.item)">
                                            <span class="akkurate-small"> <font-awesome-icon
                                                    :icon="['fas', 'edit']" /></span>
                                        </a>
                                        <a title="Delete" class="btn btn-sm akkurate-danger-btn text-white"
                                            @click="confirmDeleteBeneficiary(data.item)">
                                            <span class="akkurate-small"><font-awesome-icon
                                                    :icon="['fas', 'trash']" /></span>
                                        </a>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Transactions">
                        <div class="card">
                            <div class="card-body">

                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
                <b-modal @ok="handleAddBenefit" id="bv-modal-benficiaries-add" title="Beneficiary" ref="modal"
                    size="lg">
                    <template #modal-title>
                        <h6 class="text-center fw-bold akkurate-green mb-0">Add Beneficiary</h6>
                    </template>
                    <form @submit.stop.prevent="submitAddBeneficiary">
                        <beneficiary-form :form="beneficiary_form"></beneficiary-form>
                    </form>

                    <template #modal-footer="{ ok, cancel }">
                        <b-button v-b-modal.ok @click="ok()" class="btn akkurate-green-btn btn-size text-white">Save</b-button>
                        <b-button v-b-modal.cancel @click="cancel()" class="btn akkurate-gray-btn btn-size text-white">Close</b-button>
                    </template>
                </b-modal>
                
                    <b-modal  @ok="handleEditBenefit" id="bv-modal-benficiaries-edit" ref="modal" size="lg">
                        <template #modal-title>
                            <h6 class="text-center fw-bold akkurate-green mb-0">Edit Beneficiary</h6>
                        </template>
                        <form @submit.stop.prevent="submitEditBeneficiary">
                            <beneficiary-form :form="beneficiary_form"></beneficiary-form>
                        </form>
                        <template #modal-footer="{ ok, cancel }">
                            <b-button @click="ok()" v-b-modal.ok type="submit"
                                class="btn akkurate-green-btn btn-size text-white">Update</b-button>
                            <b-button @click="cancel()" v-b-modal.cancel
                                class="btn akkurate-gray-btn btn-size text-white">Close</b-button>
                        </template>
                    </b-modal>
            </div>
        </div>
    </div>
</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import ApiService from "@/core/services/api.service";
// import EditNextOfKin from '../edit/modal/EditNextOfKin.vue'
import AccountStatement from '../accounts/statement/AccountStatement.vue'
import LoanStatement from '../../loan/statements/LoanStatement.vue';
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import EmployerDetails from "../forms/EmployerDetails.vue";
import FinancialInformation from "../forms/FinancialInformation";
// import AddNextOfKin from '../edit/modal/AddNextOfKin.vue';
import BeneficiaryForm from '../edit/modal/BeneficiaryForm.vue';


export default {
    components: {
        SidebarView,
        TopbarView,
        // AddNextOfKin,
        // EditNextOfKin,
        BeatLoaderComponent,
        AccountStatement,
        LoanStatement,
        EmployerDetails,
        FinancialInformation,
        BeneficiaryForm
    },
    created() {
        this.getMemberView();
        this.getInsistution();
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            // id: this.$route.params.id,
            add_or_edit: 'view',
            active_beneficiary:false,
            form: {
                id: 0,
                code: 0,
                first_name: "",
                middlename: "",
                last_name: "",
                title: "",
                email: "",
                mobile: "",
                gender: "",
                date_of_birth: "",
                marital_status: "",
                address: "",
                digital_addres: "",
                home_town: "",
                landmark: "",
                employer_name: "",
                ghana_card_number: "",
                status: "",

                employer_address: null,
                employer_mobile: null,
                occupation: null,
                employee_position: null,
                source_of_funds: null,
                bankers_name: null,
                bankers_address: null,
                bankers_location: null,
                monthly_salary: null,
                signature_image_path: null,
                signature_image_name: null,
                passport_pic_path: null,
                passport_pic_name: null,
                note: null,
                mandate: null,
                errors: [],


            },
            branch: [],
            region: [],
            institution: "",
            imageLoaded: false,
            isLoading: false,
            isBusy: false,
            beneficiary_form: {
                id: 0,
                surname: "",
                first_name: "",
                email: "",
                phone_number: "",
                relation: "",
                address: "",
                model_id: "",
                errors: [],
            },


            //Accounts
            account_columns: [
                // {
                //     label: 'Account ID',
                //     field: 'id',
                // },
                {
                    label: 'Account Type Name',
                    field: 'account_type.name',
                },
                {
                    label: 'Account No. /Loan No.',
                    field: 'account_number',
                },
                {
                    label: 'Balance',
                    field: 'account_balance',
                },
                {
                    label: 'Status',
                    field: 'status',
                    type: 'string',
                },
                {
                    label: 'Action',
                    field: 'action',
                    type: 'string',
                },
            ],
            account_rows: [],

            //Beneficiaries
            beneficiary_fields: [
                {
                    label: '#',
                    key: 'id',
                },
                {
                    label: 'Surname',
                    key: 'surname',
                },
                {
                    label: 'Other names',
                    key: 'first_name',
                },
                {
                    label: 'Email',
                    key: 'email',
                },
                {
                    label: 'Phone number',
                    key: 'phone_number',
                },
                {
                    label: 'Relation',
                    key: 'relation',
                },
                {
                    label: 'Address',
                    key: 'address',
                },
                {
                    label: 'Percentage(%)',
                    key: 'percentage_share',
                },
                {
                    label: 'Actions',
                    key: 'actions',
                },
            ],
            next_rows: [],
        };
    },
    methods: {

        showBeneficiaryEditModal(data) {
            // console.log(data);
            this.initialBenefitForm();
            this.beneficiary_form = data;
            this.$bvModal.show('bv-modal-benficiaries-edit')
        },
        showBeneficiaryAddModal() {
            this.initialBenefitForm();
            this.$bvModal.show('bv-modal-benficiaries-add')
        },
        async getMemberView() {
            this.isLoading = true;
            await ApiService.get("/customers/view/" + this.id)
                .then(response => {
                    // console.log(response.data.data);
                    this.form = response.data.data;
                    this.region = response.data.data.region != null ? response.data.data.branch : "";
                    this.branch = response.data.data.branch != null ? response.data.data.branch : "";
                    this.handleImageLoad();
                    this.isLoading = false;
                    // console.log(this.form);
                    // this.forms = response.data.data;
                    // this.per_page = response.data.meta.per_page;
                    // console.log(response.data.meta.per_page)
                    // this.totalRecords = response.data.meta.total;

                }).catch((error) => {
                    this.isLoading = false;
                    swal.fire({
                        // title: response.statusText,
                        text: error.response.data.message,
                        icon: "error",
                        allowOutsideClick: true,
                        allowEscapeKey: true,
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'okay', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                    // this.isLoading = false
                });
        },
        nextofkin() {
            this.getMemberView();
        },
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                // console.log(response);
                this.institution = response.data.data;
            }).catch((error) => {
                // console.log(error);
            })
        },
        handleImageLoad() {
            // console.log(this.form?.gh_card_front_path);
            if (this.form?.gh_card_front_path) {
                this.imageLoaded = true;
                this.frontImgUrl = this.form?.gh_card_front_path;
            }
            if (this.form?.gh_card_back_path) {
                this.imageLoaded = true;
                this.backImgUrl = this.form?.gh_card_back_path;
            }


        },
        rowClass() {
            return 'custom-row-height'
        },
        handleAddBenefit(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            this.submitAddBeneficiary();
        },
        handleEditBenefit(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            this.submitEditBeneficiary();
        },
        async submitAddBeneficiary() {
            this.isSaving = true;
            this.$Progress.start();
            this.beneficiary_form.errors = [];
            this.beneficiary_form.model_id = this.form.id;
            this.beneficiary_form
            await ApiService.post("/customers/beneficiaries/store/", this.beneficiary_form)
                .then((response) => {
                    this.isSaving = false;
                    this.$Progress.finish();

                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.getMemberView();
                    this.$nextTick(() => {
                        this.$bvModal.hide('bv-modal-benficiaries-add')
                    })
                    this.active_beneficiary = true;
                    this.initialBenefitForm();

                    // this.$emit("next_of_kin_edited", response.data.data);

                    // // Hide Modal Manually
                    // this.$nextTick(() => {
                    //     this.$bvModal.hide('edit-nextofkin')
                    // })

                    // window.location.reload()
                    //   this.isSaving = false;
                    //   this.form.phone_number = "";
                    //   this.form.email = "";
                    //   this.form.selected_payment_method = "";
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    this.beneficiary_form.errors = [];
                    if (error.response.data.errors) {
                        // console.log(error.response.data.errors)
                        this.beneficiary_form.errors = error.response.data.errors;
                    }
                    // console.log(error.response.data)
                });

        },
        async submitEditBeneficiary() {
            this.isSaving = true;
            this.$Progress.start();
            this.beneficiary_form.errors = [];
            this.beneficiary_form
            await ApiService.post("/customers/beneficiaries/update/" + this.beneficiary_form.id,
                this.beneficiary_form
            )
                .then((response) => {
                    this.isSaving = false;
                    this.$Progress.finish();
                    // console.log(response);

                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    
                    // // Hide Modal Manually
                    this.$nextTick(() => {
                        this.$bvModal.hide('bv-modal-benficiaries-edit')
                    })
                    this.getMemberView();
                    this.active_beneficiary = true;
                    this.initialBenefitForm();

                    // window.location.reload()
                    //   this.isSaving = false;
                    //   this.form.phone_number = "";
                    //   this.form.email = "";
                    //   this.form.selected_payment_method = "";
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    this.form.errors = [];
                    if (error.response.data.errors) {
                        // console.log(error.response.data.errors)
                        this.form.errors = error.response.data.errors;
                    }
                    // console.log(error.response.data)
                });
        },
        async confirmDeleteBeneficiary(data) {
            swal.fire({
                title: "Delete Beneficiary",
                text: 'Are you sure you want to delete ' + data?.first_name + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                allowOutsideClick: true,
                customClass: {
                    cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
                }
            }).then(async (result) => {
                if (result.value) {

                    this.$Progress.start();
                    await ApiService.delete('/beneficiaries/delete/' + data.id)
                        .then((response) => {
                            this.$Progress.finish();
                            this.beneficiary_form
                            this.initialBenefitForm();
                            swal.fire({
                                // title: response.statusText,
                                text: response?.data?.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.getMemberView();
                            this.active_beneficiary = true;
                        }).catch((error) => {
                            this.$Progress.fail();
                            swal.fire({
                                // title: response.statusText,
                                text: error?.response?.data?.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });

        },
        initialBenefitForm() {
            this.beneficiary_form = {
                id: 0,
                surname: "",
                first_name: "",
                email: "",
                phone_number: "",
                relation: "",
                address: "",
                model_id: "",
                errors: [],
            }
        },

    }
}
</script>