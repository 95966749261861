<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="card card-shape home-box">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">Details Receipt and Payments Reports</h6>
                </div>
                <div class="col-6 text-end">
                  <b-button @click="generateExcel" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']" />
                  </b-button>
                  <report-filter :title="'RECEIPT & PAYMENTS'" :form="form" @filterAction="filterAction"
                    :filter="filter"></report-filter>
                  <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"
                    title="Print"><font-awesome-icon :icon="['fas', 'book']" /></b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"
                    title="Download"><font-awesome-icon :icon="['fas', 'download']" /></b-button>
                </div>
              </div>
            </div>
            <div class="card-body mx-3" ref="pdfContent">
              <div class="row">
                <div class="col-12 col-md-12">
                  <statement-institution :institution="institution" :current_date="false"
                    :title="'NOTE TO RECEIPTS & PAYMENTS'"></statement-institution>
                </div>
                <hr />
                <div class="row mb-2">
                  <div class="col-12 small">
                    Branch(es) : {{ branch_list }}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-4 text-end">
                  <div>Terms: <span class="fw-bold">{{ form.terms }}</span></div>
                  <span class="mt-2">From: <strong>{{ form.start_date }}</strong> To: <strong>{{
                    form.end_date }}</strong> </span>
                </div>
              </div>
              <div v-if="isBusy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
              <div v-else id="balance-sheet" class="row">
                <div class="col-12 my-2">
                </div>
                <div class="col-6 col-md-6 px-0 trans_details">
                  <b-table-simple responsive outlined bordered>
                    <b-thead>
                      <b-tr>
                        <b-th class="text-center fs-6 akkurate-green" colspan="3"><span>RECEIPTS</span></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <td class="py-0 px-2">Beg. of the month cash</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(beg_of_month_cash).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          <p class="m-0">MEMBER SHARES DEPOSIT</p>
                        </b-td>
                      </b-tr>
                      <b-tr v-for="(value, item) in member_shares.data" :key="value.id" class="list-item">
                        <b-td>
                          {{ value.name }}
                        </b-td>
                        <b-td>
                          GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="success" style="font-weight: bold;">
                          Total Shares Deposit
                        </b-td>
                        <b-td variant="success" style="font-weight: bold;">
                          GHS {{ displayNumber(total_shares_dp) }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="secondary" colspan="2" style="font-weight: bold;">
                          <p class="m-0">MEMBER SAVINGS</p>
                        </b-td>
                      </b-tr>
                      <b-tr v-for="(value, item) in member_savings.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="success" style="font-weight: bold;">
                          Total Savings Deposit
                        </b-td>
                        <b-td variant="success" style="font-weight: bold;">
                          GHS {{ displayNumber(total_savings_dp) }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          <p class="m-0">LOAN REPAYMENT</p>
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_loan_repayment) }}
                        </b-td> -->
                      </b-tr>

                      <b-tr v-for="(value, item) in member_loans.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="success" style="font-weight: bold;">
                          Total Loan Repayment
                        </b-td>
                        <b-td variant="success" style="font-weight: bold;">
                          GHS {{ displayNumber(total_loan_repayment) }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          <p class="m-0">BANK SAVINGS WITHDRWALS</P>
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_savings_wd) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in bank_savings.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="success" style="font-weight: bold;">
                          Total Bank Savings Withdrawals
                        </b-td>
                        <b-td variant="success" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_savings_wd) }}
                        </b-td>
                      </b-tr>


                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          <p class="m-0">BANK CURRENT WITHDRWALS</P>
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_current_wd) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in bank_currents.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="success" style="font-weight: bold;">
                          Total Bank Current Withdrawals
                        </b-td>
                        <b-td variant="success" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_current_wd) }}
                        </b-td>
                      </b-tr>


                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          CUA/CFF/ WITHDRAWALS
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_cff_wd) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in cff.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                      </b-tr>


                      <b-tr>
                        <b-td variant="success" style="font-weight: bold;">
                          Total CUA/CFF Withdrawals
                        </b-td>
                        <b-td variant="success" style="font-weight: bold;">
                          GHS {{ displayNumber(total_cff_wd) }}
                        </b-td>
                      </b-tr>


                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          <p class="m-0">T-BILLS DISCOUNT</P>
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_t_bill_discount) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in t_bills.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="success" style="font-weight: bold;">
                          Total T-Bill Discount
                        </b-td>
                        <b-td variant="success" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bill_discount) }}
                        </b-td>
                      </b-tr>


                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          OTHER RECEIPTS
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_other_receipt) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in others.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="success" style="font-weight: bold;">
                          Total Other Receipts
                        </b-td>
                        <b-td variant="success" style="font-weight: bold;">
                          GHS {{ displayNumber(total_other_receipt) }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="secondary" style="font-weight: bold;">
                          Sub- Total for the Month
                        </b-td>
                        <b-td variant="secondary" style="font-weight: bold;">GHS {{ displayNumber(sub_total_receipt)
                        }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="secondary" style="font-weight: bold;">
                          Income for the month
                        </b-td>
                        <b-td variant="secondary" style="font-weight: bold;">GHS {{ displayNumber(monthly_income)
                        }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="secondary" style="font-weight: bold;">
                          Total Receipts
                        </b-td>
                        <b-td variant="secondary" style="font-weight: bold;">GHS {{
                          displayNumber(Number(grand_total_receipt)) }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
                <div class="col-6 col-md-6 px-0 trans_details">
                  <b-table-simple responsive outlined bordered>
                    <b-thead>
                      <b-tr>
                        <b-th class="text-center fs-6 akkurate-green" colspan="3"><span>PAYMENTS</span></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <td class="py-0 px-2">End. of the month cash</td>
                        <td class="py-0 px-2"> GHS {{ displayNumber(Number(end_of_month_cash).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          MEMBER SHARES WITHDRAWALS
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_shares_with) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in member_shares.data" :key="value.id" class="list-item">
                        <b-td>
                          {{ value.name }}
                        </b-td>
                        <b-td>
                          GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="danger" style="font-weight: bold;">
                          Total Shares Withdrawals
                        </b-td>
                        <b-td variant="danger" style="font-weight: bold;">
                          GHS {{ displayNumber(total_shares_with) }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          MEMBER SAVING WITHDRAWALS
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_savings_with) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in member_savings.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="danger" style="font-weight: bold;">
                          Total Savings Withdrawals
                        </b-td>
                        <b-td variant="danger" style="font-weight: bold;">
                          GHS {{ displayNumber(total_savings_with) }}
                        </b-td>
                      </b-tr>


                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          <p class="m-0">LOANS TO MEMBERS</p>
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_loan_disbursed) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in member_loans.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="danger" style="font-weight: bold;">
                          Total Loans to members
                        </b-td>
                        <b-td variant="danger" style="font-weight: bold;">
                          GHS {{ displayNumber(total_loan_disbursed) }}
                        </b-td>
                      </b-tr>


                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          <p class="m-0">BANK SAVINGS DEPOSIT</P>
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_savings_dp) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in bank_savings.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="danger" style="font-weight: bold;">
                          Total Bank Saving
                        </b-td>
                        <b-td variant="danger" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_savings_dp) }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td colspan='2' variant="secondary" style="font-weight: bold;">
                          BANK CURRENT DEPOSIT
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_current_dp) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in bank_currents.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="danger" style="font-weight: bold;">
                          Total Bank Current
                        </b-td>
                        <b-td variant="danger" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_current_dp) }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          <p class="m-0">CUA/CFF DEPOSITS</P>
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_cff_dp) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in cff.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="danger" style="font-weight: bold;">
                          Total CUA/CFF Deposit
                        </b-td>
                        <b-td variant="danger" style="font-weight: bold;">
                          GHS {{ displayNumber(total_cff_dp) }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                         <p class="m-0">T-BILLS INVEST</p>
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_t_bill_invest) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in t_bills.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                      </b-tr>


                      <b-tr>
                        <b-td variant="danger" style="font-weight: bold;">
                          Total T-Bills Invest
                        </b-td>
                        <b-td variant="danger" style="font-weight: bold;">
                          GHS {{ displayNumber(total_t_bill_invest) }}
                        </b-td>
                      </b-tr>


                      <b-tr>
                        <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                          OTHER PAYMENTS
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_other_payments) }}
                        </b-td> -->
                      </b-tr>
                      <b-tr v-for="(value, item) in others.data" :key="value.id" class="list-item">
                        <b-td> {{ value.name }}</b-td>
                        <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="danger" style="font-weight: bold;">
                          Total Other Payments
                        </b-td>
                        <b-td variant="danger" style="font-weight: bold;">
                          GHS {{ displayNumber(total_other_payments) }}
                        </b-td>
                      </b-tr>

                      <b-tr>
                        <b-td variant="secondary" style="font-weight: bold;">
                          Sub- Total for the Month
                        </b-td>
                        <b-td variant="secondary" style="font-weight: bold;">GHS {{ displayNumber(sub_total_payment)
                        }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="secondary" style="font-weight: bold;">
                          Expenses for the month
                        </b-td>
                        <b-td variant="secondary" style="font-weight: bold;">GHS {{ displayNumber(monthly_expenses)
                        }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-td variant="secondary" style="font-weight: bold;">
                          Total Payments
                        </b-td>
                        <!-- <b-td variant="secondary" style="font-weight: bold;">GHS
                          {{ displayNumber(Number(Number(sub_total_payment) + Number(monthly_expenses)).toFixed(2))
                          }}</b-td> -->
                        <b-td variant="secondary" style="font-weight: bold;">GHS
                          {{ displayNumber(Number(grand_total_payment))
                          }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-md-6 p-0 trans_details">
                  <b-table-simple responsive outlined bordered>
                    <b-tbody>
                      <b-tr class="list-item">


                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import html2pdf from "html2pdf.js";
import ReportFilter from "../form/ReportFilter.vue";
import ExcelJS from 'exceljs';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter
  },
  data() {
    return {
      form: {
        start_date: this.getBeginningOfYear(),
        end_date: new Date().toISOString().substr(0, 10),
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "Periodic",
        terms_options: ["YTD", "Periodic"],
        categories: [],
      },
      filter: {
        branches: true,
        products: false,
        users: false,
        status: false,
        condition: false,
        terms: true,
        coa: false,
        voucher_status: false,
        start_date: true,
        end_date: true,
      },
      incomes: [],
      expenses: [],
      profit_or_loss: 0,
      search: "Search",
      grant_total_expenses: 0,
      grant_total_incomes: 0,
      isBusy: false,
      isSearching: false,
      selected_num: "10",
      numbers_options: [10, 50, 100, 500],
      isLoadingInstitution: false,
      institution: "",
      start_date: "",
      end_date: "",
      member_shares: [],
      member_savings: [],
      member_loans: [],
      member_shares: [],
      member_savings: [],
      member_loans: [],
      bank_savings: [],
      bank_currents: [],
      bank_savings: [],
      bank_currents: [],
      t_bills: [],
      t_bills: [],
      cff: [],
      cff: [],
      others: [],
      others: [],
      total_receipts: 0,
      total_payments: 0,
      total_shares_dp: 0,
      total_savings_dp: 0,
      total_loan_repayment: 0,
      total_shares_with: 0,
      total_savings_with: 0,
      total_loan_disbursed: 0,
      total_bank_savings_wd: 0,
      total_bank_current_wd: 0,
      total_bank_savings_dp: 0,
      total_bank_current_dp: 0,
      total_cff_wd: 0,
      total_t_bill_discount: 0,
      total_other_receipt: 0,
      total_cff_dp: 0,
      total_t_bill_invest: 0,
      total_other_payments: 0,
      monthly_income: 0,
      monthly_expenses: 0,
      sub_total_receipt: 0,
      sub_total_payment: 0,
      branch_list: [],
      beg_of_month_cash: "",
      grand_total_receipt:0,
grand_total_payment:0,
      // fields: [
      //   {
      //     key: "account_number",
      //     label: "Account Number",
      //   },
      //   {
      //     key: "customer_name",
      //     label: "Customer Name",
      //   },
      //   {
      //     key: "amount",
      //     label: "Amount",
      //   },
      //   {
      //     key: "min_balance",
      //     label: "Minimum Balance",
      //   },
      //   {
      //     key: "total",
      //     label: "Total",
      //   },

      // ],
      perPage: 20,
      currentPage: 1,
      items: [],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    // sumLoanBalance() {
    //   let total = 0;
    //   for (let i = 0; i < this.items.length; i++) {
    //     total = this.items[i].loan_balance;
    //   }
    //   return total;
    // },
    // sumSavingBalance() {
    //   let total = 0;
    //   for (let i = 0; i < this.items.length; i++) {
    //     total = this.items[i].saving_balance;
    //   }

    //   return total;
    // },
    //assets total
    //   sumAssetsTotal(){
    //     let total = 0;
    //     for(let i = 0; i < this.incomes.length; i++){
    //       total = this.incomes[i].amount;
    //     }
    //     return total;
    //   },
  },

  created() {
    this.getInstitution();
    this.getBalanceSheets();
  },
  methods: {
    async getBalanceSheets() {
      this.items = [];
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.post("/reports/financials/receipts-payments/detailed",
        {
          startDate: this.form.start_date,
          endDate: this.form.end_date,
          terms: this.form.terms
        }
      )
        .then((response) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          // var res = response.data?.data;
          var res = response.data;
          this.member_shares = res.member_shares;
          this.member_savings = res.member_savings
          this.member_loans = res.member_loans
          this.member_shares = res.member_shares;
          this.member_savings = res.member_savings;
          this.member_loans = res.member_loans;
          this.bank_savings = res.bank_savings;
          this.bank_currents = res.bank_currents;
          this.bank_savings = res.bank_savings;
          this.bank_currents = res.bank_currents;
          this.cff = res.cff;
          this.cff = res.cff;
          this.t_bills = res.t_bills;
          this.t_bills = res.t_bills;
          this.others = res.others;
          this.others = res.others;
          this.monthly_income = res.monthly_income;
          this.monthly_expenses = res.monthly_expenses;
          // this.total_receipts = res.total_receipts;
          // this.total_payments = res.total_payments;
          this.form.branch_options = res.branches
          this.beg_of_month_cash = res.beg_of_month_cash;
          this.end_of_month_cash = res.end_of_month_cash;

          this.branch_list = this.form.branches.map(branch => branch.name).join(", ");

          this.sub_total_receipt = Number(res.total_receipts).toFixed(2);
          this.sub_total_payment = Number(res.total_payments).toFixed(2);
          this.grand_total_receipt = res.grand_total_receipt;
          this.grand_total_payment = res.grand_total_payment;


          // Calculate the total amount
          this.total_shares_dp = this.member_shares.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_savings_dp = this.member_savings.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_loan_repayment = this.member_loans.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);

          this.total_bank_savings_wd = this.bank_savings.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_bank_current_wd = this.bank_currents.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_cff_wd = this.cff.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_t_bill_discount = this.t_bills.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_other_receipt = this.others.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);



          // // Calculate the total amount
          this.total_shares_with = this.member_shares.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_savings_with = this.member_savings.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_loan_disbursed = this.member_loans.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);

          this.total_bank_savings_dp = this.bank_savings.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_bank_current_dp = this.bank_currents.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_cff_dp = this.cff.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_t_bill_invest = this.t_bills.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_other_payments = this.others.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);

          // Summing the cr_amount values
          // this.total_shares_dp = res.member_shares.data.reduce((sum, item) => {
          //   return sum + parseFloat(item.cr_amount); // Convert cr_amount to float and sum
          // }, 0);
          // this.incomes = res.incomes;
          // this.expenses = res.expenses;
          // this.grant_total_incomes = res.grant_total_incomes;
          // this.grant_total_expenses = res.grant_total_expenses;
          // this.profit_or_loss = this.grant_total_incomes - this.grant_total_expenses;
          // this.start_date = this.form.start_date;
          // this.end_date = this.form.end_date;
        })
        .catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        });
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    filterAction() {
      this.getBalanceSheets();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print()
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    async generateExcel() {
      const fileUrl = '/REPORT_TEMPLATE.xlsx'; // Path to the file in the public folder

      try {
        // Fetch the Excel file from the public URL
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        // Load the file into ExcelJS
        const wb = new ExcelJS.Workbook();
        await wb.xlsx.load(arrayBuffer);

        // Access the first sheet
        const ws = wb.getWorksheet(1); // Get the first worksheet

        // Define header row
        const header = [
          "Beg. of the month cash",
          this.beg_of_month_cash,
          "End. of the month cash",
          this.end_of_month_cash
        ];

        // Add header row starting from row 13
        const startingRow = 13;
        let headerRow = ws.getRow(startingRow);
        headerRow.values = header;
        headerRow.font = { bold: true };
        headerRow.commit(); // Commit the row to the worksheet

        // Add individual data rows
        let currentRow = startingRow + 1;

        const row = ws.getRow(currentRow);
        row.values = [
          "Beg. of the month cash",
          this.beg_of_month_cash,
          "End. of the month cash",
          this.end_of_month_cash
        ]
        row.commit();
        currentRow++;
        // Process each item
        // this.incomes.forEach(item => {

        // Map item values to the corresponding header columns
        // row.values = [
        //   item.customer?.first_name + ' ' + item.customer?.last_name,
        //   item.customer.external_code, // Remove extra spaces
        //   item.customer.code,
        //   item.trans_date,
        //   item.transaction_type,
        //   item.account.account_type?.name,
        //   item.trans_amount,
        //   item.code,
        //   item.affected_coa,
        // ];

        // });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link and simulate a click to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'note_to_payment_and_receipts.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      }
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
  },
};
</script>