<template>
    <div>
        <div class="row">
            <div class="col-12 col-md-6">
                <label class="akkurate-dark small d-none d-lg-block" for="search">Search</label>
                <b-form-group id="search" label-for="search" class="akkurate-dark">
                    <b-form-input id="search" class="mt-2 field-container fields" type="text" v-model="form.filter"
                        placeholder="Customer name, phone number, customer code, email">
                    </b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <label class="akkurate-dark small d-none d-lg-block" for="loan_number">Loan number</label>
                <b-form-group id="loan_number" label-for="loan_number" class="akkurate-dark">
                    <b-form-input id="loan_number" class="mt-2 field-container fields" type="text"
                        v-model="form.loan_number" placeholder="Enter Loan number">
                    </b-form-input>
                </b-form-group>
            </div>

            <div class="col mb-2 mb-md-0">
                <label class="akkurate-dark small d-none d-lg-block" for="type">Status</label>
                <multiselect class="mt-2 field-container" v-model="form.status" :options="form.status_option">
                </multiselect>
            </div>

            <div class="d-grid gap-2 col-md-2 d-flex align-content-center justify-content-center mt-auto ">
                <b-button type="submit"
                    class="akkurate-green-btn akkurate-auth-size w-100 d-flex align-items-center justify-content-center"><i
                        class="fas fa-search "></i><span class="d-none d-lg-block ms-2">Search</span></b-button>
                <b-button size="sm" @click="show_more" title="show more filters" class="ms-2">
                    <i class="fas fa-bars akkurate-small"></i>
                </b-button>
            </div>






            <!-- <div class="col-12 col-md-3">
            <label class="akkurate-dark small" for="type">Status</label>
            <multiselect class="mt-2 field-container" v-model="form.status" :options="form.status_option"></multiselect>
           </div> -->

        </div>
        <div class="row mt-2 mb-3" v-if="more_details">
            <div class="col">
                <label class="akkurate-dark small" for="loan-re-date">Start Date</label>
                <b-form-group id="loan-re-date" label-for="loan-re-date">
                    <b-form-input class="mt-2 field-container fields" type="date"
                        v-model="form.start_date"></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <label class="akkurate-dark small" for="loan-re-date">End Date</label>
                <b-form-group id="loan-re-date" label-for="loan-re-date">
                    <b-form-input class="mt-2 field-container fields" type="date"
                        v-model="form.end_date"></b-form-input>
                </b-form-group>
            </div>

            <div class="col">
                <label class="akkurate-dark small" for="branch">Branch</label>
                <multiselect class="mt-2 field-container" v-model="form.branch" :options="branch_option" track-by="name"
                    @input="onBranchChange" :multiple="true" placeholder="Select branch" selectLabel="" deselectLabel=""
                    label="name">
                </multiselect>
            </div>
            <div class="col">
                <label class="akkurate-dark small" for="scheme">Scheme </label>
                <multiselect class="mt-2 field-container" v-model="form.schemes" :options="schemes_option"
                    @input="onSchemesChange" :multiple="true" selectLabel="" deselectLabel="" track-by="name"
                    placeholder="Select scheme" label="name">
                </multiselect>
            </div>
            <div class="col">
                <label class="akkurate-dark small" for="scheme">Loan Stages </label>
                <multiselect class="mt-2 field-container" v-model="form.loan_stages" :options="loan_stages_options"
                    :multiple="true" selectLabel="" deselectLabel="" track-by="id"
                    placeholder="Select Loan Stage" label="name">
                </multiselect>
            </div>

        </div>
    </div>


</template>

<script>
import Multiselect from 'vue-multiselect'
export default {

    props: ['form', 'filterTable', 'branch_option', 'schemes_option','loan_stages_options'],
    components: {
        Multiselect
    },
    data() {
        return {
            branch_arr: [],
            schemes_arr: [],
            more_details: false,
        }

    },
    methods: {
        onBranchChange() {
            this.branch_arr = [];
            this.form.branch.map((index) => {
                this.branch_arr.push(index.id);
            });
            this.$emit('branch_arr', this.branch_arr);

        },
        onSchemesChange() {
            this.schemes_arr = [];
            this.form.schemes.map((index) => {
                this.schemes_arr.push(index.id);

            });
            this.$emit('schemes_arr', this.schemes_arr)
        },
        show_more() {
            this.more_details = !this.more_details
        }
    }
}
</script>