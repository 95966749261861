<template>
    <div>
        <b-modal id="modal-1" @ok="handleOk" @show="Shown">
            <template #modal-title>
                <h6 class="m-0 fw-bold text-green">Generate Key</h6>
            </template>
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label-for="app-id" invalid-feedback="Name is required">
                    <template #label>
                        <label>App Name <star></star></label>
                    </template>
                    <b-form-input id="app-id" class="mb-3 mt-2 field-container fields" v-model="form.app_id" required>
                    </b-form-input>

                </b-form-group>
                <p class="mb-0 key-text">{{ note }}<span class="akkurate-danger">{{ key }}</span></p>
            </form>

            <template #modal-footer="{ ok, cancel }">
                <b-button @click="ok()" v-b-modal.ok type="submit"
                   :disabled="generated_key_disbale" class="btn akkurate-green-btn btn-size text-white"><font-awesome-icon class="me-2"
                        :icon="['fas', 'key']" /> Generate</b-button>
                <b-button @click="cancel()" v-b-modal.cancel class="btn akkurate-gray-btn btn-size text-white">
                    <font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>
            </template>
        </b-modal>
    </div>

</template>
<script>
import ApiService from '@/core/services/api.service'

export default {
    data() {
        return {
            form: {
                app_id: ""
            },
            key: "",
            note: "",
            generated_key_disbale:false
        }

    },
    methods: {
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        async handleSubmit() {
            this.isSaving = true;
            this.$Progress.start();
            await ApiService.post("/api-keys/store-key", this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();

                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });

                    console.log(response.data?.data?.app_key)
                    this.form.app_id = "";
                    this.note = "Note: This key is only visible once. Please make sure to copy it: ";
                    this.key = response.data?.data?.app_key
                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;
                    this.$emit('keySaved');
                    this.generated_key_disbale = true
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();
                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                    }
                });
        },
        initialState() {
            return {
                form: {
                    app_id: "",

                },
            }

        },
        Shown() {
            this.note = "";
            this.key = "";
            this.form.app_id = "";
            this.generated_key_disbale =  false
        }
    }
}
</script>

<style scoped>
.key-text {
    word-wrap: break-word;
    white-space: normal;
    /* Allow wrapping of the text */
    max-width: 100%;
    /* Make sure the key is confined within the container */
}
</style>
