<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">
          <div class="card card-shape home-box">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">Note to Income Statement</h6>
                </div>
                <div class="col-6 text-end">
                  <b-button @click="generateExcel" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']" />
                  </b-button>
                  <report-filter :title="'INCOME STATEMENT'" :form="form" @filterAction="filterAction"
                    :filter="filter"></report-filter>
                  <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"
                    title="Print"><font-awesome-icon :icon="['fas', 'book']" /></b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"
                    title="Download"><font-awesome-icon :icon="['fas', 'download']" /></b-button>
                </div>
              </div>
            </div>
            <div class="card-body" ref="pdfContent">
              <div class="row">
                <div class="col-12 col-md-12">
                  <statement-institution :institution="institution" :current_date="false"
                    :title="'NOTE TO INCOME STATEMENT'"></statement-institution>
                </div>
                <hr />
              </div>

              <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-4 text-end">
                  <div>Terms: <span class="fw-bold">{{ form.terms }}</span></div>
                  <span class="mt-2">Reporting Date: <strong>{{
                    form.end_date }}</strong> </span>
                </div>
              </div>
              <div v-if="isBusy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
              <div v-else id="income-statement" class="row mx-1">
                <div class="col-12 ps-0 mb-2">
                  <div class="fw-bold text-start">
                    INCOME
                  </div>
                </div>
                <div class="col-12 px-0 trans_details" v-for="data in incomes" :key="data.id">
                  <b-table-simple bordered outlined>
                    <b-thead>
                      <b-tr variant="secondary">
                        <b-th width="50%" class="py-1">{{ data.name }}</b-th>
                        <b-th width="25%" class="py-1">CURRENT MONTH</b-th>
                        <b-th width="25%" class="py-1">YEAR TO DATE</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="coa in data.coas" :key="coa.id">
                        <td width="50%" class="py-0 px-2">{{ coa?.name }}</td>
                        <td width="25%" class="py-0 px-2">{{ displayNumber(Number(coa?.monthly_amount).toFixed(2)) }}
                        </td>
                        <td width="25%" class="py-0 px-2">{{ displayNumber(Number(coa?.yearly_amount).toFixed(2)) }}
                        </td>
                      </b-tr>
                    </b-tbody>
                    <b-tfoot>
                      <b-tr>
                        <b-td class="fw-bold">
                          Total
                        </b-td>
                        <b-td class="fw-bold">{{ displayNumber(Number(data.total_monthly_amount).toFixed(2)) }}</b-td>
                        <b-td class="fw-bold">{{ displayNumber(Number(data.total_yearly_amount).toFixed(2)) }}</b-td>
                      </b-tr>
                    </b-tfoot>

                  </b-table-simple>
                </div>

                <div class="col-12 ps-0 mb-2">
                  <div class="fw-bold text-start">
                    EXPENSES
                  </div>
                </div>

                <div class="col-12 px-0 trans_details" v-for="data in expenses" :key="data.id">
                  <b-table-simple bordered outlined>
                    <b-thead>
                      <b-tr variant="secondary">
                        <b-th width="50%" class="py-1">{{ data.name }}</b-th>
                        <b-th width="25%" class="py-1">CURRENT MONTH</b-th>
                        <b-th width="25%" class="py-1">YEAR TO DATE</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="coa in data.coas" :key="coa.id">
                        <td width="50%" class="py-0 px-2">{{ coa?.name }}</td>
                        <td width="25%" class="py-0 px-2">{{ displayNumber(Number(coa?.monthly_amount).toFixed(2)) }}
                        </td>
                        <td width="25%" class="py-0 px-2">{{ displayNumber(Number(coa?.yearly_amount).toFixed(2)) }}
                        </td>
                      </b-tr>
                    </b-tbody>
                    <b-tfoot>
                      <b-tr>
                        <b-td class="fw-bold">
                          Total
                        </b-td>
                        <b-td class="fw-bold">{{ displayNumber(Number(data.total_monthly_amount).toFixed(2)) }}</b-td>
                        <b-td class="fw-bold">{{ displayNumber(Number(data.total_yearly_amount).toFixed(2)) }}</b-td>
                      </b-tr>
                    </b-tfoot>

                  </b-table-simple>
                </div>
              </div>
              <div class="row mx-1">
                <div class="col-12 col-md-12 p-0 trans_details">
                  <b-table-simple outlined bordered>
                    <b-tbody>
                      <b-tr>
                        <b-th width="50%" class="py-0 px-2">Total Incomes (GHS)</b-th>
                        <b-th width="25%" class="py-0 px-2">{{ Number(grant_monthly_total_incomes ?
                          grant_monthly_total_incomes.toFixed(2) : 0).toLocaleString()
                          }}</b-th>
                        <b-th width="25%" class="py-0 px-2">{{ Number(grant_yearly_total_incomes ?
                          grant_yearly_total_incomes.toFixed(2) : 0).toLocaleString()
                          }}</b-th>
                      </b-tr>
                      <b-tr>
                        <b-th width="50%" class="py-0 px-2">Total Expenses (GHS)</b-th>
                        <b-th width="25%" class="py-0 px-2">{{ Number(grant_monthly_total_expenses ?
                          grant_monthly_total_expenses.toFixed(2) : 0).toLocaleString()
                          }}</b-th>
                        <b-th width="25%" class="py-0 px-2">{{ Number(grant_yearly_total_expenses ?
                          grant_yearly_total_expenses.toFixed(2) : 0).toLocaleString()
                          }}</b-th>
                      </b-tr>

                      <b-tr>
                        <b-th width="50%" class="py-0 px-2" v-if="profit_or_loss_monthly < 0">Monthly Loss (GHS)</b-th>
                        <b-th width="50%" class="py-0 px-2" v-else>Monthly Profit (GHS)</b-th>
                        <b-th colspan="2" width="25%" class="py-0 px-2">{{ Number(profit_or_loss_monthly ?
                          Math.abs(profit_or_loss_monthly).toFixed(2) : 0).toLocaleString()
                        }}</b-th>

                      </b-tr>
                      <b-tr>
                        <b-th width="50%" class="py-0 px-2" v-if="profit_or_loss_yearly < 0">Yearly Loss (GHS)</b-th>
                        <b-th width="50%" class="py-0 px-2" v-else>Yearly Profit (GHS)</b-th>
                        <b-th colspan="2" width="25%" class="py-0 px-2">{{ Number(profit_or_loss_yearly ?
                          Math.abs(profit_or_loss_yearly).toFixed(2) : 0).toLocaleString()
                        }}</b-th>
                      </b-tr>
                    </b-tbody>

                    <!-- <b-tfoot>
                      <b-tr>
                        <b-th class="py-0 px-2" v-if="profit_or_loss < 0">Loss (GHS)</b-th>
                        <b-th class="py-0 px-2" v-else>Profit (GHS)</b-th>
                        <b-th class="py-0 px-2">{{ Number(profit_or_loss ? Math.abs(profit_or_loss).toFixed(2) : 0).toLocaleString()
                          }}</b-th>
                      </b-tr>
                    </b-tfoot> -->
                  </b-table-simple>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import html2pdf from "html2pdf.js";
import ReportFilter from "../form/ReportFilter.vue";
import ExcelJS from 'exceljs';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter
  },
  data() {
    return {
      form: {
        // start_date: this.getBeginningOfYear(),
        end_date: new Date().toISOString().split("T")[0],
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "Periodic",
        terms_options: ["YTD", "Periodic"],
        categories: [],
      },
      filter: {
        branches: false,
        products: false,
        users: false,
        status: false,
        condition: false,
        terms: true,
        coa: false,
        voucher_status: false,
        start_date: false,
        end_date: true,
      },
      incomes: [],
      expenses: [],
      profit_or_loss_monthly: 0,
      profit_or_loss_yearly: 0,
      search: "Search",
      grant_monthly_total_incomes: 0,
      grant_yearly_total_incomes: 0,
      grant_monthly_total_expenses: 0,
      grant_yearly_total_expenses: 0,
      isBusy: false,
      isSearching: false,
      selected_num: "10",
      numbers_options: [10, 50, 100, 500],
      isLoadingInstitution: false,
      institution: "",
      // start_date: "",
      end_date: "",
      fields: [
        {
          key: "account_number",
          label: "Account Number",
        },
        {
          key: "customer_name",
          label: "Customer Name",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "min_balance",
          label: "Minimum Balance",
        },
        {
          key: "total",
          label: "Total",
        },

      ],
      perPage: 20,
      currentPage: 1,
      items: [],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    sumLoanBalance() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = this.items[i].loan_balance;
      }
      return total;
    },
    sumSavingBalance() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = this.items[i].saving_balance;
      }

      return total;
    },
    //assets total
    //   sumAssetsTotal(){
    //     let total = 0;
    //     for(let i = 0; i < this.incomes.length; i++){
    //       total = this.incomes[i].amount;
    //     }
    //     return total;
    //   },
  },

  created() {
    this.getInstitution();
    this.getBalanceSheets();
  },
  methods: {
    async getBalanceSheets() {
      this.items = [];
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.query("/reports/financials/income-statement", {
        params: {
          // startDate: this.form.start_date,
          endDate: this.form.end_date,
          terms: this.form.terms
        },
      })
        .then((response) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          // var res = response.data?.data;
          var res = response.data;
          console.log(res);
          this.incomes = res.incomes;
          this.expenses = res.expenses;
          this.grant_monthly_total_incomes = res.grant_monthly_total_incomes;
          this.grant_yearly_total_incomes = res.grant_yearly_total_incomes;
          this.grant_monthly_total_expenses = res.grant_monthly_total_expenses;
          this.grant_yearly_total_expenses = res.grant_yearly_total_expenses;
          this.grant_total_expenses = res.grant_total_expenses;
          this.profit_or_loss_monthly = this.grant_monthly_total_incomes - this.grant_monthly_total_expenses;
          this.profit_or_loss_yearly = this.grant_yearly_total_incomes - this.grant_yearly_total_expenses;
          this.start_date = this.form.start_date;
          this.end_date = this.form.end_date;
        })
        .catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        });
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    filterAction() {
      this.getBalanceSheets();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    async generateExcel() {
      const fileUrl = '/REPORT_TEMPLATE_NEW.xlsx'; // Path to the file in the public folder
      const imageUrl = '/default_logo.png';  // Path to your image file


      // Institution details
      const institutionName = this.institution.name;
      const institutionMotto = this.institution.motto;
      const institutionAddress = this.institution.address;
      const institutionPhone = this.institution.telephone;
      const institutionEmail = this.institution.email;
      const date = 'FROM: ' + this.form.start_date + ' TO: ' + this.form.end_date
      const terms = 'TERMS:  ' + this.form.terms

      try {
        // Fetch the Excel file from the public URL
        // Fetch the Excel file from the public URL
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        // Load the file into ExcelJS
        const wb = new ExcelJS.Workbook();
        await wb.xlsx.load(arrayBuffer);

        // Access the first sheet
        const ws = wb.getWorksheet(1); // Get the first worksheet

        // Add Image to the top of the sheet
        const imageResponse = await fetch(imageUrl);
        const imageBuffer = await imageResponse.arrayBuffer();
        const imageId = wb.addImage({
          buffer: imageBuffer,
          extension: 'png',  // Adjust this if you're using a different image type (e.g., jpg)
        });

        // Insert the image at the top-left corner (row 1, column 1)
        ws.addImage(imageId, {
          tl: { col: 0, row: 0 },  // Top-left corner (adjust this as needed)
          ext: { width: 300, height: 200 },  // Set the image size (adjust width/height as needed)
        });

        // Insert institution details next to the image (starting at row 1, col 5)
        const institutionDetailsStartCol = 5; // Column where text starts after image
        const institutionDetailsRow = 1;  // Row for institution details

        // Add the institution name, address, and phone number
        ws.getCell(`L${institutionDetailsRow}`).value = institutionName;
        ws.getCell(`L${institutionDetailsRow}`).font = { bold: true, size: 14 }; // Bold and bigger font for name

        ws.getCell(`L${institutionDetailsRow + 1}`).value = institutionAddress;
        ws.getCell(`L${institutionDetailsRow + 1}`).font = { size: 12 };  // Regular font size for address

        ws.getCell(`L${institutionDetailsRow + 2}`).value = institutionMotto;
        ws.getCell(`L${institutionDetailsRow + 2}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 3}`).value = institutionPhone;
        ws.getCell(`L${institutionDetailsRow + 3}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 4}`).value = institutionEmail;
        ws.getCell(`L${institutionDetailsRow + 4}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 6}`).value = date;
        ws.getCell(`L${institutionDetailsRow + 6}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 7}`).value = terms;
        ws.getCell(`L${institutionDetailsRow + 7}`).font = { size: 12 };  // Regular font size for phone

        // Optionally adjust the column width if the text is long
        ws.getColumn('K').width = 50;  // Adjust the width of column D (if necessary)

        // Define header row
        const header = ['Income/Expense', 'Current Month', 'Year to Date'];

        // Add header row starting from row 13
        const startingRow = 13;
        let headerRow = ws.getRow(startingRow);
        headerRow.values = header;
        headerRow.font = { bold: true };
        headerRow.commit(); // Commit the row to the worksheet

        // Add income data rows (adjusted for your structure)
        let currentRow = startingRow + 1;
        this.incomes.forEach(item => {
          const row = ws.getRow(currentRow);
          row.values = [
            item.name, // Assuming each income item has a `name`
            item.total_monthly_amount, // Assuming `total_monthly_amount` exists
            item.total_yearly_amount, // Assuming `total_yearly_amount` exists
          ];
          row.commit();
          currentRow++;

          // Add the COA (Chart of Accounts) data for each income
          item.coas.forEach(coa => {
            const coaRow = ws.getRow(currentRow);
            coaRow.values = [
              coa.name, // COA Name
              coa.monthly_amount, // COA Monthly Amount
              coa.yearly_amount, // COA Yearly Amount
            ];
            coaRow.commit();
            currentRow++;
          });
        });

        // Add expense data rows (adjusted for your structure)
        this.expenses.forEach(item => {
          const row = ws.getRow(currentRow);
          row.values = [
            item.name, // Expense Name
            item.total_monthly_amount, // Expense Monthly Amount
            item.total_yearly_amount, // Expense Yearly Amount
          ];
          row.commit();
          currentRow++;

          // Add the COA (Chart of Accounts) data for each expense
          item.coas.forEach(coa => {
            const coaRow = ws.getRow(currentRow);
            coaRow.values = [
              coa.name, // COA Name
              coa.monthly_amount, // COA Monthly Amount
              coa.yearly_amount, // COA Yearly Amount
            ];
            coaRow.commit();
            currentRow++;
          });
        });

        // Write the file buffer and create a Blob for download
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link and simulate a click to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'note_to_income_statement.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      }
    },

    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
  },
};
</script>