<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">
          <div class="card card-shape home-box">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">RECEIPTS AND PAYMENTS SUMMARY</h6>
                </div>
                <div class="col-6 text-end">
                  <b-button @click="generateExcel" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']" />
                  </b-button>
                  <report-filter :form="form" @filterAction="getBalanceSheets" :filter="filter"
                    :current_financial_year="financial_year">
                  </report-filter>
                  <b-button @click="printPDF" class="btn-sm me-2 akkurate-green-btn akkurate-small" title="Print">
                    <font-awesome-icon :icon="['fas', 'book']" />
                  </b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small" title="Download">
                    <font-awesome-icon :icon="['fas', 'download']" />
                  </b-button>
                </div>
                <!-- <div class="col-2" style="display: flex; justify-content: flex-end">
                      <report-filter
                      :form="form"
                      @filterAction="filterAction"
                      :filter="filter"
                      :current_financial_year="current_financial_year"
                      ></report-filter>
                      </div> -->
              </div>

            </div>
            <div class="card-body">
              <div class="row" ref="pdfContent">
                <div class="col-12 col-md-12">
                  <statement-institution :institution="institution" :current_date="false"
                    :title="'RECEIPTS AND PAYMENTS SUMMARY'"></statement-institution>
                </div>
                <hr />
                <div class="row mb-2">
                  <div class="col-12 small">
                    Branch(es) : {{ branch_list }}
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-4 text-end">
                  <div>Terms: <span class="fw-bold">{{ form.terms }}</span></div>
                  <span style="font-weight: bold" v-if="form.start_date && form.end_date">From: {{ form.start_date }} to
                    {{ form.end_date }}</span>
                </div>
              </div>
              <!-- <div class="" style="text-align: center;">
                  <div class="fw-bold akkurate-green">NOTE TO THE RECEIPTS AND PAYMENTS</div>
                </div> -->
              <div v-if="isBusy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
              <div v-else id="receipt-payment-summary" class="row mx-1">
                <div class="col-12 p-0 fw-bold my-2">
                  RECEIPTS AND PAYMENTS
                </div>
                <div class="col-6 col-md-6 trans_details px-0">
                  <b-table-simple bordered responsive outlined>
                    <b-thead>
                      <b-tr variant="secondary">
                        <b-th class="text-center fs-6 py-1" colspan="2"><span>Receipts</span></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <td class="py-0 px-2">Beg. of the month cash</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(beg_of_month_cash).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2">Members Shares Dep.</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(total_shares_dp).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2">Members Savings Dep.</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(total_savings_dp).toFixed(2)) }}</td>
                      </b-tr>

                      <b-tr>
                        <td class="py-0 px-2">Members Loan Repay.</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(total_loan_repayment).toFixed(2)) }}</td>
                      </b-tr>

                      <b-tr>
                        <td class="py-0 px-2">Bank Savings W/D</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(total_bank_savings_wd).toFixed(2)) }}</td>
                      </b-tr>

                      <b-tr>
                        <td class="py-0 px-2">Bank Current W/D</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(total_bank_current_wd).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2">CUA/CFF/W/D</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(total_cff_wd).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2">T-Bills Discounted</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(total_t_bill_discount).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2">Other Receipts (total)</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(total_other_receipt).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2 fw-bold">Sub- Total for the Month</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(sub_total_receipt).toFixed(2)) }}</td>
                      </b-tr>
                      <!-- <b-tr>
                        <td class="py-0 px-2">Amount Deposit by Mobile Banking Client for the Month (if
                          available):
                        </td>
                        <td class="py-0 px-2">{{ mobile_deposit }}</td>
                      </b-tr> -->
                      <b-tr>
                        <td class="py-0 px-2">Income for the month</td>
                        <td class="py-0 px-2">GHS {{ displayNumber(Number(monthly_income).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2 col-span-2">(see 3. income and expenses below) </td>
                        <td></td>
                      </b-tr>
                    </b-tbody>
                    <b-tfoot>
                      <b-tr>
                        <b-th>Total Receipts</b-th>
                        <b-th>GHS {{ displayNumber(Number(grand_total_receipt))
                          }}</b-th>
                      </b-tr>
                    </b-tfoot>
                  </b-table-simple>
                </div>
                <div class="col-6 col-md-6 trans_details px-0">
                  <b-table-simple bordered responsive outlined>
                    <b-thead>
                      <b-tr variant="secondary">
                        <b-th class="text-center fs-6 py-1" colspan="2"><span>Payments</span></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <td class="py-0 px-2">End. of the month cash bal</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(end_of_month_cash).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2">Members Shares With.</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(total_savings_with).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2">Members Savings With.</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(total_bank_savings_wd).toFixed(2)) }}</td>
                      </b-tr>

                      <b-tr>
                        <td class="py-0 px-2">Loans to Members</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(total_loan_disbursed).toFixed(2)) }}</td>
                      </b-tr>

                      <b-tr>
                        <td class="py-0 px-2">Bank Savings Dep.</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(total_bank_savings_dp).toFixed(2)) }}</td>
                      </b-tr>

                      <b-tr>
                        <td class="py-0 px-2">Bank Current Dep.</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(total_bank_current_dp).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2">CUA/CFF Dep.</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(total_cff_dp).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2">T-Bills Invested</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(total_t_bill_invest).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2">Other Payments (total)</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(total_other_payments).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2 fw-bold">Sub- Total for the Month</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(sub_total_payment).toFixed(2)) }}</td>
                      </b-tr>
                      <!-- <b-tr>
                        <td class="py-0 px-2">Amount Withdrawn by Mobile Banking Client for the Month (if
                          available):
                        </td>
                        <td class="py-0 px-2">{{ mobile_withdraw }}</td>
                      </b-tr> -->
                      <b-tr>
                        <td class="py-0 px-2">Expenses for the month</td>
                        <td class="py-0 px-2">{{ displayNumber(Number(monthly_expenses).toFixed(2)) }}</td>
                      </b-tr>
                      <b-tr>
                        <td class="py-0 px-2 col-span-2">(see 3. income and expenses below)</td>
                        <td></td>
                      </b-tr>
                    </b-tbody>
                    <b-tfoot>
                      <b-tr>
                        <b-th>Total Payments</b-th>
                        <b-th>GHS  {{ displayNumber(Number(grand_total_payment))
                          }}</b-th>
                      </b-tr>
                    </b-tfoot>
                  </b-table-simple>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import html2pdf from "html2pdf.js";
import ReportFilter from "@/views/main/pages/reports/form/ReportFilter.vue";
import ExcelJS from 'exceljs';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter
  },
  data() {
    return {
      // filter:{
      //   branch:"",
      //   start_date: "",
      //   end_date: "",
      //   year:"",
      // },
      financial_year: null,
      filter: {
        branches: true,
        products: false,
        users: false,
        status: false,
        condition: false,
        coa: false,
        terms: true,
        start_date: true,
        end_date: true
      },
      current_financial_year: {},
      form: {
        start_date: this.getOneMonthEailer(),
        end_date: new Date().toISOString().substr(0, 10),
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "Periodic",
        terms_options: ["YTD", "Periodic"],
        categories: [],
      },
      receipts: [],
      payments: [],
      search: "Search",
      isBusy: false,
      isSearching: false,
      selected_num: "10",
      numbers_options: [10, 50, 100, 500],
      isLoadingInstitution: false,
      institution: "",
      start_date: "",
      end_date: "",
      perPage: 20,
      currentPage: 1,
      items: [],
      beg_of_month_cash: 0,
      end_of_month_cash: 0,
      total_receipts: 0,
      total_payments: 0,
      total_shares_dp: 0,
      total_savings_dp: 0,
      total_loan_repayment: 0,
      total_shares_with: 0,
      total_savings_with: 0,
      total_loan_disbursed: 0,
      total_bank_savings_wd: 0,
      total_bank_current_wd: 0,
      total_bank_savings_dp: 0,
      total_bank_current_dp: 0,
      total_cff_wd: 0,
      total_t_bill_discount: 0,
      total_other_receipt: 0,
      total_cff_dp: 0,
      total_t_bill_invest: 0,
      total_other_payments: 0,
      branch_list: [],
      grand_total_receipt: 0,
      grand_total_payment: 0,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    sumLoanBalance() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = this.items[i].loan_balance;
      }
      return total;
    },
    sumSavingBalance() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = this.items[i].saving_balance;
      }

      return total;
    },
    //assets total
    sumAssetsTotal() {
      let total = 0;
      for (let i = 0; i < this.assets_items.length; i++) {
        total = this.assets_items[i].amount;
      }
      return total;
    },
  },

  created() {
    this.getInstitution();
    this.getBalanceSheets();
    this.getProductDropdown();
  },
  methods: {

    filterAction() {
      // this.changePerPageNumber(this.perPage);
      // this.form.start_date = 
      this.getBalanceSheets();
    },
    async getBalanceSheets() {
      this.items = [];
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.post("/reports/financials/receipts-payments/detailed", {
        startDate: this.form.start_date,
        endDate: this.form.end_date,
        terms: this.form.terms,
        branches: this.branches
      })
        .then((response) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          // var res = response.data?.data;
          var res = response.data;
          this.beg_of_month_cash = res.beg_of_month_cash;
          this.end_of_month_cash = res.end_of_month_cash;

          this.monthly_income = res.monthly_income;
          this.monthly_expenses = res.monthly_expenses;

          this.sub_total_receipt = Number(res.total_receipts).toFixed(2);
          this.sub_total_payment = Number(res.total_payments).toFixed(2);

          this.grand_total_receipt = res.grand_total_receipt;
          this.grand_total_payment = res.grand_total_payment;


          // console.log(res.member_shares);



          // Calculate the total amount
          this.total_shares_dp = res.member_shares.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_savings_dp = res.member_savings.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_loan_repayment = res.member_loans.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);

          this.total_bank_savings_wd = res.bank_savings.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_bank_current_wd = res.bank_currents.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_cff_wd = res.cff.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_t_bill_discount = res.t_bills.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_other_receipt = res.others.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);



          // // Calculate the total amount
          this.total_shares_with = res.member_shares.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_savings_with = res.member_savings.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_loan_disbursed = res.member_loans.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);

          this.total_bank_savings_dp = res.bank_savings.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_bank_current_dp = res.bank_currents.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_cff_dp = res.cff.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_t_bill_invest = res.t_bills.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_other_payments = res.others.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);

          this.branch_list = this.form.branches.map(branch => branch.name).join(", ");



        }).catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        });
    },

    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    async getProductDropdown() {
      this.isBusy = true;
      this.search = "Processing...";
      await ApiService.query("/reports/controls/products/dropdown")
        .then((response) => {
          // console.log(response.data);
          this.isBusy = false;
          this.search = "Search";
          this.form.branch_options = response.data.branches;
          this.form.product_options = response.data.products;
          this.form.coa_options = response.data.coa_subheads;
        })
        .catch((error) => {
          // console.log(error);
          this.isBusy = false;
          this.search = "Search";
        });
    },
    async generateExcel() {
      const fileUrl = '/REPORT_TEMPLATE_NEW.xlsx'; // Path to the file in the public folder
      const imageUrl = '/default_logo.png';  // Path to your image file

      // Institution details
      const institutionName = this.institution.name;
      const institutionMotto = this.institution.motto;
      const institutionAddress = this.institution.address;
      const institutionPhone = this.institution.telephone;
      const institutionEmail = this.institution.email;
      const date = 'FROM: ' + this.form.start_date + ' TO: ' + this.form.end_date
      const terms = 'TERMS:  ' + this.form.terms

      try {
        // Fetch the Excel file from the public URL
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        // Load the file into ExcelJS
        const wb = new ExcelJS.Workbook();
        await wb.xlsx.load(arrayBuffer);

        // Access the first sheet
        const ws = wb.getWorksheet(1); // Get the first worksheet

        // Add Image to the top of the sheet
        const imageResponse = await fetch(imageUrl);
        const imageBuffer = await imageResponse.arrayBuffer();
        const imageId = wb.addImage({
          buffer: imageBuffer,
          extension: 'png',  // Adjust this if you're using a different image type (e.g., jpg)
        });

        // Insert the image at the top-left corner (row 1, column 1)
        ws.addImage(imageId, {
          tl: { col: 0, row: 0 },  // Top-left corner (adjust this as needed)
          ext: { width: 300, height: 200 },  // Set the image size (adjust width/height as needed)
        });

        // Insert institution details next to the image (starting at row 1, col 5)
        const institutionDetailsStartCol = 5; // Column where text starts after image
        const institutionDetailsRow = 1;  // Row for institution details

        // Add the institution name, address, and phone number
        ws.getCell(`L${institutionDetailsRow}`).value = institutionName;
        ws.getCell(`L${institutionDetailsRow}`).font = { bold: true, size: 14 }; // Bold and bigger font for name

        ws.getCell(`L${institutionDetailsRow + 1}`).value = institutionAddress;
        ws.getCell(`L${institutionDetailsRow + 1}`).font = { size: 12 };  // Regular font size for address

        ws.getCell(`L${institutionDetailsRow + 2}`).value = institutionMotto;
        ws.getCell(`L${institutionDetailsRow + 2}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 3}`).value = institutionPhone;
        ws.getCell(`L${institutionDetailsRow + 3}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 4}`).value = institutionEmail;
        ws.getCell(`L${institutionDetailsRow + 4}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 6}`).value = date;
        ws.getCell(`L${institutionDetailsRow + 6}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 7}`).value = terms;
        ws.getCell(`L${institutionDetailsRow + 7}`).font = { size: 12 };  // Regular font size for phone

        // Optionally adjust the column width if the text is long
        ws.getColumn('K').width = 50;  // Adjust the width of column D (if necessary)

        // Define header row
        // const header = [
        //   "Receipt Code",
        //   "Receipt Name",
        //   "Receipt NARRATION",
        //   "Receipt DR (GHS)",
        //   "Receipt CR (GHS)",
        //   "Receipt DATE",
        // ];

        // Add header row starting from row 13
        const startingRow = 13;
        let headerRow = ws.getRow(startingRow);
        // headerRow.values = header;
        headerRow.font = { bold: true };
        headerRow.commit(); // Commit the row to the worksheet

        // Add individual data rows
        let currentRow = startingRow + 1;

        const row_1 = ws.getRow(currentRow);
        // Process each item
        row_1.values = [
          "Beg. of the month cash",
          this.beg_of_month_cash,
          "End. of the month cash bal", // Remove extra spaces
          this.end_of_month_cash,
        ];
        row_1.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        const row_2 = ws.getRow(currentRow);
        row_2.values = [
          "Members Shares Dep.",
          this.total_shares_dp,
          "Members Shares W/D.", // Remove extra spaces
          this.total_shares_with,
        ];
        row_2.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        const row_3 = ws.getRow(currentRow);
        row_3.values = [
          "Members Savings Dep",
          this.total_savings_dp,
          "Members Savings W/D", // Remove extra spaces
          this.total_savings_with,
        ];
        row_3.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        const row_4 = ws.getRow(currentRow);
        row_4.values = [
          "Members Loan Repay",
          this.total_loan_repayment,
          "Loan to Members", // Remove extra spaces
          this.total_loan_disbursed,
        ];
        row_4.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt


        const row_5 = ws.getRow(currentRow);
        row_5.values = [
          "Bank Savings W/D",
          this.total_bank_savings_wd,
          "Bank Savings Dep", // Remove extra spaces
          this.total_bank_savings_dp,
        ];
        row_5.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        const row_6 = ws.getRow(currentRow);
        row_6.values = [
          "Bank Current W/D",
          this.total_bank_current_wd,
          "Bank Current Dep", // Remove extra spaces
          this.total_bank_current_dp,
        ];
        row_6.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        const row_7 = ws.getRow(currentRow);
        row_7.values = [
          "CUA/CFF/W/D",
          this.total_cff_wd,
          "CUA/CFF Dep", // Remove extra spaces
          this.total_cff_dp,
        ];
        row_7.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        const row_8 = ws.getRow(currentRow);
        row_8.values = [
          "T-Bills Discounted",
          this.total_t_bill_discount,
          "T-Bills Invested", // Remove extra spaces
          this.total_t_bill_invest,
        ];
        row_8.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        const row_9 = ws.getRow(currentRow);
        row_9.values = [
          "Other Receipts",
          this.total_other_receipt,
          "Other Payments", // Remove extra spaces
          this.total_other_payments,
        ];
        row_9.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        const row_10 = ws.getRow(currentRow);
        row_10.values = [
          "Sub- Total for the Month",
          this.sub_total_receipt,
          "Sub- Total for the Month", // Remove extra spaces
          this.sub_total_payment,
        ];
        row_10.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        const row_11 = ws.getRow(currentRow);
        row_11.values = [
          "Income for the month",
          this.monthly_income,
          "Expenses for the month", // Remove extra spaces
          this.monthly_expenses,
        ];
        row_11.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        const row_12 = ws.getRow(currentRow);
        row_12.values = [
          "Total Receipts",
          Number(this.monthly_income) + Number(this.sub_total_receipt),
          "Total Payments", // Remove extra spaces
          Number(this.monthly_expenses) + Number(this.sub_total_payment),
        ];
        row_12.commit();
        // After processing the receipts and before adding the payment headers
        currentRow++; // Increment currentRow after the last receipt

        // Define payment header
        // const paymentHeader = [
        //   "Payment Code",
        //   "Payment Name",
        //   "Payment NARRATION",
        //   "Payment DR (GHS)",
        //   "Payment CR (GHS)",
        //   "Payment DATE",
        // ];

        // Add header row for payments
        // let paymentHeaderRow = ws.getRow(currentRow); // Use currentRow here
        // paymentHeaderRow.values = paymentHeader;
        // paymentHeaderRow.font = { bold: true };
        // paymentHeaderRow.commit(); // Commit the row to the worksheet

        // currentRow++; // Now increment for the first payment entry

        // // Process each payment item
        // this.payments.forEach(item => {
        //   const row = ws.getRow(currentRow);
        //   // Map item values to the corresponding header columns
        //   row.values = [
        //     item.coasubhead?.code,
        //     item.coasubhead?.name,
        //     item.transaction_description, // Remove extra spaces
        //     item.dr_amount.toFixed(2),
        //     item.cr_amount,
        //     item.transaction_date,
        //   ];
        //   row.commit();
        //   currentRow++;
        // });

        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link and simulate a click to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'receipt_and_payment_summary.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      }
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    filterAction() {
      this.changePerPageNumber(this.perPage);
    },
    changePerPageNumber(data) {
      if (data == "All") {
        this.perPage = this.items.length;
        this.getCustomerProduct();
      } else {
        this.perPage = data;
        this.getCustomerProduct();
      }
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
  },
};
</script>