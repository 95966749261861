<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">Account Statistics Reports</h6>
                </div>
                <div class="col-6 text-end">
                  <report-filter :form="form" @filterAction="filterAction" :filter="filter"></report-filter>
                  <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon
                      :icon="['fas', 'book']" /></b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon
                      :icon="['fas', 'download']" /></b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="container-fluid" ref="pdfContent">
                <div class="row small">
                  <div class="col-12 col-md-12">
                    <statement-institution :institution="institution"
                      :title="'ACCOUNT STATISTICS REPORT'"></statement-institution>
                  </div>
                  <div class="col-12 text-end text-uppercase fw-bold">
                    Reporting Month : {{ new Intl.DateTimeFormat('en-GB', {
                      day: 'numeric', month: 'long', year: 'numeric'
                    }).format(new Date(form.end_date)) }}
                  </div>
                  <hr />
                  <div v-if="isBusy">
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>

                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col-6 p-0">
                        Number of branches
                      </div>
                      <div class="col-6 p-0">
                        {{ no_of_branches }}
                      </div>
                      <div class="col-6 p-0">
                        Number of Branches Consolidated in This Report
                      </div>
                      <div class="col-6 p-0">
                        {{ form.branches.length == 0 ? 1 : form.branches.length }}
                      </div>
                      <div class="col-6 p-0 mb-4">
                        Number of Branches Not Consolidated in This Report
                      </div>
                      <div class="col-6 p-0 mb-4">
                        {{ no_of_branches - Number(form.branches.length == 0 ? 1 : form.branches.length) }}
                      </div>
                      <div class="col-12 p-0 fw-bold mb-2">
                        MEMBERSHIP INFORMATION
                      </div>
                      <div class="col-12 px-0 trans_details">
                        <b-table-simple bordered outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th class="py-1" width="50%">MEMBERS</b-th>
                              <b-th class="py-1">Females</b-th>
                              <b-th class="py-1">Males</b-th>
                              <b-th class="py-1">Groups</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <td class="py-0 px-2">Active Members</td>
                              <td class="py-0 px-2">{{ active_female }}</td>
                              <td class="py-0 px-2">{{ active_male }}</td>
                              <td class="py-0 px-2">{{ active_group }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Inactive Members</td>
                              <td class="py-0 px-2">{{ inactive_female }}</td>
                              <td class="py-0 px-2">{{ inactive_male }}</td>
                              <td class="py-0 px-2">{{ inactive_group }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Dormant Members</td>
                              <td class="py-0 px-2">{{ dormant_female }}</td>
                              <td class="py-0 px-2">{{ dormant_male }}</td>
                              <td class="py-0 px-2">{{ dormant_group }}</td>
                            </b-tr>
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <b-td class="fw-bold py-0 px-2">
                                Total Membership
                              </b-td>
                              <b-td class="fw-bold py-0 px-2">{{ total_female }}</b-td>
                              <b-td class="fw-bold py-0 px-2">{{ total_male }}</b-td>
                              <b-td class="fw-bold py-0 px-2">{{ total_group }}</b-td>
                            </b-tr>
                            <!-- <b-tr>
                              <b-td class="fw-bold py-0 px-2">
                                Number of person in group Cell G-17 (If Avaliable)
                              </b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                            </b-tr> -->
                            <!-- <b-tr>
                              <b-td class="fw-bold py-0 px-2">
                                Number of Youth Savers (if applicable)
                              </b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                            </b-tr> -->
                            <!-- <b-tr>
                              <b-td class="fw-bold py-0 px-2">
                                Number of Mobile Banking Client (if avalibale)
                              </b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                            </b-tr> -->
                          </b-tfoot>

                        </b-table-simple>
                      </div>
                      <div class="col-12 p-0 fw-bold mb-2">
                        MEMBERS DEPOSIT AND LOANS
                      </div>

                      <div class="col-12 px-0 trans_details">
                        <b-table-simple bordered responsive outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th width="50%" class="py-1">MEMBERS</b-th>
                              <b-th class="py-1">Females</b-th>
                              <b-th class="py-1">Males</b-th>
                              <b-th class="py-1">Groups</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Active Shares (GH¢)</td>
                              <td class="py-0 px-2">{{ active_female_shares }}</td>
                              <td class="py-0 px-2">{{ active_male_shares }}</td>
                              <td class="py-0 px-2">{{ active_group_shares }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Inactive Shares (GH¢)</td>
                              <td class="py-0 px-2">{{ inactive_female_shares }}</td>
                              <td class="py-0 px-2">{{ inactive_male_shares }}</td>
                              <td class="py-0 px-2">{{ inactive_group_shares }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Dormant Shares (GH¢)</td>
                              <td class="py-0 px-2">{{ dormant_female_shares }}</td>
                              <td class="py-0 px-2">{{ dormant_male_shares }}</td>
                              <td class="py-0 px-2">{{ dormant_group_shares }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Active Savings (GH¢)</td>
                              <td class="py-0 px-2">{{ active_female_savings }}</td>
                              <td class="py-0 px-2">{{ active_male_savings }}</td>
                              <td class="py-0 px-2">{{ active_group_savings }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Inactive Savings (GH¢)</td>
                              <td class="py-0 px-2">{{ inactive_female_savings }}</td>
                              <td class="py-0 px-2">{{ inactive_male_savings }}</td>
                              <td class="py-0 px-2">{{ inactive_group_savings }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Dormant Savings (GH¢)</td>
                              <td class="py-0 px-2">{{ dormant_female_savings }}</td>
                              <td class="py-0 px-2">{{ dormant_male_savings }}</td>
                              <td class="py-0 px-2">{{ dormant_group_savings }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Youth Savings (GH¢)</td>
                              <td class="py-0 px-2">0</td>
                              <td class="py-0 px-2">0</td>
                              <td class="py-0 px-2">0</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Number of loans disbursed during the period</td>
                              <td class="py-0 px-2">{{ disbursement_female_count }}</td>
                              <td class="py-0 px-2">{{ disbursement_male_count }}</td>
                              <td class="py-0 px-2">{{ disbursement_group_count }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of loans disbursed during the period (GH¢)</td>
                              <td class="py-0 px-2">{{ disbursement_female_sum }}</td>
                              <td class="py-0 px-2">{{ disbursement_male_sum }}</td>
                              <td class="py-0 px-2">{{ disbursement_group_sum }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Number of Loans Outstanding</td>
                              <td class="py-0 px-2">{{ oustanding_female_loan_count }}</td>
                              <td class="py-0 px-2">{{ oustanding_male_loan_count }}</td>
                              <td class="py-0 px-2">{{ oustanding_group_loan_count }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Loans Outstanding (GH¢)</td>
                              <td class="py-0 px-2">{{ oustanding_female_loan_sum }}</td>
                              <td class="py-0 px-2">{{ oustanding_male_loan_sum }}</td>
                              <td class="py-0 px-2">{{ oustanding_group_loan_sum }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">No. of Delinquent Borrowers (arrears of 30 days and more):</td>
                              <td class="py-0 px-2">{{ delinquent_female_count }}</td>
                              <td class="py-0 px-2">{{ delinquent_male_count }}</td>
                              <td class="py-0 px-2">{{ delinquent_group_count }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Delinquent Loans (GH¢)</td>
                              <td class="py-0 px-2">{{ delinquent_female_sum }}</td>
                              <td class="py-0 px-2">{{ delinquent_male_sum }}</td>
                              <td class="py-0 px-2">{{ delinquent_group_sum }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount Mobile Banking Client (if available):</td>
                              <td class="py-0 px-2">{{ transaction_female }}</td>
                              <td class="py-0 px-2">{{ transaction_male }}</td>
                              <td class="py-0 px-2">{{ transaction_group }}</td>
                            </b-tr>
                          </b-tbody>
                          <!-- <b-tfoot>
                          <b-tr>
                            <b-td class="fw-bold">
                              Total Membership
                            </b-td>
                            <b-td class="fw-bold">{{ total_female }}</b-td>
                            <b-td class="fw-bold">{{ total_male }}</b-td>
                            <b-td class="fw-bold">{{ total_group }}</b-td>
                          </b-tr>
                        </b-tfoot> -->

                        </b-table-simple>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8 ps-0">
                        <div class="fw-bold my-3">
                          RECEIPTS AND PAYMENTS
                        </div>
                      </div>
                      <div class="col-4 ps-0">
                        <div class="fw-bold text-end my-3">
                          {{ getDateString }}
                        </div>
                      </div>
                      <div class="col-6 col-md-6 px-0 trans_details">
                        <b-table-simple responsive outlined bordered>
                          <b-thead>
                            <b-tr>
                              <b-th class="text-center fs-6 akkurate-green" colspan="3"><span>RECEIPTS</span></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <td class="py-0 px-2">Beg. of the month cash</td>
                              <td class="py-0 px-2">GHS {{ displayNumber(Number(beg_of_month_cash).toFixed(2)) }}</td>
                            </b-tr>
                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ member_shares_dep.head }}
                              </b-td>
                            </b-tr>
                            <b-tr v-for="(value, item) in member_shares_dep.data" :key="value.id" class="list-item">
                              <b-td>
                                {{ value.name }}
                              </b-td>
                              <b-td>
                                GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td variant="success" style="font-weight: bold;">
                                Total Shares Deposit
                              </b-td>
                              <b-td variant="success" style="font-weight: bold;">
                                GHS {{ displayNumber(total_shares_dp) }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td variant="secondary" colspan="2" style="font-weight: bold;">
                                {{ member_savings_dep.head }}
                              </b-td>
                            </b-tr>
                            <b-tr v-for="(value, item) in member_savings_dep.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td variant="success" style="font-weight: bold;">
                                Total Savings Deposit
                              </b-td>
                              <b-td variant="success" style="font-weight: bold;">
                                GHS {{ displayNumber(total_savings_dp) }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ member_loan_repayment_dep.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_loan_repayment) }}
                        </b-td> -->
                            </b-tr>

                            <b-tr v-for="(value, item) in member_loan_repayment_dep.data" :key="value.id"
                              class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="success" style="font-weight: bold;">
                                Total Loan Repayment
                              </b-td>
                              <b-td variant="success" style="font-weight: bold;">
                                GHS {{ displayNumber(total_loan_repayment) }}
                              </b-td>
                            </b-tr>

                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ bank_savings_wd.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_savings_wd) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in bank_savings_wd.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="success" style="font-weight: bold;">
                                Total Bank Savings Withdrawals
                              </b-td>
                              <b-td variant="success" style="font-weight: bold;">
                                GHS {{ displayNumber(total_bank_savings_wd) }}
                              </b-td>
                            </b-tr>


                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ bank_current_wd.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_current_wd) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in bank_current_wd.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="success" style="font-weight: bold;">
                                Total Bank Current Withdrawals
                              </b-td>
                              <b-td variant="success" style="font-weight: bold;">
                                GHS {{ displayNumber(total_bank_current_wd) }}
                              </b-td>
                            </b-tr>


                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ cff_wd.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_cff_wd) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in cff_wd.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                            </b-tr>


                            <b-tr>
                              <b-td variant="success" style="font-weight: bold;">
                                Total CUA/CFF Withdrawals
                              </b-td>
                              <b-td variant="success" style="font-weight: bold;">
                                GHS {{ displayNumber(total_cff_wd) }}
                              </b-td>
                            </b-tr>


                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ t_bill_discount.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_t_bill_discount) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in t_bill_discount.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="success" style="font-weight: bold;">
                                Total T-Bill Discount
                              </b-td>
                              <b-td variant="success" style="font-weight: bold;">
                                GHS {{ displayNumber(total_bill_discount) }}
                              </b-td>
                            </b-tr>


                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ other_receipt.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_other_receipt) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in other_receipt.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.cr_balance) ?? 0 }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td variant="success" style="font-weight: bold;">
                                Total Other Receipts
                              </b-td>
                              <b-td variant="success" style="font-weight: bold;">
                                GHS {{ displayNumber(total_other_receipt) }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-td variant="secondary" style="font-weight: bold;">
                                Sub- Total for the Month
                              </b-td>
                              <b-td variant="secondary" style="font-weight: bold;">GHS {{
                                displayNumber(sub_total_receipt)
                                }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td variant="secondary" style="font-weight: bold;">
                                Income for the month
                              </b-td>
                              <b-td variant="secondary" style="font-weight: bold;">GHS {{ displayNumber(monthly_income)
                                }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td variant="secondary" style="font-weight: bold;">
                                Total Receipts
                              </b-td>
                              <b-td variant="secondary" style="font-weight: bold;">GHS {{
                                displayNumber(Number(Number(sub_total_receipt) + Number(monthly_income)).toFixed(2))
                                }}</b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>

                      <div class="col-6 col-md-6 px-0 trans_details">
                        <b-table-simple responsive outlined bordered>
                          <b-thead>
                            <b-tr>
                              <b-th class="text-center fs-6 akkurate-green" colspan="3"><span>PAYMENTS</span></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <td class="py-0 px-2">End. of the month cash</td>
                              <td class="py-0 px-2"> GHS {{ displayNumber(Number(end_of_month_cash).toFixed(2)) }}</td>
                            </b-tr>
                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ member_shares_with.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_shares_with) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in member_shares_with.data" :key="value.id" class="list-item">
                              <b-td>
                                {{ value.name }}
                              </b-td>
                              <b-td>
                                GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}
                              </b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="danger" style="font-weight: bold;">
                                Total Shares Withdrawals
                              </b-td>
                              <b-td variant="danger" style="font-weight: bold;">
                                GHS {{ displayNumber(total_shares_with) }}
                              </b-td>
                            </b-tr>

                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ member_savings_with.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_savings_with) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in member_savings_with.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="danger" style="font-weight: bold;">
                                Total Savings Withdrawals
                              </b-td>
                              <b-td variant="danger" style="font-weight: bold;">
                                GHS {{ displayNumber(total_savings_with) }}
                              </b-td>
                            </b-tr>


                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ member_loan_disbursed.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_loan_disbursed) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in member_loan_disbursed.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="danger" style="font-weight: bold;">
                                Total Loans to members
                              </b-td>
                              <b-td variant="danger" style="font-weight: bold;">
                                GHS {{ displayNumber(total_loan_disbursed) }}
                              </b-td>
                            </b-tr>


                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ bank_savings_dp.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_savings_dp) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in bank_savings_dp.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="danger" style="font-weight: bold;">
                                Total Bank Saving
                              </b-td>
                              <b-td variant="danger" style="font-weight: bold;">
                                GHS {{ displayNumber(total_bank_savings_dp) }}
                              </b-td>
                            </b-tr>

                            <b-tr>
                              <b-td colspan='2' variant="secondary" style="font-weight: bold;">
                                {{ bank_current_dp.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_bank_current_dp) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in bank_current_dp.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="danger" style="font-weight: bold;">
                                Total Bank Current
                              </b-td>
                              <b-td variant="danger" style="font-weight: bold;">
                                GHS {{ displayNumber(total_bank_current_dp) }}
                              </b-td>
                            </b-tr>

                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ cff_dp.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_cff_dp) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in cff_dp.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="danger" style="font-weight: bold;">
                                Total CUA/CFF Deposit
                              </b-td>
                              <b-td variant="danger" style="font-weight: bold;">
                                GHS {{ displayNumber(total_cff_dp) }}
                              </b-td>
                            </b-tr>

                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ t_bill_invest.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_t_bill_invest) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in t_bill_invest.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                            </b-tr>


                            <b-tr>
                              <b-td variant="danger" style="font-weight: bold;">
                                Total T-Bills Invest
                              </b-td>
                              <b-td variant="danger" style="font-weight: bold;">
                                GHS {{ displayNumber(total_t_bill_invest) }}
                              </b-td>
                            </b-tr>


                            <b-tr>
                              <b-td colspan="2" variant="secondary" style="font-weight: bold;">
                                {{ other_payments.head }}
                              </b-td>
                              <!-- <b-td variant="secondary" style="font-weight: bold;">
                          GHS {{ displayNumber(total_other_payments) }}
                        </b-td> -->
                            </b-tr>
                            <b-tr v-for="(value, item) in other_payments.data" :key="value.id" class="list-item">
                              <b-td> {{ value.name }}</b-td>
                              <b-td> GHS {{ displayNumber(value.coasubheadstransactions?.[0]?.dr_balance) ?? 0 }}</b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="danger" style="font-weight: bold;">
                                Total Other Payments
                              </b-td>
                              <b-td variant="danger" style="font-weight: bold;">
                                GHS {{ displayNumber(total_other_payments) }}
                              </b-td>
                            </b-tr>

                            <b-tr>
                              <b-td variant="secondary" style="font-weight: bold;">
                                Sub- Total for the Month
                              </b-td>
                              <b-td variant="secondary" style="font-weight: bold;">GHS {{
                                displayNumber(sub_total_payment)
                                }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td variant="secondary" style="font-weight: bold;">
                                Expenses for the month
                              </b-td>
                              <b-td variant="secondary" style="font-weight: bold;">GHS {{
                                displayNumber(monthly_expenses)
                                }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td variant="secondary" style="font-weight: bold;">
                                Total Payments
                              </b-td>
                              <b-td variant="secondary" style="font-weight: bold;">GHS
                                {{ displayNumber(Number(Number(sub_total_payment) +
                                  Number(monthly_expenses)).toFixed(2)) }}</b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-8 ps-0">
                        <div class="fw-bold my-3">
                          NOTES TO THE STATEMENT OF COMPREHENSIVE INCOME FOR
                        </div>
                      </div>
                      <div class="col-4 ps-0">
                        <div class="fw-bold text-end my-3">
                          {{ getDateString }}
                        </div>
                      </div>
                      <div class="col-12 ps-0 mb-2">
                        <div class="fw-bold text-start">
                          INCOME
                        </div>
                      </div>
                      <div class="col-12 px-0 trans_details" v-for="data in incomes" :key="data.id">
                        <b-table-simple bordered outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th width="50%" class="py-1">{{ data.name }}</b-th>
                              <b-th width="25%" class="py-1">CURRENT MONTH</b-th>
                              <b-th width="25%" class="py-1">YEAR TO DATE</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="coa in data.coas" :key="coa.id">
                              <td width="50%" class="py-0 px-2">{{ coa?.name }}</td>
                              <td width="25%" class="py-0 px-2">{{ coa?.monthly_amount }}</td>
                              <td width="25%" class="py-0 px-2">{{ coa?.yearly_amount }}</td>
                            </b-tr>
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <b-td class="fw-bold">
                                Total
                              </b-td>
                              <b-td class="fw-bold">{{ data.total_monthly_amount }}</b-td>
                              <b-td class="fw-bold">{{ data.total_yearly_amount }}</b-td>
                            </b-tr>
                          </b-tfoot>

                        </b-table-simple>
                      </div>
                      <div class="col-12 col-md-12 p-0 trans_details">
                        <b-table-simple outlined bordered>
                          <b-tbody>
                            <b-tr>
                              <b-th width="50%" class="py-0 px-2">Total Incomes (GHS)</b-th>
                              <b-th width="25%" class="py-0 px-2">{{ Number(grant_monthly_total_incomes ?
                                grant_monthly_total_incomes.toFixed(2) : 0).toLocaleString()
                                }}</b-th>
                              <b-th width="25%" class="py-0 px-2">{{ Number(grant_yearly_total_incomes ?
                                grant_yearly_total_incomes.toFixed(2) : 0).toLocaleString()
                                }}</b-th>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>

                      <div class="col-12 ps-0 mb-2">
                        <div class="fw-bold text-start">
                          EXPENSES
                        </div>
                      </div>

                      <div class="col-12 px-0 trans_details" v-for="data in expenses" :key="data.id">
                        <b-table-simple bordered outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th width="50%" class="py-1">{{ data.name }}</b-th>
                              <b-th width="25%" class="py-1">CURRENT MONTH</b-th>
                              <b-th width="25%" class="py-1">YEAR TO DATE</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="coa in data.coas" :key="coa.id">
                              <td width="50%" class="py-0 px-2">{{ coa?.name }}</td>
                              <td width="25%" class="py-0 px-2">{{ coa?.monthly_amount }}</td>
                              <td width="25%" class="py-0 px-2">{{ coa?.yearly_amount }}</td>
                            </b-tr>
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <b-td class="fw-bold">
                                Total
                              </b-td>
                              <b-td class="fw-bold">{{ data.total_monthly_amount }}</b-td>
                              <b-td class="fw-bold">{{ data.total_yearly_amount }}</b-td>
                            </b-tr>
                          </b-tfoot>

                        </b-table-simple>
                      </div>
                      <div class="col-12 col-md-12 p-0 trans_details">
                        <b-table-simple outlined bordered>
                          <b-tbody>
                            <b-tr>
                              <b-th width="50%" class="py-0 px-2">Total Expenses (GHS)</b-th>
                              <b-th width="25%" class="py-0 px-2">{{ Number(grant_monthly_total_expenses ?
                                grant_monthly_total_expenses.toFixed(2) : 0).toLocaleString()
                              }}</b-th>
                              <b-th width="25%" class="py-0 px-2">{{ Number(grant_yearly_total_expenses ?
                                grant_yearly_total_expenses.toFixed(2) : 0).toLocaleString()
                              }}</b-th>
                            </b-tr>

                            <b-tr>
                              <b-th width="50%" class="py-0 px-2" v-if="profit_or_loss_monthly < 0">Monthly Loss
                                (GHS)</b-th>
                              <b-th width="50%" class="py-0 px-2" v-else>Monthly Profit (GHS)</b-th>
                              <b-th colspan="2" width="25%" class="py-0 px-2">{{ Number(profit_or_loss_monthly ?
                                Math.abs(profit_or_loss_monthly).toFixed(2) : 0).toLocaleString()
                              }}</b-th>

                            </b-tr>
                            <b-tr>
                              <b-th width="50%" class="py-0 px-2" v-if="profit_or_loss_yearly < 0">Yearly Loss
                                (GHS)</b-th>
                              <b-th width="50%" class="py-0 px-2" v-else>Yearly Profit (GHS)</b-th>
                              <b-th colspan="2" width="25%" class="py-0 px-2">{{ Number(profit_or_loss_yearly ?
                                Math.abs(profit_or_loss_yearly).toFixed(2) : 0).toLocaleString()
                              }}</b-th>
                            </b-tr>
                          </b-tbody>

                          <!-- <b-tfoot>
                      <b-tr>
                        <b-th class="py-0 px-2" v-if="profit_or_loss < 0">Loss (GHS)</b-th>
                        <b-th class="py-0 px-2" v-else>Profit (GHS)</b-th>
                        <b-th class="py-0 px-2">{{ Number(profit_or_loss ? Math.abs(profit_or_loss).toFixed(2) : 0).toLocaleString()
                          }}</b-th>
                      </b-tr>
                    </b-tfoot> -->
                        </b-table-simple>
                      </div>


                      <div class="col-8 ps-0">
                        <div class="fw-bold my-3">
                          NOTES TO THE BALANCE SHEET AS AT
                        </div>
                      </div>
                      <div class="col-4 ps-0">
                        <div class="fw-bold text-end my-3">
                          {{ getDateString }}
                        </div>
                      </div>

                      <div class="col-12 ps-0 mb-2">
                        <div class="fw-bold text-start">
                          ASSESTS
                        </div>
                      </div>

                      <div class="col-12 px-0 trans_details" v-for="data in assets" :key="data.id">
                        <b-table-simple bordered outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th width="50%" class="py-1">{{ data.name }}</b-th>
                              <!-- <b-th width="25%" class="py-1">CURRENT MONTH</b-th> -->
                              <b-th width="50%" class="py-1">YEAR TO DATE</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="coa in data.coas" :key="coa.id">
                              <td width="50%" class="py-0 px-2">{{ coa?.name }}</td>
                              <!-- <td width="25%" class="py-0 px-2">{{ coa?.monthly_amount }}</td> -->
                              <td width="50%" class="py-0 px-2">{{ coa?.yearly_amount }}</td>
                            </b-tr>
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <b-td class="fw-bold">
                                Total
                              </b-td>
                              <!-- <b-td class="fw-bold">{{ data.total_monthly_amount }}</b-td> -->
                              <b-td class="fw-bold">{{ data.total_yearly_amount }}</b-td>
                            </b-tr>
                          </b-tfoot>

                        </b-table-simple>
                      </div>

                      <div class="col-12 ps-0 mb-2">
                        <div class="fw-bold text-start">
                          LIABILITIES
                        </div>
                      </div>

                      <div class="col-12 px-0 trans_details" v-for="data in liabilities" :key="data.id">
                        <b-table-simple bordered outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th width="50%" class="py-1">{{ data.name }}</b-th>
                              <!-- <b-th width="25%" class="py-1">CURRENT MONTH</b-th> -->
                              <b-th width="50%" class="py-1">YEAR TO DATE</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="coa in data.coas" :key="coa.id">
                              <td width="50%" class="py-0 px-2">{{ coa?.name }}</td>
                              <!-- <td width="25%" class="py-0 px-2">{{ coa?.monthly_amount }}</td> -->
                              <td width="50%" class="py-0 px-2">{{ coa?.yearly_amount }}</td>
                            </b-tr>
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <b-td class="fw-bold">
                                Total
                              </b-td>
                              <!-- <b-td class="fw-bold">{{ data.total_monthly_amount }}</b-td> -->
                              <b-td class="fw-bold">{{ data.total_yearly_amount }}</b-td>
                            </b-tr>
                          </b-tfoot>

                        </b-table-simple>
                      </div>
                      <div class="col-6 col-md-6 px-0 trans_details">
                        <b-table-simple outlined bordered>
                          <b-tfoot>
                            <b-tr>
                              <b-th width="30%" class="py-0 px-2">Total Assest</b-th>
                              <b-th width="20%" class="py-0 px-2"></b-th>
                              <b-th class="py-0 px-2">{{ institution.currency_code }} {{
                                displayNumber(grant_total_assets) }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th class="py-0 px-2">PROVISION FOR LOAN LOSSES</b-th>
                              <b-th class="py-0 px-2">{{ institution.currency_code }} {{ displayNumber(loan_losses)
                              }}</b-th>
                              <b-th class="py-0 px-2"></b-th>
                            </b-tr>
                            <b-tr>
                              <b-th class="py-0 px-2">ACCUMULATED DEPRECIATION</b-th>
                              <b-th class="py-0 px-2">{{ institution.currency_code }} {{
                                displayNumber(accumulated_dep)
                              }}</b-th>
                              <b-th class="py-0 px-2">{{ institution.currency_code }} {{ displayNumber(accumulated_dep
                                +
                                loan_losses) }}</b-th>
                            </b-tr>
                            <!-- <b-tr>
                        <b-th class="py-0 px-2" width="50%">Total Provision</b-th>
                        <b-th class="py-0 px-2">{{ displayNumber(accumulated_dep + loan_losses) }}</b-th>
                      </b-tr> -->
                            <b-tr>
                              <b-th class="py-0 px-2">Net Asset</b-th>
                              <b-th class="py-0 px-2"></b-th>
                              <b-th class="py-0 px-2">{{ institution.currency_code }} {{
                                displayNumber(Number(grant_total_assets -
                                  (accumulated_dep + loan_losses)).toFixed(2)) }}</b-th>
                            </b-tr>
                          </b-tfoot>

                        </b-table-simple>
                      </div>
                      <div class="col-6 col-md-6 px-0 trans_details">
                        <b-table-simple outlined>
                          <b-tfoot>
                            <b-tr>
                              <b-th class="py-0 px-2" width="50%">Total Liability</b-th>
                              <b-th class="py-0 px-2">{{ institution.currency_code }} {{
                                displayNumber(this.grant_total_liabilities) }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th class="py-0 px-2" width="50%">Total Surplus</b-th>
                              <b-th class="py-0 px-2">{{ institution.currency_code }} {{
                                displayNumber(grant_total_surpluses) }}</b-th>
                            </b-tr>
                            <b-tr>
                              <b-th class="py-0 px-2" width="50%">Total Liabilities + Surplus</b-th>
                              <b-th class="py-0 px-2">{{ institution.currency_code }} {{
                                displayNumber(liabilities_plus_surplus) }}</b-th>
                            </b-tr>

                          </b-tfoot>
                        </b-table-simple>
                      </div>
                      <div class="col-12 p-0 text-center akkurate-green mb-2 fw-bold">
                        Information on Ratio
                      </div>
                      <div class="col-6 p-0">
                        <b-table-simple outlined>
                          <b-tfoot>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2">Liquidity Reserve</b-th>
                              <b-th class="py-0 px-2">{{ liquidity_res }} %</b-th>
                            </b-tr>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2" width="50%">Return on Asset</b-th>
                              <b-th class="py-0 px-2">{{ return_on_assets }} %</b-th>
                            </b-tr>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2" width="50%">Capital Adequacy</b-th>
                              <b-th class="py-0 px-2">{{ capital_adequacy }} %</b-th>
                            </b-tr>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2" width="50%">Prepared By</b-th>
                              <b-th class="py-0 px-2"></b-th>
                            </b-tr>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2" width="50%">Accountant</b-th>
                              <b-th class="py-0 px-2"></b-th>
                            </b-tr>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2" width="50%">Signature</b-th>
                              <b-th class="py-0 px-2"></b-th>
                            </b-tr>

                          </b-tfoot>
                        </b-table-simple>
                      </div>
                      <div class="col-6 p-0">
                        <b-table-simple responsive outlined>
                          <b-tfoot>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2" colspan="2">(Total liquid Funds / total assets * 100)</b-th>
                            </b-tr>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2" colspan="2">(Surplus difference / total assets * 100)</b-th>
                            </b-tr>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2" colspan="2">([Member Shares + total capital res] / total assets
                                *
                                100)</b-th>
                            </b-tr>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2">Certified By</b-th>
                              <b-th class="py-0 px-2"></b-th>
                            </b-tr>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2">Manager/Board Treasurer</b-th>
                              <b-th class="py-0 px-2"></b-th>
                            </b-tr>
                            <b-tr class="trans_details">
                              <b-th class="py-0 px-2">Signature</b-th>
                              <b-th class="py-0 px-2"></b-th>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                      </div>
                      <!-- <div class="row px-0">
                       
                      </div> -->
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top">
      <i class="fas fa-angle-up"></i>
    </a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import ReportFilter from "../form/ReportFilter.vue";
import html2pdf from "html2pdf.js";
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter,
  },
  data() {
    return {
      form: {
        start_date: this.getBeginningOfYear(),
        end_date: new Date().toISOString().substr(0, 10),
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "Periodic",
        terms_options: ["YTD", "Periodic"],
        categories: [],
        isBusy: false
      },
      filter: {
        branches: true,
        products: false,
        users: false,
        status: false,
        condition: false,
        terms: false,
        coa: false,
        voucher_status: false,
        end_date: true
      },
      search: "Search",
      isBusy: false,
      isSearching: false,
      isLoadingInstitution: false,
      rec_items: [],
      rec_total: "",
      pay_items: [],
      pay_total: "",
      institution: "",
      income_items: [],
      expense_items: [],
      assets_items: [],
      liability_items: [],
      active_male: 0,
      active_female: 0,
      active_group: 0,
      inactive_male: 0,
      inactive_female: 0,
      inactive_group: 0,
      dormant_male: 0,
      dormant_female: 0,
      dormant_group: 0,
      total_female: 0,
      total_male: 0,
      total_group: 0,
      total_active: 0,
      total_inactive: 0,
      total_dormant: 0,
      member_total: 0,
      sumAssetsTotal: 0,
      no_of_branches: 0,
      active_female_shares: 0,
      active_male_shares: 0,
      active_group_shares: 0,
      inactive_female_shares: 0,
      inactive_male_shares: 0,
      inactive_group_shares: 0,
      dormant_female_shares: 0,
      dormant_male_shares: 0,
      dormant_group_shares: 0,
      active_female_savings: 0,
      active_male_savings: 0,
      active_group_savings: 0,
      inactive_female_savings: 0,
      inactive_male_savings: 0,
      inactive_group_savings: 0,
      dormant_female_savings: 0,
      dormant_male_savings: 0,
      dormant_group_savings: 0,
      disbursement_female_count: 0,
      disbursement_male_count: 0,
      disbursement_group_count: 0,
      disbursement_female_sum: 0,
      disbursement_male_sum: 0,
      disbursement_group_sum: 0,

      oustanding_female_loan_count: 0,
      oustanding_male_loan_count: 0,
      oustanding_group_loan_count: 0,
      oustanding_female_loan_sum: 0,
      oustanding_male_loan_sum: 0,
      oustanding_group_loan_sum: 0,

      delinquent_female_count: 0,
      delinquent_male_count: 0,
      delinquent_group_count: 0,

      delinquent_female_sum: 0,
      delinquent_male_sum: 0,
      delinquent_group_sum: 0,

      transaction_female: 0,
      transaction_male: 0,
      transaction_group: 0,


      member_shares_dep: [],
      member_savings_dep: [],
      member_loan_repayment_dep: [],
      member_shares_with: [],
      member_savings_with: [],
      member_loan_disbursed: [],
      bank_savings_wd: [],
      bank_current_wd: [],
      bank_savings_dp: [],
      bank_current_dp: [],
      t_bill_discount: [],
      t_bill_invest: [],
      cff_wd: [],
      cff_dp: [],
      other_receipt: [],
      other_payments: [],
      total_receipts: 0,
      total_payments: 0,
      total_shares_dp: 0,
      total_savings_dp: 0,
      total_loan_repayment: 0,
      total_shares_with: 0,
      total_savings_with: 0,
      total_loan_disbursed: 0,
      total_bank_savings_wd: 0,
      total_bank_current_wd: 0,
      total_bank_savings_dp: 0,
      total_bank_current_dp: 0,
      total_cff_wd: 0,
      total_t_bill_discount: 0,
      total_other_receipt: 0,
      total_cff_dp: 0,
      total_t_bill_invest: 0,
      total_other_payments: 0,
      monthly_income: 0,
      monthly_expenses: 0,
      sub_total_receipt: 0,
      sub_total_payment: 0,

      sumRecTotal: 0,
      sumPayTotal: 0,

      incomes: [],
      expenses: [],
      assets: [],
      liabilities: [],

      grant_monthly_total_incomes: 0,
      grant_yearly_total_incomes: 0,
      grant_monthly_total_expenses: 0,
      grant_yearly_total_expenses: 0,

      grant_total_liabilities: 0,
      liabilities_plus_surplus: 0,
      grant_total_surpluses: 0,
      grant_total_assets: 0,

      // liability_items: "",
      // assets_items: "",
      provisions: [],
      loan_to_member: "",
      total_loans: 0,
      surpluses: "",
      loan_losses: 0,
      accumulated_dep: 0,
      liquid_funds: 0,
      reserves: 0,
      total_bill_discount:0,
      // interest_on_loans: [],
      // interest_on_financial_investments: [],
      // other_financial_incomes: [],
      // non_financial_incomes: [],
      // cost_of_funds: [],
      // personal_costs: [],
      // occupancys: [],
      // organizationals: [],
      // securitys: [],
      // administrations: [],
      // provisions: [],
      // liquid_funds: [],
      // liquid_investments: [],
      // other_investments: [],
      // loan_to_members: [],
      // current_assets: [],
      // fixed_assets: [],
      // current_liabilities: [],
      // member_savings: [],
      // member_shares: [],
      // reserves: [],
      // bank_currents: [],


    };
  },

  created() {
    this.getInstitution();
    this.getAccountStatistics();
    this.getProductDropdown();
  },
  computed: {
    //Income
    totalInterestOnLoanMonth() {
      return this.interest_on_loans?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalInterestOnLoanYear() {
      return this.interest_on_loans?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    totalInterestOnFinancialInvestmentMonth() {
      return this.interest_on_financial_investments?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalInterestOnFinancialInvestmentYear() {
      return this.interest_on_financial_investments?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    totalOtherFinancialInvestmentMonth() {
      return this.other_financial_incomes?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalOtherFinancialInvestmentYear() {
      return this.other_financial_incomes?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    totalNonFinancialIncomeMonth() {
      return this.non_financial_incomes?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalNonFinancialIncomeYear() {
      return this.non_financial_incomes?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    //Expenses
    totalCostOfFundMonth() {
      return this.cost_of_funds?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalCostOfFundYear() {
      return this.cost_of_funds?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    totalPersonalCostMonth() {
      return this.personal_costs?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalPersonalCostYear() {
      return this.personal_costs?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    totalOccupancyMonth() {
      return this.occupancys?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalOccupancyYear() {
      return this.occupancys?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    totalOrganizationMonth() {
      return this.organizationals?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalOrganizationYear() {
      return this.organizationals?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    totalSecurityMonth() {
      return this.securitys?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalSecurityYear() {
      return this.securitys?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    totalAdministrationMonth() {
      return this.administrations?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalAdministrationYear() {
      return this.administrations?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    totalProvisionMonth() {
      return this.provisions?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    },
    totalProvisionYear() {
      return this.provisions?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },

    liquidity_res() {
      return Number((this.liquid_funds / this.grant_total_assets) * 100).toFixed(2) ?? 0;
    },
    return_on_assets() {
      return Number((this.grant_total_surpluses / this.grant_total_assets) * 100).toFixed(2) ?? 0;
    },
    capital_adequacy() {
      return Number(((this.member_shares + this.reserves) / this.grant_total_assets) * 100).toFixed(2) ?? 0;
    },
    totalProvisionYear() {
      return this.provisions.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },

    totalAssetsMinusProvision() {
      return this.total_loans - this.totalProvisionYear ?? 0;
    },
    // totalMonthlyIncome() {
    //   const totalIncomeMonth = this.cleanNumber(this.totalInterestOnLoanMonth) + this.cleanNumber(this.totalInterestOnFinancialInvestmentMonth) + this.cleanNumber(this.totalOtherFinancialInvestmentMonth) + this.cleanNumber(this.totalNonFinancialIncomeMonth);
    //   return totalIncomeMonth.toFixed(2)
    // },
    // totalMonthlyExpenses() {
    //   const totalExpensesMonth = this.cleanNumber(this.totalCostOfFundMonth) + this.cleanNumber(this.totalPersonalCostMonth) + this.cleanNumber(this.totalOccupancyMonth) + this.cleanNumber(this.totalOrganizationMonth) + this.cleanNumber(this.totalSecurityMonth) + this.cleanNumber(this.totalAdministrationMonth) + this.cleanNumber(this.totalProvisionMonth);
    //   return totalExpensesMonth.toFixed(2)
    // },
    // surpluseMonthly() {
    //   const surpluseMonth = this.totalMonthlyIncome - this.totalMonthlyExpenses
    //   return surpluseMonth.toFixed(2)
    // },
    // totalYearlyIncome() {
    //   const totalIncomeYear = Number(this.totalInterestOnLoanYear) + Number(this.totalInterestOnFinancialInvestmentYear) + Number(this.totalOtherFinancialInvestmentYear) + Number(this.totalNonFinancialIncomeYear);
    //   return totalIncomeYear.toFixed(2);
    // },
    // totalYearlyExpenses() {
    //   const totalExpensesYear = Number(this.totalCostOfFundYear) + Number(this.totalPersonalCostYear) + Number(this.totalOccupancyYear) + Number(this.totalOrganizationYear) + Number(this.totalSecurityYear) + Number(this.totalAdministrationYear) + Number(this.totalProvisionYear);
    //   return totalExpensesYear.toFixed(2);
    // },
    // surpluseYearly() {
    //   const surpluseYear = this.totalYearlyIncome - this.totalYearlyExpenses
    //   return surpluseYear.toFixed(2)
    // },

    //Assets
    // totalLiquidFundYear() {
    //   return this.liquid_funds?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalLiquidInvestmentYear() {
    //   return this.liquid_investments?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalOtherInvestmentYear() {
    //   return this.other_investments?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalLoanToMemberYear() {
    //   return this.loan_to_members?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalCurrentAssetsYear() {
    //   return this.current_assets?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalFixedAssetsYear() {
    //   return this.fixed_assets?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // //Liabilities
    // totalCurrentLiabilityYear() {
    //   return this.current_liabilities?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalMemberSavingYear() {
    //   return this.member_savings?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalMemberSharesYear() {
    //   return this.member_shares?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalReserveYear() {
    //   return this.reserves?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalBankCurrentYear() {
    //   return this.bank_currents?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },


    getDateString() {
      const date = new Date(this.form.end_date); // Current date
      const options = { year: 'numeric', month: 'long' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate
    },
    // totalAssetYearly() {
    //   const total = Number(this.totalLiquidFundYear) + Number(this.totalLiquidInvestmentYear) + Number(this.totalOtherInvestmentYear) + Number(this.totalLoanToMemberYear) + Number(this.totalCurrentAssetsYear) + Number(this.totalFixedAssetsYear) + Number(this.totalBankCurrentYear);
    //   return total.toFixed(2)
    // },
    // totalLiabilityYearly() {
    //   const total = Number(this.totalCurrentLiabilityYear) + Number(this.totalMemberSavingYear) + Number(this.totalMemberSharesYear) + Number(this.totalReserveYear)
    //   return total.toFixed(2);
    // },
    // totalAssetsMinusProvisions() {
    //   return this.totalAssetYearly - this.totalProvisionYear
    // },
    // totalSurplusPlusLiabilities() {
    //   return this.surpluseYearly - this.totalLiabilityYearly
    // }
  },

  methods: {
    async getAccountStatistics() {
      this.items = [];
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.query("/reports/account/statistics",
        {
          params: {
            startDate: this.form.start_date,
            endDate: this.form.end_date,
            branch_ids: this.form.branches.map((index) => { return index.id }),
          },
        }
      )
        .then((response) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          const res = response.data;
          // console.log(res);
          this.no_of_branches = res.total_branch;
          this.active_female = res.active_female;
          this.active_male = res.active_male;
          this.active_group = res.active_group;
          this.inactive_female = res.inactive_female;
          this.inactive_male = res.inactive_male;
          this.inactive_group = res.inactive_group;
          this.dormant_female = res.dormant_female;
          this.dormant_male = res.dormant_male;
          this.dormant_group = res.dormant_group;
          this.total_female = Number(this.active_female) + Number(this.inactive_female) + Number(this.dormant_female);
          this.total_male = Number(this.active_male) + Number(this.inactive_male) + Number(this.dormant_male);
          this.total_group = Number(this.active_group) + Number(this.inactive_group) + Number(this.dormant_group);

          this.active_female_shares = Number(res.active_female_shares);
          this.active_male_shares = Number(res.active_male_shares);
          this.active_group_shares = Number(res.active_group_shares);
          this.inactive_female_shares = Number(res.inactive_female_shares);
          this.inactive_male_shares = Number(res.inactive_male_shares);
          this.inactive_group_shares = Number(res.inactive_group_shares);
          this.dormant_female_shares = Number(res.dormant_female_shares);
          this.dormant_male_shares = Number(res.dormant_male_shares);
          this.dormant_group_shares = Number(res.dormant_group_shares);

          this.active_female_savings = Number(res.active_female_savings);
          this.active_male_savings = Number(res.active_male_savings);
          this.active_group_savings = Number(res.active_group_savings);
          this.inactive_female_savings = Number(res.inactive_female_savings);
          this.inactive_male_savings = Number(res.inactive_male_savings);
          this.inactive_group_savings = Number(res.inactive_group_savings);
          this.dormant_female_savings = Number(res.dormant_female_savings);
          this.dormant_male_savings = Number(res.dormant_male_savings);
          this.dormant_group_savings = Number(res.dormant_group_savings);

          this.disbursement_female_count = Number(res.disbursement_female_count);
          this.disbursement_male_count = Number(res.disbursement_male_count);
          this.disbursement_group_count = Number(res.disbursement_group_count);
          this.disbursement_female_sum = Number(res.disbursement_female_sum);
          this.disbursement_male_sum = Number(res.disbursement_male_sum);
          this.disbursement_group_sum = Number(res.disbursement_group_sum);

          this.oustanding_female_loan_count = Number(res.oustanding_female_loan_count)
          this.oustanding_male_loan_count = Number(res.oustanding_male_loan_count)
          this.oustanding_group_loan_count = Number(res.oustanding_group_loan_count)

          this.delinquent_female_count = Number(res.delinquent_female_count)
          this.delinquent_male_count = Number(res.delinquent_male_count)
          this.delinquent_group_count = Number(res.delinquent_group_count)

          this.oustanding_female_loan_sum = Number(res.oustanding_female_loan_sum);
          this.oustanding_male_loan_sum = Number(res.oustanding_male_loan_sum);
          this.oustanding_group_loan_sum = Number(res.oustanding_group_loan_sum);

          this.oustanding_female_loan_sum = Number(res.oustanding_female_loan_sum);
          this.oustanding_male_loan_sum = Number(res.oustanding_male_loan_sum);
          this.oustanding_group_loan_sum = Number(res.oustanding_group_loan_sum);

          this.delinquent_female_sum = Number(res.delinquent_female_sum);
          this.delinquent_male_sum = Number(res.delinquent_male_sum);
          this.delinquent_group_sum = Number(res.delinquent_group_sum);

          this.grant_monthly_total_incomes = res.grant_monthly_total_incomes;
          this.grant_yearly_total_incomes = res.grant_yearly_total_incomes;
          this.grant_monthly_total_expenses = res.grant_monthly_total_expenses;
          this.grant_yearly_total_expenses = res.grant_yearly_total_expenses;
          this.grant_total_expenses = res.grant_total_expenses;
          this.profit_or_loss_monthly = this.grant_monthly_total_incomes - this.grant_monthly_total_expenses;
          this.profit_or_loss_yearly = this.grant_yearly_total_incomes - this.grant_yearly_total_expenses;

          // this.transaction_female = Number(res.transaction_female);
          // this.transaction_male = Number(res.transaction_male);
          // this.transaction_group = Number(res.transaction_group);

          // this.beg_of_month_cash = Number(res.beg_of_month_cash);
          // this.shares_member_deposit = Number(res.shares_member_deposit);
          // this.saving_member_deposit = Number(res.saving_member_deposit);
          // this.loan_repayment = Number(res.loan_repayment);
          // this.bank_current_cr = Number(res.bank_current_cr);

          // this.cff_cr = Number(res.cff_cr);
          // this.t_bills_discount = Number(res.t_bills_discount);
          // this.other_payment = Number(res.other_payment);
          // this.mobile_deposit = Number(res.mobile_deposit);

          // this.cr_sub_total = Number(this.shares_member_deposit) + Number(this.saving_member_deposit) + Number(this.loan_repayment) + Number(this.bank_current_cr) + Number(this.cff_cr) + Number(this.t_bills_discount) + Number(this.other_payment)

          // this.end_of_month_cash = Number(res.end_of_month_cash);
          // this.shares_member_withdraw = Number(res.shares_member_withdraw);
          // this.saving_member_withdraw = Number(res.saving_member_withdraw);
          // this.loan_disbursed = Number(res.loan_disbursed);
          // this.bank_current_dr = Number(res.bank_current_dr);

          // this.cff_dr = Number(res.cff_dr);
          // this.t_bills_invest = Number(res.t_bills_invest);
          // this.other_receipts = Number(res.other_receipts);

          // this.dr_sub_total = Number(this.shares_member_withdraw) + Number(this.saving_member_withdraw) + Number(this.loan_disbursed) + Number(this.bank_current_dr) + Number(this.cff_dr) + Number(this.t_bills_invest) + Number(this.other_receipts)

          // this.mobile_withdraw = Number(res.mobile_withdraw);
          this.sumRecTotal = Number(this.cr_sub_total) + Number(this.mobile_deposit) + Number(this.totalMonthlyIncome);
          this.sumPayTotal = Number(this.dr_sub_total) + Number(this.mobile_withdraw) + Number(this.totalMonthlyExpenses);

          this.incomes = res.incomes
          this.expenses = res.expenses
          this.assets = res.assets
          this.liabilities = res.liabilities

          this.member_shares_dep = res.receipt_and_payment?.member_shares_dep;
          this.member_savings_dep = res.receipt_and_payment?.member_savings_dep
          this.member_loan_repayment_dep = res.receipt_and_payment?.member_loan_repayment_dep
          this.member_shares_with = res.receipt_and_payment?.member_shares_with;
          this.member_savings_with = res.receipt_and_payment?.member_savings_with;
          this.member_loan_disbursed = res.receipt_and_payment?.member_loan_disbursed;
          this.bank_savings_wd = res.receipt_and_payment?.bank_savings_wd;
          this.bank_current_wd = res.receipt_and_payment?.bank_current_wd;
          this.bank_savings_dp = res.receipt_and_payment?.bank_savings_dp;
          this.bank_current_dp = res.receipt_and_payment?.bank_current_dp;
          this.cff_wd = res.receipt_and_payment?.cff_wd;
          this.cff_dp = res.receipt_and_payment?.cff_dp;
          this.t_bill_discount = res.receipt_and_payment?.t_bill_discount;
          this.t_bill_invest = res.receipt_and_payment?.t_bill_invest;
          this.other_receipt = res.receipt_and_payment?.other_receipt;
          this.other_payments = res.receipt_and_payment?.other_payments;
          this.monthly_income = res.receipt_and_payment?.monthly_income;
          this.monthly_expenses = res.receipt_and_payment?.monthly_expenses;
          // this.total_receipts = res.total_receipts;
          // this.total_payments = res.total_payments;
          this.form.branch_options = res.receipt_and_payment?.branches;
          this.beg_of_month_cash = res.receipt_and_payment?.beg_of_month_cash;
          this.end_of_month_cash = res.receipt_and_payment?.end_of_month_cash;

          this.branch_list = this.form.branches.map(branch => branch.name).join(", ");

          this.sub_total_receipt = Number(res.receipt_and_payment?.total_receipts).toFixed(2);
          this.sub_total_payment = Number(res.receipt_and_payment?.total_payments).toFixed(2);

          this.total_bill_discount = res.total_bill_discount;


          // Calculate the total amount
          this.total_shares_dp = this.member_shares_dep.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_savings_dp = this.member_savings_dep.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_loan_repayment = this.member_loan_repayment_dep.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);

          this.total_bank_savings_wd = this.bank_savings_wd.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_bank_current_wd = this.bank_current_wd.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_cff_wd = this.cff_wd.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_t_bill_discount = this.t_bill_discount.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);
          this.total_other_receipt = this.other_receipt.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.cr_balance ?? 0);
          }, 0);



          // // Calculate the total amount
          this.total_shares_with = this.member_shares_with.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_savings_with = this.member_savings_with.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_loan_disbursed = this.member_loan_disbursed.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);

          this.total_bank_savings_dp = this.bank_savings_dp.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_bank_current_dp = this.bank_current_dp.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_cff_dp = this.cff_dp.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_t_bill_invest = this.t_bill_invest.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);
          this.total_other_payments = this.other_payments.data.reduce((total, item) => {
            // Access the first element of coasubheadstransactions array and get cr_balance
            return total + parseFloat(item?.coasubheadstransactions[0]?.dr_balance ?? 0);
          }, 0);

          this.surpluses = res.surpluses;
          this.grant_total_assets = res.grant_total_assets;
          this.grant_total_liabilities = res.grant_total_liabilities;
          this.grant_total_surpluses = res.total_surpluses;
          this.liabilities_plus_surplus = (this.grant_total_liabilities + this.grant_total_surpluses);

          this.provisions = res.provisions;
          this.loan_to_member = res.loan_to_member
          this.loan_losses = res.loan_losses
          this.accumulated_dep = res.accumulated_dep
          this.liquid_funds = res.liquid_funds
          this.reserves = res.reserves
          this.member_shares = res.member_shares



          // this.interest_on_loans = res.interest_on_loans;
          // this.interest_on_financial_investments = res.interest_on_financial_investments;
          // this.other_financial_incomes = res.other_financial_incomes;
          // this.non_financial_incomes = res.non_financial_incomes;
          // this.cost_of_funds = res.cost_of_funds;
          // this.personal_costs = res.personal_costs;
          // this.occupancys = res.occupancys;
          // this.organizationals = res.organizationals;
          // this.securitys = res.securitys;
          // this.administrations = res.administrations;
          // this.provisions = res.provisions;
          // this.liquid_funds = res.liquid_funds;
          // this.liquid_investments = res.liquid_investments;
          // this.other_investments = res.other_investments;
          // this.loan_to_members = res.loan_to_members;
          // this.current_assets = res.current_assets;
          // this.fixed_assets = res.fixed_assets;
          // this.current_liabilities = res.current_liabilities;
          // this.member_savings = res.member_savings;
          // this.member_shares = res.member_shares;
          // this.reserves = res.reserves;
          // this.bank_currents = res.bank_currents;
        })
        .catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        });
    },
    filterAction() {
      this.getAccountStatistics();
    },
    async getProductDropdown() {
      this.isBusy = true;
      this.search = "Processing...";
      await ApiService.query("/reports/controls/products/dropdown")
        .then((response) => {
          this.isBusy = false;
          this.search = "Search";
          this.form.branch_options = response.data.branches;
          this.form.product_options = response.data.products;
          this.form.users_options = response.data.users;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.search = "Search";
        });
    },
    pdfOptions() {
      return {
        margin: [0.3, 0.2, 0.8, 0.2], // top, left, bottom, right
        filename: "account_statistics.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'css', before: '#balance-sheet' },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      }
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      html2pdf()
        .from(content)
        .set(this.pdfOptions())
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      html2pdf()
        .from(content)
        .set(this.pdfOptions())
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    filterTable() {
      this.getAccountStatistics();
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
    // cleanNumber(value) {
    //   // Convert to a string, then remove any extraneous spaces or non-numeric characters
    //   const cleanedValue = value.toString().replace(/[^\d.-]/g, '');
    //   // Parse it into a float and handle NaN cases
    //   const parsedValue = parseFloat(cleanedValue);

    //   // Ensure it's a valid number, otherwise default to 0
    //   return isNaN(parsedValue) ? 0 : parsedValue;
    // }
  },
};
</script>
<style>
.list-item {
  page-break-inside: avoid;
}
</style>