<template>
    <div>
        <b-modal size="xl" ref="showCashDeposit" @shown="modalShown">
            <template #modal-title>
                <span class="akkurate-green fw-bold">Cash Deposit</span>
            </template>
            <div class="container-fluid" ref="pdfContent">
                <!-- First Receipt -->
                <div style="border-left: 5px solid #039a67; border-bottom: 5px solid #039a67;">

                    <institution :institution="report_data?.institution" :title="'CASH TRANSACTION RECEIPT'"
                        :report_style="'deposit'"></institution>

                    <div class="row ps-2">
                        <div class="col-md-12 col-12 text-center mt-2">
                            <p class="fw-bold report-l-text">CASH DEPOSIT SLIP</p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text text-uppercase"> BRANCH: <span class="fw-bold">{{
                                report_data?.branch_name
                                    }}</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> RECEIPT NUMBER: <span class="fw-bold">{{
                                    report_data?.receipt_no
                                    }}</span></p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <div class="row pt-3">
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TRANSACTION DATE</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.trans_date }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TELLER NAME</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.teller }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NUMBER</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.account_number }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NAME</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.fullname }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">DESCRIPTION</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.transaction_mode }} {{
                                        report_data?.transaction_type }} {{ report_data.narration }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TOTAL AMOUNT</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">AMOUNT IN WORDS</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{
                                        convertMoneyToWords(report_data?.amount) }}</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6" style="background-color:#cceae0">
                            <div class="row pt-3 px-3">
                                <div class="col-md-4">
                                    <p class="fw-bold report-s-text akkurate-green">DEMONINATION</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="fw-bold report-s-text text-center akkurate-green">NO. OF DENOM.</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="fw-bold report-s-text text-center akkurate-green">TOTAL</p>
                                </div>
                                <div>
                                    <div class="row" v-for="(result, index) in denomination" :key="index">
                                        <div class="col-md-4">
                                            <p class="mb-0 report-s-text akkurate-green">{{ result.denomin }}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p v-if="result.match != 0"
                                                class="mb-0 report-s-text text-center akkurate-green">{{
                                                    result.match.denom_frequency }}</p>
                                            <p v-else class="mb-0 report-s-text text-center akkurate-green">0</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p v-if="result.match != 0"
                                                class="mb-0 report-s-text text-center akkurate-green">{{
                                                    result.match.sub_total }}</p>
                                            <p v-else class="mb-0 report-s-text text-center akkurate-green">.00</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="report-s-text akkurate-green">COINS</p>
                                        </div>
                                        <div class="col-md-4 text-center">
                                            <p class="report-s-text"></p>
                                        </div>
                                        <div class="col-md-4 text-center">
                                            <p class="report-s-text akkurate-green">0</p>
                                        </div>

                                        <div class="col-md-8">
                                            <p class="fw-bold mb-0 report-s-text akkurate-green">GRAND TOTAL</p>
                                        </div>

                                        <div class="col-md-4 text-center">
                                            <p class="fw-bold mb-0 report-s-text akkurate-green">{{
                                                report_data?.institution?.currency_code }} {{
                                                    displayNumber(report_data?.amount) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-0">
                        <div class="col-md-12 pe-0">
                            <div class="deposit-line"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">TELLER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">CUSTOMER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-12">
                            <p class="text-center report-s-text"><span class="fw-bold">All Corresponding regarding this
                                    receipt
                                    should be communicated to our customer care line on</span> {{
                                        report_data?.institution?.telephone }} or email at {{ report_data?.institution?.email }}
                            </p>
                        </div>
                    </div>

                </div>

                <hr style="border-top: dotted 1px;" />

                <!-- Second Receipt -->
                <div style="border-left: 5px solid #039a67; border-bottom: 5px solid #039a67;">

                    <institution :institution="report_data?.institution" :title="'CASH TRANSACTION RECEIPT'"
                        :report_style="'deposit'"></institution>

                    <div class="row ps-2">
                        <div class="col-md-12 col-12 text-center mt-2">
                            <p class="fw-bold report-l-text">CASH DEPOSIT SLIP</p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text text-uppercase"> BRANCH: <span class="fw-bold">{{
                                report_data?.branch_name
                                    }}</span></p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-0 report-l-text"> RECEIPT NUMBER: <span class="fw-bold">{{
                                report_data?.receipt_no
                                    }}</span></p>
                        </div>
                    </div>
                    <div class="row ms-0 ps-2">
                        <div class="col-md-6">
                            <div class="row pt-3">
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TRANSACTION DATE</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.trans_date }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TELLER NAME</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.teller }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NUMBER</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.account_number }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">ACCOUNT NAME</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.fullname }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">DESCRIPTION</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{ report_data?.transaction_mode }} {{
                                        report_data?.transaction_type }} {{ report_data.narration }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">TOTAL AMOUNT</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2">{{ report_data?.institution?.currency_code }} {{
                                        displayNumber(report_data?.amount) }}</p>
                                </div>
                                <div class="col-md-5">
                                    <p class="report-s-text fw-bold mb-2">AMOUNT IN WORDS</p>
                                </div>
                                <div class="col-md-7 p-0">
                                    <p class="report-s-text mb-2 text-uppercase">{{
                                        convertMoneyToWords(report_data?.amount) }}</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6" style="background-color:#cceae0">
                            <div class="row pt-3 px-3">
                                <div class="col-md-4">
                                    <p class="fw-bold report-s-text akkurate-green">DEMONINATION</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="fw-bold report-s-text text-center akkurate-green">NO. OF DENOM.</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="fw-bold report-s-text text-center akkurate-green">TOTAL</p>
                                </div>
                                <div>
                                    <div class="row" v-for="(result, index) in denomination" :key="index">
                                        <div class="col-md-4">
                                            <p class="mb-0 report-s-text akkurate-green">{{ result.denomin }}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p v-if="result.match != 0"
                                                class="mb-0 report-s-text text-center akkurate-green">{{
                                                    result.match.denom_frequency }}</p>
                                            <p v-else class="mb-0 report-s-text text-center akkurate-green">0</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p v-if="result.match != 0"
                                                class="mb-0 report-s-text text-center akkurate-green">{{
                                                    result.match.sub_total }}</p>
                                            <p v-else class="mb-0 report-s-text text-center akkurate-green">.00</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="report-s-text akkurate-green">COINS</p>
                                        </div>
                                        <div class="col-md-4 text-center">
                                            <p class="report-s-text"></p>
                                        </div>
                                        <div class="col-md-4 text-center">
                                            <p class="report-s-text akkurate-green">0</p>
                                        </div>

                                        <div class="col-md-8">
                                            <p class="fw-bold mb-0 report-s-text akkurate-green">GRAND TOTAL</p>
                                        </div>

                                        <div class="col-md-4 text-center">
                                            <p class="fw-bold mb-0 report-s-text akkurate-green">{{
                                                report_data?.institution?.currency_code }} {{
                                                    displayNumber(report_data?.amount) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-0">
                        <div class="col-md-12 pe-0">
                            <div class="deposit-line"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">TELLER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-6 mt-4">
                            <p class="text-center fw-bold report-s-text">CUSTOMER
                                SIGN...................................................</p>
                        </div>
                        <div class="col-md-12">
                            <p class="text-center report-s-text"><span class="fw-bold">All Corresponding regarding this
                                    receipt
                                    should be communicated to our customer care line on</span> {{
                                        report_data?.institution?.telephone }} or email at {{ report_data?.institution?.email }}
                            </p>
                        </div>
                    </div>

                </div>


            </div>
            <template #modal-footer>
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="generatePDF" type="button"
                        class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon
                                :icon="['fas', 'book']" /> Print</span></b-button>
                    <b-button @click="exportToPDF" class="akkurate-green-btn download-btn float-right"><span
                            class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>
                </div>
            </template>
        </b-modal>

    </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import Institution from '@/views/main/components/institution/Institution.vue'
export default {
    components: {
        Institution,
    },
    props: ['report_data'],
    data() {
        return {
            numbers: ["1", "2", "5", "10", "20", "50", "100", "200"],
            denomin: [],
            isPrinting: false,
            denomination: [],
            // currentModal: false,
        }
    },
    methods: {
        showModal() {
            this.$refs['showCashDeposit'].show()
        },
        hideModal() {
            this.$refs['showCashDeposit'].hide()
        },
        getMergeDenomin() {
            const merged = [];
            this.denomin = this.report_data?.denomination;
            // Iterate over the first array
            for (const number of this.numbers) {
                const match = this.denomin?.find(denomin => denomin.denom_amount == number);
                if (match) {
                    merged.push({ denomin: number, match });
                } else {
                    merged.push({ denomin: number, match: 0 });
                }
            }
            this.denomination = merged;
        },


        exportToPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            const pdfOptions = {
                margin: 10,
                filename: 'generated.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCROS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };
            // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

            // });
            html2pdf().from(content).set(pdfOptions).save();
        },
        generatePDF() {
            // Define the element to convert to a PDF
            const element = this.$refs.pdfContent; // Replace with your element's ID or selector

            // Define the options for html2pdf
            const pdfOptions = {
                margin: 10,
                filename: 'document.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCROS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                debug: true, // Enable debugging
            };
            html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
                const blob = new Blob([pdf], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const printWindow = window.open(url, '_blank', 'width=800,height=600');
                printWindow.onload = () => {
                    printWindow.print();
                    URL.revokeObjectURL(url);
                };
            });
        },
        modalShown() {
            console.log("HERE")
            // this.currentModal = true;
            this.getMergeDenomin();
        },

    }

}
</script>