<template>
  <div id="wrapper">
    <SidebarView :voucher="true" :add-voucher="true" :voucher-visible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <form @submit.prevent="submitVoucher()" :add_or_edit="add_or_edit">
          <b-container>
            <div class="row pb-2">
              <div class="col-md-12">
                <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size mb-2">
                  <font-awesome-icon class="me-2" :icon="['fas', 'save']" />{{ save }}
                </button>
              </div>
            </div>
            <div class="general-error d-flex justify-content-center pt-2 pb-2">
              <errors v-if="form.errors">
                {{ form.errors }}
              </errors>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div class="card-header d-flex flex-row align-items-center">
                    <h6 class="m-0 fw-bold text-green">Create Voucher</h6>
                  </div>
                  <div class="card-body">
                    <voucher-form :form="form" :errors="errors" :branch_options="branch_options" :file_name="file_name"
                      :upload="upload" @documentFile="documentFile"></voucher-form>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="py-3 d-flex flex-row align-items-center justify-content-between">
                <!-- <AddAccount :coa_subhead_options="coa_subhead_options" @addAccount="addAccount"></AddAccount> -->
              </div>
              <div class="err-accounts">
                <errors v-if="form.errors.accounts">
                  {{ form.errors.accounts[0] }}
                </errors>
              </div>
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div class="card-header py-3 d-flex flex-row align-items-center">
                    <h6 class="m-0 fw-bold text-green">Accounts</h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-3">
                        <label class="akkurate-dark" for="dr_amount">Debit Amount <star></star></label>
                      </div>
                      <div class="col-md-3">
                        <label class="akkurate-dark" for="cr_amount">Credit Amount <star></star></label>
                      </div>
                      <div class="col-md-3">
                        <label class="akkurate-dark" for="dr_amount">COA Subhead <star></star></label>
                      </div>

                    </div>
                    <div class="row">
                      <errors v-if="errors['vouch_journ_coa_accounts.0.coa_subhead.id']">
                        <!-- {{ errors['vouch_journ_coa_accounts.0.coa_subhead.id'][0] }} -->
                        You need to select a COA Subhead and enter a valid amount
                      </errors>
                    </div>
                    <div class="row">
                      <errors v-if="errors['vouch_journ_coa_accounts.1.coa_subhead.id']">
                        <!-- {{ errors['vouch_journ_coa_accounts.1.coa_subhead.id'][0] }} -->
                        You need to select a COA Subhead and enter a valid amount
                      </errors>
                    </div>
                    <div class="row" v-for="(field, index) in documents" :key="index">
                      <div class="col-md-3">
                        <b-form-group label-for="dr_amount">
                          <b-form-input id="dr_amount" name="dr_amount" class="mt-2 field-container fields"
                            type="number" step="any" v-model="field.dr_amount" :disabled="drDisabled(field)"
                            required></b-form-input>
                          <errors v-if="form.errors.dr_amount">
                            {{ form.errors.dr_amount[0] }}
                          </errors>
                        </b-form-group>
                      </div>

                      <div class="col-md-3">
                        <b-form-group>
                          <b-form-input id="cr_amount" name="cr_amount" class="mt-2 field-container fields"
                            type="number" step="any" v-model="field.cr_amount" :disabled="crDisabled(field)" required
                            data-vv-as="Credit Amount"></b-form-input>
                          <errors v-if="form.errors.cr_amount">
                            {{ form.errors.cr_amount[0] }}
                          </errors>
                        </b-form-group>
                      </div>
                      <div class="col-md-4">
                        <b-form-group>
                          <multiselect v-model="field.coa_subhead" name="coa_subhead" :options="coa_subhead_options"
                            class="mt-2 field-container" label="name" :custom-label="nameWithLang" required>
                          </multiselect>
                          <errors v-if="form.errors.account_type">
                            {{ form.errors.account_type[0] }}
                          </errors>
                        </b-form-group>
                      </div>
                      <div class="col-md-2 mt-auto">
                        <a v-if="index > 1" title="Delete" class="btn btn-sm akkurate-danger-btn text-white"
                          @click="removeField(index)">
                          <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'trash']" /></span>
                        </a>
                      </div>
                    </div>
                    <p class="akkurate-danger small">{{ acc_error }}</p>
                    <div class="row mt-3">
                      <div class="col-md-12">
                        <button type="button" @click="addAccount" class="btn akkurate-green-btn text-white btn-size">
                          <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row pt-4">
              <div class="col-md-12">
                <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size mb-2">
                  <font-awesome-icon class="me-2" :icon="['fas', 'save']" />{{ save }}
                </button>
              </div>
            </div>
          </b-container>
        </form>
      </div>
      <footer></footer>
    </div>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import Footer from "@/views/main/components/Footer.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import VoucherForm from '../forms/VoucherForm.vue'
import AddAccount from '../forms/modal/AddAccount.vue'
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
export default {
  name: "CreateVoucher",
  mounted() {
    this.loadPageOptions();
  },
  components: {
    SidebarView,
    Footer,
    TopbarView,
    VoucherForm,
    AddAccount,
    Multiselect,
  },
  data() {
    return {
      ...this.initialState(),
      branch_options: [],
      file: null,
      file_name: "No file chosen",
      upload: "Browse",
      isSaving: false,
      coa_subhead_options: [],
      add_or_edit: 'add',
      save: "Save",
      acc_error: "",
    };
  },
  methods: {
    loadPageOptions() {
      this.$Progress.start();
      this.isLoading = true;
      ApiService.get("/vouchers/dropdown")
        .then((response) => {
          this.coa_subhead_options = response.data.coa_subheads;
          this.form.branch = response.data?.user_branch?.name
          this.isLoading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.fail();
        });
    },
    addAccount() {
      this.documents.push({ dr_amount: "", cr_amount: "", coa_subhead: { id: "", name: "" } });
      if (this.documents.length < 2) {
        this.acc_error = "Account must be more than one"
        this.isSaving = true
      } else {
        this.acc_error = ""
        this.isSaving = false
      }
    },
    removeField(index) {
      this.documents.splice(index, 1);
      if (this.documents.length < 2) {
        this.acc_error = "account must be more than one"
        this.isSaving = true
      } else {
        this.acc_error = ""
        this.isSaving = false
      }
    },
    drDisabled(field) {
      if (field.cr_amount.length > 0) {
        field.dr_amount = 0;
        return true;
      }
    },
    crDisabled(field) {
      if (field.dr_amount.length > 0) {
        field.cr_amount = 0;
        return true;
      }
    },
    submitVoucher() {
      this.isSaving = true;
      this.form.vouch_journ_coa_accounts = this.documents
      this.uploadVoucherFile().then(() => {
        ApiService.post("/vouchers/store", this.form)
          .then((response) => {
            this.isSaving = false;
            this.$Progress.finish();
            this.save = "Save";
            toast.fire({
              icon: "success",
              title: response.data.message,
            });
            this.errors = {};
            this.form = this.initialState().form;
          })
          .catch((error) => {
            this.isSaving = false;
            this.$Progress.fail();
            this.save = "Save";
            if (error.response.data) {
              this.form.errors = error.response.data.message;
              this.errors = error.response.data.errors || {};
            }
          });
      });
    },
    uploadVoucherFile() {
      if (this.file) {
        this.upload = "Processing...";
        const data = new FormData();
        data.append('file', this.file);
        data.append('is_document', '1');
        return ApiService.post('/images/store', data)
          .then((response) => {
            this.form.receipt_name = response.data.name;
            this.form.receipt_location = response.data.path;
            this.upload = "Uploaded";
          })
          .catch(() => {
            this.upload = "Failed";
            throw new Error("File upload failed.");
          });
      }
      return Promise.resolve();
    },
    documentFile(data) {
      this.file = data;
      this.file_name = data.name;
    },
    // submitVoucher() {
    //   this.isSaving = true;
    //   this.$Progress.start();
    //   this.save = "Saving..."
    //   // console.log(this.form);
    //   this.form.vouch_journ_coa_accounts = this.documents
    //   console.log(this.form);
    //   ApiService.post("/vouchers/store", this.form)
    //     .then((response) => {
    //       // console.log(response);
    //       this.isSaving = false;
    //       this.$Progress.finish();
    //       this.save = "Save"
    //       toast.fire({
    //         icon: "success",
    //         title: response.data.message,
    //       });
    //       this.errors = [];
    //       this.form = this.initialState().form;
    //       this.documents = [{ dr_amount: "", cr_amount: "", coa_subhead: { id: "", name: "", code: "" } }, { dr_amount: "", cr_amount: "", coa_subhead: { id: "", name: "", code: "" } }]
    //       this.isSaving = false;
    //     })
    //     .catch((error) => {
    //       this.isSaving = false;
    //       this.$Progress.fail();
    //       this.save = "Save"
    //       if (error.response.data) {
    //         this.form.errors = error.response.data.message;

    //         if (error.response.data.errors) {
    //           this.errors = error.response.data.errors;
    //         }
    //         else {
    //           this.errors = [];
    //         }
    //       }

    //       //error message
    //       if (error.response.data.message) {
    //         this.form.message = error.response.data.message;
    //       }
    //     });
    // },

    // documentFile(data) {
    //   this.form.receipt_name = data.name;
    //   this.form.receipt_location = data.path
    // },
    nameWithLang({ name, code, coabranch }) {
      return `${name} — ${code == undefined ? "" : code} ${coabranch?.name == undefined ? "" : coabranch?.name}`;
    },
    initialState() {
      return {
        form: {
          beneficiary_name: "",
          branch: "",
          amount_requested: "",
          narration: "",
          receipt_name: "",
          receipt_location: "",
          recorded_date: new Date().toLocaleDateString('en-CA'),
          vouch_journ_coa_accounts: [],
          errors: "",
        },
        errors: {},
        documents: [{ dr_amount: "", cr_amount: "", coa_subhead: { id: "", name: "", code: "" } }, { dr_amount: "", cr_amount: "", coa_subhead: { id: "", name: "", code: "" } }],
      }
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>