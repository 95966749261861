<template>
    <div class="mx-3 mb-2">
        <div class="row">
            <div class="col-12 text-end">
                <a @click.prevent="printPDF" class="akkurate-green custom-pointer"><font-awesome-icon class="me-2"
                        :icon="['fas', 'book']" /></a>
            </div>
        </div>
        <div class="mx-3 mt-3" ref="pdfContent">
            <div class="row">
                <div class="col-md-4 col-4 d-flex align-items-center flex-column bd-highlight">
                    <img class="img-fluid img-visibility statement-logo"
                        :src="require('@/assets/img/default_logo.png')">
                    <div class="mt-2 akkurate-green fw-bold mt-auto p-2 bd-highlight">LOAN CONTRACT</div>
                </div>
                <div class="d-flex align-items-end flex-column bd-highlight col-md-8 col-8 text-end">
                    <p class="fw-bold text-uppercase mb-0">{{ institution?.name }}</p>
                    <p class="fw-bold">{{ institution?.motto }}</p>
                    <p class="mb-0 akkurate-small">{{ institution?.postal }}</p>
                    <p class="mb-0 akkurate-small">{{ institution?.telephone }}</p>
                    <p class="akkurate-small">{{ institution?.email }}</p>
                    <p class="fw-bold text-end mt-auto p-2 bd-highlight text-uppercase">{{ new
                        Intl.DateTimeFormat('en-GB', {
                            day: 'numeric', month: 'long', year: 'numeric'
                        }).format(Date.now()) }}</p>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-12 text-start">
                    <div class="row">
                        <div class="col-2">
                            Name
                        </div>
                        <div class="col-10">
                            {{
                                details.account.customer?.first_name + ' ' +
                                details.account.customer?.last_name
                            }}
                        </div>
                        <div class="col-2 mt-2">
                            Address
                        </div>
                        <div class="col-10 mt-2">
                            {{ details.account.customer?.address }}

                        </div>
                        <div class="col-2 mt-2">
                            Tel
                        </div>
                        <div class="col-10 mt-2">
                            {{ details.account.customer?.mobile }}
                        </div>
                    </div>
                </div>

            </div>
            <div class="row mt-1">
                <div class="col-12">
                    <h5 class="akkurate-dark fw-bold text-center"><u>LOAN APPROVAL AND OFFER LETTER</u></h5>
                </div>
                <div class="col-12 mt-1 text-start">
                    <p class="mb-0">We refer to your application for {{ institution?.currency_code }}<span
                            style="width: 10%; display: inline-block;" class="border-bottom border-dark text-start">{{
                                details?.principal_amount }}</span> and wish to inform you that the loan request has been
                        approved subject to the basic terms and conditions stated below.
                    </p>
                    <div class="table_text_size">
                        <b-table-simple small striped bordered outlined style="border-color: #2a454e !important;">
                            <b-tbody>
                                <b-tr class="text-start">
                                    <b-td><span class="fw-bold">Amount Applied:</span> {{ institution?.currency_code }}
                                        {{
                                            details?.principal_requested }} </b-td>
                                    <b-td colspan="2"><span class="fw-bold">Amount Approved</span> {{
                                        institution?.currency_code }} {{ details?.principal_amount }} </b-td>
                                </b-tr>
                                <b-tr class="text-start">
                                    <b-td><span class="fw-bold">Duration Of Loan</span> {{ details?.tenor }} {{
                                        details?.tenor_interval }}</b-td>
                                    <b-td colspan="2"><span class="fw-bold">Annual Int Rate</span> {{
                                        details?.interest_rate
                                        }} % {{ details?.interest_rate_frequency }}</b-td>
                                </b-tr>
                                <b-tr class="text-start">
                                    <b-td><span>Expected Int. Based On</span> {{ details?.interest_method }} {{
                                        institution?.currency_code }} {{ details?.expected_interest }} </b-td>
                                    <b-td><span class="fw-bold">Mode Of Payment</span></b-td>
                                    <b-td><span class="fw-bold">Avg. Mthly Rep</span></b-td>
                                </b-tr>
                                <b-tr class="text-start">
                                    <b-td colspan="2"><span class="fw-bold">Loan Purpose</span> {{ details?.purpose
                                        }}</b-td>
                                    <b-td><span class="fw-bold">Security Type</span></b-td>
                                </b-tr>
                                <b-tr class="text-start">
                                    <b-td><span class="fw-bold">Security</span> {{ details?.purpose }}</b-td>
                                    <b-td><b-form-radio class="d-inline" name="status" value="1">
                                            <span class="fw-bold">YES</span>
                                        </b-form-radio> <b-form-radio class="d-inline" name="status" value="1">
                                            <span class="fw-bold">NO</span>
                                        </b-form-radio>
                                    </b-td>
                                    <b-td><span class="fw-bold">Security Doct No.</span> </b-td>
                                </b-tr>
                                <b-tr class="text-start">
                                    <b-td colspan="2"><span class="fw-bold">Source Of Income</span></b-td>
                                    <b-td><span class="fw-bold">Secondary Income</span></b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>

                    </div>

                </div>
                <div class="col-12 mb-3">
                    <h6 class="akkurate-dark fw-bold text-center"><u>DECLARE AS FOLLOWS THAT</u></h6>
                    <div class="text-start">
                        <ul>
                            <li>I, as the applicant herein, hereby access the loan value given above </li>
                            <li>I also accept the interest above which is based on the monthly reducing balance
                                principle
                                (which requires the payment of monthly instalments stipulated above as AVG. MTHLY REP’T)
                            </li>
                            <li>I understand and accept that two missed payments make the entire loan balance due</li>
                            <li>A non-payment acts to increase the interest due and payable</li>
                            <li>In the event of a non-payment, {{ institution?.alias }} reserves the right to use part
                                of my
                                savings to service the interest due</li>
                            <li>I have an obligation to renew my loan insurance when it expires</li>
                            <li>{{ institution?.alias }} reserves the right to conduct a credit reference check on me
                            </li>
                            <li>By signing this document, I indicate my understanding and acceptance of the terms herein
                                for
                                the loan and its repayment</li>
                            <li>Any false declaration will be considered fraudulent and render me liable for criminal
                                prosecution whilst granting {{ institution?.alias }} the right to recall the loan with
                                full
                                interest.</li>
                            <li>Any payment due on a Saturday or Sunday should be paid on the preceding Friday.</li>
                        </ul>
                    </div>
                </div>
                <div class="text-start col-12">
                    <h6 class="akkurate-dark fw-bold"><u>JURAT FOR ILLITERATE APPLICANT </u></h6>
                </div>
                <div class="col-12 text-start">
                    <p class="mb-0">After the contents of this offer and the General Terms and Conditions have been read
                        and
                        explained to him / her in
                        <span style="width: 15%; display: inline-block;"
                            class="border-bottom border-dark text-start"></span> language by
                        <span style="width: 15%; display: inline-block;"
                            class="border-bottom border-dark text-start"></span> Name of interpreter and he / she
                        confirmed
                        having understood same and accepted everything contained herein before making his / her
                        sign/mark.
                    </p>
                </div>
                <div class="col-6 mt-2">
                    <div class="text-start">____________________________________</div>
                    <div class="text-start">Interpreter’s Signature.</div>
                </div>
                <div class="col-6 mt-2">
                    <div class="text-start">____________________________________</div>
                    <div class="text-start">Telephone.</div>
                </div>
                <div class="col-12 mt-2">
                    <b-table-simple small striped bordered outlined style="border-color: #2a454e !important;">
                        <b-tbody>
                            <b-tr class="text-start">
                                <b-td><span>Accepted By</span></b-td>
                                <b-td><span>Witness</span></b-td>
                            </b-tr>
                            <b-tr class="text-start">
                                <b-td><span>Name</span></b-td>
                                <b-td><span>Name</span></b-td>
                            </b-tr>
                            <b-tr class="text-start">
                                <b-td><span>Signature</span></b-td>
                                <b-td><span>Signature</span></b-td>
                            </b-tr>
                            <b-tr class="text-start">
                                <b-td><span>Date</span></b-td>
                                <b-td><span>Date</span></b-td>
                            </b-tr>

                        </b-tbody>

                    </b-table-simple>
                </div>
                <div class="col-6 border">
                    <div>{{ institution?.alias }} CREDIT UNION LTD</div>
                    <div>OFFICIAL STAMP</div>
                </div>
                <div class="text-start col-6 border">
                    <div>Name</div>
                    <div class="my-3">Signature</div>
                    <div>Date</div>

                </div>

            </div>

        </div>


    </div>

</template>

<script>
import html2pdf from "html2pdf.js";
export default {
    props: ['details', "institution"],
    data() {
        return {
        }
    },

    computed: {
        getTodayDay() {
            console.log(new Date().toDateString());
            return new Date().getDate()
        },
        getMonth() {
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const currentDate = new Date();
            const currentMonth = monthNames[currentDate.getMonth()];
            return currentMonth
        },
        getYear() {
            const year = new Date().getFullYear()
            return year
        }
    },
    methods: {
        printPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            const pdfOptions = {
                margin: [0.3, 0, 0.2, 0], //top, left, buttom, right
                filename: "statement.pdf",
                image: { type: "png", quality: 0.98 },
                html2canvas: { scale: 2 },
                pagebreak: { mode: "avoid-all" },
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get("pdf")
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        // pdf.setTextColor(150);
                        pdf.text(
                            [
                                "For Inquiries, Please Contact " +
                                insit_name +
                                ", Customer Service on " +
                                insit_tele +
                                "",
                                "or email us on " + insit_email + " " + insit_motto + "",
                                "Page " + i + " of " + totalPages,
                            ],
                            pdf.internal.pageSize.getWidth() / 2,
                            pdf.internal.pageSize.getHeight() - 0.6,
                            { align: "center" }
                        );
                    }
                    // Get the blob of the PDF
                    return pdf.output("blob");
                })
                .then((pdfBlob) => {
                    // Create a URL for the PDF blob
                    var pdfUrl = URL.createObjectURL(pdfBlob);

                    // Open the URL in a new tab
                    const printWindow = window.open(
                        pdfUrl,
                        "_blank",
                        "width=800,height=600"
                    );
                    printWindow.onload = () => {
                        printWindow.print();
                        URL.revokeObjectURL(pdfUrl);
                    };
                })
                .catch((error) => {
                    console.error("Error generating PDF:", error);
                });
        },
    }

}
</script>