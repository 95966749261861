<template>
    <div id="wrapper">
        <SidebarView :reports="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container">
                    <div class="card card-shape home-box">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-6">
                                    <h6 class="m-0 fw-bold text-green">
                                        TRIAL BALANCE
                                    </h6>
                                </div>
                                <div class="col-6 text-end">
                                    <b-form-select v-model="selected_num" @change="changePerPageNumber"
                                        :options="numbers_options"></b-form-select>
                                    <b-button @click="generateExcel"
                                        class="btn-sm me-2 ms-5 akkurate-green-btn akkurate-small">
                                        <font-awesome-icon :icon="['fas', 'file-excel']" />
                                    </b-button>
                                    <report-filter :form="form" @filterAction="filterAction"
                                        :filter="filter"></report-filter>
                                    <b-button @click="printPDF" class="btn-sm me-2 akkurate-green-btn akkurate-small">
                                        <font-awesome-icon :icon="['fas', 'book']" />
                                    </b-button>
                                    <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small">
                                        <font-awesome-icon :icon="['fas', 'download']" />
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row" ref="pdfContent">
                                <div class="col-12 col-md-12">
                                    <statement-institution :institution="institution"
                                        :title="'TRIAL BALANCE'"></statement-institution>
                                </div>
                                <hr class="mt-0 akkurate-danger" />
                                <div class="row mb-2">
                                    <div class="col-12 small">
                                        Branch(es) : {{ branch_list }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <h6 class="akkurate-dark">Account Details</h6>
                                        <hr class="w-100 mt-0" />
                                        <div class="row akkurate-small">
                                            <div class="col-md-6 fw-bold akkurate-gray">
                                                Opening Balances
                                            </div>
                                            <div class="col-md-6">
                                                {{ Number(opening_balances).toLocaleString() }}
                                            </div>
                                            <div class="col-md-6 fw-bold akkurate-gray">
                                                Total Debit
                                            </div>
                                            <div class="col-md-6">
                                                {{ Number(total_debit).toLocaleString() }}
                                            </div>
                                            <div class="col-md-6 fw-bold akkurate-gray">
                                                Total Credit
                                            </div>
                                            <div class="col-md-6">
                                                {{ Number(total_credit).toLocaleString() }}
                                            </div>
                                            <div class="col-md-6 fw-bold akkurate-gray">
                                                Closing Balance
                                            </div>
                                            <div class="col-md-6">
                                                {{ Number(closing_balance).toLocaleString() }}
                                            </div>
                                            <div class="col-md-6">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 text-end mt-auto">
                                        <div>Terms: <span class="fw-bold">{{ form.terms }}</span></div>
                                        <span class="mt-2">From: <strong>{{ form.start_date }}</strong> To: <strong>{{
                                            form.end_date }}</strong> </span>
                                    </div>
                                </div>
                                <div class="" v-if="isLoading">
                                    <beat-loader-component :isLoading="isLoading"></beat-loader-component>
                                </div>
                                <div class="row trans_details mt-3">
                                    <div class="col-12" v-for="(coaHead, index) in coa_heads" :key="coaHead.id">
                                        <div class="row">
                                            <div class="col-12">
                                                <h6 class="akkurate-dark">{{ coaHead.display_name }} (Code: {{
                                                    coaHead.code }})</h6>
                                            </div>
                                            <table class="table table-bordered table-striped table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th width="10%" scope="col">Glcode: </th>
                                                        <th width="60%" scope="col">COA Name</th>
                                                        <th width="10%" scope="col">Branch</th>
                                                        <th width="10%" scope="col">DR (GHS)</th>
                                                        <th width="10%" scope="col">CR (GHS)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in coaHead.subheads" :key="item.id">
                                                        <td>{{ item.code }}</td>
                                                        <td>{{ item.name }}</td>
                                                        <td>{{ item?.coabranch?.name }}</td>
                                                        <td>{{ displayNumber(item.adjusted_dr_amount) }}</td>
                                                        <td> {{ displayNumber(item.adjusted_cr_amount) }}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th width="10%" scope="col">TOTAL: </th>
                                                        <th width="10%" scope="col"></th>
                                                        <th width="10%" scope="col"></th>
                                                        <td width="10%">{{
                                                            displayNumber(Number(coaHead.total_dr_amount).toFixed(2)) }}
                                                        </td>
                                                        <td width="10%">{{
                                                            displayNumber(Number(coaHead.total_cr_amount).toFixed(2)) }}
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <table class="table table-bordered table-striped table-hover table-sm">
                                        <tfoot>
                                            <tr>
                                                <th width="10%" scope="col">TOTAL: </th>
                                                <th width="60%" scope="col">DIFFERENCE: GHS {{ Number(difference ?
                                                    difference.toFixed(2) : 0).toLocaleString() }}</th>
                                                <th width="10%" scope="col"></th>
                                                <th width="10%" scope="col">{{ Number(total_debit ?
                                                    total_debit.toFixed(2) :
                                                    0).toLocaleString() }}</th>
                                                <th width="10%" scope="col">{{ Number(total_credit ?
                                                    total_credit.toFixed(2)
                                                    :
                                                    0).toLocaleString() }}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import ApiService from "@/core/services/api.service";
import html2pdf from "html2pdf.js";
import config from './../../../../../../config.js'
import ReportFilter from "@/views/main/pages/reports/form/ReportFilter.vue";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ExcelJS from 'exceljs';
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        ReportFilter,
        BeatLoaderComponent,
        StatementInstitution
    },

    // props: ['data'],
    async created() {
        await this.getInsistution();
        this.getCoaTrialReport();
    },
    data() {
        return {
            isLoading: false,
            financial_year: null,
            form: {
                start_date: this.getBeginningOfYear(),
                end_date: new Date().toISOString().split('T')[0],
                branches: [],
                products: [],
                branch_options: [],
                product_options: [],
                users: [],
                users_options: [],
                status: [],
                coa: [],
                coa_options: [],
                terms: "Periodic",
                terms_options: ["YTD", "Periodic"],
                categories: [],
            },
            filter: {
                branches: true,
                products: false,
                users: false,
                status: false,
                condition: false,
                coa: false,
                terms: true,
                start_date: true,
                end_date: true,
            },
            frontImgUrl: "",
            backImgUrl: "",
            imageLoaded: false,
            institution: {
                name: "",
                address: "",
                postal: "",
                currency: "",
                motto: "",
                currency_code: "",
                logo: ""
            },
            opening_balances: 0,
            coa_heads: [],
            total_debit: 0,
            difference: 0,
            total_credit: 0,
            closing_balance: 0,
            fields: [{ key: 'code', label: 'Glcode' }, { key: 'name', label: 'COA Name' }, { key: 'dr_amount', label: 'DR' }, { key: 'cr_amount', label: 'CR' }],
            footer_fields: [{ key: 'coa_name', label: 'Totals' }, { key: 'dr', label: 'DR' }, { key: 'cr', label: 'CR' }],
            items: [],
            selected_num: "10",
            numbers_options: [10, 50, 100, 500, "All"],
            branch_list: ""

        }
    },
    computed: {
        getFullName() {
            // const first_name = this.details?.account?.customer?.first_name != undefined ? this.details?.account?.customer?.first_name : "";
            // const middle_name = this.details?.account?.customer?.middle_name != undefined ? this.details?.account?.customer?.middle_name : "";
            // const last_name = this.details?.account?.customer?.last_name != undefined ? this.details?.account?.customer?.last_name : "";
            // const first_name = this.data.customer.first_name != undefined ? this.data.customer.first_name : "";
            // const middle_name = this.data.customer.middle_name != undefined ? this.data.customer.middle_name : "";
            // const last_name = this.data.customer.last_name != undefined ? this.data.customer.last_name : "";
            // return first_name + ' ' + middle_name + ' ' + last_name;

        },
    },
    methods: {
        async getCoaTrialReport() {

            this.isLoading = true;
            //  this.search = "processing";
            //  this.isSearching = true;
            await ApiService.post("/reports/financials/trial", this.form).then((response) => {
                this.coa_heads = response.data.coa_heads;
                this.opening_balances = response.data.opening_balances;
                this.total_debit = response.data.total_debit;
                this.total_credit = response.data.total_credit;
                this.difference = response.data.difference;
                this.closing_balance = response.data.closing_balance;
                this.form.branch_options = response.data.branches;

                this.branch_list = this.form.branches.map(branch => branch.name).join(", ");

            }).catch((error) => {
            }).finally(() => {
                this.isLoading = false;
            });
        },
        // handleImageLoad() {
        //     this.imageLoaded = true;
        //     this.frontImgUrl = 'https://ggbccuapi.akkuratebank.com/' + this.details.account?.customer?.gh_card_front_path;
        //     this.backImgUrl = 'https://ggbccuapi.akkuratebank.com/' + this.details.account?.customer?.gh_card_back_path;
        // },
        // exportToPDF() {
        //     const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
        //     const pdfOptions = {
        //         margin: 10,
        //         filename: 'TrialBalance_' + this.getFormattedDateTime() + '.pdf',
        //         image: { type: 'jpeg', quality: 0.98 },
        //         html2canvas: { scale: 2 },
        //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        //     };
        //     // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

        //     // });
        //     html2pdf().from(content).set(pdfOptions).save();
        // },
        // generatePDF() {
        //     // Define the element to convert to a PDF
        //     const element = this.$refs.pdfContent; // Replace with your element's ID or selector

        //     // Define the options for html2pdf
        //     const pdfOptions = {
        //         margin: 10,
        //         filename: 'document.pdf',
        //         image: { type: 'jpeg', quality: 0.98 },
        //         html2canvas: { scale: 2 },
        //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        //         debug: true, // Enable debugging
        //     };
        //     html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
        //         const blob = new Blob([pdf], { type: 'application/pdf' });
        //         const url = URL.createObjectURL(blob);
        //         const printWindow = window.open(url, '_blank', 'width=800,height=600');
        //         printWindow.onload = () => {
        //             printWindow.print();
        //             URL.revokeObjectURL(url);
        //         };
        //     });
        // },
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                this.institution = response.data.data;
                this.institution.logo = config.base_url + "/" + this.institution.logo;
                this.financial_year = response.data.financial_year;
            }).catch((error) => {
            })
        },
        async generateExcel() {
            const fileUrl = '/REPORT_TEMPLATE_NEW.xlsx'; // Path to the file in the public folder
            const imageUrl = '/default_logo.png';  // Path to your image file

            // Institution details
            const institutionName = this.institution.name;
            const institutionMotto = this.institution.motto;
            const institutionAddress = this.institution.address;
            const institutionPhone = this.institution.telephone;
            const institutionEmail = this.institution.email;
            const date = 'FROM: ' + this.form.start_date + ' TO: ' + this.form.end_date
            const terms = 'TERMS:  ' + this.form.terms

            try {
                // Fetch the Excel file from the public URL
                const response = await fetch(fileUrl);
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                const arrayBuffer = await response.arrayBuffer();

                // Load the file into ExcelJS
                const wb = new ExcelJS.Workbook();
                await wb.xlsx.load(arrayBuffer);

                // Access the first sheet
                const ws = wb.getWorksheet(1); // Get the first worksheet

                // Add Image to the top of the sheet
                const imageResponse = await fetch(imageUrl);
                const imageBuffer = await imageResponse.arrayBuffer();
                const imageId = wb.addImage({
                    buffer: imageBuffer,
                    extension: 'png',  // Adjust this if you're using a different image type (e.g., jpg)
                });

                // Insert the image at the top-left corner (row 1, column 1)
                ws.addImage(imageId, {
                    tl: { col: 0, row: 0 },  // Top-left corner (adjust this as needed)
                    ext: { width: 300, height: 200 },  // Set the image size (adjust width/height as needed)
                });

                // Insert institution details next to the image (starting at row 1, col 5)
                const institutionDetailsStartCol = 5; // Column where text starts after image
                const institutionDetailsRow = 1;  // Row for institution details

                // Add the institution name, address, and phone number
                ws.getCell(`F${institutionDetailsRow}`).value = institutionName;
                ws.getCell(`F${institutionDetailsRow}`).font = { bold: true, size: 14 }; // Bold and bigger font for name

                ws.getCell(`F${institutionDetailsRow + 1}`).value = institutionMotto;
                ws.getCell(`F${institutionDetailsRow + 1}`).font = { size: 12 };  // Regular font size for address

                ws.getCell(`F${institutionDetailsRow + 2}`).value = institutionAddress;
                ws.getCell(`F${institutionDetailsRow + 2}`).font = { size: 12 };  // Regular font size for phone

                ws.getCell(`F${institutionDetailsRow + 3}`).value = institutionPhone;
                ws.getCell(`F${institutionDetailsRow + 3}`).font = { size: 12 };  // Regular font size for phone

                ws.getCell(`F${institutionDetailsRow + 4}`).value = institutionEmail;
                ws.getCell(`F${institutionDetailsRow + 4}`).font = { size: 12 };  // Regular font size for phone

                ws.getCell(`F${institutionDetailsRow + 6}`).value = date;
                ws.getCell(`F${institutionDetailsRow + 6}`).font = { size: 12 };  // Regular font size for phone

                ws.getCell(`F${institutionDetailsRow + 7}`).value = terms;
                ws.getCell(`F${institutionDetailsRow + 7}`).font = { size: 12 };  // Regular font size for phone

                // Optionally adjust the column width if the text is long
                ws.getColumn('E').width = 100;  // Adjust the width of column D (if necessary)


                // Define header row
                const header = [
                    "code",
                    "name",
                    "dr_amount",
                    "cr_amount"
                ];

                // Add header row starting from row 13 (or the next row after the image and details)
                const startingRow = institutionDetailsRow + 10;  // Row 4 after institution details
                let headerRow = ws.getRow(startingRow);
                headerRow.values = header;
                headerRow.font = { bold: true };
                headerRow.commit(); // Commit the row to the worksheet

                // Add individual data rows
                let currentRow = startingRow + 1;

                let totalDrAmount = 0;
                let totalCrAmount = 0;

                // Process each item
                this.coa_heads.forEach(item => {
                    const row = ws.getRow(currentRow);

                    // Add the item values for "code", "name", "dr_amount", "cr_amount"
                    row.values = [
                        item.code, // "code" goes into the first column
                        item.display_name, // "name" goes into the second column
                        item.total_dr_amount || 0, // "dr_amount" goes into the third column
                        item.total_cr_amount || 0 // "cr_amount" goes into the fourth column
                    ];

                    // Accumulate the totals
                    totalDrAmount += item.total_dr_amount || 0;
                    totalCrAmount += item.total_cr_amount || 0;

                    // If there are subheads, add their data as additional rows below this one
                    if (item.subheads && Array.isArray(item.subheads)) {
                        item.subheads.forEach(subhead => {
                            const subheadRow = ws.getRow(currentRow + 1);
                            subheadRow.values = [
                                subhead.code,  // "code" of subhead
                                subhead.name,  // "name" of subhead
                                subhead.adjusted_dr_amount || 0,  // "dr_amount" for subhead
                                subhead.adjusted_cr_amount || 0   // "cr_amount" for subhead
                            ];
                            subheadRow.commit();  // Commit the subhead row
                            currentRow++;  // Increment row for next subhead

                            // Accumulate subhead totals
                            totalDrAmount += subhead.adjusted_dr_amount || 0;
                            totalCrAmount += subhead.adjusted_cr_amount || 0;
                        });
                    }

                    // Make the main row bold
                    row.font = { bold: true };

                    // Commit the main row
                    row.commit();
                    currentRow++;  // Increment row for the next item
                });

                // Add total row at the end of the table
                const totalRow = ws.getRow(currentRow);
                totalRow.getCell(2).value = "Total";  // Column B - Text 'Total'
                totalRow.getCell(3).value = totalDrAmount;  // Column C - Total of dr_amount
                totalRow.getCell(4).value = totalCrAmount;  // Column D - Total of cr_amount
                totalRow.font = { bold: true };  // Make the total row bold
                totalRow.commit();  // Commit the total row to the worksheet

                // Write the modified workbook to a buffer
                const buffer = await wb.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create a download link and simulate a click to download the file
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'trialBals.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } catch (err) {
                console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
            }
        },


        downloadPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            const pdfOptions = {
                margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
                filename: "statement.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                pagebreak: { mode: "avoid-all" },
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get("pdf")
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        pdf.text(
                            [
                                "For Inquiries, Please Contact " +
                                insit_name +
                                ", Customer Service on " +
                                insit_tele +
                                "",
                                "or email us on " + insit_email + " " + insit_motto + "",
                                "Page " + i + " of " + totalPages,
                            ],
                            pdf.internal.pageSize.getWidth() / 2,
                            pdf.internal.pageSize.getHeight() - 0.6,
                            { align: "center" }
                        );
                    }
                })
                .save();
        },
        printPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            const pdfOptions = {
                margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
                filename: "statement.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                pagebreak: { mode: "avoid-all" },
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get("pdf")
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        pdf.text(
                            [
                                "For Inquiries, Please Contact " +
                                insit_name +
                                ", Customer Service on " +
                                insit_tele +
                                "",
                                "or email us on " + insit_email + " " + insit_motto + "",
                                "Page " + i + " of " + totalPages,
                            ],
                            pdf.internal.pageSize.getWidth() / 2,
                            pdf.internal.pageSize.getHeight() - 0.6,
                            { align: "center" }
                        );
                    }
                    // Get the blob of the PDF
                    return pdf.output("blob");
                })
                .then((pdfBlob) => {
                    // Create a URL for the PDF blob
                    var pdfUrl = URL.createObjectURL(pdfBlob);

                    // Open the URL in a new tab
                    const printWindow = window.open(
                        pdfUrl,
                        "_blank",
                        "width=800,height=600"
                    );
                    printWindow.onload = () => {
                        printWindow.print();
                        URL.revokeObjectURL(pdfUrl);
                    };
                })
                .catch((error) => {
                    console.error("Error generating PDF:", error);
                });
        },

        filterAction() {
            this.getCoaTrialReport();
        },
        changePerPageNumber(data) {
            if (data == "All") {
                this.perPage = this.items.length;
                this.getCustomerProduct();
            } else {
                this.perPage = data;
                this.getCustomerProduct();
            }
        },
    }


}

</script>