<template>
    <div>
        <div class="row">
            <div class="col-12 text-end">
                <a @click.prevent="printPDF" class="akkurate-green custom-pointer"><font-awesome-icon class="me-2"
                        :icon="['fas', 'book']" /></a>
            </div>
        </div>
        <div ref="pdfContent" class="px-4">
            <div class="row mt-3">
                <div class="col-md-4 col-4 d-flex align-items-center flex-column bd-highlight">
                    <img class="img-fluid img-visibility statement-logo"
                        :src="require('@/assets/img/default_logo.png')">
                    <div class="mt-2 akkurate-green fw-bold mt-auto p-2 bd-highlight">LOAN FORM</div>
                </div>
                <div class="d-flex align-items-end flex-column bd-highlight col-md-8 col-8 text-end">
                    <p class="fw-bold text-uppercase mb-0">{{ institution?.name }}</p>
                    <p class="fw-bold">{{ institution?.motto }}</p>
                    <p class="mb-0 akkurate-small">{{ institution?.postal }}</p>
                    <p class="mb-0 akkurate-small">{{ institution?.telephone }}</p>
                    <p class="akkurate-small">{{ institution?.email }}</p>
                    <p class="fw-bold text-end mt-auto p-2 bd-highlight text-uppercase">{{ new
                        Intl.DateTimeFormat('en-GB', {
                            day: 'numeric', month: 'long', year: 'numeric'
                        }).format(Date.now()) }}</p>
                </div>
            </div>

            <div class="row table_text_size akktrate-dark">
                <div>
                    <img :src="loan.account?.customer?.passport_pic_path"
                        style="height:200px; position:absolute; right: 10px; padding-top:26px" class="img-fluid"
                        alt="user-profile" />
                </div>
                <b-table-simple small striped bordered outlined style="border-color: #2a454e !important;">
                    <b-thead head-variant="dark">
                        <b-tr class="text-start">
                            <b-th colspan="3">ACCOUNT DETAILS (APPLICANT'S PROFILE)</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="text-start">
                            <b-td width="300px" class="fw-bold">Account Name</b-td>
                            <b-td>{{ this.loan.account.customer?.first_name }} {{ this.loan.account.customer?.last_name
                                }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Account Number</b-td>
                            <b-td>{{ this.loan.account?.account_number }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Loan Number</b-td>
                            <b-td>{{ this.loan?.loan_number }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Married Status</b-td>
                            <b-td>{{ this.loan.account.customer?.marital_status }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Nationality</b-td>
                            <b-td>GHANAIAN</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Date of Birth</b-td>
                            <b-td>{{ this.loan.account.customer?.date_of_birth }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Name Of Spouse</b-td>
                            <b-td></b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Gender</b-td>
                            <b-td>{{ this.loan.account.customer?.gender }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Residential Status</b-td>
                            <b-td></b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">No. Of Dependants</b-td>
                            <b-td></b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Mobile No</b-td>
                            <b-td>{{ this.loan.account.customer?.mobile }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Form Of ID</b-td>
                            <b-td></b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">ID No.</b-td>
                            <b-td>{{ this.loan.account.customer?.ghana_card_number }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Other Phone Nos.</b-td>
                            <b-td></b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Residential Address</b-td>
                            <b-td>{{ this.loan.account.customer?.postal_address }}</b-td>
                        </b-tr>
                    </b-tbody>


                </b-table-simple>
                <b-table-simple small striped bordered outlined style="border-color: #2a454e !important;" class="mt-2">
                    <b-thead head-variant="dark">
                        <b-tr class="text-start">
                            <b-th colspan="4">EMPLOYMENT DETAILS</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Nature Of Employment</b-td>
                            <b-td>{{ this.loan.account.customer?.employee_position }}</b-td>
                            <b-td class="fw-bold">Profession/Occupation</b-td>
                            <b-td>{{ this.loan.account.customer?.occupation }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Place Of Work</b-td>
                            <b-td>{{ this.loan.account.customer?.employee_address }}</b-td>
                            <b-td class="fw-bold">Rank</b-td>
                            <b-td>{{ this.loan.account.customer?.employee_position }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Work Address</b-td>
                            <b-td colspan="3">{{ this.loan.account.customer?.employee_address }}</b-td>
                            <!-- <b-td class="fw-bold">Rank</b-td>
                        <b-td>{{ this.loan.account.customer?.employee_position}}</b-td> -->
                        </b-tr>
                    </b-tbody>

                </b-table-simple>
                <b-table-simple small striped bordered outlined style="border-color: #2a454e !important;" class="mt-2">
                    <b-thead head-variant="dark">
                        <b-tr class="text-start">
                            <b-th colspan="4">LOAN DETAILS</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Type Of Loan</b-td>
                            <b-td>{{ this.loan?.loan_product?.name }}</b-td>
                            <b-td class="fw-bold">Loan Status</b-td>
                            <b-td :variant="statusBadge(this.loan?.status)">{{ this.loan.status }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Branch Office</b-td>
                            <b-td>{{ this.loan.account.customer?.branch?.name }}</b-td>
                            <b-td class="fw-bold">Interest Method</b-td>
                            <b-td>{{ this.loan.interest_method }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Initial Principal</b-td>
                            <b-td>{{ institution?.currency_code }} {{ this.loan?.principal_requested }}</b-td>
                            <b-td class="fw-bold">Interest Rate </b-td>
                            <b-td>{{ this.loan.interest_rate }} {{ this.loan.interest_rate_frequency }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Approved Loan</b-td>
                            <b-td>{{ institution?.currency_code }} {{ this.loan?.principal_amount }}</b-td>
                            <b-td class="fw-bold">Total Interest</b-td>
                            <b-td>{{ institution?.currency_code }} {{ this.loan?.interest_amount }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Loan Tenor (In Months)</b-td>
                            <b-td>{{ this.loan?.tenor }}</b-td>
                            <b-td class="fw-bold">Repayment Plan</b-td>
                            <b-td>{{ this.loan.repayment_cycle }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Loan Processing Fee</b-td>
                            <b-td>{{ institution?.currency_code }} {{ this.loan?.lpf }}</b-td>
                            <b-td class="fw-bold">Loan Insurance</b-td>
                            <b-td>{{ this.loan.loan_product?.insurance }}</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Purpose Of Loan</b-td>
                            <b-td colspan="3">{{ this.loan?.purpose }}</b-td>
                        </b-tr>

                    </b-tbody>
                </b-table-simple>
                <b-table-simple small striped bordered outlined style="border-color: #2a454e !important;" class="mt-2">
                    <b-thead head-variant="dark">
                        <b-tr class="text-start">
                            <b-th colspan="4">AGREED TERMS AND CONDITIONS</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="text-start">
                            <b-td colspan="3" class="fw-bold">Finance repayment from my savings/current account when an
                                instalment is due ?</b-td>
                            <b-td>No</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td colspan="3" class="fw-bold">Penalty shall be computed on this loan automatically in
                                case
                                of defualt.Do you agree ?</b-td>
                            <b-td>No</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td class="fw-bold">Monthly Penal Int. Rate</b-td>
                            <b-td>0%</b-td>
                            <b-td class="fw-bold">Grace Period (Days)</b-td>
                            <b-td>0</b-td>
                        </b-tr>
                        <b-tr class="text-start">
                            <b-td colspan="3" class="fw-bold">Is this loan payable by deductions from salary ?</b-td>
                            <b-td>No</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-table-simple small striped bordered outlined style="border-color: #2a454e !important;" class="mt-2">
                    <b-thead head-variant="dark">
                        <b-tr class="text-start">
                            <b-th colspan="3">LOAN APPLICANTS ACCOUNT INFORMATION</b-th>
                        </b-tr>
                    </b-thead>
                    <b-thead>
                        <b-tr class="text-start">
                            <b-th>Account Type</b-th>
                            <b-th>Available Balance</b-th>
                            <b-th>Total Lien On Acct.</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody v-for="(item, index) in loan?.savings_accounts" :key="'savings-' + index">
                        <b-tr class="text-start">
                            <b-td class="fw-bold">{{ item.account_type?.name }}</b-td>
                            <b-td>{{ institution?.currency_code }} {{ item?.account_balance }}</b-td>
                            <b-td>{{ institution?.currency_code }} 0.00</b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-for="(item, index) in loan?.shares_accounts" :key="'shares-' + index">
                        <b-tr class="text-start">
                            <b-td class="fw-bold">{{ item.account_type?.name }}</b-td>
                            <b-td>{{ institution?.currency_code }} {{ item?.account_balance }}</b-td>
                            <b-td>{{ institution?.currency_code }} 0.00</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <div class="row mt-5">
                    <div class="col-6">
                        <div class="text-start">____________________________________</div>
                        <div class="text-start">Credit Officer Sign.</div>
                    </div>
                    <div class="col-6">
                        <div class="text-start">____________________________________</div>
                        <div class="text-start">Customer's Sign.</div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-6 text-center">
                        <div class="text-start">____________________________________</div>
                        <div class="text-start">Branch Manager's Sign.</div>
                    </div>
                    <div class="col-6 text-center">
                        <div class="text-start">____________________________________</div>
                        <div class="text-start">CEO/Manager's Sign.</div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import html2pdf from "html2pdf.js";

export default {
    props: {
        institution: Object,
        loan: Object
    },
    methods: {
        statusBadge(item) {
            if (item === 'REJECTED') {
                return 'danger'
            } else if (item === 'RUNNING') {
                return 'success'
            } else if (item === 'PENDING') {
                return 'warning'
            } else if (item === 'REVERSED') {
                return 'secondary'
            } else if (item === 'EXPIRED') {
                return 'danger'
            } else {
                return 'secondary'
            }
        },
        // onImageLoaded() {
        //     console.log('Image is loaded!');
        //     this.printPDF();
        // },
        printPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            const pdfOptions = {
                margin: [0, 0, 0, 0], //top, left, buttom, right
                filename: "statement.pdf",
                image: { type: "png", quality: 0.98 },
                html2canvas: { scale: 2 },
                pagebreak: { mode: "avoid-all" },
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get("pdf")
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        // pdf.setTextColor(150);
                        pdf.text(
                            [
                                "For Inquiries, Please Contact " +
                                insit_name +
                                ", Customer Service on " +
                                insit_tele +
                                "",
                                "or email us on " + insit_email + " " + insit_motto + "",
                                "Page " + i + " of " + totalPages,
                            ],
                            pdf.internal.pageSize.getWidth() / 2,
                            pdf.internal.pageSize.getHeight() - 0.6,
                            { align: "center" }
                        );
                    }
                    // Get the blob of the PDF
                    return pdf.output("blob");
                })
                .then((pdfBlob) => {
                    // Create a URL for the PDF blob
                    var pdfUrl = URL.createObjectURL(pdfBlob);

                    // Open the URL in a new tab
                    const printWindow = window.open(
                        pdfUrl,
                        "_blank",
                        "width=800,height=600"
                    );
                    printWindow.onload = () => {
                        printWindow.print();
                        URL.revokeObjectURL(pdfUrl);
                    };
                })
                .catch((error) => {
                    console.error("Error generating PDF:", error);
                });
        },

    }

}

</script>