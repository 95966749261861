<template>
    <div class="row">
        <div class="col-md-6">
            <b-form-group label-for="surname" class="mb-3">
                <template #label>
                    <label class="akkurate-dark" for="surname">Surname<star></star></label>
                </template>
                <b-form-input id="surname" v-model="form.surname" name="surname" class="mt-2 field-container fields"
                    type="text" required></b-form-input>
                <!-- <b-form-invalid-feedback >
                               The Surname field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.surname">
                                   {{ form.errors.surname[0] }}
                           </errors> -->
            </b-form-group>
        </div>
        <div class="col-md-6">
            <b-form-group label-for="othername" class="mb-3">
                <template #label>
                    <label class="akkurate-dark" for="othername">Other Name(s) <star></star></label>
                </template>
                <b-form-input id="othername" v-model="form.first_name" name="othername"
                    class="mt-2 field-container fields" type="text" required></b-form-input>
                <!-- <b-form-invalid-feedback >
                               The Surname field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.othername">
                                   {{ form.errors.othername[0] }}
                           </errors> -->
            </b-form-group>
        </div>

        <div class="col-md-6">
            <b-form-group label-for="email" class="mb-3">
                <template #label>
                    <label class="akkurate-dark" for="email">Email </label>
                </template>
                <b-form-input id="email" v-model="form.email" name="email" class="mt-2 field-container fields"
                    type="email" required></b-form-input>
                <!-- <b-form-invalid-feedback >
                               The Email field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.email">
                                   {{ form.errors.email[0] }}
                           </errors> -->
            </b-form-group>
        </div>
        <div class="col-md-6">
            <b-form-group label-for="phone" class="mb-3">
                <template #label>
                    <label class="akkurate-dark" for="phone">Phone Number <star></star></label>
                </template>
                <b-form-input id="phone" v-model="form.phone_number" name="phone" class="mt-2 field-container fields"
                    type="number" required></b-form-input>
                <!-- <b-form-invalid-feedback >
                               The Phone number field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.phone">
                                   {{ form.errors.phone[0] }}
                           </errors> -->
            </b-form-group>
        </div>
        <div class="col-md-6">
            <b-form-group label-for="relation" class="mb-3">
                <template #label>
                    <label class="akkurate-dark" for="relation">Relation <star></star></label>
                </template>
                <b-form-input id="relation" v-model="form.relation" name="relation" class="mt-2 field-container fields"
                    type="text" required></b-form-input>
                <!-- <b-form-invalid-feedback >
                               The Relations field is required
                           </b-form-invalid-feedback> -->
                           <errors v-if="form.errors?.relation">
                                   {{ form.errors?.relation[0] }}
                           </errors>
            </b-form-group>
        </div>
        <div class="col-md-6">
            <b-form-group label-for="percentage_share" class="mb-3">
                <template #label>
                    <label class="akkurate-dark" for="percentage_share">Percentage Shares</label>
                </template>
                <b-form-input id="percentage_share" v-model="form.percentage_share" name="percentage_share" class="mt-2 field-container fields"
                    type="text" required></b-form-input>
                <!-- <b-form-invalid-feedback >
                               The Relations field is required
                           </b-form-invalid-feedback> -->
                           <errors v-if="form.errors?.percentage_share">
                                   {{ form.errors?.percentage_share[0] }}
                           </errors>
            </b-form-group>
        </div>
        <div class="col-12 col-md-6">
            <b-form-group label-for="address" class="mb-2">
                <template #label>
                    <label class="akkurate-dark" for="address">Address </label>
                </template>
                <b-form-textarea class="mt-2 fields" id="address" name="address" v-model="form.address"
                    placeholder="Enter Address..."></b-form-textarea>
                <!-- <b-form-invalid-feedback >
                               The Address field is required
                           </b-form-invalid-feedback>
                           <errors v-if="form.errors.address">
                                   {{ form.errors.address[0] }}
                           </errors> -->
            </b-form-group>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
    props: ['form'],
    components: {
        Multiselect,
    },
    // data() {
    //     return {
    //         form: {
    //             surname: "",
    //             othername: "",
    //             email: "",
    //             phone: "",
    //             date_of_birth:"",
    //             relation: "",
    //             address: "",
    //             errors: [],
    //         },
    //         isSaving: false,
    //     }
    // },
    // methods: {
    //     validateState(ref) {
    //         if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
    //             return !this.veeErrors.has(ref);
    //         }
    //         return null;
    //     },
    //     // resetModal() {
    //     //     this.form.surname = ''
    //     //     this.form.othername = ''
    //     //     this.form.email = ''
    //     //     this.form.phone = ''
    //     //     this.form.date_of_birth = ''
    //     //     this.form.relation = ''
    //     //     this.form.address = ''
    //     // },
    //     handleOk(bvModalEvent) {
    //         // Prevent modal from closing
    //         bvModalEvent.preventDefault();
    //         // Trigger submit handler
    //         this.handleSubmit()
    //     },
    //     handleSubmit() {
    //         // Exit when the form isn't valid
    //         this.$validator.validateAll().then(async result => {
    //             if (!result) {
    //                 return;
    //             }
    //             this.isSaving = true;
    //             this.$Progress.start();
    //             this.form.errors = [];
    //             this.form
    //             await ApiService.post("/customers/beneficiaries/store" + this.form)
    //                 .then((response) => {
    //                     this.isSaving = false;
    //                     this.$Progress.finish();
    //                     console.log(response);
    //                     toast.fire({
    //                         icon: "success",
    //                         title: response.data.message,
    //                     });

    //                     this.$emit("next_of_kin_edited", response.data.data);

    //                     // Hide Modal Manually
    //                     this.$nextTick(() => {
    //                         this.$bvModal.hide('edit-nextofkin')
    //                     })

    //                     // window.location.reload()
    //                     //   this.isSaving = false;
    //                     //   this.form.phone_number = "";
    //                     //   this.form.email = "";
    //                     //   this.form.selected_payment_method = "";
    //                 })
    //                 .catch((error) => {
    //                     this.isSaving = false;
    //                     this.$Progress.fail();
    //                     this.form.errors = [];
    //                     if (error.response.data.errors) {
    //                         console.log(error.response.data.errors)
    //                         this.form.errors = error.response.data.errors;
    //                     }
    //                     console.log(error.response.data)
    //                 });

    //         });
    //         // Hide the modal manually

    //     }
    // }
}
</script>