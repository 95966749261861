<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">
          <div class="row mb-3">
            <!-- <div class="col">
                <label class="akkurate-dark small" for="branch">Branch</label>
                <b-form-group
                  id="branch"
                  label-for="branch"
                  class="akkurate-dark">
                  <b-form-input
                    id="branch"
                    class="mt-2 field-container fields"
                    type="text"
                    v-model="filter.branch"
                    placeholder="Branch">
                  </b-form-input>
                </b-form-group>
              </div> -->

            <!-- <div class="col">
                <label class="akkurate-dark small" for="start_date">Start Date</label>
                <b-form-group
                  id="start_date"
                  label-for="start_date"
                  class="akkurate-dark">
                  <b-form-input
                    id="start_date"
                    class="mt-2 field-container fields"
                    type="date"
                    v-model="filter.start_date"
                    placeholder="Start Date">
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <label class="akkurate-dark small" for="end_date">End Date</label>
                <b-form-group
                  id="end_date"
                  label-for="end_date"
                  class="akkurate-dark">
                  <b-form-input
                    id="end_date"
                    class="mt-2 field-container fields"
                    type="date"
                    v-model="filter.end_date"
                    placeholder="End Date">
                  </b-form-input>
                </b-form-group>
              </div> -->
            <!-- <div class="col mt-auto">
                <b-button @click="filterTable" :disabled="isSearching" class="w-100 akkurate-green-btn">{{ search }}</b-button>
              </div> -->
          </div>
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">DETAILED CUSTOMER RECEIPTS REPORT</h6>
                </div>
                <div class="col-6 text-end">
                  <b-form-select v-model="selected_num" @change="changePerPageNumber"
                    :options="numbers_options"></b-form-select>
                  <b-button @click="generateExcel" class="btn-sm me-2 ms-5 akkurate-green-btn akkurate-small">
                    <font-awesome-icon :icon="['fas', 'file-excel']" />
                  </b-button>
                  <report-filter :form="form" @filterAction="getRepayment" :filter="filter"
                    :current_financial_year="financial_year">
                  </report-filter>
                  <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon
                      :icon="['fas', 'book']" /></b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon
                      :icon="['fas', 'download']" /></b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-12">
                  <statement-institution :institution="institution" :current_date="false"
                    :title="'DETAILED CUSTOMER RECEIPTS REPORT'"></statement-institution>
                </div>
                <hr />
              </div>
              <div class="row">
                <div class="col-12 mb-3" style="text-align: right">
                  <div>Terms: <span class="fw-bold">{{ form.terms }}</span></div>
                  <span class="mt-2">From: <strong>{{ form.start_date }}</strong> To: <strong>{{
                    form.end_date }}</strong> </span>
                </div>
              </div>
              <div class="row" ref="pdfContent">

                <div class="col-12 col-md-12 trans_details">
                  <b-table :items="items" :fields="fields" striped responsive="sm" :per-page="perPage"
                    :busy.sync="isBusy" :current-page="currentPage" small>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template #cell(index)="data">
                      {{ ((currentPage - 1) * selected_num) + (data.index + 1) }}
                    </template>
                    <template #cell(trans_amount)="data">
                      {{ displayNumber(data.item.trans_amount) }}
                    </template>
                    <template #cell(fullname)="data">
                      {{ data.item.customer?.first_name + ' ' + data.item.customer?.last_name }}
                    </template>
                    <template #cell(affected_coa)="data">
                      {{ data.item.coa_transaction_debit?.coasubhead?.code + " - "
                        + data.item.coa_transaction_debit?.coasubhead?.name }}
                    </template>
                    <template #custom-foot>
                      <b-tr>
                        <b-th colspan="6"></b-th>
                        <b-th>Total</b-th>
                        <b-th>{{ displayNumber(total_trans_amount) }}</b-th>

                        <b-th></b-th>
                      </b-tr>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-end">
                  <b-pagination class="text-end" v-model="currentPage" :total-rows="rows"
                    :per-page="perPage"></b-pagination>
                  <!-- <p class="mt-3">Current Page: {{ currentPage }}</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import html2pdf from "html2pdf.js";
import ReportFilter from "@/views/main/pages/reports/form/ReportFilter.vue";
import ExcelJS from 'exceljs';
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter
  },
  data() {
    return {
      financial_year: null,
      filter: {
        branches: false,
        products: false,
        users: false,
        status: false,
        condition: false,
        coa: false,
        terms: false,
        start_date:true,
        end_date:true,
      },
      form: {
        start_date: this.getOneMonthEailer(),
        end_date: new Date().toISOString().substr(0, 10),
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "Periodic",
        terms_options: ["YTD", "Periodic"],
        categories: [],
      },
      search: "Search",
      isBusy: false,
      isSearching: false,
      selected_num: 20,
      numbers_options: [10, 20, 50, 100, 500],
      isLoadingInstitution: false,
      institution: "",
      fields: [
        {
          key: "index",
          label: "S/N",
        },
        {
          key: "fullname",
          label: "Customer",
        },
        {
          key: "customer.external_code",
          label: "Old Cust. Code",
        },
        {
          key: "customer.code",
          label: "New Cust. Code",
        },
        {
          key: "trans_date",
          label: "Trans. Date",
        },
        {
          key: "transaction_type",
          label: "Receipt Type",
        },
        {
          key: "account.account_type.name",
          label: "A/C Tyoe",
        },
        {
          key: "trans_amount",
          label: "Amount",
        },
        {
          key: "code",
          label: "Ref. #",
        },
        {
          key: "affected_coa",
          label: "Affected COA",
        },

      ],
      perPage: 20,
      currentPage: 1,
      items: [],
      sumPrincipalPaid: 0,
      sumInterestPaid: 0,
      sumPrincipalBalance: 0,
      sumInterestBalance: 0,
      sumTotalBalance: 0,
      total_trans_amount: 0,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },

  created() {
    //set start date to 3 months ago
    var date = new Date();
    var lastMonth = new Date(date.setMonth(date.getMonth() - 3));
    this.filter.start_date = lastMonth.toISOString().split("T")[0];
    this.filter.end_date = new Date().toISOString().split("T")[0];

    this.getInstitution();
    this.getRepayment();
  },
  methods: {
    async getRepayment() {
      this.items = [];
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.post("/reports/financials/receipts/customers", this.filter)
        .then((response) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          var res = response.data?.data;
          // this.sumPrincipalPaid = response.data?.principal_paid_total
          // this.sumInterestPaid = response.data?.interest_paid_total
          // this.sumPrincipalBalance = response.data?.principal_balance_total
          // this.sumInterestBalance = response.data?.interest_balance_total
          // this.sumTotalBalance = response.data?.total_balance_due
          this.total_trans_amount = response.data?.total_amount

          this.items = res
        })
        .catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          console.log(error);
        });
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      // console.log("here");
      const pdfOptions = {
        margin: [0.5, 0.2, 0.8, 0.2], //top, left, buttom, right
        filename: "statement.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: "avoid-all" },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      html2pdf()
        .from(content)
        .set(pdfOptions)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    filterTable() {
      this.getRepayment();
    },
    changePerPageNumber(data) {
      this.perPage = data;
      this.getRepayment();
    },
    async generateExcel() {
      const fileUrl = '/REPORT_TEMPLATE_NEW.xlsx'; // Path to the file in the public folder
      const imageUrl = '/default_logo.png';  // Path to your image file


      // Institution details
      const institutionName = this.institution.name;
      const institutionMotto = this.institution.motto;
      const institutionAddress = this.institution.address;
      const institutionPhone = this.institution.telephone;
      const institutionEmail = this.institution.email;
      const date = 'FROM: ' + this.form.start_date + ' TO: ' + this.form.end_date
      const terms = 'TERMS:  ' + this.form.terms

      try {
        // Fetch the Excel file from the public URL
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();

        // Load the file into ExcelJS
        const wb = new ExcelJS.Workbook();
        await wb.xlsx.load(arrayBuffer);

        // Access the first sheet
        const ws = wb.getWorksheet(1); // Get the first worksheet

        // Add Image to the top of the sheet
        const imageResponse = await fetch(imageUrl);
        const imageBuffer = await imageResponse.arrayBuffer();
        const imageId = wb.addImage({
          buffer: imageBuffer,
          extension: 'png',  // Adjust this if you're using a different image type (e.g., jpg)
        });

        // Insert the image at the top-left corner (row 1, column 1)
        ws.addImage(imageId, {
          tl: { col: 0, row: 0 },  // Top-left corner (adjust this as needed)
          ext: { width: 300, height: 200 },  // Set the image size (adjust width/height as needed)
        });

        // Insert institution details next to the image (starting at row 1, col 5)
        const institutionDetailsStartCol = 5; // Column where text starts after image
        const institutionDetailsRow = 1;  // Row for institution details

        // Add the institution name, address, and phone number
        ws.getCell(`L${institutionDetailsRow}`).value = institutionName;
        ws.getCell(`L${institutionDetailsRow}`).font = { bold: true, size: 14 }; // Bold and bigger font for name

        ws.getCell(`L${institutionDetailsRow + 1}`).value = institutionAddress;
        ws.getCell(`L${institutionDetailsRow + 1}`).font = { size: 12 };  // Regular font size for address

        ws.getCell(`L${institutionDetailsRow + 2}`).value = institutionMotto;
        ws.getCell(`L${institutionDetailsRow + 2}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 3}`).value = institutionPhone;
        ws.getCell(`L${institutionDetailsRow + 3}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 4}`).value = institutionEmail;
        ws.getCell(`L${institutionDetailsRow + 4}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 6}`).value = date;
        ws.getCell(`L${institutionDetailsRow + 6}`).font = { size: 12 };  // Regular font size for phone

        ws.getCell(`L${institutionDetailsRow + 7}`).value = terms;
        ws.getCell(`L${institutionDetailsRow + 7}`).font = { size: 12 };  // Regular font size for phone

        // Optionally adjust the column width if the text is long
        ws.getColumn('K').width = 50;  // Adjust the width of column D (if necessary)

        // Define header row
        const header = [
          "fullname",
          "customer external code",
          "customer code",
          "transaction date",
          "transaction type",
          "account type name",
          "transaction amount",
          "code",
          "affected_coa",
        ];

        // Add header row starting from row 13
        const startingRow = 13;
        let headerRow = ws.getRow(startingRow);
        headerRow.values = header;
        headerRow.font = { bold: true };
        headerRow.commit(); // Commit the row to the worksheet

        // Add individual data rows
        let currentRow = startingRow + 1;

        // Process each item
        this.items.forEach(item => {
          const row = ws.getRow(currentRow);
          // Map item values to the corresponding header columns
          row.values = [
            item.customer?.first_name + ' ' + item.customer?.last_name,
            item.customer.external_code, // Remove extra spaces
            item.customer.code,
            item.trans_date,
            item.transaction_type,
            item.account.account_type?.name,
            item.trans_amount,
            item.code,
            item.affected_coa,
          ];
          row.commit();
          currentRow++;
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a download link and simulate a click to download the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Modified_REPORT_TEMPLATE.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error('Error fetching, modifying, or downloading the Excel file:', err.message);
      }
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
  },
};
</script>