<template>
    <div>
        <b-modal size="sm" ref="showMobileReceipt">
            <template #modal-title>
                <span class="akkurate-green fw-bold">Cash Deposit</span>
            </template>
            <div class="row">
                <div class="col-12 text-center">
                    <img width="150px" class="img-fluid img-visibility" :src="require('@/assets/img/default_logo.png')">
                </div>
                <div class="col-12 text-center">
                    <h6 class="fw-bold">{{ report_data.institution?.alias }}</h6>
                    <p class="mb-0">{{ report_data.institution?.motto }}</p>
                </div>
              
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="generatePDF" type="button"
                        class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon
                                :icon="['fas', 'book']" /> Print</span></b-button>
                    <b-button @click="exportToPDF" class="akkurate-green-btn download-btn float-right"><span
                            class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>
                </div>
            </template>

        </b-modal>

    </div>



</template>

<script>
export default {
    props: ['report_data'],
    methods: {
        showModal() {
            this.$refs['showMobileReceipt'].show()
        },
        hideModal() {
            this.$refs['showMobileReceipt'].hide()
        },
        exportToPDF() {

        },
        generatePDF() {

        }
    }
}
</script>