<template>
    <div>
        <a class="dropdown-item" v-b-modal="data.account_number + transaction">Inter-union Transfer</a>
        <b-modal :id="data.account_number + transaction" v-model="modalShow" size="lg" @show="resetModal"
            @hidden="resetModal" @ok="handleOk">
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <div class="w-100 d-flex align-items-center justify-content-between">
                    <h5 class="fw-bold mb-0">{{ transaction }} <span class="ms-5"><i
                                class="far fa-money-bill-alt"></i></span></h5>
                    <b-button variant="white" @click="close()">
                        &times;
                    </b-button>
                </div>

            </template>
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group label-for="member" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="member">Member</label>
                            </template>
                            <b-form-input id="member" v-model="getFullName" v-validate="{ required: false }"
                                :state="validateState('member')" name="member" class="mt-2 field-container fields"
                                type="text" disabled></b-form-input>
                            <b-form-invalid-feedback>
                                The Balance field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label-for="account_number" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="account_number">Account Number</label>
                            </template>
                            <b-form-input id="account_number" v-model="data.account_number"
                                v-validate="{ required: false }" :state="validateState('account_number')"
                                name="account_number" class="mt-2 field-container fields" type="text"
                                disabled></b-form-input>
                            <b-form-invalid-feedback>
                                The Balance field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.account_balance">
                                   {{ form.errors.account_balance[0] }}
                               </errors> -->
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label-for="teller" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="teller">Teller</label>
                            </template>
                            <b-form-input id="teller" name="teller" class="mt-2 field-container fields"
                                v-validate="{ required: false }" :state="validateState('teller')" type="text"
                                disabled></b-form-input>
                            <b-form-invalid-feedback>
                                The Balance field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.account_balance">
                                   {{ form.errors.account_balance[0] }}
                               </errors> -->
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <label class="akkurate-dark small" for="transcation_date">Transaction Date <star></star></label>
                        <b-form-group id="transcation_date" label-for="transcation_date">
                            <b-form-input class="mt-2 field-container fields" v-validate="{ required: true }"
                                :state="validateState('transcation_date')" name="transcation_date"
                                v-model="trasaction_date" type="date"></b-form-input>
                            <b-form-invalid-feedback>
                                The Trasaction Date field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.date_of_birth">
                            {{ form.errors.date_of_birth[0] }}
                        </errors> -->
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label-for="amount" class="mb-3">
                            <template #label>
                                <label class="akkurate-dark small" for="amount">Amount <star></star></label>
                            </template>
                            <b-form-input id="amount" v-validate="{ required: true }" :state="validateState('amount')"
                                name="amount" class="mt-2 field-container fields" type="text"
                                v-model="amount"></b-form-input>
                            <b-form-invalid-feedback>
                                The Amount field is required
                            </b-form-invalid-feedback>
                            <!-- <errors v-if="form.errors.account_balance">
                                   {{ form.errors.account_balance[0] }}
                               </errors> -->
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group label-for="union_code" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="union_code">Union Code <star></star></label>
                            </template>
                            <b-form-input id="union_code" v-validate="{ required: true }"
                                :state="validateState('union_code')" name="union_code"
                                class="mt-2 field-container fields" type="text" v-model="union_code"></b-form-input>
                            <b-form-invalid-feedback>
                                The Union Code field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group label-for="union_name" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="union_name">Union Name <star></star></label>
                            </template>
                            <b-form-input id="union_name" v-validate="{ required: true }"
                                :state="validateState('union_name')" name="union_name"
                                class="mt-2 field-container fields" type="text" v-model="union_name"></b-form-input>
                            <b-form-invalid-feedback>
                                The Union Name field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group label-for="customer_no" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="customer_no">Customer Number <star></star>
                                </label>
                            </template>
                            <b-form-input id="customer_no" v-validate="{ required: true }"
                                :state="validateState('customer_no')" name="customer_no"
                                class="mt-2 field-container fields" type="text" v-model="customer_no"></b-form-input>
                            <b-form-invalid-feedback>
                                The Customer Number field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group label-for="customer_name" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="customer_name">Customer Name <star></star>
                                </label>
                            </template>
                            <b-form-input id="customer_name" v-validate="{ required: true }"
                                :state="validateState('customer_name')" name="customer_name"
                                class="mt-2 field-container fields" type="text" v-model="customer_name"></b-form-input>
                            <b-form-invalid-feedback>
                                The Customer Name field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group label-for="transaction_id" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="transaction_id">Transaction ID <star></star>
                                </label>
                            </template>
                            <b-form-input id="transaction_id" v-validate="{ required: true }"
                                :state="validateState('transaction_id')" name="transaction_id"
                                class="mt-2 field-container fields" type="text" v-model="transaction_id"></b-form-input>
                            <b-form-invalid-feedback>
                                The Customer Name field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group label-for="bank_chart" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="bank_chart">Bank Chart <star></star></label>
                            </template>
                            <multiselect class="mt-2 field-container" v-model="bank_chart" :options="bank_chart_options"
                                placeholder="Select Bank Chart" selectLabel="" deselectLabel="" name="bank_chart"
                                label="name">
                            </multiselect>
                            <b-form-invalid-feedback>
                                The Bank Chart field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group label-for="narration" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark small" for="narration">Narration <star></star></label>
                            </template>
                            <b-form-textarea class="mt-2 fields" id="narration" v-validate="{ required: true }"
                                :state="validateState('narration')" name="narration" v-model="narration"
                                placeholder="Enter Narration..."></b-form-textarea>
                            <b-form-invalid-feedback>
                                The Narration field is required
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>



                <hr />
                <div class="row">
                    <div class="col-12 col-md-12 text-center">
                        <label class="label btn akkurate-gray-btn">
                            <input type="file" @change="handleFileUpload" />
                            <span class="text-white akkurate-small">Form of Identification</span>
                        </label>
                        <div v-if="selectedFile">
                            <label class="akkurate-dark small">Preview</label>
                            <img class="imagePreviewWrapper" :src="previewUrl" alt="File Preview" />
                            <b-button type="button" @click="uploadFile" :disabled="upload_processing"
                                class="akkurate-green-btn">
                                <b-spinner v-if="spin_visible" small></b-spinner>
                                <i v-if="success_visible" class="fas fa-check loading"></i>
                                <i v-if="text_visible" class="fas fa-upload loading"></i> {{ upload }}
                            </b-button>

                        </div>
                    </div>
                </div>
            </form>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
               class="float-left"
               v-if="isLoading"
               :isLoading="isLoading"
               ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="$bvModal.hide(data.account_number + transaction)" type="button"
                        class="akkurate-grey-btn border-0 btn-size ms-2 float-right"><span class="small">
                            <font-awesome-icon class="me-2" :icon="['fas', 'times']" />
                            Cancel</span>
                    </b-button>
                    <b-button :disabled="isSaving" @click="ok()" type="submit" class="akkurate-green-btn btn-size float-right border-0">
                        <span class="small"><font-awesome-icon class="me-2" :icon="['fas', 'save']" />Save</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <inter-union-deposit :report_data="report_data" :showModal="showDeposit"
            @closeModal="closeModal"></inter-union-deposit>
        <!-- <cheque-withdrawal :report_data="report_data" :showModal="showWithdrawal" @closeModal="closeModal"></cheque-withdrawal> -->

    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import InterUnionDeposit from '../../reports/transfers/InterUnionDeposit.vue'
export default {
    name: "Cheque",
    components: {
        Multiselect,
        InterUnionDeposit
        // ChequeDeposit,
        // ChequeWithdrawal,
    },
    props: ["data", "transaction"],
    data() {
        return {
            selectedFile: null,
            previewUrl: null,
            status_options: ['Active', 'Inactive'],
            isSaving: false,
            add_or_edit: "add",
            is_processing: false,
            spin_visible: false,
            text_visible: true,
            success_visible: false,
            upload_processing: false,
            upload: "Upload",
            trasaction_date: null,
            amount: "",
            narration: "",
            report_data: "",
            customer_no: "",
            customer_name: "",
            transaction_id: "",
            bank_chart: "",
            bank_chart_options: [],
            union_code: "",
            union_name: "",
            showDeposit: false,
            modalShow: false,
            // full_name: this.data.customer.first_name + ' ' + this.data.customer.last_name,

            // showWithdrawal: false,
        }
    },
    computed: {
        getFullName() {
            if (this.modalShow == true) {
                return this.data?.customer?.first_name + ' ' + this.data?.customer?.last_name
            }
        }
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
                this.previewFile(file);
            }
        },
        previewFile(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.previewUrl = reader.result;
            };
        },
        closeModal() {
            this.showDeposit = false;
            // this.showWithdrawal = false;
        },
        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async uploadFile() {
            this.upload = "Processing..."
            this.spin_visible = true;
            this.text_visible = false;
            await this.timeout(3000);
            this.spin_visible = false;
            this.text_visible = false;
            this.success_visible = true
            this.upload_processing = true
            this.upload = "Successfully Uploaded"
            // console.log(this.data.account_number);
        },
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        resetModal() {
            this.trasaction_date = ''
            this.amount = ''
            this.narration = ''
        },
        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.handleSubmit()
        },
        // loadPageOptions() {
        //     this.$Progress.start();
        //     this.isLoading = true;
        //     ApiService.post("/accounts/account-types/dropdown")
        //         .then((response) => {
        //             console.log(response.data.data);
        //             this.account_types = response.data.data;
        //             // this.branches = response.data.branches;
        //             this.isLoading = false;
        //             // $emit('coa-head-saved', response.data)
        //             this.$Progress.finish();
        //         })
        //         .catch((error) => {
        //             this.isLoading = false;
        //             this.$Progress.fail();
        //         });
        // },
        handleSubmit() {
            // Exit when the form isn't valid
            this.$validator.validateAll().then(result => {
                if (!result) {
                    console.log(result);
                    return;
                }
                this.isSaving = true;
                swal.fire({
                    title: 'Transaction Successfully Saved',
                    text: 'References No: 58518111476',
                    confirmButtonColor: "#018673",
                    icon: 'success',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton: true, // Show the "Cancel" button
                    confirmButtonText: 'OK', // Customize the text for the "OK" button
                    cancelButtonText: 'Print', // Text for the custom button
                    buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                    customClass: {
                        confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$bvModal.hide(this.data.account_number + this.transaction);
                    } else if (result.isDismissed) {
                        // let action = this.data.account_number + this.transaction
                        // let value = action.split(" ");
                        this.showDeposit = true;
                        this.$bvModal.hide(this.data.account_number + this.transaction);
                        this.report_data = {
                            'amount': this.amount,
                            'full_name': this.data.customer.first_name + ' ' + this.data.customer.last_name,
                            'denomination': this.fields,
                            'account_no': this.data.account_number,
                            'customer_no': this.customer_no,
                            'customer_name': this.customer_name,
                            'transaction_id': this.transaction_id,
                            'account_type': this.data.account_type.name,
                            'union_code': this.union_code,
                            'union_name': this.union_name,
                        }

                        // if (value[1] == "Deposit") {
                        //     this.$bvModal.hide(this.data.account_number + this.transaction);
                        //     this.showDeposit = true;
                        //     this.report_data = {
                        //         'amount': this.amount,
                        //         'full_name': this.data.customer.first_name + ' ' + this.data.customer.last_name,
                        //         'denomination': this.fields,
                        //         'account_no': this.data.account_number,
                        //         'cheque_no': this.cheque_no,
                        //     }
                        // } else if (value[1] == "Withdrawal") {
                        //     this.$bvModal.hide(this.data.account_number + this.transaction);
                        //     this.showWithdrawal = true;
                        //     this.report_data = {
                        //         'amount': this.amount,
                        //         'full_name': this.data.customer.first_name + ' ' + this.data.customer.last_name,
                        //         'denomination': this.fields,
                        //         'account_no': this.data.account_number,
                        //         'cheque_no': this.cheque_no,
                        //     }
                        // }
                    }
                });


                // this.$Progress.start();
                // this.form.errors = [];
                // this.form
                // ApiService.post("",
                //     this.form
                // )
                //     .then((response) => {
                //         this.isSaving = false;
                //         this.$Progress.finish();

                //         toast.fire({
                //             icon: "success",
                //             title: response.data.message,
                //         });

                //         this.$emit("account-type-saved", response.data.data);

                //         // Hide Modal Manually
                //         this.$nextTick(() => {
                //             this.$bvModal.hide('account-type')
                //         })

                //         // window.location.reload()
                //         //   this.isSaving = false;
                //         //   this.form.phone_number = "";
                //         //   this.form.email = "";
                //         //   this.form.selected_payment_method = "";
                //     })
                //     .catch((error) => {
                //         this.isSaving = false;
                //         this.$Progress.fail();
                //         this.form.errors = [];
                //         if (error.response.data.errors) {
                //             console.log(error.response.data.errors)
                //             this.form.errors = error.response.data.errors;
                //         }
                //         // console.log(error.response.data)
                //     });

                //    this.$emit('addAccount', this.form)
                //    this.$bvModal.hide('add-account')

            });
            // Hide the modal manually

        }
    }
}
</script>