UsersIndex

<template>
    <div id="wrapper">
        <SidebarView :api="true" :apiKey="true" :api-visible="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container-fluid">

                    <!-- begin row  -->
                    <div class="card card-shape home-box">
                        <div class="card-header d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 fw-bold text-green">Api Keys</h6>
                            <b-button class="fw-semibold akkurate-green-btn" size="sm" type="button" v-b-modal.modal-1
                                variant="primary">
                                <span class="akkurate-small"><i class="fas fa-plus"></i> Generate Key</span>
                            </b-button>
                        </div>

                        <div class="card-body table_text_size">
                            <b-table striped bordered responsive id="deposit-table" :busy.sync="isBusy" :items="rows"
                                :fields="fields" :tbody-tr-class="rowClass" show-empty>
                                <template #empty="scope">
                                    <h4 class="text-center small mt-3">{{ scope.emptyText }}</h4>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                                <template #cell(key)="data">
                                    <div>************************************</div>
                                </template>
                                <!-- A custom formatted column -->

                                <template #cell(actions)="data">
                                    <a title="Delete" class="btn btn-sm akkurate-danger-btn text-white"
                                        @click="deleteApiKey(data.item)">
                                        <span class="akkurate-small"><font-awesome-icon class="text-white"
                                                :icon="['fas', 'trash']" />
                                        </span>
                                    </a>


                                </template>
                            </b-table>
                        </div>
                    </div>
                    <!-- end row  -->
                </div>
            </div>
            <create-apikey @keySaved="keySaved"></create-apikey>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>

<script>

import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import ApiService from "@/core/services/api.service";
import CreateApikey from '../create/CreateApikey.vue'

export default {
    components: {
        BeatLoaderComponent,
        SidebarView,
        TopbarView,
        Footer,
        CreateApikey
    },
    mounted() {
        let token = localStorage.getItem('token');
        if (!token) {
            this.$router.push({ path: '/login' });
        }
    },
    data() {
        return {
            fields: [
                {
                    label: 'ID',
                    key: 'id',
                },
                {
                    label: 'App ID',
                    key: 'app_id',
                },
                {
                    label: 'Api Key',
                    key: 'key',
                },
                {
                    label: 'Created At',
                    key: 'created_at',
                },
                {
                    label: 'Actions',
                    key: 'actions',
                },

            ],
            rows: [],
        }
    },
    async created() {
        this.loadItems()
    },
    methods: {
        rowClass() {
            return 'custom-row-height'
        },

        async loadItems() {
            this.isBusy = true
            this.$Progress.start();
            this.isLoading = true;
            await ApiService.get("/api-keys/get-key")
                .then((response) => {
                    console.log(response.data);
                    this.rows = response.data;
                    // this.totalRecords = response.data.meta.total;
                    // this.all_data = response.data.data;
                    this.isLoading = false;
                    this.$Progress.finish();
                    this.isBusy = false
                }).catch((error) => {
                    console.log(error);
                    this.isBusy = false
                })
        },
        deleteApiKey(data) {
            swal.fire({
                title: "Delete Api Key",
                text: 'Are you sure you want to delete ' + data.app_id + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                allowOutsideClick: true,
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                customClass: {
                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                    cancelButton: 'btn akkurate-danger-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                }
            }).then(result => {
                if (result.value) {
                    this.$Progress.start();
                    ApiService.delete('/api-keys/delete-key' + data.id)
                        .then((response) => {
                            this.$Progress.finish();
                            this.loadItems();
                            // this.$bvToast.toast('Loan Deleted Successfully', {
                            //     title: 'Success',
                            //     variant: 'success',
                            //     solid: true
                            // })
                            swal.fire({
                                // title: response.statusText,
                                text: response.data.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.loadItems();
                        }).catch((error) => {
                            this.$Progress.fail();

                            swal.fire({
                                // title: response.statusText,
                                text: error.response.data.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });

        },
        keySaved(){
            this.loadItems();
        }
    }

};
</script>

<!-- New step!
     Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
