<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-12">
                <form @submit="handleSubmit" @reset="clearForm">
                    <div class="row">
                        <div class="col-6 col-md-4">
                            <label class="akkurate-dark" for="payment_method">Payment Mode</label>
                            <b-form-group id="payment_method" name="payment_method" label-for="payment_method">
                                <multiselect id="payment_method" name="payment_method" @select="paymentType"
                                    class="mt-2 field-container" aria-describedby="payment_method"
                                    v-model="form.payment_method" :allow-empty="false" :options="payment_options">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="col-6 col-md-4">
                            <label class="akkurate-dark" for="amount">Amount</label>
                            <b-form-group id="amount" label-for="amount">
                                <b-form-input id="amount" name="amount" class="mb-2 mt-2 field-container fields"
                                    type="text" placeholder="Enter Amount" v-model="form.amount"
                                    :disabled="disabledAmount" @input="AmountInput" aria-describedby="amount"
                                    ></b-form-input>
                                <b-form-invalid-feedback>{{ veeErrors.first('amount')
                                    }}</b-form-invalid-feedback>
                                <errors v-if="form.errors.amount">
                                    {{ form.errors.amount[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-6 col-md-4">
                            <label class="akkurate-dark" for="principal_payment">Principal</label>
                            <b-form-group id="principal_payment" label-for="principal_payment">
                                <b-form-input id="principal_payment" name="principal_payment"
                                    class="mb-2 mt-2 field-container fields" type="text"
                                    placeholder="Enter Principal Amount" v-model="form.principal_payment"
                                    :disabled="disablePrincipalInterest" @input="PrincipalInterestInput" aria-describedby="principal_payment"
                                    ></b-form-input>
                                <b-form-invalid-feedback>{{ veeErrors.first('principal_payment')
                                    }}</b-form-invalid-feedback>
                                <errors v-if="form.errors.principal_payment">
                                    {{ form.errors.principal_payment[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-6 col-md-4">
                            <label class="akkurate-dark" for="interest_payment">Interest</label>
                            <b-form-group id="interest_payment" label-for="interest_payment">
                                <b-form-input id="interest_payment" name="interest_payment"
                                    class="mb-2 mt-2 field-container fields" type="text"
                                    placeholder="Enter Interest Amount" v-model="form.interest_payment"
                                    :disabled="disablePrincipalInterest" @input="PrincipalInterestInput"  aria-describedby="interest_payment"
                                    ></b-form-input>
                                <b-form-invalid-feedback>{{ veeErrors.first('interest_payment')
                                    }}</b-form-invalid-feedback>
                                <errors v-if="form.errors.interest_payment">
                                    {{ form.errors.interest_payment[0] }}
                                </errors>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-4">
                            <label class="akkurate-dark" for="date">Date</label>
                            <b-form-group id="date" label-for="date">
                                <b-form-input class="mb-3 mt-2 field-container fields" type="date" v-model="form.date"
                                    disabled
                                    required></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-4" v-show="dmomo">
                            <label class="akkurate-dark" for="mobile">Mobile Number</label>
                            <b-form-group id="mobile" label-for="mobile">
                                <b-form-input class="mb-3 mt-2 field-container fields" type="text"
                                    v-model="form.mobile_number" placeholder="Enter Mobile Number"></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-6 col-md-4" v-if="cheque" v-show="cheque">
                            <label class="akkurate-dark" for="check_number">Cheque No</label>
                            <b-form-group id="check_number" label-for="check_number">
                                <b-form-input id="check_number" name="check_number" class="mt-2 field-container fields"
                                    type="text" v-model="form.check_number" placeholder="Enter Check Number"
                                    required></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-4" v-show="cheque">
                            <label class="akkurate-dark" for="bank_gl">Bank GL</label>
                            <b-form-group id="bank_gl" label-for="bank_gl">
                                <multiselect class="mt-2 field-container" label="name" v-model="form.coa_subhead_id"
                                    :options="coa_subheads">
                                </multiselect>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-4" v-show="marchmomo">
                            <label class="akkurate-dark" for="momo_transaction_id">Momo Transaction
                                ID</label>
                            <b-form-group id="momo_transaction_id" label-for="momo_transaction_id">
                                <b-form-input name="momo_transaction_id" id="momo_transaction_id"
                                    class="mb-3 mt-2 field-container fields" type="text"
                                    v-model="form.momo_transaction_id" placeholder="Enter Transaction ID">
                                </b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-6 col-md-5" v-show="setoff">
                            <label class="akkurate-dark" for="note">Product</label>
                            <multiselect class="mt-2 field-container" v-model="form.product_id" label="account_number"
                                :options="savings_accounts_options" :custom-label="productCustomLabel" selectLabel=""
                                deselectLabel="" track-by="id">
                            </multiselect>
                        </div>
                        <div class="col-6 col-md-4">
                            <label class="akkurate-dark" for="note">Note</label>
                            <b-form-group id="note" label-for="note">
                                <b-form-textarea class="mt-2 fields" id="note" name="note" v-model="form.note"
                                    aria-describedby="note" placeholder="Enter Note...">
                                </b-form-textarea>
                                <b-form-invalid-feedback>{{ veeErrors.first('note')
                                    }}</b-form-invalid-feedback>
                                <errors v-if="form.errors.note">
                                    {{ form.errors.note[0] }}
                                </errors>
                            </b-form-group>
                        </div>
                        <div class="col-6 col-md-8" v-show="cash">
                            <div>
                                <label>Denomination</label>
                                <denomination :denominations="form.denominations"></denomination>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <b-button :disabled="isSaving" type="submit" class="akkurate-green-btn me-2 btn-size float-right"><font-awesome-icon
                            class="me-2" :icon="['fas', 'money-bill']" />{{ save }}</b-button>
                    <b-button @click="clearForm" class="akkurate-gray-btn me-2 btn-size float-right"><font-awesome-icon class="me-2"
                            :icon="['fas', 'close']" />Clear</b-button>
                </form>
            </div>
        </div>
        <cash-repayment ref="showCashRepayment" :reportData="reportData"></cash-repayment>
        <cheque-repayment ref="showChequeRepayment" :reportData="reportData"></cheque-repayment>
        <momo-merchant-repayment ref="showMerchantRepayment" :reportData="reportData"></momo-merchant-repayment>
        <direct-momo-debit-repayment ref="showDirectDebitRepayment"
            :reportData="reportData"></direct-momo-debit-repayment>
        <setoff-repayment ref="showSetOffRepayment" :reportData="reportData"></setoff-repayment>
    </div>

</template>
<script>
import ApiService from "@/core/services/api.service";
import Multiselect from 'vue-multiselect'
import Denomination from '@/views/main/pages/transactions/form/modal/Denomination.vue'
import CashRepayment from "../../reports/cash/CashRepayment.vue";
import ChequeRepayment from "../../reports/cheque/ChequeRepayment.vue";
import MomoMerchantRepayment from "../../reports/momo/MomoMerchantRepayment.vue";
import DirectMomoDebitRepayment from "../../reports/direct-debit/DirectMomoDebitRepayment.vue";
import SetoffRepayment from "../../reports/set-off/SetoffRepayment.vue";
export default {
    props: ['coa_subheads', 'savings_accounts_options', 'institution', 'loan'],
    components: {
        Multiselect,
        Denomination,
        CashRepayment,
        ChequeRepayment,
        MomoMerchantRepayment,
        DirectMomoDebitRepayment,
        SetoffRepayment
    },

    data() {
        return {
            ...this.initialState(),
            isSaving: false,
            payment_options: ['Cash', 'Cheque', 'MOMO Merchant', 'Direct MOMO Debit', 'Set-Off'],
            cash: true,
            marchmomo: false,
            dmomo: false,
            cheque: false,
            setoff: false,
            reportData: [],
            save: "Pay",
            disabledAmount: false,
            disablePrincipalInterest: false
        }
    },
    methods: {
        customLabel({ account_type, account_number }) {
            return `${account_number} - ${account_type?.name}`
        },
        productCustomLabel({ account_balance, account_number, account_type }) {
            return `${account_number} ${account_type.name} - (${this.institution.currency_code}${this.displayNumber(account_balance)})`
        },
        handleSubmit(event) {
            event.preventDefault()
            this.saveTransaction(this.form.payment_method)
            // switch (this.form.payment_method) {
            //     case "Cash":
            //         this.saveCash();
            //         this.clearForm()
            //         break;
            //     case "MOMO Merchant":
            //         this.saveMomoMarchant();
            //         this.clearForm()
            //         break;
            //     case "Direct Momo Debit":
            //         this.saveDirectMomoDirect();
            //         this.clearForm()
            //         break;
            //     case "Cheque":
            //         this.saveCheque();
            //         this.clearForm()
            //         break;
            //     case "Set-Off":
            //         this.saveSetOff();
            //         this.clearForm()
            //         break;
            // }
            // Hide the modal manually
        },
        AmountInput(index) {
            console.log(index.length)
            if (index.length > 0) {
                this.disablePrincipalInterest = true
                this.form.principal_payment = "";
                this.form.interest_payment = "";
            }else{
                this.disablePrincipalInterest = false
            }
        },
        PrincipalInterestInput(index){
            if (index.length > 0) {
                this.disabledAmount = true
                this.form.amount = "";
            }else{
                this.disabledAmount = false
            }
        },
        paymentType(value) {
            switch (value) {
                case "Cash":
                    this.cash = true;
                    this.dmomo = false;
                    this.marchmomo = false;
                    this.cheque = false;
                    this.setoff = false;
                    this.form.denominations = [
                        { denom_amount: '1', denom_frequency: '', sub_total: '' }
                    ];
                    this.form.mobile_number = "";
                    this.form.check_number = "";
                    this.form.momo_transaction_id = "";
                    this.form.coa_subhead_id = ""
                    this.form.product_id = ""
                    break;
                case "MOMO Merchant":
                    this.dmomo = true;
                    this.marchmomo = true;
                    this.cheque = false;
                    this.setoff = false;
                    this.cash = false;
                    this.form.denominations = []
                    this.form.check_number = "";
                    this.form.coa_subhead_id = ""
                    this.form.product_id = ""
                    break;
                case "Direct MOMO Debit":
                    this.dmomo = true;
                    this.marchmomo = false;
                    this.cheque = false;
                    this.setoff = false;
                    this.cash = false;
                    this.form.denominations = []
                    this.form.check_number = "";
                    this.form.coa_subhead_id = ""
                    this.form.product_id = ""
                    break;

                case "Cheque":
                    this.dmomo = false;
                    this.cheque = true;
                    this.marchmomo = false;
                    this.setoff = false;
                    this.cash = false;
                    this.form.denominations = []
                    this.form.mobile_number = "";
                    this.form.momo_transaction_id = "";
                    this.form.product_id = ""
                    break;
                case "Set-Off":
                    this.dmomo = false;
                    this.cheque = false;
                    this.marchmomo = false;
                    this.setoff = true;
                    this.cash = false;
                    this.form.denominations = []
                    this.form.mobile_number = "";
                    this.form.check_number = "";
                    this.form.momo_transaction_id = "";
                    break;
                default:
                    this.dmomo = false;
                    this.marchmomo = false;
                    this.cheque = false
                    this.note = false;
                    this.setoff = false;
                    this.cash = false;
                    this.form.denominations = []
                    break;

            }
        },
        clearForm() {
            this.form = this.initialState().form; // Call the initialState method to get the default form
        },



        async saveTransaction(payment) {
            this.isSaving = true;
            this.$Progress.start();
            this.form.loan_number = this.loan.loan_number
            this.form.payment_method = payment
            // this.form.product_id = null;
            if (this.form.payment_method === "Cheque") {
                this.form.coa_subhead_id = this.form.coa_subhead_id.id
            } else {
                this.form.coa_subhead_id
            }
            if (this.form.payment_method === "Set-Off") {
                this.form.product_id = this.form.product_id.id;
            } else {
                this.form.product_id
            }


            this.save = "Processing..."
            if (this.form.principal_payment == "" && this.form.interest_payment == "") {
                await ApiService.post('/loans/loanRepayments', this.form)
                    .then((response) => {
                        let res = response?.data;
                        // console.log(res);
                        this.$Progress.finish();
                        this.save = "Pay"
                        this.reportData = {
                            "trans_date": res?.transaction?.trans_date,
                            // 'transaction': res?.transaction,
                            // 'loan': res?.loan,
                            'denominations': this.form.denominations,
                            'institution': this.institution,
                            'teller': res.teller.first_name + ' ' + res.teller.surname,
                            'account_number': res.loan.account.account_number,
                            'loan_number': res.loan.loan_number,
                            'fullname': res.loan.customer.first_name + ' ' + res.loan.customer.last_name,
                            'transaction_mode': res.transaction.transaction_mode,
                            'amount': res.transaction.trans_amount,
                            'denomination': res.transaction.denominations,
                            'transaction_type': res.transaction.transaction_type,
                            'cheque_number': res.transaction.cheque_number,
                            'bank_name': res.bank_name,
                            'merchant_number': res.transaction.merchant_number,
                            'transaction_id': res.transaction.code,
                            'mobile': res.transaction.mobile
                        };

                        this.$emit("reloadLoans");
                        this.paymentType(res.transaction.transaction_type)

                        this.isSaving = false;
                        swal.fire({
                            title: 'Success',
                            text: response.data.message,
                            // text: "Saved successfully",
                            confirmButtonColor: "#018673",
                            icon: 'success',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            showCancelButton: true, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            cancelButtonText: 'Print', // Text for the custom button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                            }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$bvModal.hide('loan_repayment');
                            } else if (result.isDismissed) {
                                switch (payment) {
                                    case "Cash":
                                        if (res.transaction.transaction_type == "Loan Repayment") {
                                            this.$refs.showCashRepayment.showModal();
                                        } else if (res.transaction.transaction_type == "Loan Disbursed") {
                                            this.$refs.showCashDisbursement.showModal();
                                        }
                                        break;
                                    case "Cheque":
                                        if (res.transaction.transaction_type == "Loan Repayment") {
                                            this.$refs.showChequeRepayment.showModal();
                                        } else if (res.transaction.transaction_type == "Loan Disbursed") {
                                            this.$refs.showChequeDisbursement.showModal();
                                        }
                                        break;
                                    case "MOMO Merchant":
                                        if (res.transaction.transaction_type == "Loan Repayment") {
                                            this.$refs.showMerchantRepayment.showModal();
                                        } else if (res.transaction.transaction_type == "Loan Disbursed") {
                                            this.$refs.showMerchantDisbursement.showModal();
                                        }
                                        break;
                                    case "Direct MOMO Debit":
                                        if (res.transaction.transaction_type == "Loan Repayment") {
                                            console.log(payment);
                                            console.log(res.transaction.transaction_type);
                                            this.$refs.showDirectDebitRepayment.showModal();
                                        } else if (res.transaction.transaction_type == "Loan Disbursed") {
                                            this.$refs.showDirectDebitDisbursement.showModal();
                                        }
                                        break;
                                    case "Set-Off":
                                        if (res.transaction.transaction_type == "Loan Repayment") {
                                            // console.log(data.item.transaction_type)
                                            console.log(this.$refs.showSetOffRepayment);
                                            this.$refs.showSetOffRepayment.showModal();
                                        } else if (res.transaction.transaction_type == "Loan Disbursed") {
                                            this.$refs.showSetOffDisbursement.showModal();
                                        }
                                        break;
                                }
                            }
                        })
                    }).catch((error) => {
                        this.save = "Pay"
                        this.isSaving = false;
                        this.$Progress.fail();
                        // if (error.response?.status == 422) {
                        swal.fire({
                            icon: "error",
                            title: "Error",
                            text: error.response?.data?.message,
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                        // }
                    })

            } else {
                await ApiService.post('/loans/custom/repayment/store', this.form)
                    .then((response) => {
                        let res = response?.data;
                        // console.log(res);
                        this.$Progress.finish();
                        this.save = "Pay"
                        this.reportData = {
                            "trans_date": res?.transaction?.trans_date,
                            // 'transaction': res?.transaction,
                            // 'loan': res?.loan,
                            'denominations': this.form.denominations,
                            'institution': this.institution,
                            'teller': res.teller.first_name + ' ' + res.teller.surname,
                            'account_number': res.loan.account.account_number,
                            'loan_number': res.loan.loan_number,
                            'fullname': res.loan.customer.first_name + ' ' + res.loan.customer.last_name,
                            'transaction_mode': res.transaction.transaction_mode,
                            'amount': res.transaction.trans_amount,
                            'denomination': res.transaction.denominations,
                            'transaction_type': res.transaction.transaction_type,
                            'cheque_number': res.transaction.cheque_number,
                            'bank_name': res.bank_name,
                            'merchant_number': res.transaction.merchant_number,
                            'transaction_id': res.transaction.code,
                            'mobile': res.transaction.mobile
                        };

                        this.$emit("reloadLoans");
                        this.paymentType(res.transaction.transaction_type)

                        this.isSaving = false;
                        swal.fire({
                            title: 'Success',
                            text: response.data.message,
                            // text: "Saved successfully",
                            confirmButtonColor: "#018673",
                            icon: 'success',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            showCancelButton: true, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            cancelButtonText: 'Print', // Text for the custom button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                            }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$bvModal.hide('loan_repayment');
                            } else if (result.isDismissed) {
                                switch (payment) {
                                    case "Cash":
                                        if (res.transaction.transaction_type == "Loan Repayment") {
                                            this.$refs.showCashRepayment.showModal();
                                        } else if (res.transaction.transaction_type == "Loan Disbursed") {
                                            this.$refs.showCashDisbursement.showModal();
                                        }
                                        break;
                                    case "Cheque":
                                        if (res.transaction.transaction_type == "Loan Repayment") {
                                            this.$refs.showChequeRepayment.showModal();
                                        } else if (res.transaction.transaction_type == "Loan Disbursed") {
                                            this.$refs.showChequeDisbursement.showModal();
                                        }
                                        break;
                                    case "MOMO Merchant":
                                        if (res.transaction.transaction_type == "Loan Repayment") {
                                            this.$refs.showMerchantRepayment.showModal();
                                        } else if (res.transaction.transaction_type == "Loan Disbursed") {
                                            this.$refs.showMerchantDisbursement.showModal();
                                        }
                                        break;
                                    case "Direct MOMO Debit":
                                        if (res.transaction.transaction_type == "Loan Repayment") {
                                            console.log(payment);
                                            console.log(res.transaction.transaction_type);
                                            this.$refs.showDirectDebitRepayment.showModal();
                                        } else if (res.transaction.transaction_type == "Loan Disbursed") {
                                            this.$refs.showDirectDebitDisbursement.showModal();
                                        }
                                        break;
                                    case "Set-Off":
                                        if (res.transaction.transaction_type == "Loan Repayment") {
                                            // console.log(data.item.transaction_type)
                                            console.log(this.$refs.showSetOffRepayment);
                                            this.$refs.showSetOffRepayment.showModal();
                                        } else if (res.transaction.transaction_type == "Loan Disbursed") {
                                            this.$refs.showSetOffDisbursement.showModal();
                                        }
                                        break;
                                }
                            }
                        })
                    }).catch((error) => {
                        this.save = "Pay"
                        this.isSaving = false;
                        this.$Progress.fail();
                        // if (error.response?.status == 422) {
                        swal.fire({
                            icon: "error",
                            title: "Error",
                            text: error.response?.data?.message,
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                        // }
                    })
            }

        },

        initialState() {
            return {
                form: {
                    amount: "",
                    payment_method: "Cash",
                    date: new Date().toLocaleDateString('en-CA'),
                    loan_number: "",
                    source: "Web",
                    note: "",
                    bank: "",
                    mobile_number: "",
                    check_number: "",
                    momo_transaction_id: "",
                    denominations: [
                        { denom_amount: '1', denom_frequency: '', sub_total: '' }
                    ],
                    coa_subhead_id: null,
                    product_id: null,
                    product: null,
                    errors: [],
                    principal_payment: "",
                    interest_payment: ""
                },
            }
        }
    }
}
</script>