<template>
    <div>
        <b-modal :id="String(data.id + 'statement_preview')" ref="modal" size="xxl" @show="modalShown">
            <template #modal-title>
                <h6 class="m-0 fw-bold text-green">Product Statement Preview</h6>
            </template>
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <form @submit.prevent="filterAccountStatement">
                            <statement-filter :filter="filter" :is-searching="isSearching"
                                :search="search"></statement-filter>
                        </form>
                    </div>
                    <div class="card card-shape home-box">
                        <div class="card-header py-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <h6 class="m-0 fw-bold text-green">Profile Preview</h6>
                                </div>
                            </div>
                        </div>
                        <div class="card-body" style="background-color:#eaeff2">
                            <div class="row">
                                <div class="col-md-3 col-12 text-center">
                                    <img src="@/assets/img/user-avater.jpg" style="height:200px" class="img-fluid"
                                        alt="user-profile" />
                                    <p class="mt-2 akkurate-green fw-bold">Preview</p>
                                </div>
                                <div class="col-md-3 col-12 text-center">
                                    <img v-if="imageLoaded" v-bind:src="frontImgUrl" alt="Image" @load="handleImageLoad"
                                        style="height:200px" class="img-fluid text-center" />
                                    <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image"
                                        style="height:200px" class="img-fluid text-center" />
                                    <p class="mt-2 akkurate-green fw-bold">ID Card - Front</p>
                                </div>
                                <div class="col-md-3 col-12 text-center">
                                    <img v-if="imageLoaded" v-bind:src="backImgUrl" alt="Image" @load="handleImageLoad"
                                        style="height:200px" class="img-fluid text-center" />
                                    <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image"
                                        style="height:200px" class="img-fluid text-center" />
                                    <p class="mt-2 akkurate-green fw-bold">ID Card - Back</p>
                                </div>
                                <div class="col-md-3 col-12 akkurate-green">
                                    <h6 class="mb-0 fw-bold">Customer Name</h6>
                                    <p class="mb-2">{{ form.customer?.title }} {{ getFullNameAndID }}</p>
                                    <h6 class="mb-0 fw-bold">Mobile</h6>
                                    <p class="mb-2">{{ form.customer?.mobile }}</p>
                                    <h6 class="mb-0 fw-bold">Email</h6>
                                    <p class="mb-2">{{ form.customer?.email }}</p>
                                    <h6 class="mb-0 fw-bold">Address</h6>
                                    <p class="mb-2">{{ form.customer?.address }}</p>
                                    <div class="d-flex">
                                        <!-- <p class="fw-bold">TEL: <span class="fw-light me-3"> {{ form.customer?.mobile  }}</span></p> -->
                                        <!-- <p class="fw-bold">STAFF ID: <span class="fw-light">9858958</span></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark small" for="account_number">Account Number</label>
                                    <b-form-group id="account_number" label-for="account_number" class="akkurate-dark">
                                        <b-form-input id="account_number" class="mb-3 mt-2 field-container fields"
                                            type="text" v-model="form.account_number" disabled>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark small" for="account_type">Account Type</label>
                                    <b-form-group id="account_type" label-for="account_type" class="akkurate-dark">
                                        <b-form-input id="account_type" class="mb-3 mt-2 field-container fields"
                                            type="text" v-model="form.account_type.name" disabled>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark small" for="branch">Branch</label>
                                    <b-form-group id="branch" label-for="branch" class="akkurate-dark">
                                        <b-form-input id="branch" class="mb-3 mt-2 field-container fields" type="text"
                                            v-model="form.branch.name" disabled>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark small" for="account_balance">Account Balance
                                        (GHS)</label>
                                    <b-form-group id="account_balance" label-for="account_balance"
                                        class="akkurate-dark">
                                        <b-form-input id="account_balance" class="mb-3 mt-2 field-container fields"
                                            type="text" v-model="form.account_balance" disabled>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark small" for="total_debits">Total Debits (GHS)</label>
                                    <b-form-group id="total_debits" label-for="total_debits" class="akkurate-dark">
                                        <b-form-input id="total_debits" class="mb-3 mt-2 field-container fields"
                                            type="text" v-model="form.total_debits" disabled>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark small" for="total_credits">Total Credits (GHS)</label>
                                    <b-form-group id="total_credits" label-for="total_credits" class="akkurate-dark">
                                        <b-form-input id="total_credits" class="mb-3 mt-2 field-container fields"
                                            type="text" v-model="form.total_credits" disabled>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark small" for="total_credits">Opening Balance (GHS)</label>
                                    <b-form-group id="opening_balance" label-for="opening_balance"
                                        class="akkurate-dark">
                                        <b-form-input id="opening_balance" class="mb-3 mt-2 field-container fields"
                                            type="text" v-model="form.opening_balance" disabled>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <label class="akkurate-dark small" for="closing_balance">Closing Balance
                                        (GHS)</label>
                                    <b-form-group id="closing_balance" label-for="closing_balance"
                                        class="akkurate-dark">
                                        <b-form-input id="closing_balance" class="mb-3 mt-2 field-container fields"
                                            type="text" v-model="form.closing_balance" disabled>
                                        </b-form-input>
                                    </b-form-group>
                                </div>
                            </div>

                        </div>
                        <hr class="m-0" />

                        <div class="card-header">
                            <div class="d-flex align-items-center">
                                <h6 class="m-0 fw-bold text-green">Transactions</h6>
                                <!-- <div class="flex-grow-1 text-end">
                                                            <statement-preview :data="filter" :institution="institution"></statement-preview>
                                                        </div> -->
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 trans_details">
                                    <b-table striped small :busy.sync="isBusy" :items="items" :fields="fields">
                                        <template #table-busy>
                                            <div class="text-center text-danger my-2">
                                                <b-spinner class="align-middle"></b-spinner>
                                                <strong>Loading...</strong>
                                            </div>
                                        </template>
                                        <template #cell(trans_status)="data">
                                            <span :class="statusBadge(data.item.trans_status)">{{ data.item.trans_status
                                            }}</span>
                                        </template>
                                        <template #cell(debit)="data">
                                            <p class="mb-0"
                                                v-if="data.item.transaction_type == 'Withdrawal' || data?.item?.transaction_type == 'Loan Repayment'">
                                                {{
                                                   displayNumber(data.item.trans_amount)  }}</p>
                                        </template>

                                        <template #cell(credit)="data">
                                            <p class="mb-0"
                                                v-if="data.item.transaction_type == 'Deposit' || data?.item?.transaction_type == 'Loan Disbursed'">
                                                {{
                                                    displayNumber(data.item.trans_amount) }}</p>
                                        </template>

                                        <template #cell(teller)="data">
                                            <p class="mb-0">{{ data.item?.created_user?.first_name }} {{
                                                data.item?.created_user?.surname }}</p>
                                        </template>
                                        <template #cell(current_balance)="data">
                                            <p class="mb-0">{{
                                                displayNumber(data.item?.current_balance)}}</p>
                                        </template>

                                        <template #cell(action)="data">
                                            <div class="d-flex">
                                                <a title="Receipts" class="btn btn-sm akkurate-green-btn text-white"
                                                    @click="printReceipt(data)">
                                                    <span class="akkurate-small"><font-awesome-icon
                                                            :icon="['fas', 'book']" /></span>
                                                </a>
                                                <a title="Reverse" class="btn btn-sm akkurate-danger-btn text-white"
                                                    @click="transactionReverse(data)">
                                                    <span class="akkurate-small"><font-awesome-icon
                                                            :icon="['fas', 'exchange-alt']" /></span>
                                                </a>

                                            </div>

                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <template #modal-footer>
                <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                class="float-left"
                v-if="isLoading"
                :isLoading="isLoading"
                ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="$bvModal.hide(String(data.id + 'statement_preview'))"
                        class="akkurate-grey me-2 btn-size  float-right"><font-awesome-icon class="me-2"
                            :icon="['fas', 'close']" />Close</b-button>
                </div>
            </template>
        </b-modal>
        <cash-deposit ref="showCashDeposit" :report_data="report_data"></cash-deposit>
        <cash-withdrawal ref="showCashWithdrawal" :report_data="report_data"></cash-withdrawal>
        <cheque-deposit ref="showChequeDeposit" :report_data="report_data"></cheque-deposit>
        <cheque-withdrawal ref="showChequeWithdrawal" :report_data="report_data"></cheque-withdrawal>
        <momo-deposit ref="showMomoDeposit" :report_data="report_data"></momo-deposit>
        <momo-withdrawal ref="showMomoWithdrawal" :report_data="report_data"></momo-withdrawal>
        <direct-debit-deposit ref="showDirectDebitDeposit" :report_data="report_data"></direct-debit-deposit>
        <direct-debit-withdrawal ref="showDirectDebitWithdrawal" :report_data="report_data"></direct-debit-withdrawal>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import ChequeDeposit from '@/views/main/pages/transactions/reports/cheque/ChequeDeposit.vue'
import ChequeWithdrawal from '@/views/main/pages/transactions/reports/cheque/ChequeWithdrawal.vue'
import CashDeposit from '@/views/main/pages/transactions/reports/cash/CashDeposit.vue'
import CashWithdrawal from '@/views/main/pages/transactions/reports/cash/CashWithdrawal.vue'
import MomoDeposit from "@/views/main/pages/transactions/reports/momo/MomoDeposit.vue";
import MomoWithdrawal from "@/views/main/pages/transactions/reports/momo/MomoWithdrawal.vue";
import DirectDebitDeposit from "@/views/main/pages/transactions/reports/direct-debit/DirectDebitDeposit.vue";
import DirectDebitWithdrawal from "@/views/main/pages/transactions/reports/direct-debit/DirectDebitWithdrawal.vue";
import StatementFilter from './form/StatementFilter.vue'
// import PictureView from './modal/PictureView.vue'
// import DocumentView from './modal/DocumentView.vue'
export default {
    props: ['data', 'institution'],
    components: {
        BeatLoaderComponent,
        CashDeposit,
        CashWithdrawal,
        ChequeDeposit,
        ChequeWithdrawal,
        MomoDeposit,
        MomoWithdrawal,
        DirectDebitDeposit,
        DirectDebitWithdrawal,
        StatementFilter
    },
    computed: {
        getFullNameAndID() {
            const first_name = this.data.customer.first_name != undefined ? this.data.customer.first_name : "";
            const middle_name = this.data.customer.middle_name != undefined ? this.data.customer.middle_name : "";
            const last_name = this.data.customer.last_name != undefined ? this.data.customer.last_name : "";
            const code = this.data.customer.code != undefined ? this.data.customer.code : "";
            return first_name + ' ' + middle_name + ' ' + last_name + ' - ' + code;
        },

    },
    data() {
        return {
            search: "Search",
            isSearching: false,
            report_data: {},
            isBusy: false,
            filter: {
                account_number: "",
                start_date: this.getOneMonthBeforeDate(),
                end_date: new Date().toISOString().substr(0, 10),
            },
            form: {
                customer: [],
                branch: [],
                account_type: [],
            },
            imageLoaded: false,
            frontImgUrl: "",
            backImgUrl: "",
            isLoading: false,
            isBusy: false,
            user: "",
            fields: [
                {
                    key: 'trans_date',
                    label: 'Transaction_Date',
                },
                {
                    key: 'transaction_mode',
                    label: 'Mode',
                },
                {
                    key: 'narration',
                    label: 'Description',
                },
                {
                    key: 'trans_status',
                    label: 'Status',
                },
                {
                    key: 'debit',
                    label: 'Debit',
                },
                {
                    key: 'credit',
                    label: 'Credit',
                },
                {
                    key: 'current_balance',
                    label: 'Balance',
                },
                {
                    key: 'Teller',
                    label: 'teller',
                },
                {
                    key: 'action',
                    label: 'Action',
                },
            ],
            items: [],


        }
    },

    methods: {
        async getAccountStatement() {
            this.search = "Processing...";
            this.isSearching = true;
            this.isBusy = true
            await ApiService.query(`/accounts/statement/${this.data.account_number}`, {
                params: {
                    start_date: this.filter.start_date,
                    end_date: this.filter.end_date,
                }
            })
                .then((response) => {
                    this.search = "Search";
                    this.isSearching = false;
                    this.isBusy = false;
                    this.form = response.data?.account;
                    this.items = response.data.account?.transactions;
                    this.filter.account_number = response.data?.account?.account_number
                    // console.log(response.data?.account?.transactions);
                    this.handleImageLoad();
                }).catch((error) => {
                    this.search = "Search";
                    this.isSearching = false;
                    this.isBusy = false;
                });
        },
        filterAccountStatement() {
            this.getAccountStatement();
        },
        modalShown() {
            // console.log(this.data);
            this.getAccountStatement();
        },
        getOneMonthBeforeDate() {
            // Get today's date
            let currentDate = new Date();

            // Remove one month to the current date
            currentDate.setMonth(currentDate.getMonth() - 1);

            // Format the date as 'YYYY-MM-DD'
            return currentDate.toISOString().substr(0, 10);
        },
        statusBadge(item) {
            if (item === 'COMPLETED') {
                return 'badge akkurate-green-bg'
            } else if (item === 'FAILED') {
                return 'badge akkurate-danger-bg'
            } else if (item === 'REVERSED') {
                return 'badge akkurate-danger-bg'
            } else if (item === 'REJECTED') {
                return 'badge akkurate-danger-bg'
            } else if (item === 'PENDING') {
                return 'badge akkurate-warning-bg'
            } else {
                return 'badge akkurate-warning-bg'
            }
        },
        rowClass() {
            return 'custom-row-height'
        },
        handleImageLoad() {

            if (this.form.customer?.gh_card_front_path) {
                this.imageLoaded = true;
                this.frontImgUrl = this.form.customer?.gh_card_front_path;
            }
            if (this.form.customer?.gh_card_back_path) {
                this.imageLoaded = true;
                this.backImgUrl = this.form.customer?.gh_card_back_path;
            }


        },
        async transactionReverse(data) {
            // console.log(data.item);
            switch (data.item?.transaction_type) {
                case 'Deposit':
                    swal.fire({
                        // title: response.data.transaction.trans_status,
                        title: "Reverse Deposit Transaction",
                        // text: 'CODE : ' + response.data.transaction.code,
                        text: 'Are you sure?',
                        confirmButtonColor: "#018673",
                        icon: 'question',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showCancelButton: true, // Show the "Cancel" button
                        confirmButtonText: 'Yes', // Customize the text for the "OK" button
                        cancelButtonText: 'No', // Text for the custom button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                        }
                    }).then((result) => {
                        this.isLoading = true;
                        this.$Progress.start();
                        if (result.isConfirmed) {
                            this.reverseDeposit(data.item?.code);
                            this.getAccountStatement();
                            this.isLoading = false;
                            this.$Progress.finish();
                            // this.$bvModal.hide(String(this.loan_no + this.statement));
                        } else if (result.isDismissed) {
                            this.isLoading = false;
                            this.$Progress.finish();
                            this.$bvModal.hide(String(this.loan_no + this.statement));

                        }
                    })
                    break;
                case 'Withdrawal':
                    swal.fire({
                        // title: response.data.transaction.trans_status,
                        title: "Reverse Withdrawal Transaction",
                        // text: 'CODE : ' + response.data.transaction.code,
                        text: 'Are you sure?',
                        confirmButtonColor: "#018673",
                        icon: 'question',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showCancelButton: true, // Show the "Cancel" button
                        confirmButtonText: 'Yes', // Customize the text for the "OK" button
                        cancelButtonText: 'No', // Text for the custom button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            cancelButton: 'btn akkurate-ash modal-btn-width text-white' // Custom class for the custom button
                        }
                    }).then((result) => {
                        this.isLoading = true;
                        this.$Progress.start();
                        if (result.isConfirmed) {
                            this.reverseWithdrawal(data.item?.code);
                            this.getAccountStatement();
                            this.isLoading = false;
                            this.$Progress.finish();
                            // this.$bvModal.hide(String(this.loan_no + this.statement));
                        } else if (result.isDismissed) {
                            this.isLoading = false;
                            this.$Progress.finish();
                            this.$bvModal.hide(String(this.loan_no + this.statement));
                        }
                    })

                    break;
            }



        },
        async reverseDeposit(data) {

            await ApiService.post('/transactions/deposits/reverse', {
                code: data,
                source: "Web"
            }).then((response) => {
                toast.fire({
                    icon: "success",
                    title: response.data.message,
                });
                this.form.reset();
                this.form = this.initialState().form;
                console.log(response);
            }).catch((error) => {
                console.log(error);
                this.isLoading = false;
                this.$Progress.finish();
                // console.log(error.response.data)
                if (error.response?.status == 404) {
                    swal.fire({
                        icon: "error",
                        title: error.response.statusText,
                        text: "Something went wrong!",
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                        // footer: '<a href="#">Why do I have this issue?</a>'
                    });
                }

            })
        },
        async reverseWithdrawal(data) {
            await ApiService.post('/transactions/withdrawals/reverse', {
                code: data,
                source: "Web"
            }).then((response) => {
                toast.fire({
                    icon: "success",
                    title: response.data.message,
                });
                this.form.reset();
                this.form = this.initialState().form;

            }).catch((error) => {
                this.isLoading = false;
                this.$Progress.finish();
                if (error.response?.status == 404) {
                    swal.fire({
                        icon: "error",
                        title: error.response.statusText,
                        text: error.response?.data?.message,
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                        // footer: '<a href="#">Why do I have this issue?</a>'
                    });
                }

            })

        },
        printReceipt(data) {
            // console.log(data.item);
            this.report_data = {
                'trans_date': data.item.trans_date,
                'account_number': this.form.account_number,
                'fullname': this.getFullNameAndID,
                'transaction_mode': data.item.transaction_mode,
                'transaction_type': data.item.transaction_type,
                'amount': data.item.trans_amount,
                'cheque_no': "not avaliable",
                'bank_name': "not avaliable",
                'branch_name': this.form.branch?.name,
                'narration': data.item.narration,
                'institution': this.institution,
                'receipt_no': data.item.id,
                'teller': data.item?.created_user?.first_name + ' ' + data.item?.created_user?.surname,
                'denomination': data.item.denominations,
                'mobile': data.item.mobile,
                'network': data.item.network,
                'external_reference': data.external_reference,
                'momo_reference': data.momo_reference,

            };
            switch (data.item.transaction_mode) {
                case "Cash":
                    if (data.item.transaction_type == 'Deposit') {
                        this.$refs.showCashDeposit.showModal();
                        // console.log(this.form);

                    } else if (data.item.transaction_type == 'Withdrawal') {
                        this.$refs.showCashWithdrawal.showModal();
                    }

                    break;
                case "Cheque":
                    if (data.item.transaction_type == 'Deposit') {
                        this.$refs.showChequeDeposit.showModal();
                        // console.log(this.form);

                    } else if (data.item.transaction_type == 'Withdrawal') {
                        this.$refs.showChequeWithdrawal.showModal();
                    }
                    break;
                case "Momo":
                    if (data.item.transaction_type == 'Deposit') {
                        this.$refs.showMomoDeposit.showModal();
                        // console.log(this.form);

                    } else if (data.item.transaction_type == 'Withdrawal') {
                        this.$refs.showMomoWithdrawal.showModal();
                    }
                    break;
                case "Direct Debit":
                    if (data.item.transaction_type == 'Deposit') {
                        this.$refs.showDirectDebitDeposit.showModal();
                        // console.log(this.form);

                    } else if (data.item.transaction_type == 'Withdrawal') {
                        this.$refs.showDirectDebitWithdrawal.showModal();
                    }
                    break;
            }
        }
    }
}
</script>