<template>
  <div id="wrapper">
    <SidebarView :journal="true" :add-journal="true" :journal-visible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <form @submit.prevent="submitJournal()" :add_or_edit="add_or_edit">
          <div class="mx-3">
            <div class="general-error d-flex justify-content-center">
              <errors v-if="form.message">
                {{ form.message }}
              </errors>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-12">
                <div class="card card-shape home-box">
                  <div class="card-header pe-1 d-flex flex-row align-items-center justify-content-between">
                    <h6 class="m-0 fw-bold text-green">Create Journal</h6>
                    <button type="submit" :disabled="isSaving" class="btn akkurate-green-btn text-white btn-size">
                      <font-awesome-icon class="me-2" :icon="['fas', 'save']" />{{ save }}
                    </button>
                  </div>
                  <div class="card-body">
                    <journal-form :form="form" :branch_options="branch_options"
                      @desChecked="updateStatus" :edit_date_pemission="edit_date_pemission"></journal-form>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="d-flex flex-row align-items-center justify-content-between">
                <div class="err-accounts">
                  <errors v-if="form.errors.accounts">
                    {{ form.errors.accounts[0] }}
                  </errors>
                </div>
                <div class="col-12 col-md-12">
                  <div class="card card-shape home-box">
                    <div class="card-header d-flex flex-row align-items-center py-2">
                      <h6 class="m-0 fw-bold text-green">Accounts</h6>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 col-md-2">
                          <label class="akkurate-dark" for="dr_amount">Debit Amount <star></star></label>
                        </div>
                        <div class="col-12 col-md-2">
                          <label class="akkurate-dark" for="cr_amount">Credit Amount <star></star></label>
                        </div>
                        <div class="col-12 col-md-3">
                          <label class="akkurate-dark" for="dr_amount">COA Subhead <star></star></label>
                        </div>
                        <div class="col-12 col-md-3">
                          <label class="akkurate-dark" for="dr_amount">COA Description</label>
                        </div>

                      </div>
                      <div class="row" v-for="(field, index) in documents" :key="index">
                        <div class="col-12 col-md-2">
                          <b-form-group label-for="dr_amount">
                            <b-form-input id="dr_amount" name="dr_amount" class="mt-2 field-container fields"
                              type="number" step="any" v-model="field.dr_amount" :disabled="drDisabled(field)"
                              required></b-form-input>
                            <errors v-if="form.errors.dr_amount">
                              {{ form.errors.dr_amount[0] }}
                            </errors>
                          </b-form-group>
                        </div>

                        <div class="col-12 col-md-2">
                          <b-form-group>
                            <b-form-input id="cr_amount" v-model="field.cr_amount" name="cr_amount"
                              class="mt-2 field-container fields" type="number" step="any" :disabled="crDisabled(field)"
                              required></b-form-input>
                            <errors v-if="form.errors.cr_amount">
                              {{ form.errors.cr_amount[0] }}
                            </errors>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-3">
                          <b-form-group>
                            <multiselect v-model="field.coa_subhead" name="coa_subhead" :options="coa_subhead_options"
                              class="mt-2 field-container" label="name" :custom-label="nameWithLang" required>
                            </multiselect>
                            <errors v-if="form.errors.account_type">
                              {{ form.errors.account_type[0] }}
                            </errors>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-4">
                          <b-form-group>
                            <b-form-input id="coa_description" v-model="field.coa_description" name="coa_description"
                              class="mt-2 field-container fields" placeholder="Enter COA Description" type="text"
                              :disabled="coa_desc_state"></b-form-input>
                            <errors v-if="form.errors.coa_description">
                              {{ form.errors.coa_description[0] }}
                            </errors>
                          </b-form-group>
                        </div>
                        <div class="col mt-auto">
                          <a title="Delete" class="btn btn-sm akkurate-danger-btn text-white"
                            @click="removeField(index)">
                            <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'trash']" /></span>
                          </a>
                        </div>
                      </div>
                      <!-- Displaying Total Debit and Total Credit -->
                      <div class="row mt-4">
                        <div class="col-12 col-md-2">
                          <b-form-group>
                            <label>Total Debit</label>
                            <b-form-input class="field-container fields" type="text" :value="totalDebit"
                              disabled></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-12 col-md-2">
                          <b-form-group>
                            <label>Total Credit</label>
                            <b-form-input class="field-container fields" type="text" :value="totalCredit"
                              disabled></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-12 col-md-3">
                          <b-form-group>
                            <label>Difference</label>
                            <b-form-input class="field-container fields" type="text"
                              :value="totalDebit - totalCredit" disabled></b-form-input>
                          </b-form-group>
                        </div>
                      </div>
                      <p class="akkurate-danger small">{{ acc_error }}</p>
                      <div class="row mt-3">
                        <div class="col-md-12">
                          <button type="button" @click="addAccount" class="btn akkurate-green-btn text-white btn-size">
                            <font-awesome-icon class="me-2" :icon="['fas', 'plus']" /> Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
      <footer></footer>
    </div>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import Footer from "@/views/main/components/Footer.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import JournalForm from '../forms/JournalForm.vue'
import AddAccount from '../forms/modal/AddAccount.vue'
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import { hasPermission } from '@/helper';

export default {
  name: "JournalCreate",
  mounted() {
    this.loadPageOptions();
    this.checkPemission();
  },
  components: {
    SidebarView,
    Footer,
    TopbarView,
    JournalForm,
    AddAccount,
    Multiselect,
  },
  data() {
    return {
      ...this.initialState(),
      coa_subhead_options: [],
      branch_options: [],
      add_or_edit: 'add',
      save: "Post",
      isSaving: false,
      acc_error: "",
      coa_desc_state: true,
      edit_date_pemission: true,
    };
  },
  computed: {
    totalDebit() {
      return this.documents.reduce((sum, field) => sum + parseFloat(field.dr_amount || 0), 0);
    },
    totalCredit() {
      return this.documents.reduce((sum, field) => sum + parseFloat(field.cr_amount || 0), 0);
    }
  },
  methods: {
    checkPemission() {
      if (hasPermission('journal_management_edit_date')) {
        this.edit_date_pemission =  false
      } else {
        this.edit_date_pemission =  true
      }
    },
    loadPageOptions() {
      this.$Progress.start();
      this.isLoading = true;
      ApiService.get("/journals/dropdown")
        .then((response) => {
          this.coa_subhead_options = response.data.coa_subheads;
          // this.branch_options = response.data.branches;
          this.form.branch = response.data.user_branch;
          // console.log(response.data.branches?.[0].name);
          this.isLoading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.fail();
        });
    },

    addAccount() {
      this.documents.push({ dr_amount: "", cr_amount: "", coa_subhead: { id: "", name: "" } });
      if (this.documents.length < 2) {
        this.acc_error = "Account must be more than one"
        this.isSaving = true
      } else {
        this.acc_error = ""
        this.isSaving = false
      }
    },
    removeField(index) {
      this.documents.splice(index, 1);
      if (this.documents.length < 2) {
        this.acc_error = "account must be more than one"
        this.isSaving = true
      } else {
        this.acc_error = ""
        this.isSaving = false
      }
    },
    submitJournal() {
      this.isSaving = true;
      this.$Progress.start();
      this.save = "Posting..."
      this.form.vouch_journ_coa_accounts = this.documents
      ApiService.post("/journals/store", this.form)
        .then((response) => {
          this.isSaving = false;
          this.$Progress.finish();
          this.save = "Post"
          toast.fire({
            icon: "success",
            title: response.data.message,
          });

          this.form = this.initialState().form;
          this.documents = [{ dr_amount: "", cr_amount: "", coa_subhead: { id: "", name: "", code: "" } }, { dr_amount: "", cr_amount: "", coa_subhead: { id: "", name: "", code: "" } }]
          this.isSaving = false;
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          this.save = "Post"
          if (error.response.data) {
            this.form.errors = error.response.data.message;
          }
          //error message
          if (error.response.data.message) {
            this.form.message = error.response.data.message;
          }
          // console.log(error.response.data)
        });
    },

    drDisabled(field) {
      if (field.cr_amount.length > 0) {
        field.dr_amount = 0;
        return true;
      }
    },
    crDisabled(field) {
      if (field.dr_amount.length > 0) {
        field.cr_amount = 0;
        return true;
      }
    },
    updateStatus(newStatus) {
      // console.log(newStatus);
      this.coa_desc_state = newStatus === 'accepted';
    },

    documentFile(data) {
      // console.log(data);
      this.form.receipt_name = data.name;
      this.form.receipt_location = data.path
    },
    nameWithLang({ name, code, coabranch }) {
      return `${name} — ${code == undefined ? "" : code} ${coabranch?.name == undefined ? "" : coabranch?.name}`;
    },
    initialState() {
      return {
        form: {
          branch: "",
          amount_requested: "",
          recorded_date: new Date().toLocaleDateString('en-CA'),
          narration: "",
          receipt_name: "",
          receipt_location: "",
          vouch_journ_coa_accounts: [],
          errors: [],
        },
        documents: [{ dr_amount: "", cr_amount: "", coa_subhead: { id: "", name: "", code: "" } }, { dr_amount: "", cr_amount: "", coa_subhead: { id: "", name: "", code: "" } }],
      }
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>