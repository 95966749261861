<template>
    <div class="row">

           <!-- employment status -->
             
           <div class="col-12 col-md-6">
           <label class="akkurate-dark" for="employer_name">Employment Status</label>
           <!-- <b-form-group
                id="employment_status"
                label-for="employment_status">
                <multiselect class="field-container mb-3 mt-2 " 
                v-model="form.employment_status" 
                selectLabel=""
                deselectLabel=""       
                :options="employment_statuses"></multiselect>
                <errors v-if="form.errors.employment_status">
                  {{ form.errors.employment_status[0] }}
                </errors>
            </b-form-group> -->
           </div>


           <div class="col-12 col-md-6">
           <label class="akkurate-dark" for="employer_name">Name of Institution/ Employer Name</label>
               <b-form-group
               id="employer_name"
               label-for="employer_name">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter name of institution/ employer name"
                v-model="form.employer_name"
                :disabled="add_or_edit == 'view'"
               ></b-form-input>
               <errors v-if="form.errors && form.errors.employer_name">
                  {{ form.errors.employer_name[0] }}
                </errors>
               </b-form-group>
           </div>

           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="staff_id">Staff Number</label>
               <b-form-group
               id="staff_id"
               label-for="staff_id">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Staff Number"
                v-model="form.staff_id"
                :disabled="add_or_edit == 'view'"
               ></b-form-input>
               <errors v-if="form.errors && form.errors.staff_id">
                  {{ form.errors.staff_id[0] }}
                </errors>
               </b-form-group>
           </div>
           <div class="col-12 col-md-6">
               <b-form-group
                label-for="employer_address"
                class="mb-2">
                <template #label>
                  <label class="akkurate-dark" for="employer_address">Employer's Address</label>
                </template>
                  <b-form-textarea
                  class="mt-2 fields"
                  id="employer_address"
                :disabled="add_or_edit == 'view'"
                  v-model="form.employer_address"
                  placeholder="Enter Employer's Address..."
                ></b-form-textarea>
              </b-form-group>
              
           </div>
           
           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="employee_position">Position</label>
               <b-form-group
               id="employee_position"
               label-for="employee_position">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter Position"
                v-model="form.employee_position"
                :disabled="add_or_edit == 'view'"
               ></b-form-input>
               <errors v-if="form.errors && form.errors.employee_position">
                  {{ form.errors.employee_position[0] }}
                </errors>
               </b-form-group>
           </div>


           <div class="col-12 col-md-6">
               <label class="akkurate-dark" for="occupation">Occupation</label>
               <b-form-group
               id="occupation"
               label-for="occupation">
               <b-form-input
               class="mb-3 mt-2 field-container fields"
               type="text"
               placeholder="Enter occupation"
                :disabled="add_or_edit == 'view'"
                v-model="form.occupation"
               ></b-form-input>
               <errors v-if="form.errors && form.errors.occupation">
                  {{ form.errors.occupation[0] }}
                </errors>
               </b-form-group>
           </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
// import ApiService from "@/core/services/api.service";
export default {
  name: "EmployerDetails",
  props: ['form', 'add_or_edit'],
  components: {
    Multiselect
  },
  data() {
    return {
      back_text_visible: false,
      employment_statuses: [
        "Employed",
        "Unemployed",
        "Self Employed",
        "Retired",
        "Student",
        "Trainee",
      ]
    }
  },
  methods: {
    
  }
}
</script>