<template>
    <div id="wrapper">
        <SidebarView />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <b-container>
                    <b-row>
                        <div>
                            <b-tabs justified>
                                <b-tab title="Overview" active>
                                    <div class="card">
                                        <div class="card-body">
                                            <form>
                                                <overview-form :form="retrived" :roles="roles"></overview-form>
                                                <hr />
                                                <div class="row my-4">
                                                    <div class="col-12 col-md-12 text-center">
                                                        <b-button type="submit" variant="primary"
                                                            class="akkurate-green-btn"><font-awesome-icon class="me-2"
                                                                :icon="['fas', 'save']" />Update</b-button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Change Password">
                                    <div class="card">
                                        <div class="card-body">
                                            <form @submit.prevent="changePassword()">
                                                <change-password :form="form"></change-password>
                                                <hr class="mt-4" />
                                                <div class="row my-4">
                                                    <div class="col-12 col-md-12 text-center">
                                                        <b-button type="submit" variant="primary"
                                                            class="akkurate-green-btn"> <font-awesome-icon class="me-2"
                                                                :icon="['fas', 'save']" />Update</b-button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </b-row>
                </b-container>

            </div>
            <footer></footer>
        </div>

    </div>


</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import Footer from '@/views/main/components/Footer.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import OverviewForm from '../forms/Overview';
import ChangePassword from '../forms/ChangePassword';
import ApiService from "@/core/services/api.service";
export default {
    name: "ProfileView",
    components: {
        SidebarView,
        Footer,
        TopbarView,
        OverviewForm,
        ChangePassword
    },
    // data: () => ({
    //     retrived: [],
    //     roles: [],

    // }),
    data() {
        return {
            ...this.initiateState(),
            retrived: [],
            roles: [],

        }
    },
    mounted() {
        this.getUserProfile();
    },
    methods: {
        async getUserProfile() {
            const response = await ApiService.get('users/profile')
                .then(function (success) {
                    if (success.status == 200) {
                        return success.data;
                    }
                }).catch(function (error) {
                    return error;
                });
            if (response != undefined) {
                this.retrived = response.user
                this.roles = response.user.roles[0]
            }

        },
        async changePassword() {
            console.log(this.form);
            ApiService.post("users/password/update", this.form).then((response) => {
                // console.log(response);
                this.isSaving = false;
                this.$Progress.finish();
                toast.fire({
                    icon: "success",
                    title: response.data.message,
                });
                this.isSaving = false;
                this.form = this.initiateState().form;
                setTimeout(() => {
                    window.location.reload();
                }, 1000);

            }).catch((error) => {
                this.isSaving = false;
                this.$Progress.fail();
                // console.log(error);
                if (error.response.data.errors) {
                    // console.log(error.response.data.errors);
                    this.form.errors = error.response.data.errors;
                }

                //error message
                if (error.response.data.message) {
                    this.form.message = error.response.data.message;
                }
                console.log(error.response.data)
            });
        },
        initiateState() {
            return {
                form: {
                    current_password: "",
                    password: "",
                    password_confirmation: "",
                }
            }
        }
    }
}
</script>