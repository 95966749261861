<template>
  <div>
    <div>
      <b-button class="akkurate-green-btn me-2" @click="add_or_edit = 'add'; $bvModal.show('roles-modal')">
        <span><font-awesome-icon :icon="['fas', 'plus']" /></span>
      </b-button>
      <b-button :disabled="disable_edit" class="akkurate-green-btn" @click="openEditModal()">
        <span><font-awesome-icon :icon="['fas', 'edit']" /></span>
      </b-button>
      <!-- Modal code here -->
    </div>

    <b-modal id="roles-modal" ref="modal" @ok="handleOk" @hidden="resetModal">
      <template #modal-title>
        <h6 class="akkurate-green fw-bold mb-0">{{ add_or_edit == 'add' ? 'Add Roles' : 'Edit Roles' }}</h6>
      </template>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label-for="name-input" invalid-feedback="Name is required" :state="nameState">
          <template #label>
            <label>Name <star></star></label>
          </template>
          <b-form-input id="name-input" :disabled="add_or_edit == 'edit'" class="mb-3 mt-2 field-container fields" v-model="form.display_name" required>
          </b-form-input>
        </b-form-group>
        <b-form-group label-for="name-input" invalid-feedback="Withdraw max thershold is required"
          :state="unapproved_withdrawal_max_amountState">
          <template #label>
            <label>Withdrawal Max Threshold <star></star></label>
          </template>
          <b-form-input id="name-input" class="mb-3 mt-2 field-container fields"
            v-model="form.unapproved_withdrawal_max_amount">
          </b-form-input>

          <errors v-if="form.errors?.first_name">
            {{ form.errors?.first_name[0] }}
          </errors>
        </b-form-group>
      </form>
      <template #modal-footer="{ ok }">
        <div class="w-100">
          <b-button @click="ok()" type="submit" class="akkurate-green-btn btn-size me-2 float-right"><font-awesome-icon
              class="me-2" :icon="['fas', 'save']" />Save</b-button>
          <b-button @click="$bvModal.hide('roles-modal')" type="button"
            class="akkurate-grey-btn btn-size border-0 me-2 float-right"><font-awesome-icon class="me-2"
              :icon="['fas', 'close']" />Close</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "RolesModal",
  props: ['selected_role','disable_edit'],
  // selected_role: {
  //   handler(newVal) {
  //     console.log("Here");
  //     // Disable edit button if selected_role is empty, otherwise enable it
  //     this.disable_edit = !newVal || Object.keys(newVal).length === 0;
  //   },
  //   immediate: true, // Trigger the handler immediately on component creation
  //   deep: true // Watch for nested changes in selected_role
  // },
  data() {
    return {
      form: {
        id:"",
        display_name: "",
        unapproved_withdrawal_max_amount: "",
      },
      unapproved_withdrawal_max_amountState: null,
      nameState: null,
      errors: [],
      add_or_edit: "add",
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      this.unapproved_withdrawal_max_amountState = valid;
      return valid
    },
    resetModal() {
      this.form.display_name = ''
      this.form.unapproved_withdrawal_max_amount = ''
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      if(this.add_or_edit == 'add'){
        this.$emit('saveRole', this.form);
      }else{
        this.$emit('editRole', this.form);
      }
      
      this.$emit('resetForm', this.resetModal())
      this.resetModal();
      // Hide the modal manually
      this.$nextTick(() => {
        // this.$bvModal.hide("roles-modal");
      });
    },
    openEditModal() {
      // console.log(this.selected_role);
      this.add_or_edit = 'edit';
      this.form.id = this.selected_role.id; // Populate form with role data
      this.form.display_name = this.selected_role.display_name; // Populate form with role data
      this.form.unapproved_withdrawal_max_amount = this.selected_role.unapproved_withdrawal_max_amount; // Populate form with role data
      // console.log(this.form);
      this.$bvModal.show('roles-modal');
    }
  }
};
</script>