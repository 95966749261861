<template>
    <div>
        <!-- <a title="Loan Statement" class="me-3 btn-size akkurate-green" v-b-modal="String(data.loan_code)" ><font-awesome-icon :icon="['fas', 'book']" /></a> -->
        <b-modal 
         :id="String(data.id + statement)"
         @shown="modalShown"
         size="xxl">
            <template #modal-title>
                <p class="m-0 fw-bold text-green">Loan Statement</p>
            </template>
            <div class="row">
                <div class="col-md-12">
                    <beat-loader-component
                    v-if="isLoading"
                    :isLoading="isLoading">
                    </beat-loader-component>
                </div>
             </div>
             <div class="container mb-3">
                <div class="row">
                    <form @submit.prevent="filterLoanStatement">
                        <loan-filter :filter="filter" :is-searching="isSearching" :search="search"></loan-filter>
                    </form>
                </div>
             </div>
             <hr/>
             <div class="container text-start" ref="pdfContent">
                <statement-institution :institution="institution" :title="'LOAN STATEMENT OF ACCOUNT'"></statement-institution>
                <hr class="mt-0 akkurate-danger"/>

                <div class="row">
                <div class="col-md-5">
                    <h6 class="akkurate-dark report-l-text">ACCOUNT DETAILS</h6>
                    <hr class="w-100 mt-0"/>
                    <div class="row akkurate-small">
                        <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                            Customer Name
                        </div>
                        <div class="col-md-8 report-s-text">
                            {{ getFullName }}
                        </div>
                        <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                            Ext. Code
                        </div>
                        <div class="col-md-8 report-s-text">
                            {{ this.details.account?.customer.external_code }}
                        </div>
                        <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                            Loan Code
                        </div>
                        <div class="col-md-8 report-s-text">
                            {{ details?.loan_number }}
                        </div>
                        <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                           Loan Type
                        </div>
                        <div class="col-md-8 report-s-text">
                           {{ details?.loan_product?.name }}
                        </div>
                        <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                           Branch
                        </div>
                        <div class="col-md-8 report-s-text">
                           {{ branch?.name }}
                        </div>
                        <div class="col-md-4 fw-bold akkurate-gray report-s-text">
                           Currency
                        </div>
                        <div class="col-md-8 report-s-text">
                           {{ institution?.currency_code }}
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
                <div class="col-md-4">
                    <h6 class="akkurate-dark report-l-text">ACCOUNT SUMMARY</h6>
                    <hr class="w-100 mt-0"/>
                    <div class="row akkurate-small">
                        <div class="col-md-5 fw-bold akkurate-gray">
                           Principal
                        </div>
                        <div class="col-md-7 report-s-text">
                            {{ institution?.currency_code }} {{ displayNumber(details?.principal_amount) }}
                        </div>
                        <div class="col-md-5 fw-bold akkurate-gray">
                           Expected Interest
                        </div>
                        <div class="col-md-7 report-s-text">
                            {{ institution?.currency_code }} {{ displayNumber(details?.expected_interest) }}
                        </div>
                        <div class="col-md-5 fw-bold akkurate-gray">
                           Interest Due
                        </div>
                        <div class="col-md-7 report-s-text">
                            {{ institution?.currency_code }} {{ displayNumber(details?.interest_due) }}
                        </div>
                        <div class="col-md-5 fw-bold akkurate-gray ">
                            Rate
                        </div>
                        <div class="col-md-7 report-s-text">
                            {{ details?.interest_rate }}% / {{ details?.interest_rate_frequency }}
                        </div>
                        <div class="col-md-5 fw-bold akkurate-gray ">
                            Tenure
                        </div>
                        <div class="col-md-7 report-s-text">
                           {{ details?.tenor }} {{ details?.tenor_interval }}
                        </div>
                        <div class="col-md-5 fw-bold akkurate-gray ">
                            Status
                        </div>
                        <div class="col-md-7 report-s-text">
                           {{ details.status }}
                        </div>
                        <div class="col-md-5 fw-bold akkurate-gray ">
                            Interest Balance
                        </div>
                        <div class="col-md-7 report-s-text">
                           {{ details.interest_balance }}
                        </div>
                        <div class="col-md-5 fw-bold akkurate-gray ">
                            Principal Balance
                        </div>
                        <div class="col-md-7 report-s-text">
                           {{ details.principal_balance }}
                        </div>
                        <hr class="w-100 mt-0 mb-2"/>
                        <div class="col-md-5 fw-bold akkurate-gray ">
                           Current Balance
                        </div>
                        <div class="col-md-7 fw-bold report-s-text">
                            {{ institution?.currency_code }} {{ displayNumber(details?.total_loan_balance) }}
                        </div>
                    </div>
                </div>
                </div>

                <div class="row mt-5">
                    <div class="col-6">
                        <h6 class="report-l-text">ACCOUNT TRANSACTIONS</h6>
                    </div>
                    <div class="col-6 text-end">
                        <h6 class="report-l-text"><span class="fw-bold">From:</span> {{ filter?.start_date }}  <span class="fw-bold">To:</span> {{ filter?.end_date }}</h6>
                    </div>
                    <div class="col-12 d-flex my-3 flex-wrap">
                       <div class="d-flex align-items-center">
                            <p class="mb-0 akkurate-extra-small me-2 akkurate-dark"><font-awesome-icon :icon="['fas', 'circle']" /></p>
                            <span class="akkurate-extra-small me-2">Prev.BAL(Previous Balance)</span>
                       </div>
                        <div class="d-flex align-items-center">
                            <p class="mb-0 akkurate-extra-small me-2 akkurate-orange-text"><font-awesome-icon :icon="['fas', 'circle']" /></p>
                            <span class="akkurate-extra-small me-2">PP(Principal Paid)</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <p class="mb-0 akkurate-extra-small me-2 akkurate-danger"><font-awesome-icon :icon="['fas', 'circle']" /></p>
                            <span class="akkurate-extra-small me-2">PB(Principal Balance)</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <p class="mb-0 akkurate-extra-small me-2 akkurate-green"><font-awesome-icon :icon="['fas', 'circle']" /></p>
                            <span class="akkurate-extra-small me-2">IP(Interest Paid)</span>
                        </div>
                        <div class="d-flex align-items-center">
                            <p class="mb-0 akkurate-extra-small me-2 akkurate-blue-text"><font-awesome-icon :icon="['fas', 'circle']" /></p>
                            <span class="akkurate-extra-small me-2">IB(Interest Balance)</span>
                        </div>
                    </div>
                    <div class="col-md-12 trans_details" >
                        <b-table responsive :fields="fields" :items="items">
                            <template #cell(debit)="data">
                                <p class="mb-0" v-if="data?.item?.transaction_type == 'Loan Disbursed'">{{ displayNumber(data?.item?.trans_amount) }}</p>
                            </template>

                            <template #cell(credit)="data">
                                <p class="mb-0" v-if="data?.item?.transaction_type == 'Loan Repayment'">{{ displayNumber(data?.item?.trans_amount) }}</p>
                            </template>

                            <template #cell(description)="data">
                                <p class="mb-0">{{ data?.item?.transaction_type }} By {{ data?.item?.transaction_mode }}</p>
                            </template>
                        </b-table>
                    </div>
                    <div class="col-md-12 text-center end_statemt">
                        *********************************
                        END OF STATEMENT 
                        *********************************
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                <!-- begin loader -->
                <!-- <beat-loader-component 
                class="float-left"
                v-if="isLoading"
                :isLoading="isLoading"
                ></beat-loader-component> -->
                <!-- end loader -->
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button @click="printPDF" type="button" class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon :icon="['fas', 'book']" /> Print</span></b-button>
                <b-button @click="downloadPDF" class="akkurate-green-btn download-btn float-right"><span class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>     
            </div>
            </template>
        
        </b-modal>
    </div>
</template>
<script>
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import ApiService from "@/core/services/api.service";
import LoanFilter from './form/LoanFilter.vue'
import html2pdf from "html2pdf.js";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
export default {

    props: ['data', 'institution', 'branch', 'statement'],
    components: {
        BeatLoaderComponent,
        LoanFilter,
        StatementInstitution
    },
    data() {
        return {
            filter: {
                loan_code: "",
                start_date: this.getOneMonthBeforeDate(),
                end_date: new Date().toISOString().substr(0, 10),
            },
            frontImgUrl: "",
            backImgUrl: "",
            imageLoaded: false,
            isLoading: false,
            details: {
                fullname: "",
                customer: "",
                account_number: "",
                gender: "",
                relationship_officer: "",
                created_at: "",
                date_of_birth: "",
                gh_card_front_path: "",
                gh_card_back_path: "",
                interest_balance: "",
                principal_amount: "",
                interest_rate: "",
            },
            search: "Search",
            isSearching: false,
            fields: [
                {
                    key: 'trans_date',
                    label: 'Date',
                },

                {
                    key: 'description',
                    label: 'Description',
                },
                {
                    key: 'debit',
                    label: 'Debit',
                },
                {
                    key: 'credit',
                    label: 'Credit',
                },

                {
                    key: 'previous_balance',
                    label: 'Prev.BAL',
                },
                // Principal component of the credit amount 
                {
                    key: 'principal',
                    label: 'Principal',
                },
                // Interest component of the credit amount
                {
                    key: 'interest',
                    label: 'Interest',
                },
                {
                    key: 'principal_paid',
                    label: 'PP',
                },
                {
                    key: 'principal_balance',
                    label: 'PB',
                },
                {
                    key: 'interest_paid',
                    label: 'IP',
                },
                {
                    key: 'interest_balance',
                    label: 'IB',
                },
            ],
            items: [],

        }
    },
    computed: {
        getFullName() {
            // console.log(this.details);
            const first_name = this.details.account?.customer.first_name != undefined ? this.details.account?.customer.first_name : "";
            const middle_name = this.details.account?.customer.middle_name != undefined ? this.details.account?.customer.middle_name : "";
            const last_name = this.details.account?.customer.last_name != undefined ? this.details.account?.customer.last_name : "";
            return first_name + ' ' + middle_name + ' ' + last_name;

        },
    },
    methods: {
        filterLoanStatement() {
            // console.log(this.filter.start_date);
            this.search = "processing...";
            this.isSearching = true;
            ApiService.query("/loans/loanStatements", {
                params: {
                    loan_number: this.filter?.loan_code,
                    start_date: this.filter?.start_date,
                    end_date: this.filter?.end_date,
                }
            }).then((response) => {
                // console.log(response.data.data);
                this.details = response.data.data;
                this.isLoading = false;
                this.items = response.data.data.account.transactions.map((index) => {
                    return {
                        trans_date: index.trans_date,
                        transaction_type: index.transaction_type,
                        transaction_mode: index.transaction_mode,
                        trans_amount : index.trans_amount,
                        debit: Math.round(index.debit * 100) / 100,
                        credit: Math.round(index.credit * 100) / 100,
                        previous_balance: Math.round(index.previous_balance * 100) / 100,
                        principal: Math.round(index.principal * 100) / 100,
                        principal_paid: Math.round(index.principal_paid * 100) / 100,
                        principal_balance: Math.round(index.principal_balance * 100) / 100,
                        interest_paid: Math.round(index.interest_paid * 100) / 100,
                        interest_balance: Math.round(index.interest_balance * 100) / 100,
                        interest: index.interest,
                        denomination: index.denominations,
                    }
                });
                this.handleImageLoad();
                this.search = "Search";
                this.isSearching = false;
            }).catch((error) => {
                this.search = "Search";
                this.isSearching = false;
                if (error.response.status == 404) {
                    swal.fire({
                        icon: "error",
                        title: error.response.statusText,
                        text: "Something went wrong!",
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                        // footer: '<a href="#">Why do I have this issue?</a>'
                    });
                }
            })
        },
        handleImageLoad() {
            this.imageLoaded = true;
            this.frontImgUrl = this.details.account?.customer?.gh_card_front_path;
            this.backImgUrl = this.details.account?.customer?.gh_card_back_path;
        },
        downloadPDF() {

            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: [0.3, 0.1, 1.2, 0.1],
                filename: 'statement.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        pdf.text(["For Inquiries, Please Contact " + insit_name + ", Customer Service on " + insit_tele + "", "or email us on " + insit_email + " " + insit_motto + "", "Page " + i + " of " + totalPages], pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 0.6, { align: "center" });
                    }
                }).save();

        },
        printPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: [0.3, 0.1, 1.2, 0.1],
                filename: 'statement.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
            };
            html2pdf()
                .from(content)
                .set(pdfOptions)
                .toPdf()
                .get('pdf')
                .then((pdf) => {
                    var totalPages = pdf.internal.getNumberOfPages();
                    var insit_name = this.institution.name;
                    var insit_tele = this.institution.telephone;
                    var insit_email = this.institution.email;
                    var insit_motto = this.institution.motto;

                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(150);
                        pdf.text(["For Inquiries, Please Contact " + insit_name + ", Customer Service on " + insit_tele + "", "or email us on " + insit_email + " " + insit_motto + "", "Page " + i + " of " + totalPages], pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() - 0.6, { align: "center" });
                    }

                    // Get the blob of the PDF
                    return pdf.output("blob");
                })
                .then((pdfBlob) => {
                    // Create a URL for the PDF blob
                    var pdfUrl = URL.createObjectURL(pdfBlob);

                    // Open the URL in a new tab
                    const printWindow = window.open(pdfUrl, '_blank', 'width=800,height=600');
                    printWindow.onload = () => {
                        printWindow.print();
                        // URL.revokeObjectURL(url);
                    };
                })
                .catch((error) => {
                    console.error("Error generating PDF:", error);
                });
        },
        getOneMonthBeforeDate() {
            // Get today's date
            let currentDate = new Date();

            // Remove one month to the current date
            currentDate.setMonth(currentDate.getMonth() - 1);

            // Format the date as 'YYYY-MM-DD'
            return currentDate.toISOString().substr(0, 10);
        },
        modalShown() {
            // console.log(this.data);
            if(this.data.id != 'app_statement'){
                this.filter.loan_code = this.data?.loan?.loan_number;
                this.filterLoanStatement();
            }
          
        }


    }
}
</script> 
<style>
.instit_details {
    font-size: 16px;
}

.trans_details {
    font-size: 12px;
}

.end_statemt {
    font-size: 12px;
}
</style>