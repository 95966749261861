<template>
  <div id="wrapper">
    <SidebarView :reports="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container">
          <div class="card card-shape home-box">
            <div class="card-header py-3">
              <div class="row align-items-center">
                <div class="col-6">
                  <h6 class="m-0 fw-bold text-green">Account Statistics Reports</h6>
                </div>
                <div class="col-6 text-end">
                  <report-filter :form="form" @filterAction="filterAction" :filter="filter"></report-filter>
                  <b-button @click="printPDF" class="me-2 btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon
                      :icon="['fas', 'book']" /></b-button>
                  <b-button @click="downloadPDF" class="btn-sm akkurate-green-btn akkurate-small"><font-awesome-icon
                      :icon="['fas', 'download']" /></b-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="container-fluid" ref="pdfContent">
                <div class="row small">
                  <div class="col-12 col-md-12">
                    <statement-institution :institution="institution"
                      :title="'ACCOUNT STATISTICS REPORT'"></statement-institution>
                  </div>
                  <div class="col-12 text-end text-uppercase">
                    {{ new Intl.DateTimeFormat('en-GB', {
                      day: 'numeric', month: 'long', year: 'numeric'
                    }).format(new Date(form.start_date)) }} - {{ new Intl.DateTimeFormat('en-GB', {
                      day: 'numeric', month: 'long', year: 'numeric'
                    }).format(new Date(form.end_date)) }}
                  </div>
                  <hr />
                  <div v-if="isBusy">
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>

                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col-12 p-0 fw-bold mb-2">
                        MEMBERSHIP INFORMATION
                      </div>
                      <div class="col-12 px-0 trans_details mb-2">
                        <b-table-simple bordered responsive outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th class="py-1" width="50%">MEMBERS</b-th>
                              <b-th class="py-1">Total</b-th>
                              <b-th class="py-1">Females</b-th>
                              <b-th class="py-1">Males</b-th>
                              <b-th class="py-1">Groups</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <td class="py-0 px-2">Active Members</td>
                              <td class="py-0 px-2">{{ total_active_member }}</td>
                              <td class="py-0 px-2">{{ active_female }}</td>
                              <td class="py-0 px-2">{{ active_male }}</td>
                              <td class="py-0 px-2">{{ active_group }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Inactive Members</td>
                              <td class="py-0 px-2">{{ total_inactive_member }}</td>
                              <td class="py-0 px-2">{{ inactive_female }}</td>
                              <td class="py-0 px-2">{{ inactive_male }}</td>
                              <td class="py-0 px-2">{{ inactive_group }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Dormant Members</td>
                              <td class="py-0 px-2">{{ total_dormant_member }}</td>
                              <td class="py-0 px-2">{{ dormant_female }}</td>
                              <td class="py-0 px-2">{{ dormant_male }}</td>
                              <td class="py-0 px-2">{{ dormant_group }}</td>
                            </b-tr>
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <b-td class="fw-bold py-0 px-2">
                                Total Membership
                              </b-td>
                              <b-td class="fw-bold py-0 px-2">{{ total_member }}</b-td>
                              <b-td class="fw-bold py-0 px-2">{{ total_female }}</b-td>
                              <b-td class="fw-bold py-0 px-2">{{ total_male }}</b-td>
                              <b-td class="fw-bold py-0 px-2">{{ total_group }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="fw-bold py-0 px-2">
                                Number of person in group Cell G-17 (If Avaliable)
                              </b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="fw-bold py-0 px-2">
                                Number of Youth Savers (if applicable)
                              </b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="fw-bold py-0 px-2">
                                Number of Mobile Banking Client (if avalibale)
                              </b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                              <b-td class="fw-bold py-0 px-2"></b-td>
                            </b-tr>
                          </b-tfoot>

                        </b-table-simple>
                      </div>
                      <div class="col-12 p-0 fw-bold mb-2">
                        MEMBERS DEPOSIT AND LOANS
                      </div>

                      <div class="col-12 px-0 trans_details">
                        <b-table-simple bordered responsive outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th width="50%" class="py-1">MEMBERS</b-th>
                              <b-th class="py-1">Total</b-th>
                              <b-th class="py-1">Females</b-th>
                              <b-th class="py-1">Males</b-th>
                              <b-th class="py-1">Groups</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Active Shares (GH¢)</td>
                              <td class="py-0 px-2">{{ total_active_shares_members }}</td>
                              <td class="py-0 px-2">{{ active_female_shares }}</td>
                              <td class="py-0 px-2">{{ active_male_shares }}</td>
                              <td class="py-0 px-2">{{ active_group_shares }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Inactive Shares (GH¢)</td>
                              <td class="py-0 px-2">{{ total_inactive_shares_members }}</td>
                              <td class="py-0 px-2">{{ inactive_female_shares }}</td>
                              <td class="py-0 px-2">{{ inactive_male_shares }}</td>
                              <td class="py-0 px-2">{{ inactive_group_shares }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Dormant Shares (GH¢)</td>
                              <td class="py-0 px-2">{{ total_dormant_shares_members }}</td>
                              <td class="py-0 px-2">{{ dormant_female_shares }}</td>
                              <td class="py-0 px-2">{{ dormant_male_shares }}</td>
                              <td class="py-0 px-2">{{ dormant_group_shares }}</td>
                            </b-tr>
                            <b-tr variant="secondary">
                              <td class="py-0 px-2 fw-bold">Total Amount of Shares (GH¢)</td>
                              <td class="py-0 px-2">{{ total_active_shares_members + total_inactive_shares_members +
                                total_dormant_shares_members }}</td>
                              <td class="py-0 px-2">{{ active_female_shares + inactive_female_shares +
                                dormant_female_shares }}</td>
                              <td class="py-0 px-2">{{ active_male_shares + inactive_male_shares + dormant_male_shares
                                }}
                              </td>
                              <td class="py-0 px-2">{{ active_group_shares + inactive_group_shares +
                                dormant_group_shares }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Active Savings (GH¢)</td>
                              <td class="py-0 px-2">{{ total_active_savings_members }}</td>
                              <td class="py-0 px-2">{{ active_female_savings }}</td>
                              <td class="py-0 px-2">{{ active_male_savings }}</td>
                              <td class="py-0 px-2">{{ active_group_savings }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Inactive Savings (GH¢)</td>
                              <td class="py-0 px-2">{{ total_inactive_savings_members }}</td>
                              <td class="py-0 px-2">{{ inactive_female_savings }}</td>
                              <td class="py-0 px-2">{{ inactive_male_savings }}</td>
                              <td class="py-0 px-2">{{ inactive_group_savings }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Dormant Savings (GH¢)</td>
                              <td class="py-0 px-2">{{ total_dormant_savings_members }}</td>
                              <td class="py-0 px-2">{{ dormant_female_savings }}</td>
                              <td class="py-0 px-2">{{ dormant_male_savings }}</td>
                              <td class="py-0 px-2">{{ dormant_group_savings }}</td>
                            </b-tr>
                            <b-tr variant="secondary">
                              <td class="py-0 px-2 fw-bold">Total Amount Of Savings (GH¢)</td>
                              <td class="py-0 px-2">{{ total_active_savings_members + total_inactive_savings_members +
                                total_dormant_savings_members }}</td>
                              <td class="py-0 px-2">{{ active_female_savings + inactive_female_savings +
                                dormant_female_savings }}</td>
                              <td class="py-0 px-2">{{ active_male_savings + inactive_male_savings +
                                dormant_male_savings }}</td>
                              <td class="py-0 px-2">{{ active_group_savings + inactive_group_savings +
                                dormant_group_savings }}</td>
                            </b-tr>

                            <b-tr>
                              <td class="py-0 px-2">Number of loans disbursed during the period</td>
                              <td class="py-0 px-2">{{ total_disbursement_count }}</td>
                              <td class="py-0 px-2">{{ disbursement_female_count }}</td>
                              <td class="py-0 px-2">{{ disbursement_male_count }}</td>
                              <td class="py-0 px-2">{{ disbursement_group_count }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of loans disbursed during the period (GH¢)</td>
                              <td class="py-0 px-2">{{ total_disbursement_sum }}</td>
                              <td class="py-0 px-2">{{ disbursement_female_sum }}</td>
                              <td class="py-0 px-2">{{ disbursement_male_sum }}</td>
                              <td class="py-0 px-2">{{ disbursement_group_sum }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Number of Loans Outstanding</td>
                              <td class="py-0 px-2">{{ total_oustanding_loan }}</td>
                              <td class="py-0 px-2">{{ oustanding_female_loan_count }}</td>
                              <td class="py-0 px-2">{{ oustanding_male_loan_count }}</td>
                              <td class="py-0 px-2">{{ oustanding_group_loan_count }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Loans Outstanding (GH¢)</td>
                              <td class="py-0 px-2">{{ oustanding_loan_sum }}</td>
                              <td class="py-0 px-2">{{ oustanding_female_loan_sum }}</td>
                              <td class="py-0 px-2">{{ oustanding_male_loan_sum }}</td>
                              <td class="py-0 px-2">{{ oustanding_group_loan_sum }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">No. of Delinquent Borrowers (arrears of 30 days and more):</td>
                              <td class="py-0 px-2">{{ total_delinquent_count }}</td>
                              <td class="py-0 px-2">{{ delinquent_female_count }}</td>
                              <td class="py-0 px-2">{{ delinquent_male_count }}</td>
                              <td class="py-0 px-2">{{ delinquent_group_count }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Delinquent Loans (GH¢)</td>
                              <td class="py-0 px-2">{{ delinquent_sum }}</td>
                              <td class="py-0 px-2">{{ delinquent_female_sum }}</td>
                              <td class="py-0 px-2">{{ delinquent_male_sum }}</td>
                              <td class="py-0 px-2">{{ delinquent_group_sum }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount of Youth Savings (GH¢)</td>
                              <td class="py-0 px-2">0</td>
                              <td class="py-0 px-2">0</td>
                              <td class="py-0 px-2">0</td>
                              <td class="py-0 px-2">0</td>
                            </b-tr>
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <b-td class="fw-bold py-0 px-2 text-end">
                                Deliquency Rate (%)
                              </b-td>
                              <b-td class="fw-bold py-0 px-2">{{ 0 }}%</b-td>
                              <b-td class="fw-bold py-0 px-2">{{ 0 }}%</b-td>
                              <b-td class="fw-bold py-0 px-2">{{ 0 }}%</b-td>
                              <b-td class="fw-bold py-0 px-2">{{ 0 }}%</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td class="fw-bold py-0 px-2 text-end">
                                Standard:
                              </b-td>
                              <b-td colspan="4" class="fw-bold py-0 px-2">{{ 0 }}</b-td>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                      </div>
                      <div class="col-12 p-0 fw-bold my-2">
                        RECEIPTS AND PAYMENTS
                      </div>
                      <div class="col-6 col-md-6 trans_details px-0">
                        <b-table-simple bordered responsive outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th class="text-center fs-6 py-1" colspan="2"><span>Receipts</span></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <td class="py-0 px-2">Beg. of the month cash</td>
                              <td class="py-0 px-2">{{ beg_of_month_cash }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Members Shares Dep.</td>
                              <td class="py-0 px-2">{{ shares_member_deposit }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Members Savings Dep.</td>
                              <td class="py-0 px-2">{{ saving_member_deposit }}</td>
                            </b-tr>

                            <b-tr>
                              <td class="py-0 px-2">Members Loan Repay.</td>
                              <td class="py-0 px-2">{{ loan_repayment }}</td>
                            </b-tr>

                            <b-tr>
                              <td class="py-0 px-2">Bank Savings W/D</td>
                              <td class="py-0 px-2">0</td>
                            </b-tr>

                            <b-tr>
                              <td class="py-0 px-2">Bank Current W/D</td>
                              <td class="py-0 px-2">{{ bank_current_cr }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">CUA/CFF/W/D</td>
                              <td class="py-0 px-2">{{ cff_cr }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">T-Bills Discounted</td>
                              <td class="py-0 px-2">{{ t_bills_discount }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Other Receipts (total)</td>
                              <td class="py-0 px-2">{{ other_payment }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2 fw-bold">Sub- Total for the Month</td>
                              <td class="py-0 px-2">{{ cr_sub_total }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount Deposit by Mobile Banking Client for the Month (if
                                available):
                              </td>
                              <td class="py-0 px-2">{{ mobile_deposit }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Income for the month</td>
                              <td class="py-0 px-2">{{ totalIncome }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2 col-span-2">(see 3. income and expenses below) </td>
                              <td></td>
                            </b-tr>
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <b-th>Total Receipts</b-th>
                              <b-th>{{ sumRecTotal }}</b-th>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                      </div>
                      <div class="col-6 col-md-6 trans_details px-0">
                        <b-table-simple bordered responsive outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th class="text-center fs-6 py-1" colspan="2"><span>Payments</span></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <td class="py-0 px-2">End. of the month cash bal</td>
                              <td class="py-0 px-2">{{ end_of_month_cash }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Members Shares With.</td>
                              <td class="py-0 px-2">{{ shares_member_withdraw }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Members Savings With.</td>
                              <td class="py-0 px-2">{{ saving_member_withdraw }}</td>
                            </b-tr>

                            <b-tr>
                              <td class="py-0 px-2">Loans to Members</td>
                              <td class="py-0 px-2">{{ loan_disbursed }}</td>
                            </b-tr>

                            <b-tr>
                              <td class="py-0 px-2">Bank Savings Dep.</td>
                              <td class="py-0 px-2">0</td>
                            </b-tr>

                            <b-tr>
                              <td class="py-0 px-2">Bank Current Dep.</td>
                              <td class="py-0 px-2">{{ bank_current_dr }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">CUA/CFF Dep.</td>
                              <td class="py-0 px-2">{{ cff_dr }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">T-Bills Invested</td>
                              <td class="py-0 px-2">{{ t_bills_invest }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Other Payments (total)</td>
                              <td class="py-0 px-2">{{ other_receipts }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2 fw-bold">Sub- Total for the Month</td>
                              <td class="py-0 px-2">{{ dr_sub_total }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Amount Withdrawn by Mobile Banking Client for the Month (if
                                available):
                              </td>
                              <td class="py-0 px-2">{{ mobile_withdraw }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">Expenses for the month</td>
                              <td class="py-0 px-2">{{ totalExpenses }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2 col-span-2">(see 3. income and expenses below)</td>
                              <td></td>
                            </b-tr>
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <b-th>Total Payments</b-th>
                              <b-th>{{ sumPayTotal }}</b-th>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                      </div>
                      <div class="col-8 ps-0">
                        <div class="fw-bold my-3">
                          NOTES TO THE STATEMENT OF COMPREHENSIVE INCOME AND EXPENSES FOR
                        </div>
                      </div>
                      <div class="col-4 ps-0">
                        <div class="fw-bold text-end my-3">
                          {{ getDateString }}
                        </div>
                      </div>
                      <div class="col-12 px-0 trans_details">
                        <b-table-simple class="mb-0" bordered outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th width="50%" class="py-1">INCOME</b-th>
                              <b-th class="py-1">CURRENT MONTH</b-th>
                              <b-th width='300px' class="py-1">YEAR TO DATE</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(income, index) in incomes" :key="income.id">
                              <td class="py-0 px-2">{{ income?.name }}</td>
                              <td class="py-0 px-2">{{ income?.amount }}</td>
                              <td class="py-0 px-2">{{ income?.yearly_amount }}</td>
                            </b-tr>
                            <!-- <b-tr>
                              <td class="py-0 px-2">{{ interest_on_financial_investments?.name }}</td>
                              <td class="py-0 px-2">{{ interest_on_financial_investments?.monthly_amount }}</td>
                              <td class="py-0 px-2">{{ interest_on_financial_investments?.yearly_amount }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">{{ other_financial_incomes?.name }}</td>
                              <td class="py-0 px-2">{{ other_financial_incomes?.monthly_amount }}</td>
                              <td class="py-0 px-2">{{ other_financial_incomes?.yearly_amount }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">{{ non_financial_incomes?.name }}</td>
                              <td class="py-0 px-2">{{ non_financial_incomes?.monthly_amount }}</td>
                              <td class="py-0 px-2">{{ non_financial_incomes?.yearly_amount }}</td>
                            </b-tr> -->
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <td class="py-0 px-2 fw-bold">Total Income</td>
                              <td class="py-0 px-2">{{ totalIncome }}</td>
                              <td class="py-0 px-2">{{ totalYearlyIncome }}</td>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                        <b-table-simple bordered outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th width="50%" class="py-1">EXPENSES</b-th>
                              <b-th class="py-1">CURRENT MONTH</b-th>
                              <b-th width='300px' class="py-1">YEAR TO DATE</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(expense, index) in expenses" :key="expense.id">
                              <td class="py-0 px-2">{{ expense?.name }}</td>
                              <td class="py-0 px-2">{{ expense?.amount }}</td>
                              <td class="py-0 px-2">{{ expense?.yearly_amount }}</td>
                            </b-tr>
                            <!-- <b-tr>
                              <td class="py-0 px-2">{{ personal_costs?.name }}</td>
                              <td class="py-0 px-2">{{ personal_costs?.monthly_amount }}</td>
                              <td class="py-0 px-2">{{ personal_costs?.yearly_amount }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">{{ occupancys?.name }}</td>
                              <td class="py-0 px-2">{{ occupancys?.monthly_amount }}</td>
                              <td class="py-0 px-2">{{ occupancys?.yearly_amount }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">{{ organizationals?.name }}</td>
                              <td class="py-0 px-2">{{ organizationals?.monthly_amount }}</td>
                              <td class="py-0 px-2">{{ organizationals?.yearly_amount }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">{{ securitys?.name }}</td>
                              <td class="py-0 px-2">{{ securitys?.monthly_amount }}</td>
                              <td class="py-0 px-2">{{ securitys?.yearly_amount }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">{{ administrations?.name }}</td>
                              <td class="py-0 px-2">{{ administrations?.monthly_amount }}</td>
                              <td class="py-0 px-2">{{ administrations?.yearly_amount }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2">{{ provisions?.name }}</td>
                              <td class="py-0 px-2">{{ provisions?.monthly_amount }}</td>
                              <td class="py-0 px-2">{{ provisions?.yearly_amount }}</td>
                            </b-tr> -->
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <td class="py-0 px-2 fw-bold">Total Expenses</td>
                              <td class="py-0 px-2">{{ totalExpenses }}</td>
                              <td class="py-0 px-2">{{ totalYearlyExpenses }}</td>
                            </b-tr>
                            <b-tr>
                              <td class="py-0 px-2 fw-bold">Surplus Difference</td>
                              <td class="py-0 px-2">{{ totalIncome -  totalExpenses }}</td>
                              <td class="py-0 px-2">{{ totalYearlyIncome - totalYearlyExpenses }}</td>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                      </div>
                      <div class="col-8 ps-0">
                        <div class="fw-bold my-3">
                          STATEMENT OF FINANCIAL POSITION (BALANCE SHEET) AS AT
                        </div>
                      </div>
                      <div class="col-4 ps-0">
                        <div class="fw-bold text-end my-3">
                          {{ getDateString }}
                        </div>
                      </div>
                      <div class="col-6 col-md-6 trans_details px-0">
                        <b-table-simple bordered responsive outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th class="text-center fs-6 py-1" colspan="2"><span>ASSETS</span></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(asset, index) in assets" :key="asset.id">
                              <td class="py-0 px-2">{{ asset.name }} - {{ asset.code }}</td>
                              <td width="150px" class="py-0 px-2">{{ asset.yearly_amount }}</td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                      <div class="col-6 col-md-6 trans_details px-0">
                        <b-table-simple bordered responsive outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th class="text-center fs-6 py-1" colspan="2"><span>LIABILITY + CAPITAL</span></b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(liability, index) in liabilities" :key="liability.id">
                              <td class="py-0 px-2">{{ liability.name }} - {{ liability.code }}</td>
                              <td width="150px" class="py-0 px-2">{{ liability.yearly_amount }}</td>
                            </b-tr>

                          </b-tbody>
                        </b-table-simple>
                      </div>
                      <div class="col-6 col-md-6 trans_details px-0">
                        <b-table-simple bordered outlined>
                          <b-tfoot>
                            <b-tr>
                              <b-th>Total Assets</b-th>
                              <b-th width="150px">{{ totalAssets }}</b-th>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                      </div>
                      <div class="col-6 col-md-6 trans_details px-0">
                        <b-table-simple bordered outlined>
                          <b-tfoot>
                            <b-tr>
                              <b-th>(Total Liabilities + Capital) + Surplus</b-th>
                              <b-th width="150px">{{ Number(totalLiabilities).toFixed(2) }} {{ }}</b-th>
                            </b-tr>
                          </b-tfoot>
                        </b-table-simple>
                      </div>
                      <div class="col-6 col-md-12 trans_details px-0">
                        <b-table-simple bordered outlined>
                          <b-tbody>
                            <b-tr>
                              <b-td>Liquidity reserve</b-td>
                              <b-td>{{ liquidityReserve / 100 }}</b-td>
                              <b-td>{{ liquidityReserve }}%</b-td>
                              <b-td>(Total liquid fund / total asset * 100)</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>Return on Assets</b-td>
                              <b-td>{{ returnOnAssets / 100 }}</b-td>
                              <b-td>{{ returnOnAssets }}%</b-td>
                              <b-td>(Surplus Difference / total asset * 100)</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>Capital Adequacy</b-td>
                              <b-td>{{ capitalAdequacy / 100 }}</b-td>
                              <b-td>{{ capitalAdequacy }}%</b-td>
                              <b-td>([Member Shares + Total Capital res ]/ total asset * 100)</b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>Deliquency Rate</b-td>
                              <b-td>{{ deliquencyRate / 100 }}</b-td>
                              <b-td>{{ deliquencyRate }}%</b-td>
                              <b-td>([Amount of Deliquent Loans ]/ Amount of outstanding Loan * 100)</b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                      <div class="col-8 ps-0">
                        <div class="fw-bold my-3">
                          OTHER INFORMATION
                        </div>
                      </div>
                      <div class="col-4 ps-0">
                        <div class="fw-bold text-end my-3">
                          {{ getDateString }}
                        </div>
                      </div>
                      <div class="col-12 col-6 col-md-12 trans_details px-0">
                        <b-table-simple bordered outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th class="py-1"></b-th>
                              <b-th class="py-1">In Place (Yes/No)</b-th>
                              <b-th class="py-1">Regular Meetings Date</b-th>
                              <b-th class="py-1">No of meeting in period</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <b-td>Management Committee</b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>Supervisory Committee</b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>Loan Committee</b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>Education Committee</b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                            </b-tr>
                          </b-tbody>

                        </b-table-simple>
                      </div>

                      <div class="col-8 ps-0">
                        <div class="fw-bold my-3">
                          EMPLOYEES INFORMATION
                        </div>
                      </div>
                      <div class="col-4 ps-0">
                        <div class="fw-bold text-end my-3">
                          {{ getDateString }}
                        </div>
                      </div>
                      <div class="col-12 col-6 col-md-12 trans_details px-0">
                        <b-table-simple bordered outlined>
                          <b-thead>
                            <b-tr variant="secondary">
                              <b-th class="py-1"></b-th>
                              <b-th class="py-1">Total</b-th>
                              <b-th class="py-1">Male</b-th>
                              <b-th class="py-1">Female</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr>
                              <b-td>No of Permanent Employees</b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>No of Temporal Employees</b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                            </b-tr>
                            <b-tr>
                              <b-td>No of Employees covered under CCUPP</b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                              <b-td></b-td>
                            </b-tr>
                          </b-tbody>
                          <b-tfoot>
                            <b-tr>
                              <b-th>Total Number Of Employees</b-th>
                              <b-th></b-th>
                              <b-th></b-th>
                              <b-th></b-th>
                            </b-tr>
                          </b-tfoot>

                        </b-table-simple>
                      </div>
                      <div class="col-6">
                        <div>Prepared By:</div>

                      </div>
                      <div class="col-6">
                        <div>Approved By:</div>

                      </div>

                      <div class="col-4">
                        <div class="text-start">____________________________________</div>
                        <div class="text-start">Signature</div>
                      </div>
                      <div class="col-4">
                        <div class="text-start">____________________________________</div>
                        <div class="text-start">Signature</div>
                      </div>
                      <div class="col-4">
                        <div class="text-start">____________________________________</div>
                        <div class="text-start">Signature</div>
                      </div>


                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top">
      <i class="fas fa-angle-up"></i>
    </a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import StatementInstitution from "@/views/main/components/institution/StatementInstitution.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import ReportFilter from "../form/ReportFilter.vue";
import html2pdf from "html2pdf.js";
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    StatementInstitution,
    Multiselect,
    ReportFilter,
  },
  data() {
    return {
      form: {
        start_date: this.getBeginningOfYear(),
        end_date: new Date().toISOString().split('T')[0],
        branches: [],
        products: [],
        branch_options: [],
        product_options: [],
        users: [],
        users_options: [],
        status: [],
        coa: [],
        coa_options: [],
        terms: "Periodic",
        terms_options: ["YTD", "Periodic"],
        categories: [],
        isBusy: false
      },
      filter: {
        branches: true,
        products: false,
        users: false,
        status: false,
        condition: false,
        terms: false,
        coa: false,
        voucher_status: false,
        end_date:true
      },
      search: "Search",
      isBusy: false,
      isSearching: false,
      isLoadingInstitution: false,
      rec_items: [],
      rec_total: "",
      pay_items: [],
      pay_total: "",
      institution: "",
      income_items: [],
      expense_items: [],
      assets_items: [],
      liability_items: [],
      active_male: 0,
      active_female: 0,
      active_group: 0,
      inactive_male: 0,
      inactive_female: 0,
      inactive_group: 0,
      dormant_male: 0,
      dormant_female: 0,
      dormant_group: 0,
      total_female: 0,
      total_male: 0,
      total_group: 0,
      total_active: 0,
      total_inactive: 0,
      total_dormant: 0,
      member_total: 0,
      sumAssetsTotal: 0,
      no_of_branches: 0,
      total_active_member: 0,
      total_inactive_member: 0,
      total_dormant_member: 0,
      total_member: 0,
      active_female_shares: 0,
      active_male_shares: 0,
      active_group_shares: 0,
      inactive_female_shares: 0,
      inactive_male_shares: 0,
      inactive_group_shares: 0,
      dormant_female_shares: 0,
      dormant_male_shares: 0,
      dormant_group_shares: 0,
      active_female_savings: 0,
      active_male_savings: 0,
      active_group_savings: 0,
      inactive_female_savings: 0,
      inactive_male_savings: 0,
      inactive_group_savings: 0,
      dormant_female_savings: 0,
      dormant_male_savings: 0,
      dormant_group_savings: 0,
      disbursement_female_count: 0,
      disbursement_male_count: 0,
      disbursement_group_count: 0,
      disbursement_female_sum: 0,
      disbursement_male_sum: 0,
      disbursement_group_sum: 0,

      oustanding_female_loan_count: 0,
      oustanding_male_loan_count: 0,
      oustanding_group_loan_count: 0,
      oustanding_female_loan_sum: 0,
      oustanding_male_loan_sum: 0,
      oustanding_group_loan_sum: 0,

      delinquent_female_count: 0,
      delinquent_male_count: 0,
      delinquent_group_count: 0,

      delinquent_female_sum: 0,
      delinquent_male_sum: 0,
      delinquent_group_sum: 0,

      total_active_shares_members: 0,
      total_inactive_shares_members: 0,
      total_dormant_shares_members: 0,

      total_active_savings_members: 0,
      total_inactive_savings_members: 0,
      total_dormant_savings_members: 0,

      total_disbursement_count: 0,
      total_disbursement_sum: 0,

      total_oustanding_loan: 0,
      total_delinquent_count: 0,
      oustanding_loan_sum: 0,
      delinquent_sum: 0,



      // transaction_female: 0,
      // transaction_male: 0,
      // transaction_group: 0,

      beg_of_month_cash: 0,
      shares_member_deposit: 0,
      saving_member_deposit: 0,
      loan_repayment: 0,

      bank_current_cr: 0,
      cff_cr: 0,
      t_bills_discount: 0,
      other_payment: 0,
      mobile_deposit: 0,

      cr_sub_total: 0,
      end_of_month_cash: 0,

      shares_member_withdraw: 0,
      saving_member_withdraw: 0,

      loan_disbursed: 0,
      bank_current_dr: 0,

      cff_dr: 0,
      t_bills_invest: 0,
      other_receipts: 0,
      dr_sub_total: 0,
      mobile_withdraw: 0,
      sumRecTotal: 0,
      sumPayTotal: 0,

      incomes: [],
      expenses: [],
      
      total_monthly_income: 0,
      total_yearly_income: 0,

      cost_of_funds: {
        id: "",
        name: "",
        monthly_amount: "",
        yearly_amount: "",
      },
      personal_costs: {
        id: "",
        name: "",
        monthly_amount: "",
        yearly_amount: "",
      },
      occupancys: {
        id: "",
        name: "",
        monthly_amount: "",
        yearly_amount: "",
      },
      organizationals: {
        id: "",
        name: "",
        monthly_amount: "",
        yearly_amount: "",
      },
      securitys: {
        id: "",
        name: "",
        monthly_amount: "",
        yearly_amount: "",
      },
      administrations: {
        id: "",
        name: "",
        monthly_amount: "",
        yearly_amount: "",
      },
      provisions: {
        id: "",
        name: "",
        monthly_amount: "",
        yearly_amount: "",
      },
      total_monthly_expenses: 0,
      total_monthly_expenses: 0,
      assets: [],
      liabilities: [],
      capital: [],
      // cost_of_funds: [],
      // personal_costs: [],
      // occupancys: [],
      // organizationals: [],
      // securitys: [],
      // administrations: [],
      // provisions: [],
      // liquid_funds: [],
      // liquid_investments: [],
      // other_investments: [],
      // loan_to_members: [],
      // current_assets: [],
      // fixed_assets: [],
      // current_liabilities: [],
      // member_savings: [],
      // member_shares: [],
      // reserves: [],
      // bank_currents: [],


    };
  },

  created() {
    this.getInstitution();
    this.getAccountStatistics();
    this.getProductDropdown();
  },
  computed: {
    //Total Assets
    totalAssets() {
      return this.assets?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    //Total Liabilities
    totalLiabilities() {
      const liabilities = this.liabilities?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
      const surplus = Number(this.total_yearly_income) - Number(this.total_yearly_expenses)
      return Number(liabilities) + Number(surplus)
    },
    // Total Income
    totalIncome() {
      return this.incomes?.reduce((total, item) => total + parseFloat(item.amount), 0).toFixed(2);
    },
    // Total Expenses
    totalExpenses() {
      return this.expenses?.reduce((total, item) => total + parseFloat(item.amount), 0).toFixed(2);
    },
    // Total Yearly Income
    totalYearlyIncome() {
      return this.incomes?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
    // Total Yearly Expenses
    totalYearlyExpenses() {
      return this.expenses?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    },
     // Total LiquidFunds
    totalLiquidFunds() {
      return this.getCOAHead(this.assets, 'liquid-fund')?.[0];
    },
    // Total Reserve
    liquidityReserve() {
      return (this.totalLiquidFunds / this.totalAssets * 100).toFixed(2);
    },
  // Return on Assets
    returnOnAssets() {
      const year_income = this.incomes?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
      const year_expense = this.expenses?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
      const surplus = year_income - year_expense;
      const return_on_assets = (surplus / this.totalAssets * 100).toFixed(2);
      return return_on_assets
    },
    capitalAdequacy() {
      const total_member_shares = this.total_active_shares_members + this.total_inactive_shares_members + this.total_dormant_shares_members;
      // console.log(total_member_shares)
      const capital = this.capital?.amount ?? 0;
      return Number((total_member_shares + capital) / this.totalAssets * 100).toFixed(2)

    },
    deliquencyRate() {
      return Number(this.delinquent_sum / this.oustanding_loan_sum * 100).toFixed(2)
    },
    //Income
    // totalInterestOnLoanMonth() {
    //   return this.interest_on_loans?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalInterestOnLoanYear() {
    //   return this.interest_on_loans?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalInterestOnFinancialInvestmentMonth() {
    //   return this.interest_on_financial_investments?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalInterestOnFinancialInvestmentYear() {
    //   return this.interest_on_financial_investments?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalOtherFinancialInvestmentMonth() {
    //   return this.other_financial_incomes?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalOtherFinancialInvestmentYear() {
    //   return this.other_financial_incomes?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalNonFinancialIncomeMonth() {
    //   return this.non_financial_incomes?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalNonFinancialIncomeYear() {
    //   return this.non_financial_incomes?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // //Expenses
    // totalCostOfFundMonth() {
    //   return this.cost_of_funds?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalCostOfFundYear() {
    //   return this.cost_of_funds?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalPersonalCostMonth() {
    //   return this.personal_costs?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalPersonalCostYear() {
    //   return this.personal_costs?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalOccupancyMonth() {
    //   return this.occupancys?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalOccupancyYear() {
    //   return this.occupancys?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalOrganizationMonth() {
    //   return this.organizationals?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalOrganizationYear() {
    //   return this.organizationals?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalSecurityMonth() {
    //   return this.securitys?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalSecurityYear() {
    //   return this.securitys?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalAdministrationMonth() {
    //   return this.administrations?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalAdministrationYear() {
    //   return this.administrations?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalProvisionMonth() {
    //   return this.provisions?.reduce((total, item) => total + parseFloat(item.monthly_amount), 0).toFixed(2);
    // },
    // totalProvisionYear() {
    //   return this.provisions?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalMonthlyIncome() {
    //   const totalIncomeMonth = this.cleanNumber(this.totalInterestOnLoanMonth) + this.cleanNumber(this.totalInterestOnFinancialInvestmentMonth) + this.cleanNumber(this.totalOtherFinancialInvestmentMonth) + this.cleanNumber(this.totalNonFinancialIncomeMonth);
    //   return totalIncomeMonth.toFixed(2)
    // },
    // totalMonthlyExpenses() {
    //   const totalExpensesMonth = this.cleanNumber(this.totalCostOfFundMonth) + this.cleanNumber(this.totalPersonalCostMonth) + this.cleanNumber(this.totalOccupancyMonth) + this.cleanNumber(this.totalOrganizationMonth) + this.cleanNumber(this.totalSecurityMonth) + this.cleanNumber(this.totalAdministrationMonth) + this.cleanNumber(this.totalProvisionMonth);
    //   return totalExpensesMonth.toFixed(2)
    // },
    // surpluseMonthly() {
    //   const surpluseMonth = this.totalMonthlyIncome - this.totalMonthlyExpenses
    //   return surpluseMonth.toFixed(2)
    // },
    // totalYearlyIncome() {
    //   const totalIncomeYear = Number(this.totalInterestOnLoanYear) + Number(this.totalInterestOnFinancialInvestmentYear) + Number(this.totalOtherFinancialInvestmentYear) + Number(this.totalNonFinancialIncomeYear);
    //   return totalIncomeYear.toFixed(2);
    // },
    // totalYearlyExpenses() {
    //   const totalExpensesYear = Number(this.totalCostOfFundYear) + Number(this.totalPersonalCostYear) + Number(this.totalOccupancyYear) + Number(this.totalOrganizationYear) + Number(this.totalSecurityYear) + Number(this.totalAdministrationYear) + Number(this.totalProvisionYear);
    //   return totalExpensesYear.toFixed(2);
    // },
    // surpluseYearly() {
    //   const surpluseYear = this.totalYearlyIncome - this.totalYearlyExpenses
    //   return surpluseYear.toFixed(2)
    // },

    //Assets
    // totalLiquidFundYear() {
    //   return this.liquid_funds?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalLiquidInvestmentYear() {
    //   return this.liquid_investments?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalOtherInvestmentYear() {
    //   return this.other_investments?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalLoanToMemberYear() {
    //   return this.loan_to_members?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalCurrentAssetsYear() {
    //   return this.current_assets?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalFixedAssetsYear() {
    //   return this.fixed_assets?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // //Liabilities
    // totalCurrentLiabilityYear() {
    //   return this.current_liabilities?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalMemberSavingYear() {
    //   return this.member_savings?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalMemberSharesYear() {
    //   return this.member_shares?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalReserveYear() {
    //   return this.reserves?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },
    // totalBankCurrentYear() {
    //   return this.bank_currents?.reduce((total, item) => total + parseFloat(item.yearly_amount), 0).toFixed(2);
    // },


    getDateString() {
      const date = new Date(this.form.end_date); // Current date
      const options = { year: 'numeric', month: 'long' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      return formattedDate
    },
    // totalAssetYearly() {
    //   const total = Number(this.totalLiquidFundYear) + Number(this.totalLiquidInvestmentYear) + Number(this.totalOtherInvestmentYear) + Number(this.totalLoanToMemberYear) + Number(this.totalCurrentAssetsYear) + Number(this.totalFixedAssetsYear) + Number(this.totalBankCurrentYear);
    //   return total.toFixed(2)
    // },
    // totalLiabilityYearly() {
    //   const total = Number(this.totalCurrentLiabilityYear) + Number(this.totalMemberSavingYear) + Number(this.totalMemberSharesYear) + Number(this.totalReserveYear)
    //   return total.toFixed(2);
    // },
    // totalAssetsMinusProvisions() {
    //   return this.totalAssetYearly - this.totalProvisionYear
    // },
    // totalSurplusPlusLiabilities() {
    //   return this.surpluseYearly - this.totalLiabilityYearly
    // }
  },

  methods: {
    getCOAHead(arr, name) {
      // console.log(arr);
      const liquidFundItems = arr.filter(item => item.short_name.toLowerCase().includes(name));
      return liquidFundItems.map(item => item.amount);
    },
    async getAccountStatistics() {
      this.items = [];
      this.isBusy = true;
      this.search = "Processing...";
      this.isSearching = true;
      await ApiService.query("/reports/account/brief/statistics",
        {
          params: {
            startDate: this.form.start_date,
            endDate: this.form.end_date,
            branch_ids: this.form.branches.map((index) => { return index.id }),
          },
        }
      )
        .then((response) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          const res = response.data;
          // console.log(res);
          this.no_of_branches = res.total_branch;
          this.active_female = res.active_female;
          this.active_male = res.active_male;
          this.active_group = res.active_group;
          this.inactive_female = res.inactive_female;
          this.inactive_male = res.inactive_male;
          this.inactive_group = res.inactive_group;
          this.dormant_female = res.dormant_female;
          this.dormant_male = res.dormant_male;
          this.dormant_group = res.dormant_group;
          this.total_female = Number(this.active_female) + Number(this.inactive_female) + Number(this.dormant_female);
          this.total_male = Number(this.active_male) + Number(this.inactive_male) + Number(this.dormant_male);
          this.total_group = Number(this.active_group) + Number(this.inactive_group) + Number(this.dormant_group);

          this.total_active_member = Number(this.active_male) + Number(this.active_female) + Number(this.active_group)
          this.total_inactive_member = Number(this.inactive_male) + Number(this.inactive_female) + Number(this.inactive_group)
          this.total_dormant_member = Number(this.dormant_male) + Number(this.dormant_female) + Number(this.dormant_group)

          this.total_member = this.total_active_member + this.total_inactive_member + this.total_dormant_member

          this.active_female_shares = Number(res.active_female_shares);
          this.active_male_shares = Number(res.active_male_shares);
          this.active_group_shares = Number(res.active_group_shares);
          this.inactive_female_shares = Number(res.inactive_female_shares);
          this.inactive_male_shares = Number(res.inactive_male_shares);
          this.inactive_group_shares = Number(res.inactive_group_shares);
          this.dormant_female_shares = Number(res.dormant_female_shares);
          this.dormant_male_shares = Number(res.dormant_male_shares);
          this.dormant_group_shares = Number(res.dormant_group_shares);

          this.total_active_shares_members = Number(this.active_female_shares) + Number(this.active_male_shares) + Number(this.active_group_shares);
          this.total_inactive_shares_members = Number(this.inactive_female_shares) + Number(this.inactive_male_shares) + Number(this.inactive_group_shares);
          this.total_dormant_shares_members = Number(this.dormant_female_shares) + Number(this.dormant_male_shares) + Number(this.dormant_group_shares);

          this.active_female_savings = Number(res.active_female_savings);
          this.active_male_savings = Number(res.active_male_savings);
          this.active_group_savings = Number(res.active_group_savings);
          this.inactive_female_savings = Number(res.inactive_female_savings);
          this.inactive_male_savings = Number(res.inactive_male_savings);
          this.inactive_group_savings = Number(res.inactive_group_savings);
          this.dormant_female_savings = Number(res.dormant_female_savings);
          this.dormant_male_savings = Number(res.dormant_male_savings);
          this.dormant_group_savings = Number(res.dormant_group_savings);

          this.total_active_savings_members = Number(this.active_female_savings) + Number(this.active_male_savings) + Number(this.active_group_savings);
          this.total_inactive_savings_members = Number(this.inactive_female_savings) + Number(this.inactive_male_savings) + Number(this.inactive_group_savings);
          this.total_dormant_savings_members = Number(this.dormant_female_savings) + Number(this.dormant_male_savings) + Number(this.dormant_group_savings);


          this.disbursement_female_count = Number(res.disbursement_female_count);
          this.disbursement_male_count = Number(res.disbursement_male_count);
          this.disbursement_group_count = Number(res.disbursement_group_count);
          this.disbursement_female_sum = Number(res.disbursement_female_sum);
          this.disbursement_male_sum = Number(res.disbursement_male_sum);
          this.disbursement_group_sum = Number(res.disbursement_group_sum);

          this.total_disbursement_count = Number(this.disbursement_female_count) + Number(this.disbursement_male_count) + Number(this.disbursement_group_count);
          this.total_disbursement_sum = Number(this.disbursement_female_sum) + Number(this.disbursement_male_sum) + Number(this.disbursement_group_sum);


          this.oustanding_female_loan_count = Number(res.oustanding_female_loan_count)
          this.oustanding_male_loan_count = Number(res.oustanding_male_loan_count)
          this.oustanding_group_loan_count = Number(res.oustanding_group_loan_count)

          this.total_oustanding_loan = Number(this.oustanding_female_loan_count) + Number(this.oustanding_male_loan_count) + Number(this.oustanding_group_loan_count);


          this.delinquent_female_count = Number(res.delinquent_female_count)
          this.delinquent_male_count = Number(res.delinquent_male_count)
          this.delinquent_group_count = Number(res.delinquent_group_count)

          this.total_delinquent_count = Number(this.delinquent_female_count) + Number(this.delinquent_male_count) + Number(this.delinquent_group_count);


          this.oustanding_female_loan_sum = Number(res.oustanding_female_loan_sum);
          this.oustanding_male_loan_sum = Number(res.oustanding_male_loan_sum);
          this.oustanding_group_loan_sum = Number(res.oustanding_group_loan_sum);

          this.oustanding_loan_sum = Number(this.oustanding_female_loan_sum) + Number(this.oustanding_male_loan_sum) + Number(this.oustanding_group_loan_sum);


          this.oustanding_female_loan_sum = Number(res.oustanding_female_loan_sum);
          this.oustanding_male_loan_sum = Number(res.oustanding_male_loan_sum);
          this.oustanding_group_loan_sum = Number(res.oustanding_group_loan_sum);

          this.delinquent_female_sum = Number(res.delinquent_female_sum);
          this.delinquent_male_sum = Number(res.delinquent_male_sum);
          this.delinquent_group_sum = Number(res.delinquent_group_sum);

          this.delinquent_sum = Number(this.delinquent_female_sum) + Number(this.delinquent_male_sum) + Number(this.delinquent_group_sum);


          this.transaction_female = Number(res.transaction_female);
          this.transaction_male = Number(res.transaction_male);
          this.transaction_group = Number(res.transaction_group);

          this.beg_of_month_cash = (Number(res.beg_of_month_cash) == 0 ? '50869.10' : Number(res.beg_of_month_cash));
          this.shares_member_deposit = Number(res.shares_member_deposit);
          this.saving_member_deposit = Number(res.saving_member_deposit);
          this.loan_repayment = Number(res.loan_repayment);
          this.bank_current_cr = Number(res.bank_current_cr);

          this.cff_cr = Number(res.cff_cr);
          this.t_bills_discount = Number(res.t_bills_discount);
          this.other_payment = Number(res.other_payment);
          this.mobile_deposit = Number(res.mobile_deposit);

          this.cr_sub_total = Number(this.shares_member_deposit) + Number(this.saving_member_deposit) + Number(this.loan_repayment) + Number(this.bank_current_cr) + Number(this.cff_cr) + Number(this.t_bills_discount) + Number(this.other_payment)

          
          this.shares_member_withdraw = Number(res.shares_member_withdraw);
          this.saving_member_withdraw = Number(res.saving_member_withdraw);
          this.loan_disbursed = Number(res.loan_disbursed);
          this.bank_current_dr = Number(res.bank_current_dr);

          this.cff_dr = Number(res.cff_dr);
          this.t_bills_invest = Number(res.t_bills_invest);
          this.other_receipts = Number(res.other_receipts);

          this.dr_sub_total = Number(this.shares_member_withdraw) + Number(this.saving_member_withdraw) + Number(this.loan_disbursed) + Number(this.bank_current_dr) + Number(this.cff_dr) + Number(this.t_bills_invest) + Number(this.other_receipts)

          this.mobile_withdraw = Number(res.mobile_withdraw);
          this.sumRecTotal = Number(this.cr_sub_total) + Number(this.mobile_deposit) + Number(this.totalIncome);
          this.sumPayTotal = Number(this.dr_sub_total) + Number(this.mobile_withdraw) + Number(this.totalExpenses);

          this.end_of_month_cash = this.sumRecTotal - this.sumPayTotal;

          // this.interest_on_loans = res.interest_on_loans;
          // this.interest_on_financial_investments = res.interest_on_financial_investments;
          // this.other_financial_incomes = res.other_financial_incomes;
          // this.non_financial_incomes = res.non_financial_incomes;
          this.incomes = res?.incomes
          this.expenses = res?.expenses
          this.assets = res.assets;
          this.liabilities = res.liabilities;
          this.capital = res.capital;
          this.surplus = Number(this?.total_monthly_income) - Number(this?.total_monthly_expenses);


          // this.total_monthly_income = Number(this.interest_on_loans?.monthly_amount) + Number(this.interest_on_financial_investments?.monthly_amount) + Number(this.other_financial_incomes?.monthly_amount) + Number(this.non_financial_incomes?.monthly_amount)
          // this.total_yearly_income = Number(this.interest_on_loans?.yearly_amount) + Number(this.interest_on_financial_investments?.yearly_amount) + Number(this.other_financial_incomes?.yearly_amount) + Number(this.non_financial_incomes?.yearly_amount);
          // this.cost_of_funds = res.cost_of_funds;
          // this.personal_costs = res.personal_costs;
          // this.occupancys = res.occupancys;
          // this.organizationals = res.organizationals;
          // this.securitys = res.securitys;
          // this.administrations = res.administrations;
          // this.provisions = res.provisions;




          this.total_monthly_expenses = Number(this.cost_of_funds?.monthly_amount) + Number(this.personal_costs?.monthly_amount) + Number(this.occupancys?.monthly_amount) + Number(this.organizationals?.monthly_amount) + Number(this.securitys?.monthly_amount) + Number(this.administrations?.monthly_amount) + Number(this.provisions?.monthly_amount);
          this.total_yearly_expenses = Number(this.cost_of_funds?.yearly_amount) + Number(this.personal_costs?.yearly_amount) + Number(this.occupancys?.yearly_amount) + Number(this.organizationals?.yearly_amount) + Number(this.securitys?.yearly_amount) + Number(this.administrations?.yearly_amount) + Number(this.provisions?.yearly_amount);
          // this.liquid_funds = res.liquid_funds;
          // this.liquid_investments = res.liquid_investments;
          // this.other_investments = res.other_investments;
          // this.loan_to_members = res.loan_to_members;
          // this.current_assets = res.current_assets;
          // this.fixed_assets = res.fixed_assets;
          // this.current_liabilities = res.current_liabilities;
          // this.member_savings = res.member_savings;
          // this.member_shares = res.member_shares;
          // this.reserves = res.reserves;
          // this.bank_currents = res.bank_currents;
        })
        .catch((error) => {
          this.isBusy = false;
          this.search = "Search"
          this.isSearching = false;
          // console.log(error);
        });
    },
    filterAction() {
      this.getAccountStatistics();
    },
    async getProductDropdown() {
      this.isBusy = true;
      this.search = "Processing...";
      await ApiService.query("/reports/controls/products/dropdown")
        .then((response) => {
          this.isBusy = false;
          this.search = "Search";
          this.form.branch_options = response.data.branches;
          this.form.product_options = response.data.products;
          this.form.users_options = response.data.users;
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.search = "Search";
        });
    },
    pdfOptions() {
      return {
        margin: [0.3, 0.2, 0.8, 0.2], // top, left, bottom, right
        filename: "account_statistics.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'css', before: '#balance-sheet' },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      }
    },
    downloadPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      html2pdf()
        .from(content)
        .set(this.pdfOptions())
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
        })
        .save();
    },
    printPDF() {
      const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
      html2pdf()
        .from(content)
        .set(this.pdfOptions())
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var insit_name = this.institution.name;
          var insit_tele = this.institution.telephone;
          var insit_email = this.institution.email;
          var insit_motto = this.institution.motto;

          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(8);
            pdf.setTextColor(150);
            pdf.text(
              [
                "For Inquiries, Please Contact " +
                insit_name +
                ", Customer Service on " +
                insit_tele +
                "",
                "or email us on " + insit_email + " " + insit_motto + "",
                "Page " + i + " of " + totalPages,
              ],
              pdf.internal.pageSize.getWidth() / 2,
              pdf.internal.pageSize.getHeight() - 0.6,
              { align: "center" }
            );
          }
          // Get the blob of the PDF
          return pdf.output("blob");
        })
        .then((pdfBlob) => {
          // Create a URL for the PDF blob
          var pdfUrl = URL.createObjectURL(pdfBlob);

          // Open the URL in a new tab
          const printWindow = window.open(
            pdfUrl,
            "_blank",
            "width=800,height=600"
          );
          printWindow.onload = () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
          };
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    },
    filterTable() {
      this.getAccountStatistics();
    },
    async getInstitution() {
      this.isLoadingInstitution = true;
      await ApiService.get("/settings/institution")
        .then((response) => {
          this.institution = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingInstitution = false;
        });
    },
    cleanNumber(value) {
      // Convert to a string, then remove any extraneous spaces or non-numeric characters
      const cleanedValue = value?.toString().replace(/[^\d.-]/g, '');
      // Parse it into a float and handle NaN cases
      const parsedValue = parseFloat(cleanedValue);

      // Ensure it's a valid number, otherwise default to 0
      return isNaN(parsedValue) ? 0 : parsedValue;
    }
  },
};
</script>
<style>
.list-item {
  page-break-inside: avoid;
}
</style>