<template>
  <div>
    <b-button class="akkurate-green-btn akkurate-small" id="show-btn" @click="showModal"><i
        class="fas fa-plus"></i></b-button>
    <b-modal id="chart-of-acc" ref="coa" size="lg" @shown="visibleModal" @hidden="resetModal" @ok="handleOk">
      <template #modal-title>
        <div class="akkurate-green small fw-bold">Add Subtype</div>
      </template>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="general-error d-flex justify-content-center pt-2 pb-2">
              <errors v-if="form.error_msg">
                {{ form.error_msg }}
              </errors>
            </div>
          </div>
          <!-- COA Head -->
          <div class="col-md-6">
            <b-form-group label-for="coa-head" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="name">Coa Head: <star></star></label>
              </template>
              <b-form-input id="coa-head" class="mt-2 field-container fields" v-model="coa_head.display_name"
                disabled></b-form-input>
            </b-form-group>
          </div>
          <!-- Branch -->
          <div class="col-md-6">
            <b-form-group label-for="branch" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="name">Branch <star></star></label>
              </template>
              <multiselect v-validate="{ required: false }" v-model="form.branch" name="teller_id" track-by="id"
                :state="validateState('branch')" placeholder="Search or select" :options="branches"
                :custom-label="customBranch" class="mt-2 field-container" data-vv-as="BRANCH" required></multiselect>
            </b-form-group>
          </div>
          <!-- Account Cartegory -->
          <div class="col-md-6">
            <b-form-group label-for="branch" class="mb-3" invalid-feedback="Category is a required select"
              :state="validateState('category')">
              <template #label>
                <label class="akkurate-dark" for="name">Account Category <star></star></label>
              </template>
              <div class="">
                <multiselect :disabled="add_or_edit == 'edit'" v-validate="{ required: true }"
                  v-model="form.account_category" name="category" :state="validateState('category')"
                  placeholder="Search or select" :options="account_categories" class="mt-2 field-container"
                  data-vv-as="Cateory" required @select="dispatchAction"></multiselect>
                <errors v-if="form.errors.category">
                  {{ form.errors.category[0] }}
                </errors>
              </div>
            </b-form-group>
          </div>
          <!-- User dropdown visible when account category is user -->
          <div class="col-md-6" v-show="teller_show">
            <b-form-group label-for="users" class="mb-3" invalid-feedback="User is a required select"
              :state="validateState('category')">
              <template #label>
                <label class="akkurate-dark" for="name">Teller</label>
              </template>
              <div class="">
                <multiselect :disabled="add_or_edit == 'edit'" v-validate="{ required: false }" v-model="form.teller_id"
                  name="teller_id" track-by="id" :state="validateState('teller_id')" placeholder="Search or select"
                  :options="teller_options" :custom-label="customLabel" class="mt-2 field-container" data-vv-as="TELLER"
                  required></multiselect>
                <errors v-if="form.errors.teller_id">
                  {{ form.errors.teller_id[0] }}
                </errors>
              </div>
            </b-form-group>
          </div>
          <!-- Product type dropdown visible when account category is product -->
          <div class="col-md-6" v-show="product_show">
            <b-form-group label-for="product" class="mb-3" invalid-feedback="Product is a required select"
              :state="validateState('product')">
              <template #label>
                <label class="akkurate-dark" for="name">Products</label>
              </template>
              <div class="">
                <multiselect :disabled="add_or_edit == 'edit'" v-validate="{ required: false }"
                  v-model="form.product_id" name="product_id" :state="validateState('product_id')"
                  placeholder="Search or select" :options="product_options" :custom-label="customProductLabel"
                  class="mt-2 field-container" data-vv-as="PRODUCT" @select="dispatchProductAction" required>
                </multiselect>
                <errors v-if="form.errors.product_id">
                  {{ form.errors.product_id[0] }}
                </errors>
              </div>
            </b-form-group>
          </div>
          <!-- Loan Product Scheme dropdown visible when account category is product -->
          <div class="col-md-6" v-show="loan_product_scheme_show">
            <b-form-group label-for="loan_product_scheme" class="mb-3"
              invalid-feedback="Loan Product Scheme is a required select" :state="validateState('loan_product_scheme')">
              <template #label>
                <label class="akkurate-dark" for="name">Loan Product Schemes</label>
              </template>
              <div class="">
                <multiselect :disabled="add_or_edit == 'edit'" v-validate="{ required: false }"
                  v-model="form.loan_product_scheme_id" name="loan_product_scheme_id"
                  :state="validateState('loan_product_scheme_id')" placeholder="Search or select"
                  :options="loan_product_scheme_options" :custom-label="customLoanProductSchemeLabel"
                  class="mt-2 field-container" data-vv-as="LOAN_PRODUCT_SCHEME" required></multiselect>
                <errors v-if="form.errors.product_id">
                  {{ form.errors.product_id[0] }}
                </errors>
              </div>
            </b-form-group>
          </div>
          <!-- Fixed Term Deposit Product Scheme dropdown visible when account category is product -->
          <div class="col-md-6" v-show="fixed_term_deposit_product_scheme_show">
            <b-form-group label-for="fixed_term_deposit_product_scheme" class="mb-3"
              invalid-feedback="Fixed Term Deposit Product Scheme is a required select"
              :state="validateState('fixed_term_deposit_product_scheme')">
              <template #label>
                <label class="akkurate-dark" for="name">Fixed Term Deposit Schemes</label>
              </template>
              <div class="">
                <multiselect :disabled="add_or_edit == 'edit'" v-validate="{ required: false }"
                  v-model="form.fixed_term_deposit_product_scheme_id" name="fixed_term_deposit_product_scheme_id"
                  :state="validateState('fixed_term_deposit_product_scheme_id')" placeholder="Search or select"
                  :options="fixed_term_deposit_scheme_options" :custom-label="customLoanProductSchemeLabel"
                  class="mt-2 field-container" data-vv-as="FIXED_TERM_DEPOSIT_PRODUCT_SCHEME" required></multiselect>
                <errors v-if="form.errors.product_id">
                  {{ form.errors.product_id[0] }}
                </errors>
              </div>
            </b-form-group>
          </div>
          <!-- Account Name -->
          <div class="col-md-6">
            <b-form-group label-for="branch" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="acc_name">Account Name <star></star></label>
              </template>
              <b-form-input id="acc_name" name="acc_name" v-validate="{ required: true }"
                :state="validateState('acc_name')" class="mt-2 field-container fields" :class="errorState"
                placeholder="Enter account name" v-model="form.account_name" required
                data-vv-as="Account name"></b-form-input>
              <b-form-invalid-feedback>
                {{ veeErrors.first('acc_name') }}
              </b-form-invalid-feedback>
              <errors v-if="form.errors.account_name">
                {{ form.errors.account_name[0] }}
              </errors>
            </b-form-group>
          </div>
          <!-- Account Number -->
          <div class="col-md-6">
            <b-form-group label-for="branch" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="acc_name">Account Number <star></star></label>
              </template>
              <b-form-input id="acc_name" class="mt-2 field-container fields" v-model="form.account_number"
                disabled></b-form-input>
            </b-form-group>
          </div>

          <!-- Balance -->
          <div class="col-md-6">
            <b-form-group label-for="branch" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="acc_name">Balance</label>
              </template>
              <b-form-input id="balance" class="mt-2 field-container fields" v-model="form.balance"></b-form-input>
            </b-form-group>
          </div>
          <!-- Description -->
          <div class="col-md-12">
            <b-form-group label-for="descripton" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="address">Description <star></star></label>
              </template>
              <b-form-textarea class="mt-2 fields" name="desc" v-validate="{ required: true }"
                :state="validateState('desc')" v-model="form.description" id="textarea-lazy"
                placeholder="Enter Description..." data-vv-as="Description" required lazy-formatter></b-form-textarea>
              <b-form-invalid-feedback>
                {{ veeErrors.first('desc') }}
              </b-form-invalid-feedback>
              <errors v-if="form.errors.description">
                {{ form.errors.description[0] }}
              </errors>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <!-- Increase by -->
          <div class="col-md-6">
            <b-form-group label-for="descripton" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="address">Increase By <star></star></label>
              </template>
              <multiselect disabled v-model="coa_head.headtype.increase_by" name="increase_by"
                placeholder="Search or select" :options="debit_or_credit" class="mt-2 field-container"></multiselect>
            </b-form-group>
          </div>
          <!-- Descrease by -->
          <div class="col-md-6">
            <b-form-group label-for="descripton" class="mb-3">
              <template #label>
                <label class="akkurate-dark" for="address">Decrease By <star></star></label>
              </template>
              <multiselect disabled v-model="coa_head.headtype.decrease_by" name="decrease_by"
                placeholder="Search or select" :options="debit_or_credit" class="mt-2 field-container"></multiselect>
            </b-form-group>
          </div>
        </div>
      </form>
      <template #modal-footer="{ ok }">
        <div class="w-100">
          <!-- begin loader -->
          <beat-loader-component class="float-left" v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
          <!-- end loader -->
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button @click="$bvModal.hide('chart-of-acc')" type="button"
            class="border-0 akkurate-grey-btn btn-size me-2 float-right"><font-awesome-icon class="me-2"
              :icon="['fas', 'close']" />Close</b-button>
          <b-button :disabled="is_pending" @click="ok()" type="submit"
            class="akkurate-green-btn btn-size me-2 float-right"><font-awesome-icon class="me-2"
              :icon="['fas', 'save']" />{{ save }}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
export default {
  props: ["coa_head", "account_types", "branches"],
  components: {
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      form: {
        coa_head: "",
        account_number: "",
        account_name: "",
        description: "",
        account_type: "",
        decrease_by: "",
        increase_by: "",
        teller_id: "",
        product_id: "",
        errors: [],
        balance: 0,
        loan_product_scheme_id: "",
        fixed_term_deposit_product_scheme_id: "",
        error_msg: "",
      },
      save: 'Save',
      is_pending: false,
      teller_show: false,
      product_show: false,
      loan_product_scheme_show: false,
      fixed_term_deposit_product_scheme_show: false,
      fixed_term_deposit_scheme_options: [],
      errorState: "",
      teller_options: [],
      product_options: [],
      loan_product_scheme_options: [],
      // account_types: [],
      account_categories: ["STANDARD", "USER", "PRODUCT"],
      debit_or_credit: ["Debit", "Credit"],
      // branches: [],
      isSaving: false,

      add_or_edit: "add",
    };
  },
  methods: {
    showModal() {
      this.$refs['coa'].show()
    },
    hideModal() {
      this.$refs['coa'].hide()
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetModal() {
      this.form.coa_head = "";
      this.form.account_number = ""
      this.form.account_name = ""
      this.form.description = ""
      this.form.account_type = ""
      this.form.account_category = ""
      this.form.balance = 0
      this.teller_id = "",
        this.product_id = "",
        this.errorState = ""
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }
        this.isSaving = true;
        this.save = 'Processing...';
        //   this.$Progress.start();
        //   this.form
        this.form.errors = [];
        // console.log(this.coa_head);
        // console.log(this.form.coa_head)
        this.form.coa_head = this.coa_head
        ApiService.post("/finance/chart-of-accounts/postCOASubType", this.form)
          .then((response) => {
            this.isSaving = false;
            toast.fire({
              icon: "success",
              title: response.data.message,
            });
            this.form.error_msg = ""

            this.$emit("coa-sub-head-saved", response.data)

            this.$nextTick(() => {
              this.$bvModal.hide('chart-of-acc')
            })

            this.save = 'Save';

            //   window.location.reload();
          })
          .catch((error) => {
            if (error.response.data.message) {
              this.form.error_msg = error.response.data.message;
              this.errorState = 'is-invalid'
            }
            this.isSaving = false;
            this.save = 'Save';
            // console.log(error.response.data)
          });
      });
    },
    tellerDropdown() {
      ApiService.get("/users/dropdown")
        .then((response) => {
          this.teller_options = response.data.users
          this.product_options = response.data.product_type
          this.loan_product_scheme_options = response.data.loan_product_scheme;
          this.fixed_term_deposit_scheme_options = response.data.fixed_term_deposit_scheme

        }).catch((errors) => {
          console.log(errors)
        })

    },
    customLabel({ first_name, surname, id }) {
      return `${first_name}  ${surname} - (${id})`
    },
    customProductLabel({ name, code }) {
      return `${name} - (${code})`
    },
    customLoanProductSchemeLabel({ name, id }) {
      return `${name} - (${id})`
    },
    customBranch({ name, id }) {
      return `${name} - (${id})`
    },
    dispatchAction(event) {
      switch (event) {
        case 'USER':
          this.teller_show = true;
          this.product_show = false;
          this.form.product_id = ""
          break;
        case 'PRODUCT':
          this.product_show = true;
          this.teller_show = false;
          this.form.teller_id = ""
          break
        default:
          this.teller_show = false;
          this.product_show = false;
      }

    },
    dispatchProductAction(event) {
      if (event.code == "1006") {
        this.loan_product_scheme_show = true
      } else {
        this.loan_product_scheme_show = false
        this.form.loan_product_scheme_id = "";
      }
      if (event.code == "1008") {
        this.fixed_term_deposit_product_scheme_show = true
      } else {
        this.fixed_term_deposit_product_scheme_show = false
        this.form.fixed_term_deposit_product_scheme_id = "";
      }
    },
    visibleModal() {
      this.tellerDropdown();

    }
  }
}
</script>